import styled from '@emotion/styled'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { TheNightlyThemeConfig } from '@news-mono/component-library'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'

export const StyledPublicationDate = styled.time(({ theme }) => [
    {
        color: theme.colors.text.secondary,
        textTransform: 'uppercase',
        textDecoration: 'none',
        alignSelf: 'flex-end',
    },
    textMixin<TheNightlyThemeConfig>(theme, 'byline-secondary'),
])

export const StyledPublicationDateWrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
})

export const StyledUpdateLabel = styled.div(({ theme }) => [
    {
        color: theme.colors.text.secondary,
        textTransform: 'uppercase',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        marginRight: calcRem(4),
    },
    textMixin<TheNightlyThemeConfig>(theme, 'byline-secondary'),
])
