import React from 'react'
import {
    CardEvents,
    CollectionEvent,
    PublicationCardItemWithoutVideo,
    useFeature,
} from '@news-mono/web-common'
import { getLatestLiveBlog } from './content-selector-helper'
import {
    LatestLiveBlogMilestones,
    GetLinkWithChildrenComponent,
} from './components/LatestLiveBlogMilestones'

export interface CardContentWithLiveBlogsProps {
    result?: PublicationCardItemWithoutVideo
    isVrTest?: boolean
    DefaultComponent: JSX.Element
    getLinkComponentWithChildren?: GetLinkWithChildrenComponent
    onEvent: (event: CardEvents | CollectionEvent) => void
    hasBackground?: boolean
}
export const CardContentWithLiveBlogs = ({
    isVrTest,
    result,
    getLinkComponentWithChildren,
    DefaultComponent,
    hasBackground = false,
}: CardContentWithLiveBlogsProps): JSX.Element | null => {
    const isLiveBlogTeaserMilestonesEnabled = useFeature(
        'live-blog-milestones-teaser',
    )

    if (!result) {
        return null
    }

    // check the publication kind to see if it's an event and if the toggle is enabled
    const isLiveBlog =
        result.publicationKind === 'event' && isLiveBlogTeaserMilestonesEnabled
    const eventLiveBlogTextLength = 250

    // Not a live blog, so we should just show the default component.
    if (!isLiveBlog) {
        return DefaultComponent
    }

    // Else, now we need to fetch the posts.
    const postsToRetrieve = isLiveBlogTeaserMilestonesEnabled ? 5 : 1
    const latestLiveBlogPosts = getLatestLiveBlog(
        result.entries,
        eventLiveBlogTextLength,
        postsToRetrieve,
    )

    // No post was found, so we probably should just show the teaser as a fallback.
    if (!latestLiveBlogPosts || latestLiveBlogPosts.length === 0) {
        return DefaultComponent
    }

    // Style to show the rows of milestones.
    return (
        <LatestLiveBlogMilestones
            isVrTest={isVrTest}
            posts={latestLiveBlogPosts}
            getLinkComponentWithChildren={getLinkComponentWithChildren}
            hasBackground={hasBackground}
        />
    )
}
