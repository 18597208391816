import { FilterQueryParam } from '../../../result-filters'

export const SORT_FILTER_QUERY_PARAM = 'sort'

export const seatSortItems = [
    'Latest',
    'Alphabetical (descending)',
    'Alphabetical (ascending)',
]

export const sortMethods: FilterQueryParam[] = [
    {
        displayName: 'Latest',
        paramName: 'latest',
    },
    {
        displayName: 'Alphabetical (ascending)',
        paramName: 'a-z',
    },
    {
        displayName: 'Alphabetical (descending)',
        paramName: 'z-a',
    },
]

export type ElectionSortFilterOptions = typeof sortMethods
