/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
    AllEvents,
    CardBreakpointRatios,
    CardItem,
    createCollectionAvailableEvent,
    FixedRatio,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { Fragment, ReactNode } from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    DisplayOnBreakpoint,
    StyledGridItem,
    StyledGridWrapper,
    StyledHeader,
    StyledRomeo,
} from './Romeo.styled'
import {
    ResponsiveContainer,
    ResponsivePictureSizes,
} from '../../content/Picture/responsive'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product/useProduct'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { invertMaybeLoadedItems } from '../helpers/loading'
import {
    PNSectionHeader,
    PNSectionHeaderProps,
} from '../../nextgen-news/perthnow/PNSectionHeader'
import {
    ImageMode,
    HeaderSectionTagType,
    PNArticleCard,
    PNFontScales,
    KickerMode,
    PNArticleCardProps,
} from '../../nextgen-news/perthnow/cards/PNArticleCard'
import { TeaserMode } from '../../cards/CardText/CardTeaser'

type InterlacedComponent = {
    /** The component to interlace. */
    component: (index: number) => ReactNode
    /** After how many cards the first instance of this component appears. Defaults to 1. */
    initialOffset?: number
    /** How many cards between each instance of this component. Defaults to 1. */
    cardsBetween?: number
    /** How many times to interlace this component. Defaults to 1. */
    repetitions?: number
}

export interface RomeoProps extends CommonCardProps, ResponsiveContainer {
    className?: string
    sectionHeader?: PNSectionHeaderProps
    showLargeCard?: boolean
    fixedRatios?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    imageWidths?: ResponsivePictureSizes
    isTimestamped?: boolean
    verticalSpacing?: keyof ThemeMargins
    onEvent: (event: AllEvents) => void
    disableImageLazyLoad?: boolean
    items: MaybeLoaded<CardItem[]>
    expectedCards: number
    noHorizontalDividers?: boolean
    topicLevel?: 'parent'
    hideTopDivider?: boolean
    hideLastDivider?: boolean
    cardSpacing?: number
    hideOnMobile?: boolean
    fontScale?: PNFontScales
    teaserFontScale?: PNFontScales
    headerFontScale?: PNFontScales
    imageMode?: ImageMode
    teaserMode?: TeaserMode
    kickerMode?: KickerMode
    showAuthor?: boolean
    showCardTopic?: boolean
    hideCardFooter?: boolean
    horizontalDividerColor?: string
    displayOnBreakpoint?: DisplayOnBreakpoint
    marginTop?: number
    marginBottom?: number
    headerSectionTag?: HeaderSectionTagType
    centreOnTablet?: boolean
    topBottomDividerColorOverride?: string
    headerFontOverride?: PNArticleCardProps['headerFontOverride']
    imageSizeOverride?: PNArticleCardProps['imageSizeOverride']
    interlacedComponents?: Record<'advert' | string, InterlacedComponent>
    itemClickEventHandler?: (
        e: React.MouseEvent<HTMLElement>,
        item: MaybeLoaded<CardItem>,
        cardNumber: number,
    ) => void
}

export const Romeo: React.FC<RomeoProps> = (props) => {
    const {
        className,
        sectionHeader,
        onEvent,
        expectedCards,
        noHorizontalDividers,
        hideTopDivider,
        hideLastDivider,
        topBottomDividerColorOverride,
        horizontalDividerColor,
        cardSpacing = 24,
        displayOnBreakpoint,
        marginTop,
        marginBottom,
        centreOnTablet,
        interlacedComponents = {},
        itemClickEventHandler,
    } = props
    const product = useProduct()
    const items = invertMaybeLoadedItems(props.items, expectedCards)

    const buildList = () => {
        // Keep track of how many times left to repeat.
        const interlacedRepetitionsRemaining: Record<string, number> = {}

        for (const [id, interlacedComponent] of Object.entries(
            interlacedComponents,
        )) {
            // Display once if no repetitions specified.
            interlacedRepetitionsRemaining[id] =
                interlacedComponent.repetitions ?? 1
        }

        const listResult: ReactNode[] = []

        for (let i = 0; i < items.length; i++) {
            let shouldInsertDivider = true

            // Insert interlaced components.
            for (const [id, interlacedComponent] of Object.entries(
                interlacedComponents,
            )) {
                if (
                    isValidInterlaceIndex(
                        i,
                        interlacedComponent.initialOffset,
                        interlacedComponent.cardsBetween,
                    ) &&
                    interlacedRepetitionsRemaining[id] !== 0
                ) {
                    listResult.push(
                        <Fragment key={`${i}-${id}`}>
                            {interlacedComponent.component(i)}
                        </Fragment>,
                    )
                    interlacedRepetitionsRemaining[id]--
                    // Do not insert a divider if there is an interlaced component.
                    shouldInsertDivider = false
                }
            }

            // Insert card.
            const loadableItem = items[i]

            if (!loadableItem.loaded) {
                return null
            }

            const { result } = loadableItem

            if (result.cardType === 'marketing-redirect-tile') {
                // Do not render marketing redirects.
                continue
            } else {
                listResult.push(
                    <StyledGridItem
                        onClick={(event) =>
                            itemClickEventHandler &&
                            itemClickEventHandler(event, loadableItem, i)
                        }
                        key={i}
                        hideTopDivider={hideTopDivider ?? true}
                        horizontalDividerColor={horizontalDividerColor}
                        noHorizontalDividers={noHorizontalDividers === true}
                        hideLastDivider={hideLastDivider}
                        cardSpacing={cardSpacing}
                        topBottomDividerColorOverride={
                            topBottomDividerColorOverride
                        }
                    >
                        <PNArticleCard
                            key={result.id}
                            item={{ loaded: true, result }}
                            onEvent={props.onEvent}
                            cardNumber={i + 1}
                            cardType={'horizontal'}
                            teaserMode={props.teaserMode || 'hidden'}
                            fontScale={props.fontScale || 'S'}
                            teaserFontScale={
                                props.teaserFontScale || props.fontScale || 'S'
                            }
                            headerFontScale={
                                props.headerFontScale || props.fontScale || 'S'
                            }
                            hideByline={props.hideByline}
                            hideFooter={props.hideCardFooter}
                            fixedRatio={props.fixedRatios || ['4:3', '16:9']}
                            disableImageLazyLoad={props.disableImageLazyLoad}
                            imageMode={props.imageMode}
                            showAuthor={props.showAuthor}
                            showTopic={props.showCardTopic}
                            headerSectionTag={props.headerSectionTag}
                            kickerMode={props.kickerMode}
                            headerFontOverride={props.headerFontOverride}
                            imageSizeOverride={props.imageSizeOverride}
                            isTimestamped={props.isTimestamped}
                        />
                    </StyledGridItem>,
                )
            }
        }

        return listResult
    }

    const numItems = items

    const renderRomeo = () => (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'Romeo',
                        product,
                        onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledRomeo
                    ref={ref}
                    className={className}
                    displayOnBreakpoint={displayOnBreakpoint}
                    hideTopDivider={hideTopDivider ?? true}
                    cardSpacing={cardSpacing}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    centreOnTablet={centreOnTablet}
                >
                    {sectionHeader && items && (
                        <StyledHeader>
                            <PNSectionHeader
                                {...sectionHeader}
                                onEvent={onEvent}
                            />
                        </StyledHeader>
                    )}
                    <StyledGridWrapper>
                        {numItems.length > 0 && buildList()}
                    </StyledGridWrapper>
                </StyledRomeo>
            )}
        </ImpressionAvailable>
    )

    return renderRomeo()
}
Romeo.displayName = 'Romeo'

/** Checks if a 0-indexed position is valid, provided an initial offset and spaces between. */
const isValidInterlaceIndex = (
    index: number,
    initialOffset = 1,
    spotsBetween = 1,
) =>
    // Sanity checks.
    initialOffset > 0 &&
    spotsBetween > 0 &&
    // Interlaced components can only be between cards.
    index !== 0 &&
    // Actual condition. Object.is() distinguishes between -0 and 0
    Object.is((index - initialOffset) % spotsBetween, 0)
