/* eslint-disable no-redeclare */
import {
    CardBreakpointRatios,
    CardItem,
    CollectionEvent,
    FixedRatio,
    isPublicationCardItem,
    Product,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { HeroCard } from '../../../cards/HeroCard/HeroCard'
import { Landscape } from '../../../cards/Landscape/Landscape'
// Themes, Metrics, Events
import {
    getLandscapeCardItemProps,
    LandscapeLayout,
} from '../../../cards/Landscape/Landscape.layouts'
import { LandscapeStacked } from '../../../cards/LandscapeStacked/LandscapeStacked'
import {
    getLandscapeStackedCardItemProps,
    LandscapeStackedLayout,
} from '../../../cards/LandscapeStacked/LandscapeStacked.layouts'
import {
    CardOrientationOptions,
    Portrait,
} from '../../../cards/Portrait/Portrait'
import {
    getCardItemProps,
    LayoutName,
} from '../../../cards/Portrait/Portrait.layouts'
import { StyledPortrait } from '../../../cards/Portrait/Portrait.styled'
import { VideoTrailerCard } from '../../../cards/VideoTrailerCard/VideoTrailerCard'
import { November } from '../../../collections/November/November'
import { TimestampType } from '../../../content/CardTimestamp/CardTimestamp'
import {
    ContainerWidthRatios,
    ResponsivePictureSizes,
} from '../../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../../content/Picture/ResponsivePictureLayouts'
import { CardSwitcher } from '../../../__helpers/CardSwitcher'
import {
    CardOverrideOptions,
    LimaHeroLayout,
    LimaLayout,
    LimaLayouts,
    LimaProps,
    NovemberLayout,
} from '../Lima'
import { StyledGridItem } from '../Lima.styled'

export function isNovemberLayout(layout: LimaLayout): layout is NovemberLayout
export function isNovemberLayout(layout: any) {
    return (
        typeof layout === 'object' &&
        'type' in layout &&
        layout.type === 'november'
    )
}

export function isLandscapeLayout(layout: any) {
    return typeof 'name' in layout && layout.name === 'landscape'
}

function isHeroLayout(
    layout: LimaLayout[] | LimaHeroLayout,
): layout is LimaHeroLayout {
    return 'type' in layout && layout.type === 'hero'
}

function isVideoTrailerCardLayout(layout: LayoutName) {
    return layout === 'video-trailer-card-single'
}

export function calculateLayoutsLength(
    layouts: LimaLayout[] | LimaHeroLayout,
): number {
    if (isHeroLayout(layouts)) {
        return 1
    }

    return layouts.reduce((accumulator, layout) => {
        const addition = isNovemberLayout(layout)
            ? layout.listLength
            : layout.length
        return accumulator + addition
    }, 0)
}

// Switches between Landscape and Landscape Stacked
// ! DEAD FUNCTION.
export function renderLandscapeType(
    layout: LandscapeLayout | LandscapeStackedLayout,
    items: Array<MaybeLoaded<CardItem>>,
    cardNumber: number,
    onEvent: (event: CollectionEvent) => void,
    columnLayoutLength: number,
    disableImageLazyLoad?: boolean,
    timestamp?: TimestampType,
    imageWidths?: ResponsivePictureSizes,
) {
    const item = items.length > columnLayoutLength && items[columnLayoutLength]
    if (!item) {
        return null
    }

    switch (layout.type) {
        case 'landscape':
            return (
                <CardSwitcher
                    key={columnLayoutLength}
                    onEvent={onEvent}
                    item={item}
                    cardContext="lima-landscape"
                    cardNumber={cardNumber}
                    publicationCard={(publicationItem) => (
                        <Landscape
                            timestamp={timestamp}
                            key={columnLayoutLength}
                            disableImageLazyLoad={disableImageLazyLoad}
                            imageWidths={imageWidths}
                            {...getLandscapeCardItemProps(
                                publicationItem,
                                layout,
                                { onEvent },
                                cardNumber,
                            )}
                        />
                    )}
                />
            )

        case 'landscape-stacked':
            return (
                <CardSwitcher
                    key={columnLayoutLength}
                    onEvent={onEvent}
                    item={item}
                    cardContext="lima-landscapestacked"
                    cardNumber={cardNumber}
                    publicationCard={(publicationItem) => (
                        <LandscapeStacked
                            key={columnLayoutLength}
                            timestamp={timestamp}
                            disableImageLazyLoad={disableImageLazyLoad}
                            {...getLandscapeStackedCardItemProps(
                                publicationItem,
                                layout,
                                { onEvent },
                                cardNumber,
                            )}
                        />
                    )}
                />
            )
        default:
            return () => undefined
    }
}

function renderCard(
    layouts: Array<LayoutName | LandscapeLayout | LandscapeStackedLayout>,
    items: Array<MaybeLoaded<CardItem>>,
    prevLayoutsLength: number,
    onEvent: (event: CollectionEvent) => void,
    cardOptionOverrides: CardOverrideOptions,
    containerWidthRatios?: ContainerWidthRatios,
    fixedRatios?: FixedRatio | FixedRatio[] | CardBreakpointRatios,
    hasBackground?: boolean,
    cardOrientation?: CardOrientationOptions | undefined,
    disableImageLazyLoad?: boolean,
    timestamp?: TimestampType,
    imageWidths?: any,
    allowLiveBlogMilestoneTeaser?: boolean,
) {
    return layouts.map(
        (
            layout: LayoutName | LandscapeLayout | LandscapeStackedLayout,
            columnIndex: number,
        ) => {
            const columnLayoutLength =
                calculateLayoutsLength([layouts.slice(0, columnIndex)]) +
                prevLayoutsLength
            const cardNumber = columnLayoutLength + 1

            if (
                !isPortraitCard(layout) ||
                (!isPortraitCard(layout) && !isVideoTrailerCardLayout(layout))
            ) {
                return renderLandscapeType(
                    layout,
                    items,
                    cardNumber,
                    onEvent,
                    columnLayoutLength,
                    disableImageLazyLoad,
                    timestamp,
                    imageWidths,
                )
            }

            if (isVideoTrailerCardLayout(layout)) {
                return (
                    <StyledPortrait key={columnLayoutLength}>
                        <VideoTrailerCard
                            cardNumber={cardNumber}
                            layoutMode="single"
                            onEvent={onEvent}
                        />
                    </StyledPortrait>
                )
            }

            const item =
                items.length > columnLayoutLength && items[columnLayoutLength]
            if (!item) {
                return null
            }

            return (
                <CardSwitcher
                    key={columnLayoutLength}
                    onEvent={onEvent}
                    item={item}
                    cardContext="lima-portrait"
                    cardNumber={cardNumber}
                    publicationCard={(publicationItem) => (
                        <Portrait
                            timestamp={timestamp}
                            data-layout={layout}
                            containerWidthRatios={containerWidthRatios}
                            hasBackground={hasBackground}
                            disableImageLazyLoad={disableImageLazyLoad}
                            fixedRatio={fixedRatios}
                            {...cardOptionOverrides}
                            {...getCardItemProps(
                                publicationItem,
                                layout,
                                {
                                    onEvent,
                                },
                                cardNumber,
                                cardOrientation,
                            )}
                            allowLiveBlogMilestoneTeaser={
                                allowLiveBlogMilestoneTeaser
                            }
                        />
                    )}
                />
            )
        },
    )
}

export function renderCardLayout(
    cardLayout: LimaLayouts,
    cardOverrideOptions: CardOverrideOptions,
    props: LimaProps,
    items: MaybeLoaded<CardItem>[],
    _getImageWidth: (
        itemsCount: number,
        product: Product,
    ) => 500 | 650 | 268 | 320,
    _product: Product,
): React.ReactNode {
    const {
        hasBackground,
        onEvent,
        initialColumns = 1,
        intermediateColumns = 4,
        finalColumns = 4,
        cardOrientation,
        disableImageLazyLoad,
        imageWidths,
        allowLiveBlogMilestoneTeaser,
    } = props

    if (isHeroLayout(cardLayout)) {
        const item: MaybeLoaded<CardItem> =
            props.items.loaded && props.items.result.length > 0
                ? { loaded: true, result: props.items.result[0] }
                : { loaded: false }

        return (
            <StyledGridItem
                numberOfItems={1}
                intermediateColumns={1}
                initialColumns={1}
                finalColumns={1}
            >
                <CardSwitcher
                    onEvent={onEvent}
                    cardNumber={0}
                    item={item}
                    cardContext="lima-hero"
                    publicationCard={(publicationCardItem) => (
                        <HeroCard
                            item={publicationCardItem}
                            fixedRatio={'16:9'}
                            cardNumber={0}
                            onEvent={onEvent}
                            hasBackground
                            teaserMode="visible"
                            imageLayout={
                                ResponsivePictureLayout.ObjectFitContain
                            }
                        />
                    )}
                />
            </StyledGridItem>
        )
    }

    // Typescript is not narrowing properly after isHeroLayout(cardLayout)
    const cardLayoutLimaOnly = cardLayout as LimaLayout[]

    return cardLayoutLimaOnly.map((layout, layoutIndex) => {
        const prevLayouts = cardLayoutLimaOnly.slice(0, layoutIndex)
        const prevLayoutsLength = calculateLayoutsLength(prevLayouts)

        const containerValues = props.containerWidthRatios
            ? props.containerWidthRatios
            : { desktop: 1, tablet: 1, mobile: 1 }

        const column = isNovemberLayout(layout)
            ? renderNovember(props, layout, prevLayoutsLength, onEvent)
            : renderCard(
                  layout,
                  items,
                  prevLayoutsLength,
                  onEvent,
                  cardOverrideOptions,
                  {
                      mobile: (1 / initialColumns) * containerValues.mobile,
                      tablet:
                          (1 / intermediateColumns) * containerValues.tablet,
                      desktop: (1 / finalColumns) * containerValues.desktop,
                  },
                  props.fixedRatios,
                  hasBackground,
                  cardOrientation,
                  disableImageLazyLoad,
                  props.timestamp,
                  imageWidths,
                  allowLiveBlogMilestoneTeaser,
              )
        if (!column) {
            return null
        }

        return (
            <StyledGridItem
                key={layoutIndex}
                numberOfItems={cardLayoutLimaOnly.length}
                intermediateColumns={intermediateColumns}
                initialColumns={initialColumns}
                finalColumns={finalColumns}
            >
                {column}
            </StyledGridItem>
        )
    })
}

function renderNovember(
    props: LimaProps,
    layout: NovemberLayout,
    prevLayoutsLength: number,
    onEvent: (event: CollectionEvent) => void,
): React.ReactNode {
    const itemsSubset: MaybeLoaded<CardItem[]> = props.items.loaded
        ? {
              loaded: true,
              result: props.items.result
                  .slice(
                      prevLayoutsLength,
                      prevLayoutsLength + layout.listLength,
                  )
                  // November doesn't support non-publication cards
                  // so just filter them. If they end up in a november
                  // index that is probably a bug anyways
                  .filter(isPublicationCardItem),
          }
        : { loaded: false }

    if (doesNotMeetMinimumCards(itemsSubset, layout)) {
        return null
    }

    return (
        <November
            hasBackground
            disableImpressionEvent={true}
            cardNumberOffset={prevLayoutsLength}
            sectionHeader={layout.sectionHeader}
            onEvent={onEvent}
            timestamp={layout.timestamp}
            noStretch={layout.noStretch}
            expectedCards={layout.listLength}
            items={itemsSubset}
        />
    )
}

function doesNotMeetMinimumCards(
    itemsSubset: MaybeLoaded<CardItem[]>,
    layout: NovemberLayout,
) {
    return (
        itemsSubset.loaded &&
        layout.minimumCards !== undefined &&
        itemsSubset.result.length < layout.minimumCards
    )
}

// TODO - when card layouts are updated to be the same we should be able to get rid of this and simplfy the checking
function isPortraitCard(
    layout: LayoutName | LandscapeLayout | LandscapeStackedLayout,
): layout is LayoutName {
    return (
        typeof layout === 'string' ||
        !(
            ('type' in layout && layout.type === 'landscape') ||
            layout.type === 'landscape-stacked'
        )
    )
}
