import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { CardText } from '../../../cards/CardText/CardText'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { WebLink, WebLinkProps, createPropFilter } from '@news-mono/web-common'

export interface StyledInlineContentCardProps {
    isSideBarCmp?: boolean
    numberOfCards?: number
}

export const StyledInlineContentCard = styled('div', {
    shouldForwardProp: createPropFilter<StyledInlineContentCardProps>()([
        'isSideBarCmp',
        'numberOfCards',
    ]),
})<StyledInlineContentCardProps>(({ isSideBarCmp, numberOfCards }) => [
    {
        display: 'grid',
        gridTemplateColumns: '3fr 2fr',
        columnGap: calcRem(15),
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: '0.7',
        },
        [breakpoint('sm')]: {
            gridTemplateColumns:
                numberOfCards && numberOfCards > 1 ? '3fr 2fr' : '8fr 2fr',
        },
    },
    isSideBarCmp
        ? {
              marginBottom: calcRem(20),
              position: 'relative',
              '&::after': {
                  position: 'absolute',
                  content: `''`,
                  height: calcRem(1),
                  width: calcRem(40),
                  background: colors.black10,
                  bottom: 0,
              },
          }
        : {
              padding: calcRem(10),
              border: `${calcRem(1)} solid ${
                  tokens.sevennews.colors.palette.articlesV2.cardsBorder
              }`,
          },
])

export const StyledTextContainer = styled('div')(() => [
    {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    {
        '& [class*="StyledHeadline"]': {
            fontWeight: 500,
            fontSize: calcRem(15),
            lineHeight: calcRem(19),
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            overflow: 'hidden',
        },
    },
])

export const StyledCardImageContainer = styled(
    'div',
)<StyledInlineContentCardProps>(({ isSideBarCmp }) => [
    {
        '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]': {
            top: 'unset',
            left: 'unset',
            bottom: calcRem(10),
            right: calcRem(10),
            width: calcRem(16),
            height: calcRem(16),
            border: `${calcRem(1.92)} solid ${colors.white}`,
            zIndex: 0,
            '&::before': {
                left: '42%',
                top: '79%',
                backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
            },
        },
        '& [class*="StyledMediaIndicator"][class*="media_indicator_gallery_icon"]':
            {
                border: 'none',
                '&::before': {
                    backgroundSize: 'contain',
                    width: '100%',
                    height: '100%',
                },
            },
    },
])

export const StyledText = styled(CardText)(({}) => [])

export const StyledTimeToRead = styled('p')(() => [
    {
        color: colors.sevennews.nero30,
        fontSize: calcRem(12),
    },
])

export const StyledCardImage = styled(CardMedia)(({}) => [{}])

export const StyledLinkWrapper = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([]),
})(({}) => [
    {
        textDecoration: 'none',
    },
])

export const StyledCardListItemWrapper = styled('li')({
    listStyle: 'none',
})
