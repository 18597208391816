import React from 'react'
import { PNVideoProps, StyledPNVideoIcon } from './IconPNVideo.styled'

export interface IconPNVideoProps {
    title?: string
}

export const IconPNVideo: React.FC<IconPNVideoProps & PNVideoProps> = ({
    className,
    title,
    width = 24,
    height = 16,
    horizontalSpacing,
}) => (
    <StyledPNVideoIcon
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        horizontalSpacing={horizontalSpacing}
    >
        <title>{title || 'Video Icon'}</title>
        <path
            d="M23 7L16 12L23 17V7Z"
            stroke="#666869"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
            stroke="#666869"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </StyledPNVideoIcon>
)
IconPNVideo.displayName = 'IconPNVideo'
