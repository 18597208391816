import {
    ElectionDefinition,
    LoadedElectionDefinition,
} from '../election/electionTypes'
import { UseQueryOptions } from '@tanstack/react-query'
import { getElectionData } from './get-election-data'
import { consoleLogger } from 'typescript-log'

type BuildElectionDefinitionQueryArgs = {
    initialDefinition: ElectionDefinition
    electionAPI: string
    caller: string
}

export const buildElectionDefinitionQuery = ({
    initialDefinition,
    electionAPI,
    caller,
}: BuildElectionDefinitionQueryArgs): UseQueryOptions<
    LoadedElectionDefinition,
    unknown,
    LoadedElectionDefinition,
    ['election-api', string, 'definition']
> => {
    const dataFrequency = initialDefinition.electionData
        ? (initialDefinition.electionData.config?.electionApi.frequency ?? 1) *
          60 *
          1000
        : 60 * 1000

    return {
        queryKey: ['election-api', initialDefinition.electionId, 'definition'],
        queryFn: async (): Promise<LoadedElectionDefinition> => {
            const electionData = await getElectionData(
                consoleLogger(),
                electionAPI,
                caller,
                initialDefinition.electionId,
            )
            return {
                ...initialDefinition,
                electionData,
            }
        },
        // Only provide initial data if it's loaded.
        ...(initialDefinition.electionData
            ? {
                  initialData: initialDefinition,
              }
            : {}),
        // Do not refetch on initial render. Wait a single dataFrequency interval.
        staleTime: dataFrequency,
        // Refetch every dataFrequency interval.
        refetchInterval: dataFrequency,
        refetchOnWindowFocus: 'always',
    }
}
