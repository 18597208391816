import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { isTabletOrMobileViewport, metrics } from '@news-mono/component-library'
import {
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../../App.routing'
import { breakingNewsComponent } from '../../shared-components/ad-and-breaking-news'
import { box } from '../../shared-components/compositions'
import { mobileAdThree, mobileAdTwo } from '../../shared-components/mobile-ads'

interface ContentArgs {
    topic: Topic
    promoCardName: string
    getAdTargeting: GetRouteAdTargeting
    features: TogglesReduxState
}

export const getV2PodcastPage = ({
    topic,
    promoCardName,
    getAdTargeting,
    features,
}: ContentArgs): PageType<'default'> => {
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        'default',
        topic,
    )

    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const isV2LeaderBoardBilbordToggleEnabled = isFeatureEnabled(
        toFeatureState(features),
        '7-news-2-top-leaderboard-ad',
    )
    const isElectionHeaderWidgetEnabled = isFeatureEnabled(
        toFeatureState(features),
        '7-news-wa-election-widgets',
    )

    const content: CheckedComponentInformation[] = [
        breakingNewsComponent,
        ...(isElectionHeaderWidgetEnabled
            ? [
                  layout.component({
                      type: 'election-head-to-head-seat-count-widget',
                      props: {
                          electionDefinition: {
                              electionId: 'wa-election-2025',
                              electionData: undefined,
                              logo: {
                                  mobile: '',
                              },
                              meta: {},
                          },
                      },
                  }),
              ]
            : []),
        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['unset', 'unset'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'home-page-hero-collection',
                        props: {
                            onEvent: () => {},
                            adUnitPath: adTargeting.adUnitPath,
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'Podcasts',
                                offset: 0,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'six-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'Podcasts',
                                offset: 5,
                                pageSize: 6,
                            },
                        },
                    }),
                ],
            },
        }),
        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['lg', 'lg'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'above-center',
                            hiddenUntilLoaded: false,
                            slot: {
                                id: 'leaderboard-billboard-two',
                                size: 'leaderboardBillboard',
                            },
                            adType: 'inline',
                        },
                    }),
                    mobileAdTwo,
                ],
            },
        }),

        layout.component({
            type: 'horizontal-divider',
            props: {},
        }),
        layout.component({
            type: 'promo-cards-seven',
            props: {
                verticalGutters: ['lg', 'lg'],
                dataDefinitionArgs: {
                    type: 'curation',
                    name: promoCardName,
                    offset: 0,
                    pageSize: 3,
                },
            },
        }),
        layout.component({
            type: 'horizontal-divider',
            props: {},
        }),
        layout.component({
            type: 'horizontal-pill-menu',
            props: {
                data: {
                    type: 'single',
                    name: 'More Podcasts',
                },
            },
        }),
        layout.component({
            type: 'five-card-collection',
            props: {
                verticalSpacing: 'lg',
                verticalGutters: ['unset', 'md'],
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: [topic.id],
                    paging: {
                        page: 0,
                        pageSize: 5,
                    },
                },
            },
        }),

        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['lg', 'lg'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'above-center',
                            hiddenUntilLoaded: false,
                            slot: {
                                id: 'leaderboard-billboard-three',
                                size: 'leaderboardBillboard',
                            },
                            adType: 'inline',
                        },
                    }),
                    mobileAdThree,
                ],
            },
        }),
        layout.component({
            type: 'horizontal-divider',
            props: {},
        }),
        layout.component({
            type: 'promo-cards-seven',
            props: {
                verticalGutters: ['lg', 'lg'],
                dataDefinitionArgs: {
                    type: 'curation',
                    name: promoCardName,
                    offset: 3,
                    pageSize: 3,
                },
            },
        }),
    ]

    const pageComposition = layout.composition({
        type: 'box',
        props: {
            verticalGutters: ['unset', 'unset'],
            horizontalGutters: 'md',
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            breakpoints: {
                sm: {
                    horizontalGutters: 'lg',
                },
                md: {
                    horizontalGutters: 'xl',
                },
            },
        },
        contentAreas: {
            main: [
                ...content,
                layout.component({
                    type: 'taboola',
                    props: {
                        taboolaContainerId: 'taboola-below-category-thumbnails',
                        locationType: 'category',
                        isFourColumns: isTabletOrMobileViewport()
                            ? false
                            : true,
                    },
                }),
            ],
        },
    })

    return {
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        pageMeta: {
            title: topic.seoTitle,
            description: topic.seoDescription,
            meta: [
                {
                    property: 'og:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:title',
                    content:
                        `${topic.seoTitle} | 7NEWS` || `${topic.title} | 7NEWS`,
                },
                {
                    property: 'twitter:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
            ],
        },
        adTargeting,
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'page-header-v2',
                            props: {
                                sectionHeader: {
                                    heading: topic.title,
                                },
                                topic: topic,
                            },
                        }),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {
                    id: '7newsHeaderAd',
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition(
                            box({
                                main: [
                                    ...(isV2LeaderBoardBilbordToggleEnabled
                                        ? []
                                        : [
                                              layout.component({
                                                  type: 'ad-unit',
                                                  props: {
                                                      noticePosition:
                                                          'above-center',
                                                      hiddenUntilLoaded: false,
                                                      preventCollapse: true,
                                                      padding: [
                                                          metrics.sevennews
                                                              .margins.lg,
                                                          0,
                                                          metrics.sevennews
                                                              .margins.sm,
                                                          0,
                                                      ],
                                                      slot: {
                                                          id: 'leaderboard-billboard-one',
                                                          size: 'leaderboardBillboard',
                                                      },
                                                      adType: 'inline',
                                                  },
                                              }),
                                          ]),
                                ],
                            }),
                        ),
                    ],
                },
            }),
            pageComposition,
        ],
    }
}
