import { datadogLogs } from '@datadog/browser-logs'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { BaseClientConfig } from '@news-mono/web-common'
import { TogglesReduxState } from './feature-togglings'

// defaults to a 'failover' category on the Token
// site is derived from docs https://docs.datadoghq.com/getting_started/site/

export function datadogInitialise(
    config: BaseClientConfig,
    toggles: TogglesReduxState,
) {
    if (isFeatureEnabled(toFeatureState(toggles), 'datadog-client', false)) {
        datadogLogs.init({
            clientToken:
                config.datadogToken ?? 'pubbaa717e27c4dd0c66d8bd39cab4dd429',
            site: 'datadoghq.com',
            forwardErrorsToLogs: true,
            forwardConsoleLogs: ['error', 'warn', 'info'],
            sessionSampleRate: 100,
            service: config.publicHostname,
            silentMultipleInit: true,
            trackSessionAcrossSubdomains: true,
            useSecureSessionCookie: true,
            useCrossSiteSessionCookie: true,
        })
    }
}
