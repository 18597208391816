import {
    Block_TextV4DTO,
    EventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { compareDesc, subHours } from 'date-fns'
import { LiveBlogEntry } from '@news-mono/web-common'

export type LatestLiveBlogContent = {
    postId: string
    title: string
    text: string
    link: string
    publishedDate: string
    isPinned?: true
}

export const getLatestLiveBlog = (
    liveBlogEntry?: LiveBlogEntry,
    expectedTextLength?: number,
    amountToSelect?: number,
): LatestLiveBlogContent[] | undefined => {
    const textLength = expectedTextLength ?? 250
    const latestPostHoursLimit = 4

    if (!liveBlogEntry) return undefined

    const latestContent = selectLatestContent(
        liveBlogEntry.milestones,
        liveBlogEntry.stickies,
        liveBlogEntry.documents,
        textLength,
        latestPostHoursLimit,
        amountToSelect,
    )

    if (!latestContent) return undefined

    // Now map all the provided data into an appropriate format
    return latestContent.map((content): LatestLiveBlogContent => {
        let text = ''

        // Find the first text block, and set the text to that
        content.content.blocks
            .filter((block) => block.kind === 'text')
            .map((block) => block as Block_TextV4DTO)
            .find((block) => (text = block.text))

        // take the first text block
        const blogText = (content.content.blocks[0] as Block_TextV4DTO).text

        return {
            title: content.title || '',
            text: blogText,
            publishedDate: content.publishedDate,
            link: content._self,
            postId: content.id,
            isPinned: content.sticky === true || undefined,
        }
    })
}

const selectLatestContent = (
    milestones: EventPostV4DTO[],
    stickies: EventPostV4DTO[],
    posts: EventPostV4DTO[],
    textLength: number,
    latestPostHoursLimit: number,
    amountToSelect = 1,
): EventPostV4DTO[] | null => {
    // lol
    if (amountToSelect < 1) {
        return null
    }

    const hasValidTextBlock = (item: EventPostV4DTO): boolean => {
        return item.content.blocks.some(
            (block) => block.kind === 'text' && block.text.trim() !== '',
        )
    }

    // Filter items to only include those with kind = 'text' and non-empty text
    const filterValidItems = (items: EventPostV4DTO[]) =>
        items.filter(hasValidTextBlock)

    const filteredMilestones = filterValidItems(milestones)
    const filteredStickies = filterValidItems(stickies)
    const filteredPosts = filterValidItems(posts)

    // If it's attempting to select multiple posts for the display in the new live blog
    // styling, then we need to return an array of the selected posts
    if (amountToSelect === 1) {
        return selectSingleLatestContent(
            filteredMilestones,
            filteredStickies,
            filteredPosts,
            textLength,
            latestPostHoursLimit,
        )
    } else {
        return selectMultipleLatestContent(
            filteredMilestones,
            filteredStickies,
            filteredPosts,
            textLength,
            latestPostHoursLimit,
            amountToSelect,
        )
    }
}

const selectSingleLatestContent = (
    filteredMilestones: EventPostV4DTO[],
    filteredStickies: EventPostV4DTO[],
    filteredPosts: EventPostV4DTO[],
    textLength: number,
    latestPostHoursLimit: number,
): EventPostV4DTO[] | null => {
    // Select the latest milestone post
    if (filteredMilestones.length > 0) {
        const selectedItem = filteredMilestones[0]
        selectedItem.content.blocks = selectedItem.content.blocks.map(
            (block) => {
                if (block.kind === 'text') {
                    block.text = truncateText(block.text, textLength)
                }
                return block
            },
        )
        return [selectedItem]
    } else {
        // If none, Select the recent post, posted within the last latestPostHoursLimit hours
        const now = new Date()
        const latestPostTimeThreshold = subHours(now, latestPostHoursLimit)
        const recentPosts = filteredPosts.filter(
            (post) => new Date(post.publishedDate) > latestPostTimeThreshold,
        )

        if (recentPosts.length > 0) {
            const selectedItem = recentPosts[0]
            selectedItem.content.blocks = selectedItem.content.blocks.map(
                (block) => {
                    if (block.kind === 'text') {
                        block.text = truncateText(block.text, textLength)
                    }
                    return block
                },
            )
            return [selectedItem]
        } else {
            // If no recent posts, select the latest sticky post
            if (filteredStickies.length > 0) {
                const selectedItem = filteredStickies[0]
                selectedItem.content.blocks = selectedItem.content.blocks.map(
                    (block) => {
                        if (block.kind === 'text') {
                            block.text = truncateText(block.text, textLength)
                        }
                        return block
                    },
                )
                return [selectedItem]
            } else if (filteredPosts.length > 0) {
                const selectedItem = filteredPosts[0]
                selectedItem.content.blocks = selectedItem.content.blocks.map(
                    (block) => {
                        if (block.kind === 'text') {
                            block.text = truncateText(block.text, textLength)
                        }
                        return block
                    },
                )
                return [selectedItem]
            }
        }
    }

    return null
}

const selectMultipleLatestContent = (
    filteredMilestones: EventPostV4DTO[],
    filteredStickies: EventPostV4DTO[],
    filteredPosts: EventPostV4DTO[],
    textLength: number,
    latestPostHoursLimit: number,
    amountToSelect: number,
): EventPostV4DTO[] | null => {
    const sort: ((a: EventPostV4DTO, b: EventPostV4DTO) => number) | undefined =
        (a, b) =>
            compareDesc(new Date(a.publishedDate), new Date(b.publishedDate))

    // Sort all posts by the published date
    const recentMilestones = filteredMilestones.sort(sort)
    const recentStickies = filteredStickies.sort(sort)

    const recentPosts = [
        ...(recentStickies.length > 0 ? [recentStickies[0]] : []),
        ...recentMilestones,
    ].slice(0, amountToSelect)

    // If there's no milestones or pinned posts, then we want to just treat it like normal
    if (recentPosts.length === 0) {
        return selectLatestContent(
            filteredMilestones,
            filteredStickies,
            filteredPosts,
            textLength,
            latestPostHoursLimit,
            1,
        )
    }

    // apply text truncation
    recentPosts.forEach(
        (selectedItem) =>
            (selectedItem.content.blocks = selectedItem.content.blocks.map(
                (block) => {
                    if (block.kind === 'text') {
                        block.text = truncateText(block.text, textLength)
                    }
                    return block
                },
            )),
    )

    return recentPosts
}

const truncateText = (text: string, maxLength: number): string => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}\u2026` // horizontal ellipsis unicode
    }
    return text
}
