import React from 'react'
import { useOptaWidgetStart } from '../../cricket-match-centre/useOptaWidgetStart'
import { AflFixturesWidgetContainer } from './AflFixturesWidget.styled'

export interface AflFixturesWidgetProps {
    competitionId?: string
    isAflw?: boolean
}
export const AflFixturesWidget = ({
    isAflw,
    competitionId,
}: AflFixturesWidgetProps) => {
    useOptaWidgetStart()
    return (
        <AflFixturesWidgetContainer>
            <opta-widget
                widget="fixtures"
                template="normal"
                competition={
                    competitionId ? competitionId : isAflw ? '12527' : '12726'
                }
                season={isAflw ? '2024' : '2025'}
                show_venue="true"
                match_status="all"
                grouping="round"
                show_grouping="true"
                navigation="tabs_more"
                default_nav="1"
                show_date_picker="true"
                start_on_current="true"
                sub_grouping="date"
                show_subgrouping="true"
                order_by="date_ascending"
                away_team_first="false"
                show_crests="true"
                date_format="ll"
                time_format="HH:mm"
                month_date_format="MMMM"
                show_logo="false"
                show_title="false"
                breakpoints="400,768"
                sport="afl"
                match_link={`/sport/${isAflw ? 'aflw' : 'afl'}/match-centre`}
                image_size="medium"
                pre_match="true"
            ></opta-widget>
        </AflFixturesWidgetContainer>
    )
}
