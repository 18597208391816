import { getClientConfig } from '../client-config'
import { CommonServerConfig } from '../common-server-config'

export const testSiteName = 'Test Masthead'
export const testSiteServerConfig: CommonServerConfig = {
    appName: 'neutral',
    facebookAppId: '',
    facebookClientToken: '',
    akamaiImageManagerPolicy: 'test_v1',
    allowRobots: true,
    baseAdUnitPath: 'TEST_AD_PATH',
    baseAdUnits: ['TEST', 'AD', 'PATH'],
    externalContentApi: 'https://content.testsite.com',
    internalContentApi: 'https://internal-content.testsite.com',
    contentfulStaticSiteEndpoint: '',
    firehoseTopicName: '',
    userPollServiceEndpoint: '',
    brightcoveAccountId: 'TEST_BC_ACCOUNT_1234',
    featureStateFile: './features.json',
    ampGtmId: 'TESTAMPGTM',
    gtmId: 'TESTGTM',
    ampgGaFourMeasureId: 'TESTMEASUREID',
    gtmTrackingId: 'TESTTRACKINGID',
    hostnameRedirectsEnabled: false,
    iasClientId: 1231241,
    launchDarklyClientId: undefined,
    launchDarklyClientTimeout: 60,
    launchDarklySdkKey: undefined,
    logToConsoleInClient: false,
    nielsenAmpApid: 'NIELSEN_AMP_ID',
    nielsenApn: 'NIELSEN_APN',
    nielsenMarkAsTestEnv: true,
    nielsenSiteName: 'Test site',
    oovvuuPath: 'OOVVUU',
    oovvuuUrl: 'https://oovvuu.com',
    prebidAppNexusId: 1234,
    prebidDebug: false,
    prebidIxId: 1234,
    publicHostname: 'testsite.com',
    publicUrl: 'https://testsite.com',
    sectionDomainsRedirect: false,
    snowPlowAppId: 'snowplow_id',
    snowPlowAppName: 'snowplow_testsite',
    ssrTimeoutMs: 500,
    videoServiceApikey: 'video-service-api-key',
    videoServiceUrl: 'https://videoservice.snol.com',
    apiCallerHeader: 'test-site',
    adRefreshValues: {
        maximumAdRefreshes: 5,
        adRefreshRateMs: 15000,
        stopAdRefreshFromClick: true,
        pauseAdRefreshWhileHovering: true,
    },
    sectionAdPathMapping: {
        default: 'TEST',
    },
    cricketWidgetLink: 'https://cricket.widgets/widget',
    logLevel: 'debug',
    requestLogLevel: 'debug',
    contentSharingPartnerPublicKey: `-----BEGIN PUBLIC KEY-----
MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAN1UBrFiVSk+2BNuqvIwLFrvWX/Y9Z9c
+0UYo2C5urBrkDryf3haazymS6E7BJljJxcrmYHd5I6Hx5QKzRd7e90CAwEAAQ==
-----END PUBLIC KEY-----`,
    chartbeatId: 4032010,
    emailQueueUrl: 'http://testsite:4100',
    userIdHashSalt: 'a3vNjZuTAZWLiJPvYqq5',
    nielsenApids: {
        default: 'NIELSEN_ID',
    },
    emailServiceApi: 'http://localhost:5700',
    emailServiceApiKey: 'CCci6CBp_bLewGJqxz19fdk7WsPNtyIxsCeGq447qD',
    noAuthEmailVerifyDecryptKey: 'WUgPk3IfAyTJzyyvmTP5Qm5hQxcvcWPT',
    datadogClientToken: 'pubbaa717e27c4dd0c66d8bd39cab4dd429',
    salesforceBeacon: {
        default: '',
    },
    electionApi: 'https://elections.testsite.com',
}

// NOTE: This does call a getClientConfig call on the client, however it's not
//       utilised on the client and can be ignored!
export const testSiteClientConfig = getClientConfig(
    testSiteServerConfig,
    false,
    undefined,
    undefined,
)
