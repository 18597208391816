import { useQuery } from '@tanstack/react-query'

interface ElectionNeedleData {
    needlePosition: number
}

const fetchElectionNeedleData =
    (apiEndpoint: string) => async (): Promise<ElectionNeedleData> => {
        if (!fetchElectionNeedleData.hasInitialLoad) {
            await new Promise((resolve) => setTimeout(resolve, 1500))
            fetchElectionNeedleData.hasInitialLoad = true
        }

        const response = await fetch(
            `${apiEndpoint}/election-needle/election-needle.json`,
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            throw new Error('Failed to fetch election data')
        }

        // Ensure the response has the required shape
        const data = await response.json()
        if (typeof data.needlePosition !== 'number') {
            throw new Error('Invalid election data format')
        }

        return data
    }

fetchElectionNeedleData.hasInitialLoad = false

export const useElectionNeedleData = (apiEndpoint: string) => {
    return useQuery<ElectionNeedleData, Error>(
        ['election-data'],
        fetchElectionNeedleData(apiEndpoint),
        {
            refetchInterval: 24000,
            staleTime: 24000,
            cacheTime: 28000,
            retry: 2,
            keepPreviousData: true,
        },
    )
}

export type ElectionDataQueryResult = ReturnType<typeof useElectionNeedleData>
