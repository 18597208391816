import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem } from '../../../../__styling/style-functions'
import { IconChevronDownTN } from '../../../../icons/IconChevronDownTN/IconChevronDownTN'
import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../mixins'

export const PNTopicAccordionContainer = styled.nav<{ isTopicPage?: boolean }>(
    ({ isTopicPage }) => {
        return {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: 0,
            marginBottom: isTopicPage
                ? metricsPN.spacing['2XS']
                : metricsPN.spacing['L'],
        }
    },
)

export const AccordionWrapper = styled.div(() => {
    return {
        display: 'flex',
        justifyContent: 'flex-start',
    }
})

export const TopicChevronAccordion = styled.button({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(metricsPN.spacing['4XS']),
    padding: 0,
    transition: 'padding 0.25s',
    flexWrap: 'wrap',
    cursor: 'pointer',

    // Remove button default styling
    background: 'none',
    border: 'none',

    // Focus style
    '&:focus-visible': {
        outline: 'revert',
        outlineOffset: calcRem(metricsPN.spacing['5XS']),
        borderRadius: metricsPN.radius['S'],
    },
})

export const TopicContainer = styled.div(() => {
    return {
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(metricsPN.spacing['2XS']),
        flexWrap: 'wrap',
    }
})

export const ChevronContainer = styled.span(() => {
    return {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
    }
})

export const StyledChevron = styled(IconChevronDownTN)<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            width: calcRem(24),
            height: calcRem(24),
            display: 'flex',
            color: colorsPN.text.brand,

            rotate: `${isOpen ? -180 : 0}deg`,

            transition: 'rotate 0.25s',
        }
    },
)

export const StyledTopic = styled('span')<{ isOpen: boolean }>(
    ({ isOpen = false }) => {
        return [
            {
                color: isOpen ? colorsPN.text.brand : colorsPN.text.secondary,
                textDecoration: 'none',
                textTransform: 'uppercase',
                ...perthNowNGNTextMixin.label['M'].medium,
            },
        ]
    },
)

export const TopicDivider = styled.div(() => {
    return {
        width: calcRem(1),
        height: calcRem(16),
        backgroundColor: colorsPN.stroke.strong,
    }
})

export const SecondaryTopicsWrapper = styled.div<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            display: 'grid',
            gridTemplateRows: `${isOpen ? 1 : 0}fr`,
            marginTop: isOpen ? metricsPN.spacing['4XS'] : 0,

            transition: 'grid-template-rows 0.25s, margin 0.25s',
        }
    },
)

export const SecondaryTopicsContainer = styled.ul<{ isOpen: boolean }>(
    ({ isOpen }) => {
        return {
            // Allows the container to collapse.
            minHeight: 0,

            display: 'flex',
            paddingLeft: 0,
            margin: 0,
            listStyle: 'none',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: metricsPN.spacing['4XS'],
            overflow: isOpen ? 'visible' : 'hidden',
        }
    },
)

interface TopicItemProps {
    isCurrent?: boolean
}
export const SecondaryTopicsItem = styled.li<TopicItemProps>(
    ({ isCurrent }) => {
        return [
            {
                cursor: 'pointer',
                color: colorsPN.text.primary,
                padding: calcRem(metricsPN.spacing['3XS'], 0),
            },
            isCurrent
                ? {
                      ...perthNowNGNTextMixin.label.M.semibold,
                  }
                : { ...perthNowNGNTextMixin.label.M.regular },
        ]
    },
)

export const StyledLink = styled(WebLink)(({ theme }) => {
    return {
        color: colorsPN.text.primary,
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },

        // Focus style
        '&:focus-visible': {
            outline: 'revert',
            borderRadius: metricsPN.radius['S'],
            outlineOffset: '2px',
        },
    }
})
