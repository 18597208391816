import { useTheme } from '@emotion/react'
import {
    AllEvents,
    Block,
    DataLayerEventName,
    ImageSourceWithCaptions,
    Profile,
    RequiredAccess,
} from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import { Logger } from 'typescript-log'
import { StyledButton } from '../../buttons/Button/Button.styled'
import { GalleryCaption } from '../../content/Gallery/components/GalleryCaption'
import {
    GalleryImages,
    numPerPage,
} from '../../content/Gallery/components/GalleryImages'
import { GalleryThumbnails } from '../../content/Gallery/components/GalleryThumbnails'
import {
    GalleryStyledSharing,
    StyledButtonCenterContainer,
    StyledGallery,
    StyledGalleryContentWrapper,
    StyledHeroCaptionContainer,
    StyledHeroWrap,
    StyledIcon,
    StyledShowMoreButton,
    StyledTitle,
    StyledTitleBar,
} from '../../content/Gallery/Gallery.styled'
import { Skeleton } from '../../content/Skeleton/Skeleton'
import { ArticleBlockContent } from '../../publication/ArticleBlockContent/ArticleBlockContent'
import { StyledFigcaption } from '../../publication/ArticleImage/ArticleImage.styled'
import { Byline } from '../../publication/Byline/Byline'
import {
    StyledNestedGrid,
    TheWestStyledSharing,
} from '../../templates/Publication/TheWestPublication/TheWestPublication.styled'
import { smoothScrollTo } from '../../__helpers/smoothscroll'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { ResponsivePictureLayout } from '../Picture'
import { ResponsivePicture } from '../Picture/Picture'
import { PicturePlaceholder } from '../Picture/PicturePlaceholder'
import { GalleryThumbnailsPagination } from './components/GalleryThumbnailsPagination'

export interface CommonGalleryProps {
    onEvent: (
        event: // | PlayerEvent
        // | LinkClickedEvent
        // | CardEvents
        // | CollectionEvent
        // | GalleryEvent
        AllEvents,
    ) => void
    className?: string
    adFrequency?: number
    // We can't know how many ads to init until we are loaded
    // because that is based on the number of images
    ads?: React.ReactElement<any>[]
    adUnitPath: string
    log: Logger
}

export interface GalleryLoadingProps extends CommonGalleryProps {
    loading: true
}

export interface GalleryLoadedProps extends CommonGalleryProps {
    loading?: false
    title: string
    mainImage: ImageSourceWithCaptions | undefined
    images: ImageSourceWithCaptions[]
    shareUrl: string
    byline?: string
    profile?: Profile[]
    publicationDate: string
    primaryTopic?: any
    lastUpdated?: string
    source: string
    requiredAccess: RequiredAccess
    entitled: boolean
    blocks: Block[]
}

export type GalleryProps = GalleryLoadingProps | GalleryLoadedProps

export const Gallery: React.FC<GalleryProps> = (props) => {
    const [showThumbnails, setShowThumbnails] = useState(false)
    const [totalLoaded, setTotalLoaded] = useState(() => {
        if (props.loading || props.images.length > 0) {
            return numPerPage
        }
        return 0
    })
    const theme = useTheme()

    function loadMoreImages(numToLoad = numPerPage) {
        // If we are loading, assume we will get enough to fill the first page
        // TODO This could hurt viewability if an ad is loaded, then we remove it due to
        // less images in gallery?
        if (props.loading) {
            setTotalLoaded(numToLoad)
        } else if (totalLoaded < props.images.length) {
            setTotalLoaded(totalLoaded + numToLoad)
        }
    }

    useEffect(
        () => {
            if (props.loading) return

            setTotalLoaded(Math.min(props.images.length, numPerPage))
        },
        // Ignore props.images.length changing, the value is only relevant when props.loading becomes true
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.loading],
    )

    function toggleThumbnails() {
        if (showThumbnails) {
            props.onEvent({
                type: DataLayerEventName.galleryThumbnailsShow,
                originator: 'Gallery',
                payload: {},
            })
        } else {
            props.onEvent({
                type: DataLayerEventName.galleryThumbnailsHide,
                originator: 'Gallery',
                payload: {},
            })
        }
        setShowThumbnails(!showThumbnails)
    }

    async function scrollTo(index: number) {
        if (index + 1 > totalLoaded) {
            await loadMoreImages(index + 1 - totalLoaded)
        }
        const targetElement = document.querySelector(`#image-${index}`)

        if (!targetElement) {
            return
        }

        smoothScrollTo(targetElement)
    }

    function showMoreClicked() {
        props.onEvent({
            type: DataLayerEventName.galleryLoadMore,
            originator: 'Gallery',
            payload: {},
        })
        loadMoreImages()
    }

    function renderLoadMore() {
        if (theme.kind === 'sevennews') {
            return (
                !props.loading &&
                totalLoaded < props.images.length && (
                    <StyledButtonCenterContainer>
                        <StyledShowMoreButton
                            type="button"
                            onClick={showMoreClicked}
                        >
                            Show More
                        </StyledShowMoreButton>
                    </StyledButtonCenterContainer>
                )
            )
        }

        return (
            !props.loading &&
            totalLoaded < props.images.length && (
                <StyledShowMoreButton type="button" onClick={showMoreClicked}>
                    Show More
                </StyledShowMoreButton>
            )
        )
    }

    const { className, onEvent } = props

    const mainImage = props.loading ? undefined : props.mainImage
    const caption = props.loading ? (
        <StyledHeroCaptionContainer>
            <Skeleton count={2} />
        </StyledHeroCaptionContainer>
    ) : (
        mainImage && (
            <React.Fragment>
                {theme.kind === 'perthnow' && (
                    <StyledHeroCaptionContainer>
                        <GalleryCaption
                            captionText={mainImage.captionText}
                            credit={mainImage.credit}
                            byline={mainImage.byline}
                        />
                        <StyledButton
                            style={{ marginBottom: calcRem(10) }}
                            type="button"
                            onClick={toggleThumbnails}
                        >
                            {showThumbnails
                                ? 'Hide Thumbnails'
                                : 'Show Thumbnails'}
                        </StyledButton>
                    </StyledHeroCaptionContainer>
                )}

                {theme.kind === 'thewest' && (
                    <StyledFigcaption
                        byline={mainImage.byline}
                        captionText={mainImage.captionText}
                        credit={mainImage.credit}
                        kind="image"
                        imageRenderType="gallery"
                    />
                )}
            </React.Fragment>
        )
    )
    const overlayImage = !props.loading && props.mainImage !== undefined
    // If there is a 16:9 we want to use that, but sometimes the wire feeds don't have proper crops, so we fallback to original so it displays an image
    const fixedRatioValue =
        !props.loading && props.mainImage && props.mainImage.crops['16:9']
            ? '16:9'
            : 'original'
    const desktopWidth = themedValue(theme, {
        thewest: 1224,
        perthnow: 984,
        sevennews: 650,
        fallback: 1224,
    })

    return (
        <StyledGallery className={className}>
            {theme.kind !== 'sevennews' && (
                <StyledHeroWrap>
                    {props.loading ? (
                        <PicturePlaceholder
                            fixedRatio="16:9"
                            image={undefined}
                        />
                    ) : props.mainImage ? (
                        <ResponsivePicture
                            image={props.mainImage}
                            imageWidths={{
                                mobile: '100vw',
                                tablet: '100vw',
                                desktop: `${desktopWidth}px`,
                                fallbackWidth: desktopWidth,
                            }}
                            fixedRatio={fixedRatioValue}
                            layout={ResponsivePictureLayout.ObjectFitContain}
                        />
                    ) : null}

                    {theme.kind === 'perthnow' && !props.loading && (
                        <StyledTitleBar
                            entitled={props.entitled}
                            overlayImage={overlayImage}
                        >
                            <StyledTitle>
                                <StyledIcon />
                                {props.loading ? <Skeleton /> : props.title}
                            </StyledTitle>
                            {!props.loading && (
                                <GalleryStyledSharing
                                    url={props.shareUrl}
                                    text={props.title}
                                    onEvent={onEvent}
                                    shareOptions={[
                                        'facebook',
                                        'twitter',
                                        'email',
                                        'clipboard',
                                    ]}
                                />
                            )}
                        </StyledTitleBar>
                    )}
                </StyledHeroWrap>
            )}

            {(theme.kind === 'thewest' || theme.kind === 'sevennews') && (
                <StyledHeroWrap>{caption}</StyledHeroWrap>
            )}

            {theme.kind === 'thewest' && !props.loading && props.entitled && (
                <StyledButton
                    buttonWidth="gallery"
                    type="button"
                    onClick={toggleThumbnails}
                >
                    {showThumbnails ? 'Hide Thumbnails' : 'Show Thumbnails'}
                </StyledButton>
            )}

            {theme.kind === 'sevennews' && !props.loading && props.entitled && (
                <StyledButtonCenterContainer>
                    <StyledButton
                        buttonWidth="gallery"
                        type="button"
                        onClick={toggleThumbnails}
                    >
                        {showThumbnails ? 'Hide thumbnails' : 'Show thumbnails'}
                    </StyledButton>
                </StyledButtonCenterContainer>
            )}

            {theme.kind === 'thewest' &&
                showThumbnails &&
                !props.loading &&
                props.entitled && (
                    <GalleryThumbnails
                        images={props.images}
                        onEvent={onEvent}
                        scrollTo={scrollTo}
                        toggleThumbnails={toggleThumbnails}
                    />
                )}
            {theme.kind === 'sevennews' &&
                showThumbnails &&
                !props.loading &&
                props.entitled && (
                    <GalleryThumbnailsPagination
                        images={props.images}
                        onEvent={onEvent}
                        scrollTo={scrollTo}
                        toggleThumbnails={toggleThumbnails}
                    />
                )}
            {theme.kind === 'thewest' && (
                <StyledGalleryContentWrapper>
                    <StyledNestedGrid>
                        <aside>
                            {!props.loading && (
                                <TheWestStyledSharing
                                    url={props.shareUrl}
                                    text={props.title}
                                    onEvent={onEvent}
                                    shareOptions={[
                                        'facebook',
                                        'twitter',
                                        'email',
                                        'clipboard',
                                    ]}
                                />
                            )}
                        </aside>
                        <div>
                            <React.Fragment>
                                <StyledTitle>
                                    {props.loading ? <Skeleton /> : props.title}
                                </StyledTitle>
                            </React.Fragment>

                            {!props.loading && (
                                <Byline
                                    profile={props.profile}
                                    text={props.byline}
                                    timestamp={props.publicationDate}
                                    source={props.source}
                                    isFullWidth={true}
                                    url={props.shareUrl}
                                    title={props.title}
                                    requiredAccess={props.requiredAccess}
                                    allowCommenting={false}
                                    noLine={true}
                                />
                            )}
                            {!props.loading && (
                                <ArticleBlockContent
                                    blocks={props.blocks}
                                    onEvent={props.onEvent}
                                    log={props.log}
                                    adUnitPath={props.adUnitPath}
                                    publicationKind={'gallery'}
                                />
                            )}
                        </div>
                    </StyledNestedGrid>
                </StyledGalleryContentWrapper>
            )}

            {theme.kind === 'perthnow' && (
                <StyledGalleryContentWrapper>
                    <StyledNestedGrid hideSocialLinks>
                        {caption}
                        {showThumbnails && !props.loading && props.entitled && (
                            <GalleryThumbnails
                                images={props.images}
                                onEvent={onEvent}
                                scrollTo={scrollTo}
                                toggleThumbnails={toggleThumbnails}
                            />
                        )}
                        {!props.loading && (
                            <Byline
                                profile={props.profile}
                                text={props.byline}
                                timestamp={props.publicationDate}
                                source={props.source}
                                isFullWidth={true}
                                url={props.shareUrl}
                                title={props.title}
                                requiredAccess={props.requiredAccess}
                                allowCommenting={false}
                                noLine={true}
                            />
                        )}
                        {!props.loading && (
                            <ArticleBlockContent
                                blocks={props.blocks}
                                onEvent={props.onEvent}
                                log={props.log}
                                adUnitPath={props.adUnitPath}
                                publicationKind={'gallery'}
                            />
                        )}
                    </StyledNestedGrid>
                </StyledGalleryContentWrapper>
            )}
            <GalleryImages
                loading={props.loading}
                totalLoaded={totalLoaded}
                images={!props.loading ? props.images : []}
                adFrequency={props.adFrequency}
                ads={props.ads}
                themeKind={theme.kind}
            />
            {renderLoadMore()}
        </StyledGallery>
    )
}
Gallery.displayName = 'Gallery'
