import styled from '@emotion/styled'
import React from 'react'
import {
    getComponentTheme,
    breakpointSwitch,
    breakpoint,
    calcRem,
} from '../../../__styling'

export interface ElectionTitleProps {
    children?: React.ReactNode
    as?: React.ElementType
}

export const ElectionTitle = ({ children, as = 'h2' }: ElectionTitleProps) => (
    <StyledElectionTitle as={as}>{children}</StyledElectionTitle>
)

// Container object, handles the background colours and the hover, & onClick!
export const StyledElectionTitle: React.FC<{ as?: React.ElementType }> = styled(
    'h2',
)(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return [
        {
            margin: 0,
            fontSize: calcRem(32),
            [breakpoint('sm')]: {
                fontSize: calcRem(40),
            },
        },
        electionTheme.title &&
            breakpointSwitch(electionTheme.title, (styles) => styles),
    ]
})
