import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import {
    CardMedia,
    CardMediaProps,
} from '../../../../cards/CardMedia/CardMedia'
import { breakpoint } from '../../../../__styling/style-functions/breakpoint'
import { calcRem, perthNowNGNTextMixin } from '@news-mono/component-library'
import { PNArticleCardTheme } from '../PNCard.Theme'
import {
    CardType,
    ImageMode,
    PNArticleCardProps,
    PNFontScales,
} from './PNArticleCard'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { StyledPNVideoPlayButton } from '../../buttons/PNVideoPlayButton.styled'

const { background, stroke, media, text, fill } = colorsPN

/**
 * Returns the styles for the PNArticleCard component based on the card type and image mode.
 *
 * @param cardType - The type of the card ('vertical' or 'horizontal').
 * @param imageMode - The mode of the image ('right', 'left', or any other value).
 * @returns An object containing the grid template columns styles for the PNArticleCard component.
 */

const getArticleCardStyles = (
    cardType: CardType,
    imageMode: ImageMode,
    fontScale: PNFontScales,
    imageSizeOverride?: PNArticleCardProps['imageSizeOverride'],
    isTimestamped?: boolean,
) => {
    switch (cardType) {
        case 'vertical':
            return {
                gridTemplateColumns: '1fr',
                gridTemplateRows: 'auto auto',
            }
        case 'horizontal':
            if (imageMode === 'none') {
                return {
                    gridTemplateColumns: '1fr',
                }
            }
            return {
                gridTemplateColumns:
                    PNArticleCardTheme[imageSizeOverride?.mobile || 'XS']
                        .horizontalGridImageColumns?.imageMode[imageMode],
                [breakpoint('sm')]: {
                    gridTemplateColumns:
                        PNArticleCardTheme?.[
                            imageSizeOverride?.tablet || fontScale
                        ]?.horizontalGridImageColumns?.imageMode[imageMode],
                },
                [breakpoint('lg')]: {
                    gridTemplateColumns:
                        PNArticleCardTheme?.[
                            imageSizeOverride?.desktop || fontScale
                        ]?.horizontalGridImageColumns?.imageMode[imageMode],
                    gridColumnGap: isTimestamped
                        ? calcRem(metricsPN.spacing['L'])
                        : undefined,
                },
            }
        default:
            return {}
    }
}

/**
 * StyledArticleCard component styles.
 *
 * @param {CardType} data-card-type - The type of the card.
 * @param {ImageMode} data-image-mode - The mode of the image.
 *
 * @returns {object} - The styled article card styles.
 */

export interface StyledArticleCardProps {
    'data-card-type'?: CardType
    'data-image-mode'?: ImageMode
    'data-font-scale'?: PNFontScales
    imageSizeOverride?: PNArticleCardProps['headerFontOverride']
    isTimestamped?: boolean
}

export const StyledArticleCard = styled.article<StyledArticleCardProps>(
    (props: StyledArticleCardProps) => {
        const {
            'data-card-type': cardType,
            'data-image-mode': imageMode,
            'data-font-scale': fontScale,
            isTimestamped,
        } = props
        return {
            background: 'transparent',
            display: 'grid',
            alignItems: 'start',
            margin: '0 auto',
            width: '100%',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto',
            justifySelf: 'flex-start',
            alignSelf: 'start',
            ...getArticleCardStyles(
                cardType ?? 'horizontal',
                imageMode ?? 'right',
                fontScale ?? 'M',
                props.imageSizeOverride,
                isTimestamped,
            ),
        }
    },
)
const getAnchorStyles = (
    cardType: CardType,
    imageMode: ImageMode,
    isTimestamped?: boolean,
) => {
    switch (cardType) {
        case 'vertical':
            return {
                gridColumn: '1',
                gridRow: '1',
                borderRadius: `${metricsPN.radius['M']}px`,
            }
        case 'horizontal':
            return {
                gridColumn: imageMode === 'right' ? '2/3' : '1/2',
                gridRow: isTimestamped ? '1/3' : '1/2',
                div: {
                    maxWidth: calcRem(194),
                    borderRadius: `${metricsPN.radius['S']}px`,
                },
            }

        default:
            return {}
    }
}

/**
 * StyledAnchor component.
 *
 * @remarks
 * This component is used to style an anchor element.
 *
 * @param props - The component props.
 * @param props.data-card-type - The type of the card.
 * @param props.data-image-mode - The image mode.
 * @returns The styled anchor element.
 */
export const StyledAnchor = styled(WebLink)(
    (props: {
        'data-card-type'?: CardType
        'data-image-mode'?: ImageMode
        isTimestamped?: boolean
    }) => {
        const {
            'data-card-type': cardType = 'horizontal',
            'data-image-mode': imageMode = 'right',
            isTimestamped = false,
        } = props
        return {
            position: 'relative',
            display: 'inline-block',
            alignSelf: 'auto',
            // Focus style
            '&:focus-visible': {
                outline: 'revert',
                borderRadius:
                    cardType === 'vertical'
                        ? `${metricsPN.radius['M']}px`
                        : `${metricsPN.radius['S']}px`,
            },
            ...getAnchorStyles(cardType, imageMode, isTimestamped),
        }
    },
)

export const StyledCardMedia = styled(CardMedia)<CardMediaProps>(
    ({ imageWidths }) => ({
        flex: `1 3 ${calcRem(106)}`,
        height: 'fit-content',
        borderRadius:
            imageWidths.fallbackWidth >= 300
                ? `${metricsPN.radius['M']}px`
                : `${metricsPN.radius['S']}px`,
        margin: `0 auto`,
        span: {
            backgroundColor: fill.weak,
        },
        // Hover style
        '&:hover': {
            span: {
                backgroundColor: background.brand.strong,
            },
        },
        '&:hover::after': {
            backgroundColor: media.hover,
        },
        [`&:hover ${StyledPNVideoPlayButton}`]: {
            background: colorsPN.background.brand.strong,
        },
        // Focus style
        '&:focus-visible': {
            outline: 'revert',
        },
        // Active style
        '&:active': {
            span: {
                backgroundColor: background.brand.weak,
            },
        },
    }),
)

export const StyledTeaser = styled.p(() => ({
    color: text.primary,
    margin: 0,
}))

export const StyledTeaserAnchor = styled(WebLink)(
    (props: {
        'data-card-type'?: CardType
        'data-font-scale'?: PNFontScales
        isTimestamped?: boolean
    }) => {
        const {
            'data-card-type': cardType = 'vertical',
            'data-font-scale': fontScale = 'M',
            isTimestamped = false,
        } = props
        return {
            borderRadius: metricsPN.radius['S'],
            textDecoration: 'none',
            marginTop: calcRem(metricsPN.spacing['XS']),
            ...(cardType === 'horizontal' && {
                gridColumn: '1 / 3',
                gridRow: '2',
            }),
            p: {
                ...PNArticleCardTheme?.[fontScale]?.teaser,
            },
            // Focus style
            '&:focus-visible': {
                outline: 'revert',
            },
            ...(isTimestamped && {
                display: 'none',

                [breakpoint('lg')]: {
                    display: 'block',
                    gridColumn: cardType === 'horizontal' ? '1 / 2' : '1/3',
                },
            }),
        }
    },
)

export const StyledTimestampedWrapper = styled.div(() => {
    return {
        background: 'transparent',
        display: 'grid',
        alignItems: 'start',
        margin: '0 auto',
        width: '100%',
        gridTemplateColumns: '1fr',

        [breakpoint('lg')]: {
            gridTemplateColumns: 'minmax(auto, 150px) 1fr',
        },
    }
})

export const StyledTimestamp = styled.p<{ isDesktop: boolean }>(
    ({ isDesktop }) => ({
        color: text.primary,
        margin: isDesktop ? 0 : calcRem(metricsPN.spacing['2XS'], 0, 0, 0),
        paddingRight: calcRem(metricsPN.spacing['XL']),
        display: isDesktop ? 'none' : 'block',
        ...(isDesktop
            ? perthNowNGNTextMixin.body['L'].regular
            : perthNowNGNTextMixin.label['S'].regular),

        [breakpoint('lg')]: {
            display: isDesktop ? 'block' : 'none',
        },
    }),
)
