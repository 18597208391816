import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledWidgetContainer = styled('div')({
    width: '100%',
    maxWidth: calcRem(600),
    margin: '0 auto',
    marginBottom: calcRem(18),
})

export const StyledWidgetInnerContainer = styled('div')({
    position: 'relative',
    width: '100%',
    paddingBottom: '50%',
})

export const StyledMeterBackgroundImage = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
})

export const StyledNeedleContainer = styled('div')({
    position: 'absolute',
    left: '50%',
    bottom: '1%',
    transformOrigin: 'center calc(100% - 28%)', // Moves needle rotation point along Y-axis
    transition: 'transform 0.2s ease-out',
    width: '5%',
    minWidth: '15px',
    maxWidth: '30px',
})

export const StyledNeedleImage = styled('img')({
    width: '100%',
    height: 'auto',
    display: 'block',
})

export const StyledLoadingContainer = styled('div')({
    width: '100%',
    height: calcRem(300),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

export const StyledSpinner = styled('div')({
    width: '3rem',
    height: '3rem',
    border: `0.25rem solid ${tokens.sevennews.colors.palette.red}`,
    borderTopColor: 'transparent',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
        to: {
            transform: 'rotate(360deg)',
        },
    },
})

export const StyledErrorContainer = styled('div')({
    width: '100%',
    padding: '1rem',
    marginBottom: '1rem',
    backgroundColor: tokens.sevennews.colors.palette.AFL.lightRed,
    color: tokens.sevennews.colors.palette.red,
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
})

export const StyledErrorText = styled('span')({
    marginLeft: '0.5rem',
})
