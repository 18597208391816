import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { MapOverview } from './MapOverviewWidget'

type MapOverviewWidgetRouteProps = {
    electionDefinition: ElectionDefinition
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
    descriptionText?: string
    hasSidebar?: boolean
}
export const MapOverviewWidgetRegistration = createRegisterableComponent(
    'election-map-overview-widget',
    (props: MapOverviewWidgetRouteProps, services) => {
        return (
            <MapOverview
                electionDefinition={props.electionDefinition}
                onEvent={services.onEvent}
                headingLevel={props.headingLevel}
                titleText={props.titleText}
                descriptionText={props.descriptionText}
                hasSidebar={props.hasSidebar}
            />
        )
    },
)
