import { AllEvents } from '@news-mono/web-common'
import React from 'react'
import { Logger } from 'typescript-log'
import { LiveEventPost } from '.'
import { StyledPinnedPosts } from './LiveEventPinnedPosts.styled'
import { TruncatingContainer } from './utility/TruncatingContainer'
import { LiveEventPostV2 } from '../../../SevenNewsV2/LiveEventPostV2/LiveEventPostV2'
import { useProduct } from '../../../__product'
import { EventPostV4DTO } from '@west-australian-newspapers/publication-types'

export interface PinnedPostsProps {
    eventTitle: string
    pinned: EventPostV4DTO[]
    log: Logger
    adUnitPath: string
    onEvent: (event: AllEvents) => void
}

export const LiveEventPinnedPosts: React.FC<PinnedPostsProps> = ({
    eventTitle,
    pinned,
    log,
    adUnitPath,
    onEvent,
}) => {
    const product = useProduct()
    return (
        <StyledPinnedPosts>
            {pinned.map((post) => (
                <TruncatingContainer
                    key={`pinned-${post.id}`}
                    customStyle={'pinned-post'}
                >
                    {product === 'sevennews' ? (
                        <LiveEventPostV2
                            post={post}
                            eventTitle={eventTitle}
                            log={log}
                            adUnitPath={adUnitPath}
                            onEvent={onEvent}
                            renderIDAnchorTag={false}
                        />
                    ) : (
                        <LiveEventPost
                            post={post}
                            eventTitle={eventTitle}
                            log={log}
                            adUnitPath={adUnitPath}
                            onEvent={onEvent}
                            renderIDAnchorTag={false}
                            isPinnedPost={true}
                        />
                    )}
                </TruncatingContainer>
            ))}
        </StyledPinnedPosts>
    )
}
