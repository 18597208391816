import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { metricsPN } from '@news-mono/design-tokens'
import { breakpoint } from '../../../../__styling'
import { insetMarginDesktop } from '../../../../templates/Publication/PerthNowPublication/PNPublicationNGN.styled'

export const pnArticleVideoClassName = 'pn-article-video'

export interface StyledPNArticleVideoProps {
    isHeroVideo?: boolean
    mediaMode?: string
}

const { spacing } = metricsPN

export const StyledPNArticleVideo = styled('figure')<StyledPNArticleVideoProps>(
    ({ isHeroVideo }) => ({
        flexGrow: 1,
        clear: 'both',
        marginTop: isHeroVideo ? 0 : calcRem(spacing['L']),
        marginBottom: isHeroVideo ? 0 : calcRem(spacing['L']),
        marginLeft: calcRem(-spacing['S']),
        marginRight: calcRem(-spacing['S']),
        pointerEvents: 'auto',

        [breakpoint('md')]: {
            marginLeft: calcRem(-spacing['L']),
            marginRight: calcRem(-spacing['L']),
        },

        [breakpoint('lg')]: {
            marginLeft: isHeroVideo ? 0 : insetMarginDesktop,
            marginRight: isHeroVideo ? 0 : insetMarginDesktop,
        },
    }),
)

export const StyledPNArticleVideoContainer = styled(
    'div',
)<StyledPNArticleVideoProps>(({ theme, isHeroVideo, mediaMode }) => ({}))
