import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { HeadToHeadSeatCountWidget } from './HeadToHeadSeatCountWidget'

export interface HeadToHeadSeatCountWidgetRouteProps {
    electionDefinition: ElectionDefinition
    showPredictedSeats?: boolean
}

export const HeadToHeadSeatCountWidgetRegistration =
    createRegisterableComponent(
        'election-head-to-head-seat-count-widget',
        (props: HeadToHeadSeatCountWidgetRouteProps, services) => {
            return (
                <HeadToHeadSeatCountWidget
                    onEvent={services.onEvent}
                    {...props}
                />
            )
        },
    )
