export const getTwitterOnClick = (url: string, text: string) => () => {
    const windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes'
    const width = 550
    const height = 420
    const winHeight = screen.height
    const winWidth = screen.width

    const left = Math.round(winWidth / 2 - width / 2)
    let top = 0

    if (winHeight > height) {
        top = Math.round(winHeight / 2 - height / 2)
    }

    const twitterWindow = window.open(
        '',
        'intent',
        `${windowOptions},width=${width},height=${height},left=${left},top=${top}`,
    )
    if (twitterWindow) {
        twitterWindow.opener = null // noopener equivalent

        const queryUrl = `?url=${encodeURIComponent(url)}`
        const queryText = text ? `&text=${encodeURIComponent(text)}` : ''

        twitterWindow.location.href = `https://x.com/intent/tweet${queryUrl}${queryText}`
    }
}
