import {
    BreakpointRatios,
    FixedRatio,
    ImageSource,
    RenderTargetContext,
} from '@news-mono/web-common'
import { LinkDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import {
    applyContainerRatio,
    ResponsiveContainer,
} from '../../../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../../../content/Picture/ResponsivePictureLayouts'
import {
    FigcaptionLayout,
    FigCaptionProps,
} from '../../../../publication/FigCaption/FigCaption'
import { createRenderTarget } from '../../../../render-target'
import { FetchPriority } from '../../../../content/Picture/Image.web.types'
import {
    StyledPNArticleImage,
    StyledPNFigure,
    StyledPNImageLink,
} from './PNArticleImage.styled'
import { StyledPNFigcaption } from '../PNFigCaption/PNFigCaption.styled'

export type FigcaptionRenderOptions = 'hidden' | 'feature-article-position'

export interface PNArticleImageProps extends ResponsiveContainer {
    align?: 'left' | 'right'
    isTwin?: true
    image?: ImageSource
    imageRenderType?: 'feature'
    figCaption?: FigCaptionProps
    isHeroImage?: boolean
    hideCaption?: boolean
    className?: string
    editButtons?: React.ReactElement<any>
    figcaptionRenderOptions?: FigcaptionRenderOptions
    link?: LinkDTO | undefined
    imageFetchPriority?: FetchPriority
    isHeroImageAmp?: boolean
}

const hiddenCaptionRegex = /^\s*(Promo|Logos?|Call To Action)\s*:\s*/i

function isHiddenCaption(captionText?: string) {
    return captionText?.match(hiddenCaptionRegex)
}

const shouldShowFigcaption = (
    figCaption?: FigCaptionProps,
    figcaptionRenderOptions?: FigcaptionRenderOptions,
): figcaptionRenderOptions is FigcaptionLayout | undefined => {
    if (!figCaption || figcaptionRenderOptions === 'hidden') {
        return false
    }

    const { captionText, credit, byline } = figCaption

    if (isHiddenCaption(captionText)) {
        return false
    }

    if (captionText || credit || byline) {
        return true
    }

    return false
}

const PNWebArticleImage: React.FC<PNArticleImageProps> = (props) => {
    const {
        align,
        image,
        isTwin,
        figCaption,
        isHeroImage,
        imageFetchPriority,
        className,
        figcaptionRenderOptions,
        imageRenderType,
        containerWidthRatios,
        link,
        isHeroImageAmp,
        ...extraProps
    } = props
    const imageLayout =
        imageRenderType === 'feature'
            ? ResponsivePictureLayout.ObjectFitCover
            : ResponsivePictureLayout.ObjectFitContain
    const { renderTarget } = React.useContext(RenderTargetContext)

    // if the render type is a feature we add a breakpoint ratio fallback of 4:3, otherwise existing
    // logic is followed
    const fixedRatioOrBreakpointRatio: FixedRatio | BreakpointRatios =
        isHeroImage
            ? imageRenderType === 'feature'
                ? { default: '16:9', sm: '4:3' }
                : '16:9'
            : image?.userSelectedCrop || 'original'

    const articleImage = (
        <StyledPNArticleImage
            isHeroImage={isHeroImage}
            isHeroImageAmp={isHeroImageAmp}
            image={image}
            imageFetchPriority={imageFetchPriority}
            layout={imageLayout}
            fixedRatio={fixedRatioOrBreakpointRatio}
            imageWidths={applyContainerRatio(containerWidthRatios, {
                mobile: '100vw',
                tablet: '100vw',
                desktop: '668px',
                fallbackWidth: 668,
            })}
            disableLazyLoad={true}
            imageRenderType={imageRenderType}
            renderPicture={(picture) => {
                const captionedPicture = (
                    <React.Fragment>
                        {picture}
                        {shouldShowFigcaption(
                            figCaption,
                            figcaptionRenderOptions,
                        ) && (
                            <StyledPNFigcaption
                                isHero={isHeroImage}
                                shouldIndent={false}
                                {...figCaption}
                            />
                        )}
                    </React.Fragment>
                )

                return (
                    <StyledPNFigure
                        isHero={isHeroImage}
                        align={align}
                        isTwin={isTwin}
                        className={className}
                        aria-labelledby={
                            figcaptionRenderOptions === 'hidden'
                                ? 'media-caption'
                                : undefined
                        }
                        imageRenderType={imageRenderType}
                        // For draftjs to handle clicks etc
                        {...extraProps}
                    >
                        {link && renderTarget !== 'rss' ? (
                            <StyledPNImageLink
                                to={link.href}
                                title={link.title}
                                openExternalInNewWindow={
                                    link.target === '_blank'
                                }
                            >
                                {captionedPicture}
                            </StyledPNImageLink>
                        ) : (
                            captionedPicture
                        )}
                    </StyledPNFigure>
                )
            }}
        />
    )
    return articleImage
}
PNWebArticleImage.displayName = 'PNWebArticleImage'

export const PNArticleImage = createRenderTarget<PNArticleImageProps>(
    'PNArticleImage',
    {
        web: PNWebArticleImage,
        amp: PNWebArticleImage,
        rss: PNWebArticleImage,
        preview: PNWebArticleImage,
        app: PNWebArticleImage,
    },
)
