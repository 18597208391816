import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../../__styling/style-mixins'
import { perthNowNGNTextMixin } from '../../../../../mixins'
import { StyledPNParagraph } from '../PNParagraph/PNParagraph.styled'

export const styledPNListClassName = 'PNList'

export const StyledPNBaseList = styled('ol')(({ as = 'ol' }) => ({
    ...breakWords,
    ...perthNowNGNTextMixin.body.L.regular,
    clear: 'both',
}))

export const StyledPNListItem = styled('li')(() => ({
    marginLeft: calcRem(metricsPN.spacing['L']),
    '&::marker': {
        color: colorsPN.text.brand,
    },

    '&:before': {
        margin: calcRem(0, metricsPN.spacing['2XS']),
    },
}))
StyledPNListItem.displayName = 'StyledPNListItem'

export const StyledPNTextListItem = styled(
    StyledPNListItem.withComponent(StyledPNParagraph),
)({
    margin: calcRem(0, 0, 0, metricsPN.spacing['L']),
})
