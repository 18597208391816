import React, { OlHTMLAttributes } from 'react'
import { AllEvents, ListBlock } from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import { GetVideoQueue } from '../../../../../content/Video/PlayerInterface'
import { PNListItem } from './PNListItem'
import { StyledPNBaseList } from './PNList.styled'
import { getTypeMapping } from '../../../../../typography/List/List'

interface PNListProps {
    list: ListBlock
    onEvent: (event: AllEvents) => void
    adUnitPath: string
    getVideoQueue: GetVideoQueue | undefined
    showInlineVideoTitles: boolean | undefined
    publicationKind?: string | undefined
    publicationPathname: string | undefined
    log: Logger
}

export const PNList: React.FC<PNListProps> = (props) => {
    if (props.list.kind === 'ordered-list') {
        return (
            <StyledPNBaseList
                type={getTypeMapping(props.list.type)}
                start={
                    props.list.start
                        ? parseInt(props.list.start, 10)
                        : undefined
                }
            >
                {props.list.items.map((item, index) => {
                    return (
                        <PNListItem
                            key={index}
                            blocks={item.blocks}
                            log={props.log}
                            adUnitPath={props.adUnitPath}
                            getVideoQueue={props.getVideoQueue}
                            onEvent={props.onEvent}
                            publicationPathname={props.publicationPathname}
                            showInlineVideoTitles={props.showInlineVideoTitles}
                        />
                    )
                })}
            </StyledPNBaseList>
        )
    }
    return (
        <StyledPNBaseList as={'ul'}>
            {props.list.items.map((item, index) => {
                return (
                    <PNListItem
                        key={index}
                        blocks={item.blocks}
                        log={props.log}
                        adUnitPath={props.adUnitPath}
                        getVideoQueue={props.getVideoQueue}
                        onEvent={props.onEvent}
                        publicationKind={props.publicationKind}
                        publicationPathname={props.publicationPathname}
                        showInlineVideoTitles={props.showInlineVideoTitles}
                    />
                )
            })}
        </StyledPNBaseList>
    )
}
PNList.displayName = 'PNList'
