import {
    ElectionDataResponse,
    electionDataResponseSchema,
} from '@west-australian-newspapers/election-api-types'
import { httpGet } from '../../helpers/http'
import { Logger } from 'typescript-log'
import { BaseClientConfig } from '../../client'

function validateGetElectionDataResponse(value: any): ElectionDataResponse {
    const toValidate = value

    if (!toValidate) {
        throw new Error('Election API data missing')
    }

    const parsedObject = electionDataResponseSchema.parse(toValidate)
    if (!parsedObject) {
        throw new Error('Election API data invalid')
    }

    return parsedObject
}

export function getElectionData(
    log: Logger,
    electionAPI: string,
    caller: string,
    electionId: string,
): Promise<ElectionDataResponse> {
    return httpGet({
        log: log,
        validate: validateGetElectionDataResponse,
        baseUrl: electionAPI,
        path: `data/${electionId}?includeConfig=true`,
        customHeaders: {
            caller,
        },
    })
}
