import styled from '@emotion/styled'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { calcRem, TheNightlyThemeConfig } from '../../../__styling'
import { Headline } from '../components'
import { WebLink } from '@news-mono/web-common'

export const StyledHeadline = styled(Headline)(({ theme }) =>
    textMixin<TheNightlyThemeConfig>(theme, 'article'),
)

export const CardLinkWrapper = styled(WebLink)(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    // Remove default anchor styling.
    color: 'unset',
    textDecoration: 'none',

    // Underline Headline when hovered or active.
    [`&:is(:hover, :focus) > ${StyledHeadline}`]: {
        textDecoration: 'underline',
    },
}))

export const StyledIconWrapper = styled('div')(({ theme }) => ({
    color: theme.colors.text.secondary,
    height: calcRem(16),
    width: calcRem(16),
    padding: calcRem(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const StyledCardContentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
})
