import { useTheme } from '@emotion/react'
import {
    DataLayerEventName,
    Product,
    toArray,
    WebStoriesEvents,
} from '@news-mono/web-common'
import debug from 'debug'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { A11y, Keyboard, Navigation, Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import {
    HeaderV2,
    SectionHeader,
    SectionHeaderProps,
    isTabletOrMobileViewport,
    useProduct,
} from '..'
import { ImpressionAvailable } from '../__helpers/impression-available-helper'
import { PerthNowZindex } from '../__styling/settings/z-index'
import {
    StyledCarouselCardContainer,
    StyledCarouselWrapper,
    StyledEntryPointArrowLeft,
    StyledEntryPointArrowRight,
    StyledGradientFadeLeft,
    StyledGradientFadeRight,
} from './WebStories.styled'
import { WebStoriesCard } from './WebStoriesCard/WebStoriesCard'
import { StyledCardSkeleton } from './WebStoriesCard/WebStoriesCard.styled'
import { useFetchStories } from './fetch-stories'
import { sanitiseArrayForAmpPlayer } from './stories-helpers'

export const webStoriesDebug = debug('web-stories')

export type TrueSwiper = SwiperType & {
    visibleSlidesIndexes: Array<number>
}

interface WebStoriesProps {
    sectionHeader?: SectionHeaderProps
    onEvent: (event: WebStoriesEvents) => void
}

export interface StoryProps {
    title: string
    href: string
    posterImage: string
}

const storiesEndpoint: Record<Product, string> = {
    sevennews:
        'https://api.v2.makestories.io/channel/-NEilJXRoT6tLK3pppKi/stories?tags=Top%20Stories',
    thewest:
        'https://api.v2.makestories.io/channel/-NEikQyKDrAj8KcJWzF1/stories?tags=Top%20Stories',
    perthnow:
        'https://api.v2.makestories.io/channel/-NEikEUWpt-J0zjZKlup/stories?tags=Top%20Stories',
    // Todo: update this when available
    thenightly:
        'https://api.v2.makestories.io/channel/-NEikQyKDrAj8KcJWzF1/stories?tags=Top%20Stories',
}

export const WebStories: React.FC<WebStoriesProps> = ({
    sectionHeader,
    onEvent,
}) => {
    const product = useProduct()
    const theme = useTheme()
    const notSevenNews = product !== Product.SevenNews
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)
    const [playerData, setPlayerData] = useState<StoryProps[]>([])
    const { data: stories, isLoading } = useFetchStories(
        storiesEndpoint[product],
    )
    const [localSwiper, setSwiper] = useState<SwiperType>()

    const [showRightGradientFade, setShowRightGradientFade] =
        useState(notSevenNews)
    const [showLeftGradientFade, setShowLeftGradientFade] = useState(false)

    const closePLayer = useCallback(
        (player: any) => {
            player.pause()
            document.body.classList.remove('web-stories-open')
            //amp-player component have bug on mobile devices, it's imposible to prevent scrolling site behind player need to scroll component into view after closing it
            if (isTabletOrMobileViewport()) {
                document.querySelector('.news-flash')?.scrollIntoView()
            }
            const lightbox = document.querySelector(
                '.web-stories-lightbox',
            ) as HTMLElement
            if (product === Product.PerthNow) {
                lightbox.style.removeProperty('z-index')
            }
            lightbox?.classList.add('closed')
        },
        [product],
    )

    useEffect(() => {
        const player = document.body.querySelector('amp-story-player') as any
        webStoriesDebug('Setting stories...', stories, player)
        if (!player || !stories || (stories && stories.length === 0)) {
            return
        }

        const sanitazedData = sanitiseArrayForAmpPlayer(stories)

        setPlayerData(sanitazedData)
        if (player.isReady) {
            player.add(sanitazedData)
        } else {
            player.addEventListener('ready', () => {
                player.add(sanitazedData)
            })
        }

        player.addEventListener('amp-story-player-close', () => {
            closePLayer(player)
        })
    }, [stories, closePLayer])

    const showPlayer = (href: string, title: string, pos: number) => {
        webStoriesDebug('Clicked on Story...', {
            href,
            title,
            pos,
        })
        document.body.classList.add('web-stories-open')
        const lightbox = document.querySelector(
            '.web-stories-lightbox',
        ) as HTMLElement
        lightbox?.classList.remove('closed')
        const player = document.body.querySelector('amp-story-player') as any
        const zIndex = theme.zIndex as PerthNowZindex
        if (product === Product.PerthNow) {
            lightbox.style.zIndex = zIndex.ampPlayerOverride.toString()
        }
        player.show(href)
        // Send a click event to the Data Layer
        onEvent({
            type: DataLayerEventName.webStoryClicked,
            originator: `WebStories`,
            payload: {
                href,
                title,
                position: pos,
            },
        })
        player.play()
    }

    function returnSectionHeader() {
        switch (product) {
            case Product.SevenNews: {
                return (
                    <>
                        {sectionHeader?.heading && (
                            <HeaderV2 sectionHeader={sectionHeader} />
                        )}
                    </>
                )
            }
            default: {
                return (
                    <>
                        {sectionHeader?.heading && (
                            <SectionHeader {...sectionHeader} />
                        )}
                    </>
                )
            }
        }
    }

    const handleSwipe = () => {
        if (localSwiper) {
            setShowRightGradientFade(notSevenNews && !localSwiper.isEnd)
            setShowLeftGradientFade(notSevenNews && localSwiper.isEnd)
        }
    }

    const handleKeyPress = (
        event: React.KeyboardEvent<HTMLElement>,
        data: {
            title: string
            href: string
            index: number
        },
    ) => {
        const currentIndex = document.activeElement?.getAttributeNode(
            'data-swiper-slide-index',
        )?.value

        if (
            currentIndex &&
            event.keyCode === 32 &&
            parseInt(currentIndex) === data.index
        ) {
            event.preventDefault()
            showPlayer(data.href, data.title, data.index)
        }
    }

    return (
        <ImpressionAvailable
            loading={isLoading}
            available={() => {
                if (isLoading) {
                    console.warn(
                        'Available should not be called when isLoading is true',
                    )
                    return
                }

                onEvent({
                    type: DataLayerEventName.webStoryCarouselViewed,
                    originator: `WebStories`,
                    payload: {},
                })
            }}
        >
            {(ref) => (
                <div className="news-flash" ref={ref}>
                    {returnSectionHeader()}
                    <StyledCarouselWrapper className="carousel-container">
                        <StyledCarouselCardContainer
                            ref={scrollContainerRef}
                            className="carousel-cards-container"
                        >
                            <StyledEntryPointArrowLeft
                                hasEffects={notSevenNews}
                                className="story-swiper-button-prev"
                            />
                            <StyledEntryPointArrowRight
                                hasEffects={notSevenNews}
                                className="story-swiper-button-next"
                            />
                            {showLeftGradientFade && <StyledGradientFadeLeft />}
                            {showRightGradientFade && (
                                <StyledGradientFadeRight />
                            )}
                            <Swiper
                                slidesPerView={'auto'}
                                watchSlidesProgress={true}
                                spaceBetween={15}
                                loop={false}
                                watchOverflow={true}
                                navigation={{
                                    nextEl: '.story-swiper-button-next',
                                    prevEl: '.story-swiper-button-prev',
                                }}
                                a11y={{
                                    enabled: true,
                                }}
                                keyboard={{
                                    enabled: true,
                                }}
                                modules={[Navigation, A11y, Keyboard]}
                                cssMode={false}
                                onSwiper={(swiper) => setSwiper(swiper)}
                                onSlideChange={() => handleSwipe()}
                                onSliderMove={() => handleSwipe()}
                            >
                                {isLoading
                                    ? toArray(6).map((_, idx) => (
                                          <SwiperSlide
                                              key={idx}
                                              virtualIndex={idx}
                                          >
                                              <StyledCardSkeleton />
                                          </SwiperSlide>
                                      ))
                                    : playerData.map((item, idx) => (
                                          <SwiperSlide
                                              key={idx}
                                              virtualIndex={idx}
                                              tabIndex={0}
                                              onKeyDown={(event) => {
                                                  handleKeyPress(event, {
                                                      title: item.title,
                                                      href: item.href,
                                                      index: idx,
                                                  })
                                              }}
                                          >
                                              <WebStoriesCard
                                                  open={() =>
                                                      showPlayer(
                                                          item.href,
                                                          item.title,
                                                          idx,
                                                      )
                                                  }
                                                  data={item}
                                                  key={idx}
                                                  product={product}
                                              />
                                          </SwiperSlide>
                                      ))}
                            </Swiper>
                        </StyledCarouselCardContainer>
                    </StyledCarouselWrapper>
                </div>
            )}
        </ImpressionAvailable>
    )
}
