import React from 'react'
import {
    AllEvents,
    createCollectionAvailableEvent,
    PublicationCardItem,
} from '@news-mono/web-common'
import { ImpressionAvailable } from '../../../../../__helpers/impression-available-helper'
import { PNInlineRelatedContentCards } from './components/PNInlineRelatedContentCards'
import { StyledAside } from './PNInlineRelatedContent.styled'
import { useProduct } from '../../../../../__product'

export interface PNInlinerelatedContentProps {
    items: PublicationCardItem[]
    onEvent: (event: AllEvents) => void
    publicationPath?: string
    publicationKind?: string
    isV2ArticlePageEnabled?: boolean
}

export const PNInlineRelatedContent: React.FC<PNInlinerelatedContentProps> = ({
    items,
    onEvent,
}) => {
    const product = useProduct()

    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                onEvent(
                    createCollectionAvailableEvent(
                        items,
                        'InlineRelatedContent',
                        product,
                        onEvent,
                        {
                            componentType: 'inlinerelatedcontent',
                        },
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledAside ref={ref}>
                    <PNInlineRelatedContentCards
                        onEvent={onEvent}
                        expectedCards={items.length}
                        items={{ loaded: true, result: items }}
                    />
                </StyledAside>
            )}
        </ImpressionAvailable>
    )
}
