import React, { FC, ReactNode, useEffect, useState } from 'react'
import { getBreakpoint } from '../../helpers/getBreakpoint'
import { useBreakpointSize } from '../../hooks/useBreakpoint'
import {
    StyledButtonContainer,
    StyledChildrenContainer,
    StyledShowMoreButton,
    StyledTruncContainer,
    customStyles,
} from './TruncatingContainer.styled'

export interface TruncatingContainerProps {
    /** Max height on {xs - Mobile, sm - Tablet, md - Desktop} in px*/
    maxHeights?: {
        xs: number
        sm: number
        md: number
    }
    children: React.ReactNode
    /** Custom style to be applied to truncating container */
    customStyle?: customStyles
    /**
     * Customized button text in a tuple format: [<show more>, <show less>]
     */
    buttonText?: [string, string]
}

export const TruncatingContainer: FC<TruncatingContainerProps> = ({
    maxHeights = { xs: 350, sm: 400, md: 500 },
    children,
    customStyle,
    buttonText = ['＋ Show more', '－ Show less'],
}) => {
    const ref = React.createRef<HTMLDivElement>()
    const truncatedContainerRef = React.createRef<HTMLDivElement>()
    const [truncated, setTruncated] = useState(true)
    const [canOverflow, setCanOverflow] = useState<boolean>()
    const currentBreakpoint = useBreakpointSize()

    useEffect(() => {
        /* Check if container is going to overflow with given children */

        if (!ref.current) {
            return
        }
        const maxTruncatedHeight = ref.current.scrollHeight
        const OverflowHeight = maxHeights[getBreakpoint(currentBreakpoint)]

        /**
         * Truncating container overflow states
         */

        /* Container height is below overflow height aka. a small child*/
        if (maxTruncatedHeight <= OverflowHeight) {
            setCanOverflow(false)
            return
        }

        /* Container height is only 30% above overflow height resulting in a weird show more xp */
        if (maxTruncatedHeight - OverflowHeight <= 0.3 * maxTruncatedHeight) {
            setCanOverflow(false)
            return
        }

        /* Container height is above overflow height */
        if (maxTruncatedHeight > OverflowHeight) {
            setCanOverflow(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, currentBreakpoint])

    return (
        <StyledTruncContainer
            customStyle={customStyle}
            ref={truncatedContainerRef}
        >
            <StyledChildrenContainer
                truncated={!!canOverflow && truncated}
                maxHeights={maxHeights}
                ref={ref}
            >
                {React.Children.map<ReactNode, ReactNode>(children, (child) => {
                    if (React.isValidElement<any>(child)) {
                        return React.cloneElement(child, {
                            canTruncate: true,
                            truncated,
                            truncatedContainerRef,
                        })
                    }
                })}
            </StyledChildrenContainer>
            <StyledButtonContainer
                customStyle={customStyle}
                isVisible={!!canOverflow}
                truncated={truncated}
            >
                <StyledShowMoreButton
                    id="showmore"
                    onClick={() => setTruncated(!truncated)}
                >
                    <span>
                        {`${truncated ? buttonText[0] : buttonText[1]}`}
                    </span>
                </StyledShowMoreButton>
            </StyledButtonContainer>
        </StyledTruncContainer>
    )
}
