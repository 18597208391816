import React, { ReactElement } from 'react'
import { cx } from '@emotion/css'
import { DataLayerEventName, getProductName } from '@news-mono/web-common'
import {
    PaginationListItem,
    StyledPaginationItem,
    StyledPaginationLink,
    StyledText,
} from '../Pagination.styled'
import { DisplayProperty } from 'csstype'
import { BreakpointState, ThemeConfig } from '../../../__styling'
import { useProduct } from '../../../__product'

export type DisplayProps = BreakpointState<
    ThemeConfig,
    {
        display: DisplayProperty
    }
>
export type PaginationItemProps = {
    text?: string
    isDisabled: boolean
    layout: {
        showText: boolean
        hideTextAtMobile?: boolean
        display: DisplayProps
    }
    onClick: (e: number) => void
    onEvent: (event: any) => void
    routePath: string
    params?: string
    page: {
        currentPage: number
        nextPage: number
    }
    icon?: {
        iconElement: ReactElement
        iconPosition: 'before' | 'after'
    }
    flexGrow?: boolean
    hideItemBorder?: boolean
}

export const PaginationItem = ({
    text,
    isDisabled,
    layout,
    onClick,
    routePath,
    params,
    page,
    icon,
    flexGrow,
    onEvent,
    hideItemBorder = true,
}: PaginationItemProps) => {
    const isCurrentPage = page.currentPage === page.nextPage
    const product = getProductName(useProduct())

    // append the parameters, if provided
    const path = params ? `${routePath}?${params}` : routePath
    const nextPagePath = params
        ? `${path}&page=${page.nextPage}`
        : `${path}?page=${page.nextPage}`

    const route = page.nextPage === 1 ? path : nextPagePath

    const ariaCurrent = isCurrentPage ? 'page' : undefined
    const ariaLabel = `Goto Page ${text ? text : page.nextPage}`
    const tabIndex = isDisabled ? -1 : undefined

    const borderClassName = hideItemBorder ? '' : 'bordered'

    const handleClick = (isDisabled: boolean) => {
        if (!isDisabled) {
            onEvent({
                type: DataLayerEventName.linkClicked,
                originator: `${product}Pagination`,
                payload: {
                    current_page: `${page.currentPage}`,
                    next_page: `${page.nextPage}`,
                },
            })
            onClick(page.nextPage)
        }
    }
    const content = (
        <>
            {icon?.iconPosition === 'before' && icon.iconElement}
            <StyledText hideTextAtMobile={layout.hideTextAtMobile}>
                {layout.showText ? (text ? text : page.nextPage) : null}
            </StyledText>
            {icon?.iconPosition === 'after' && icon.iconElement}
        </>
    )
    return (
        <>
            {isDisabled ? (
                <PaginationListItem
                    aria-hidden="true"
                    display={layout.display}
                    flexGrow={flexGrow}
                >
                    <StyledPaginationItem
                        display={layout.display}
                        tabIndex={tabIndex}
                        onClick={() => handleClick(isDisabled)}
                        hasIcon={icon !== undefined}
                        className={cx('disabled', borderClassName)}
                    >
                        {content}
                    </StyledPaginationItem>
                </PaginationListItem>
            ) : (
                <PaginationListItem
                    aria-label={ariaLabel}
                    aria-current={ariaCurrent}
                    display={layout.display}
                    flexGrow={flexGrow}
                >
                    <StyledPaginationLink
                        to={route}
                        display={layout.display}
                        onClick={() => handleClick(isDisabled)}
                        hasIcon={icon !== undefined}
                        className={cx(
                            isCurrentPage ? 'enabled-active' : 'enabled',
                            borderClassName,
                        )}
                    >
                        {content}
                    </StyledPaginationLink>
                </PaginationListItem>
            )}
        </>
    )
}
