import debounce from 'lodash.debounce'
import { GptAdSlotDefinition } from '../advertising/gpt-ad-slot-definintion'
import { SizeMapping, uniqueSlotSizes } from '../advertising/size-mapping'
import { isServerEnvironment } from '../environment'
import { adsDebug } from './ads-debug'
import { MagniteSlot } from './magnite'
import { Logger } from 'pino'
import { AdSlotRefresh } from './ad-slot-refresh'
import { AdRefreshConfigSection } from './AdRefreshConfig'
import { getAdfixusId } from './ad-fixus'

const gptApiDebug = adsDebug.extend('gpt-api')
const gptApiMagniteDebug = adsDebug.extend('gpt-api-magnite')
const refreshDebug = adsDebug.extend('ad-slot-refresh')
const adRefreshDebug = refreshDebug.extend('general')
const adRefreshWarn = refreshDebug.extend('error')

export interface MediaQueryMap {
    [width: number]: {
        mq: MediaQueryList
        slotIds: string[]
    }
}

export interface SetSlotAdditionalTargetingOptions {
    gptSlots: googletag.Slot[]
    slots: GptAdSlotDefinition[]
    gptApi: GptApi
}

export interface GptApiOptions {
    /** Loads the gpt script and waits for it to be initialised successfully */
    loadGptLibrary: () => Promise<GptLibrary | false>

    /**
     * Called after ad slots are cleaned up to perform any additional cleanup
     **/
    performAdditionalCleanup?: (gptApi: GptApi) => void

    requestIdleCallback?: typeof window.requestIdleCallback

    enableCompanionAds?: boolean
    isMagniteEnabeled?: boolean
    logger?: Logger
}

export interface SlotRenderEndedEvent
    extends googletag.events.SlotRenderEndedEvent {
    unit: GptAdSlotDefinition
}
export type SlotRenderEndedEventHandler = (event: SlotRenderEndedEvent) => void

export interface SlotMap {
    [id: string]: {
        gptSlot: googletag.Slot
        definition: GptAdSlotDefinition
        adSlotRefresh: AdSlotRefresh
    }
}

// To reduce mocking, pick just what we need
export type GptLibrary = Pick<
    typeof googletag,
    | 'defineSlot'
    | 'apiReady'
    | 'pubadsReady'
    | 'destroySlots'
    | 'enableServices'
    | 'defineOutOfPageSlot'
    | 'sizeMapping'
    | 'companionAds'
    | 'display'
> & {
    pubads: () => Pick<
        googletag.PubAdsService,
        'clear' | 'addEventListener' | 'refresh' | 'setTargeting'
    >
}

const AllowRepeatAdvertiserIds = [
    5371122954, //Magnite Demand Manager
    67418433, //Google AdX - AdvertiserID
    66889833, //SWM AdX - AdvertiserID
    59726073, //Seven West Media AdX - AdvertiserID
]
const stopRefreshIds: string[] = []

/**
 * Wrapper around GPT which sets targeting info and refreshes slots when
 * their size mappings change
 */
export class GptApi {
    displayedSlots: SlotMap = {}
    mediaQueries: MediaQueryMap = {}
    gptLibrary: GptLibrary | undefined
    logger: Logger | undefined
    private loadingGptLibrary: Promise<void>
    private initialised = false
    private slotRenderEndedEventHandler: SlotRenderEndedEventHandler | undefined
    private refreshAllSlots = debounce(() => {
        const slotIds = Object.keys(this.displayedSlots)
        const displayedSlots = slotIds.map((id) => this.displayedSlots[id])
        const gptSlots = displayedSlots.map((slot) => slot.gptSlot)
        const slots = displayedSlots.map((slot) => slot.definition)

        // TODO this probably needs some consideration with what happens to ad slot and observers

        this.clearSlots(gptSlots)
        const ric =
            this.options.requestIdleCallback || window.requestIdleCallback
        ric(() => this.refreshSlots(slots, gptSlots), { timeout: 300 })
    }, 250)
    companionAdsEnabled = false
    private advertiserIds: { [slotId: string]: number } = {}

    constructor(private options: GptApiOptions) {
        if (!isServerEnvironment()) {
            this.companionAdsEnabled = options.enableCompanionAds ?? false
            gptApiDebug('Loading GPT')
            this.logger = options.logger
            this.loadingGptLibrary = options
                .loadGptLibrary()
                .then(async (gpt) => {
                    if (gpt) {
                        const pubAds = gpt.pubads()
                        pubAds.addEventListener('slotRenderEnded', (event) => {
                            //Check if Advertiser Repeat
                            if (event.advertiserId) {
                                const slotId = event.slot.getSlotElementId()
                                const prevAdvertiserId =
                                    this.advertiserIds[slotId]
                                const currentAdvertiserId = event.advertiserId

                                adRefreshDebug(
                                    `Advertiser Ids for slot id: ${slotId}
                                    previous: ${prevAdvertiserId}
                                    current: ${currentAdvertiserId}`,
                                )

                                //If the current and previous ad IDs are the same, the advertiser is not Magine or AdX and the advertiser id is not a negative no.
                                if (
                                    event.advertiserId === prevAdvertiserId &&
                                    !AllowRepeatAdvertiserIds.includes(
                                        event.advertiserId,
                                    ) &&
                                    event.advertiserId > 0
                                ) {
                                    //do not refresh the slot again
                                    stopRefreshIds.push(slotId)
                                    adRefreshDebug(
                                        `Adding slot id: ${slotId} to do not refresh list`,
                                    )
                                }

                                //update advertiser ID list with new advertiser ID for this slot id
                                this.advertiserIds[slotId] = event.advertiserId
                            }

                            const idArray = event.slot.getTargeting('slotId')
                            gptApiDebug('Ad rendered: %o', {
                                id: idArray ? idArray[0] : 'unknown',
                                isEmpty: event.isEmpty,
                                event,
                            })
                            if (this.slotRenderEndedEventHandler) {
                                this.slotRenderEndedEventHandler(
                                    event as SlotRenderEndedEvent,
                                )
                            }
                        })
                        /**
                         * At this point:
                         * - gpt.js has loaded and initialised
                         * - gpt.js API methods are ready to be called
                         * - gpt.js PubAds service methods are ready to be called
                         */
                    }

                    return gpt
                })
                .then((gpt) => {
                    if (gpt) {
                        gptApiDebug('GPT Loaded and initialised successfully')
                        this.gptLibrary = gpt
                    } else {
                        gptApiDebug('GPT not loaded')
                    }
                })
        } else {
            this.loadingGptLibrary = Promise.resolve()
        }
    }

    /**
     * Idempotent function to ensure GPT library is loaded, initialised and the API and PubAds services are ready!
     */
    async ensureInitialised() {
        if (this.initialised) {
            return
        }
        await this.loadingGptLibrary
        this.initialised = true
    }

    mapToMagniteSlot(slot: GptAdSlotDefinition): MagniteSlot {
        return {
            divId: slot.originalDefinition.id,
            name: slot.adUnitPath,
            sizes: uniqueSlotSizes(slot.sizeMapping).map((slotItem) => {
                return {
                    w: slotItem[0],
                    h: slotItem[1],
                }
            }),
        }
    }

    /**
     * changeCorrelator: boolean - changeCorrelator specifies whether or not a new correlator is to be generated for fetching ads
     * Google's servers maintain the Correlator (~30 seconds) so this isn't guaranteed depending on timing
     * https://developers.google.com/doubleclick-gpt/reference#googletag.PubAdsService_refresh
     */
    displaySlots(
        slots: GptAdSlotDefinition[],
        changeCorrelator = false,
        refreshAds = false,
        adRefreshValues: AdRefreshConfigSection,
    ): Promise<void> {
        gptApiDebug('Displaying slots: %o', { slots, changeCorrelator })

        return new Promise<void>((resolve, reject) => {
            const maxPollingAttempts = 20 //increase polling attempts for Firefox browsers

            let pollingInterval: NodeJS.Timeout | undefined = undefined
            let gptInitialized = false
            let pollingAttempts = 0

            const pollAdRender = () => {
                // STAGE #1
                // Only allow it to poll a few times and then give up, as it'll re-attempt later.
                if (++pollingAttempts >= maxPollingAttempts) {
                    gptApiDebug(
                        `FAILED: AdRender Poll could not find an ad provider after ${maxPollingAttempts}, will try to render ads again on next page interaction.`,
                    )
                    clearInterval(pollingInterval)
                    reject()
                    return false
                }

                // STAGE #2, initialize GPT & remove PrebidJS
                try {
                    // remove prebidjs best practices suggestion from test cases
                    if (
                        !gptInitialized &&
                        process.env.NODE_ENV !== 'test' &&
                        this.options.isMagniteEnabeled &&
                        typeof googletag !== typeof undefined &&
                        typeof googletag.pubads() !== typeof undefined
                    ) {
                        googletag.pubads().disableInitialLoad()
                        googletag.pubads().enableSingleRequest()
                        // Get AdFixus ID using the utility function
                        const adfixusId = getAdfixusId()
                        if (adfixusId) {
                            googletag.pubads().setPublisherProvidedId(adfixusId)
                        }
                        googletag.enableServices()

                        gptApiMagniteDebug('Displaying slots: %o', { slots })
                        gptInitialized = true
                    }
                } catch (error) {
                    gptApiDebug(
                        'FAILED: AdRender Poll was unable to find googletag.pubads, so will attempt next render!',
                    )
                }

                const gptSlots = slots
                    .map((slot) => this.defineGptSlot(slot, adRefreshValues))
                    .filter((slot): slot is googletag.Slot => !!slot)

                // STAGE #3
                // Try to define GPT slots, this is created via. the
                // GPT Library, and can't be created until the GPT Library is rendered.
                if (gptSlots.length === 0) {
                    gptApiDebug(
                        `FAILED: AdRender Poll could not find the GPT Library to render GPT Slots.`,
                    )
                    return false
                }

                // STAGE #4
                // registered the slot refresh if enabled
                if (refreshAds) {
                    slots.forEach((slot) => {
                        const id = slot.id
                        const displayedSlot = this.displayedSlots[id]
                        const elementId =
                            displayedSlot?.gptSlot?.getSlotElementId() || id

                        if (
                            displayedSlot?.adSlotRefresh?.isRegistered(
                                elementId,
                            )
                        ) {
                            adRefreshWarn(
                                `Attempted to register an ad slot that already is registered for refresh with the slot id: ${elementId}`,
                            )
                        } else {
                            displayedSlot?.adSlotRefresh?.registerSlotRefresh(
                                elementId,
                            )
                            adRefreshDebug(
                                `Slot: ${slot.id} Registered for Slot Refresh with values: %o`,
                                { adRefreshValues },
                            )
                        }
                    })
                }

                // STAGE #5
                // If Magnite is Enabled & PBJS is rendered, use this for ad rendering
                if (this.options.isMagniteEnabeled && window.pbjs) {
                    gptApiMagniteDebug(
                        'AdRender Poll found Magnite/PBJS and will now render ad slots.',
                    )
                    window.pbjs?.que.push(() => {
                        const magniteSlots: MagniteSlot[] = slots.map((slot) =>
                            this.mapToMagniteSlot(slot),
                        )

                        this.demandManagerRequest(magniteSlots)
                    })
                } else if (this.gptLibrary) {
                    gptApiDebug(
                        'AdRender Poll found GPT Library and will now render ad slots.',
                        gptSlots,
                    )
                    this.gptLibrary
                        ?.pubads()
                        .refresh(gptSlots, { changeCorrelator })
                } else {
                    gptApiDebug(
                        `AdRender Poll couldn't find an ad provider, trying again...`,
                    )
                    return false
                }

                // Stage #6
                // Log companion ad slots
                if (this.gptLibrary && this.companionAdsEnabled) {
                    try {
                        googletag
                            .companionAds()
                            .getSlots()
                            .forEach((slot) =>
                                gptApiDebug('companion-dump: ', {
                                    id: slot.getSlotElementId(),
                                    adUnitPath: slot.getAdUnitPath(),
                                    attributeKeys: slot.getAttributeKeys(),
                                    categoryExclusions:
                                        slot.getCategoryExclusions(),
                                    responseInformation:
                                        slot.getResponseInformation(),
                                    targetingKeys: slot.getTargetingKeys(),
                                    gootestTargeting:
                                        slot.getTargeting('gootest'),
                                    elementId: slot.getSlotElementId(),
                                }),
                            )
                    } catch (e) {
                        gptApiDebug('failed companion-dump: %o', { err: e })
                    }
                }

                // It didn't fail out because it couldn't find an ad rendered, so return true to know
                // it was successful.
                resolve()
                clearInterval(pollingInterval)
                return true
            }

            // we don't need to resolve, as it was successful the first time around
            if (pollAdRender()) {
                return
            }

            pollingInterval = setInterval(pollAdRender, 500)
        })
    }

    destroySlots(slots: GptAdSlotDefinition[]) {
        gptApiDebug('Destroying slots', { slots })
        adRefreshDebug('Destroying slots', { slots })

        const slotsToDestroy = slots
            .map((slot) => {
                const displayedSlot = this.displayedSlots[slot.id]
                if (!displayedSlot) {
                    return undefined
                }

                // clean up ad refresh observer
                displayedSlot.adSlotRefresh.disconnect()

                const gptSlot = displayedSlot.gptSlot

                delete this.displayedSlots[slot.id]
                if (slot.sizeMapping) {
                    this.removeMediaQuery(slot.id, slot.sizeMapping)
                }
                return gptSlot
            })
            .filter((slot): slot is googletag.Slot => slot !== undefined)
        if (!slotsToDestroy.length) {
            return
        }
        try {
            this.gptLibrary?.destroySlots(slotsToDestroy)
        } catch (err) {
            if (window && this.logger && err instanceof Error) {
                this.logger.warn(
                    {
                        slots,
                        err,
                    },
                    'unable to destory slot(s) owing to error',
                )
            }
        }
    }

    registerOnSlotRenderEnded(handler: SlotRenderEndedEventHandler) {
        this.slotRenderEndedEventHandler = handler
    }

    private clearSlots(slots?: googletag.Slot[]) {
        adRefreshDebug('Clearing slot: %o', { slots })
        this.gptLibrary?.pubads()?.clear(slots)

        if (this.options.performAdditionalCleanup) {
            gptApiDebug('Performing additional ad cleanup')
            this.options.performAdditionalCleanup(this)
        }
    }

    private tryDefineSlot(
        slot: GptAdSlotDefinition,
        isRetry: boolean,
    ): googletag.Slot | null {
        if (!this.gptLibrary) {
            return null
        }

        let gptSlot
        if (slot.outOfPage) {
            gptSlot = this.gptLibrary.defineOutOfPageSlot(
                slot.adUnitPath,
                slot.id,
            )
        } else {
            // The value below is used when there are no size mappings, or the viewport width is below all specified size mappings.
            // We always cover all breakpoints in our size mappings so this fallback should not be used
            // https://developers.google.com/doubleclick-gpt/reference#googletag.defineSlot
            const sizes = uniqueSlotSizes(slot.sizeMapping)
            gptApiDebug('Defining slot with fallback sizes: %o', {
                id: slot.id,
                sizes,
            })
            gptSlot = this.gptLibrary.defineSlot(
                slot.adUnitPath,
                sizes,
                slot.id,
            )
        }

        // Don't retry when adUnitPath is empty, it will fail
        if (gptSlot || isRetry || slot.adUnitPath === '') {
            return gptSlot
        }

        /**
         * At this point, Googletag has returned `null`, which means a slot with
         * the same element id still exists in Googletag's internal slot list.
         */
        console.warn(
            'GPT Api: slot could not be defined, retrying! (duplicate id?)',
            { id: slot.id, slot },
        )

        const slotToDestroy = Object.keys(this.displayedSlots)
            .filter((id) => id === slot.id)
            .map((id) => this.displayedSlots[id])

        this.gptLibrary.destroySlots(slotToDestroy.map((slot) => slot.gptSlot))
        // Remove ad slots!
        slotToDestroy
            .map((slot) => slot.adSlotRefresh)
            .forEach((adSlotRefresh) => adSlotRefresh.disconnect())

        return this.tryDefineSlot(slot, true)
    }

    private defineGptSlot(
        slot: GptAdSlotDefinition,
        adRefreshValues: AdRefreshConfigSection,
    ): googletag.Slot | null {
        if (!this.gptLibrary) {
            return null
        }

        const gptSlot = this.tryDefineSlot(slot, false)
        if (!gptSlot) {
            // Static pages do not have ad unit paths
            if (slot.adUnitPath !== '') {
                console.error(
                    'GPT Api: slot could not be defined! (duplicate id?)',
                    { id: slot.id, slot },
                )
            }
            return gptSlot
        }

        /**
         * Handle ad exclusion labels: https://support.google.com/dfp_premium/answer/3238504?hl=en
         * This is required for scenarios such as: a slot that only serves a specific campaign that
         * should NOT have programmatic/house/paid ads so that it can auto-collapse when the campaign
         * is not running.
         */
        const { exclusionLabels = [] } = slot
        for (const label of exclusionLabels) {
            gptSlot.setCategoryExclusion(label)
        }
        const targeting = slot.targeting
        if (targeting) {
            //set initial refresh value
            targeting['refresh'] = '-1'
            Object.keys(targeting).forEach((key) => {
                gptSlot.setTargeting(key, targeting[key])
            })
        }

        if (slot.sizeMapping) {
            // Tests don't have clientWidth, so default to innerWidth
            const clientWidth =
                document.documentElement.clientWidth || window.innerWidth
            const scrollbarWidth = window.innerWidth - clientWidth
            let smbuilder = this.gptLibrary.sizeMapping()
            let sizeAdded = false
            slot.sizeMapping.forEach((mapping) => {
                // It appears that GPT works on width excluding scrollbars, but
                // breakpoints include scrollbars. This takes this into account
                let width = mapping.viewport[0] - scrollbarWidth
                if (width < 0) {
                    width = 0
                }

                // It's invalid to specify a size mapping but have no sizes.
                // We also would unmount this ad slot when the viewport is increased
                if (mapping.slot.length > 0) {
                    const height = mapping.viewport[1]
                    smbuilder = smbuilder.addSize([width, height], mapping.slot)
                    sizeAdded = true
                }
            })

            if (!sizeAdded) {
                console.warn(
                    { id: slot.id, slot },
                    'GPT API: Empty size mapping',
                )
            }

            const sizeMapping = smbuilder.build()
            gptSlot.defineSizeMapping(sizeMapping)
            this.addMediaQuery(slot.id, slot.sizeMapping)

            gptApiDebug('Defined GPT slot with size mapping: %o', {
                id: slot.id,
                slot,
                sizeMapping,
            })
        } else {
            console.warn(
                { id: slot.id, slot },
                'GPT API: No size mapping found',
            )
        }
        if (slot.forceSafeFrame !== undefined) {
            // It appears that some ad blockers you still get an ad slot object,
            // but it does not have a `setForceSafeFrame` function on it
            if (gptSlot.setForceSafeFrame) {
                gptSlot.setForceSafeFrame(slot.forceSafeFrame)
            }
        }

        if (this.companionAdsEnabled && slot.companion) {
            gptApiDebug('gptSlot.addService(this.gpt.companionAds()): %o', {
                id: slot.id,
                slot,
            })
            gptSlot.addService(this.gptLibrary.companionAds())
        }
        gptSlot.addService(this.gptLibrary.pubads() as any)
        // this does not actually display the slot as we set disableInitialLoad()
        // we have to call refresh() to actually display it

        gptApiDebug('Display slot: %o', { id: slot.id, slot })
        this.gptLibrary.display(slot.id)

        this.displayedSlots[slot.id] = {
            gptSlot,
            definition: slot,
            adSlotRefresh: new AdSlotRefresh(
                slot,
                () => {
                    this.demandManagerRequest([this.mapToMagniteSlot(slot)])
                },
                adRefreshValues,
                stopRefreshIds,
            ),
        }
        return gptSlot
    }

    private addMediaQuery(slotId: string, sizeMapping: SizeMapping[]) {
        if (typeof window === 'undefined' || window.matchMedia === undefined) {
            return
        }
        sizeMapping.forEach((mapping) => {
            const width = mapping.viewport[0]
            if (width === 0) {
                return
            }
            const existingContainer = this.mediaQueries[width]
            if (existingContainer) {
                if (existingContainer.slotIds.indexOf(slotId) === -1) {
                    existingContainer.slotIds.push(slotId)
                }
                return
            }
            const mqContainer = {
                mq: window.matchMedia(`(min-width: ${width}px)`),
                slotIds: [slotId],
            }
            mqContainer.mq.addEventListener('change', this.refreshAllSlots)
            this.mediaQueries[width] = mqContainer
        })
    }

    private removeMediaQuery(slotId: string, sizeMapping: SizeMapping[]) {
        if (typeof window === 'undefined' || window.matchMedia === undefined) {
            return
        }
        sizeMapping.forEach((mapping) => {
            const width = mapping.viewport[0]
            const mqContainer = this.mediaQueries[width]
            if (width === 0 || !mqContainer) {
                return
            }
            const index = mqContainer.slotIds.indexOf(slotId)
            if (index !== -1) {
                mqContainer.slotIds.splice(index, 1)
            }
            if (mqContainer.slotIds.length > 0) {
                return
            }
            mqContainer.mq.removeEventListener('change', this.refreshAllSlots)
            delete this.mediaQueries[width]
        })
    }

    private refreshSlots(
        slots: GptAdSlotDefinition[],
        gptSlots?: googletag.Slot[],
    ) {
        if (gptSlots) {
            this.gptLibrary
                ?.pubads()
                ?.refresh(gptSlots, { changeCorrelator: false })
        }
    }

    demandManagerRequest(slotMap: MagniteSlot[]) {
        gptApiDebug('Demand manager request: %o', { slotMap })
        gptApiMagniteDebug('Demand manager request: %o', { slotMap })
        adRefreshDebug('Demand manager request: %o', { slotMap })

        const FAILSAFE_TIMEOUT = 3500

        const sendServerRequest = failsafeHandler((bids: any) => {
            googletag.cmd.push(function () {
                const slotsToRefresh: googletag.Slot[] = []

                slotMap.forEach((slotMapObject) => {
                    window.pbjs?.setTargetingForGPTAsync(
                        [slotMapObject.divId],
                        function (gptSlot) {
                            return function (slotId: string) {
                                if (
                                    slotId.includes(
                                        gptSlot.getTargeting('slotId')[0],
                                    )
                                ) {
                                    if (!slotsToRefresh.includes(gptSlot)) {
                                        slotsToRefresh.push(gptSlot)
                                    }
                                    return true
                                }
                            }
                        },
                    )
                })

                if (slotsToRefresh.length) {
                    const allowedKeys = [
                        'hb_bidder',
                        'hb_adid',
                        'hb_pb',
                        'hb_size',
                        'hb_deal',
                        'hb_format',
                        'hb_uuid',
                        'hb_cache_host',
                        'hb_native_title',
                        'hb_native_body',
                        'hb_native_body2',
                        'hb_native_privacy',
                        'hb_native_privicon',
                        'hb_native_brand',
                        'hb_native_image',
                        'hb_native_icon',
                        'hb_native_linkurl',
                        'hb_native_displayurl',
                        'hb_native_cta',
                        'hb_native_rating',
                        'hb_native_address',
                        'hb_native_downloads',
                        'hb_native_likes',
                        'hb_native_phone',
                        'hb_native_price',
                        'hb_native_saleprice',
                        'hb_renderer_url',
                        'hb_adTemplate',
                    ]
                    slotsToRefresh.forEach((slot) => {
                        //update refresh targeting key value for each slot
                        const refresh = +slot.getTargeting('refresh')[0]
                        if (refresh !== undefined) {
                            slot.setTargeting('refresh', [
                                (refresh + 1).toString(),
                            ])
                        }

                        const targetingKeys = slot.getTargetingKeys()
                        targetingKeys.forEach((key) => {
                            if (
                                key.startsWith('hb_') &&
                                !allowedKeys.includes(key)
                            ) {
                                slot.clearTargeting(key)
                            }
                        })
                    })
                    googletag.pubads().refresh(slotsToRefresh)
                }
            })
        }, slotMap)

        window.pbjs?.rp.requestBids({
            callback: sendServerRequest,
            slotMap,
            divPatternMatching: true,
        })

        setTimeout(sendServerRequest, FAILSAFE_TIMEOUT)

        function failsafeHandler(cb: any, initialSlots: any) {
            let adserverRequestSent = false
            return (bidsBackSlots: any) => {
                if (adserverRequestSent) return
                adserverRequestSent = true
                cb(bidsBackSlots || initialSlots)
            }
        }
    }
}
