/**
 * Types and utilities for AdFixus integration
 */

export interface AfxIdentityProfile {
    /** AdFixus User ID */
    acid: string
}

export interface AfxIdentityType {
    getProfile(): AfxIdentityProfile | undefined
}

declare global {
    interface Window {
        AfxIdentity?: AfxIdentityType
    }
}

/**
 * Safely gets the AdFixus ID if available
 * @returns The AdFixus ID or undefined if not available
 */
export function getAdfixusId(): string | undefined {
    if (typeof window === 'undefined') {
        return undefined
    }

    try {
        const profile = window.AfxIdentity?.getProfile?.()
        return profile?.acid
    } catch (error) {
        console.warn('Failed to get AdFixus ID:', error)
        return undefined
    }
}
