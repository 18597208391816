import styled from '@emotion/styled'
import { placeholderAnimation } from '../components/placeholderAnimation'
import {
    breakpoint,
    calcRem,
    themedValue,
    TheNightlyThemeConfig,
} from '../../../__styling'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { tokens } from '@news-mono/design-tokens'

const { utility, neutral } = tokens.thenightly.colors.palette

export const StyledContainer = styled('div')(({ theme }) => [
    {
        width: '100%',
        margin: '0 auto',
        fontFamily: theme.fonts.sansSerif,

        gap: 24,

        borderWidth: 0,
        borderBottomWidth: themedValue(theme, {
            thenightly: calcRem(1),
            thewest: calcRem(1),
            sevennews: 0,
            fallback: calcRem(1),
        }),
        borderStyle: 'solid',
        borderColor: theme.colors.borders.tertiary,

        padding: calcRem(8, 0),

        [breakpoint('sm')]: {
            padding: calcRem(16),
        },

        [breakpoint('lg')]: {
            padding: calcRem(16, 0),
        },
    },
    theme.kind === 'thenightly' && {
        borderColor: neutral[20],
    },
])

export const StyledWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    height: calcRem(46),
    display: 'flex',
    alignItems: 'flex-end',
    gap: calcRem(8),
    bottom: calcRem(2),

    padding: themedValue(theme, {
        thenightly: calcRem(0, 8),
        thewest: calcRem(0, 8),
        sevennews: 0,
        fallback: calcRem(0, 8),
    }),

    [breakpoint('sm')]: {
        padding: calcRem(0, 0),
        height: calcRem(52),
    },
}))

export const StyledBarsContainer = styled('div')({
    position: 'relative',
    flex: 1,
    height: '100%',
})

export const StyledBackgroundBar = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: calcRem(2),
    left: 0,
    right: 0,
    height: calcRem(16),
    backgroundColor: neutral[20],
    zIndex: themedValue(theme, {
        thenightly: 0,
        thewest: 0,
        sevennews: 0,
        fallback: -1,
    }),
}))

export const StyledPartyBar = styled('div')(
    (props: { width: number; isRight?: boolean }) => ({
        position: 'absolute',
        bottom: calcRem(2),
        ...(props.isRight ? { right: 0 } : { left: 0 }),
        height: 'auto',
        width: `${props.width}%`,
        display: 'flex',
        alignItems: 'flex-end',
        ...(props.isRight && { justifyContent: 'flex-end' }),

        transition: 'width 0.5s',
    }),
)

export const StyledBarContent = styled('div')({
    width: '100%',
    height: calcRem(16),
    display: 'flex',
})

export const StyledWonSeats = styled('div')(
    (props: { color: string; width: number }) => ({
        backgroundColor: props.color,
        width: `${props.width}%`,

        transition: 'width 0.5s',
    }),
)

export const StyledPredictedSeats = styled('div')(
    (props: { color: string; width: number; show?: boolean }) => ({
        backgroundColor: props.color,
        width: `${props.width}%`,
        visibility: props.show ? 'visible' : 'hidden',

        transition: 'width 0.5s',
    }),
)

export const StyledSeatCountContainer = styled('div')(
    (props: { isRight?: boolean }) => ({
        display: 'none',
        alignItems: 'flex-end',
        justifyContent: props.isRight ? 'flex-start' : 'flex-end',
        [breakpoint('sm')]: {
            display: 'flex',
        },
    }),
)

export const StyledSeatCount = styled('span')(
    (props: { color: string; isRight?: boolean }) => ({
        fontSize: calcRem(64),
        fontWeight: 700,
        color: props.color,
        display: 'flex',
        alignItems: 'flex-end',
        whiteSpace: 'nowrap',
        lineHeight: calcRem(48),
        userSelect: 'none',
    }),
)

export const StyledPartyLabel = styled('span')(
    (props: { color: string; isRight?: boolean }) => ({
        position: 'absolute',
        ...(props.isRight ? { right: '0' } : { left: '0' }),
        bottom: calcRem(18),
        fontSize: calcRem(16),
        fontWeight: 600,
        color: props.color,
        userSelect: 'none',
        whiteSpace: 'nowrap', // Prevent wrapping to a second line
        display: 'flex', // Ensure label and seat count are on the same line
        alignItems: 'center', // Vertically center the text
        gap: calcRem(4), // Add some space between label and seat count
        [breakpoint('sm')]: {
            fontSize: calcRem(20),
            bottom: calcRem(18),
        },
    }),
)

export const StyledPartyLabelSeatCount = styled('span')(
    (props: { isRight?: boolean }) => ({
        userSelect: 'none',
        display: 'inline', // Ensure seat count is displayed inline with the label
        [breakpoint('sm')]: {
            display: 'none',
        },
    }),
)

export const StyledCenterLine = styled('div')({
    position: 'absolute',
    left: '50%',
    height: '50%',
    borderLeft: `1px solid ${neutral[100]}`,
    bottom: calcRem(-2),
    zIndex: 9,
})

export const StyledCenterLabel = styled('span')({
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    marginBottom: 0,
    fontSize: calcRem(12),
    color: neutral[100],
    whiteSpace: 'nowrap',
    width: 'auto',
    userSelect: 'none',
})

export const StyledLoadingPlaceholder = styled('div')({
    width: '100%',
    height: calcRem(52),
    ...placeholderAnimation,
})

export const StyledSeatCountErrorContainer = styled('div')({
    height: calcRem(52),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: neutral[10],
    borderRadius: calcRem(4),
    border: `${calcRem(1)} solid ${neutral[20]}`,
    flexDirection: 'row',
    gap: calcRem(8),
    wdith: '100%',
})

export const StyledSeatCountErrorText = styled('p')<{ isBold?: boolean }>(
    ({ theme, isBold = false }) => {
        return [
            textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
            {
                color: utility.error.default,
                margin: 0,
                fontWeight: isBold ? 700 : 400,
            },
        ]
    },
)
