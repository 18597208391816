import React, { PropsWithChildren, ReactNode } from 'react'
import {
    StyledLatestLiveBlogMilestonesContainer,
    StyledLiveBlogBulletMilestoneMark,
    StyledLiveBlogBulletPoint,
    StyledLiveBlogBulletPointContainer,
    StyledLiveBlogMilestoneRow,
    StyledLiveBlogRowContentContainer,
    StyledLiveBlogRowTimestamp,
    StyledLiveBlogRowTitle,
} from './LatestLiveBlogMilestones.styled'
import { getReadableTimeSince } from '../../../__helpers'
import { IconBulletPoint } from '../../../icons'
import { format } from 'date-fns'
import { LatestLiveBlogContent } from '../content-selector-helper'
import { useProduct } from '../../../__product'
import { Product } from '@news-mono/web-common'

export type GetLinkWithChildrenComponent = (
    props: PropsWithChildren<{ to: string; key: string }>,
) => ReactNode

export interface LatestLiveBlogMilestonesProps {
    posts: LatestLiveBlogContent[]
    isVrTest?: boolean
    getLinkComponentWithChildren?: GetLinkWithChildrenComponent
    hasBackground?: boolean
}

export const LatestLiveBlogMilestones = ({
    posts,
    isVrTest,
    getLinkComponentWithChildren,
    hasBackground,
}: LatestLiveBlogMilestonesProps) => {
    const size = posts.length

    return (
        <StyledLatestLiveBlogMilestonesContainer>
            {posts.map((content, index) => {
                const RowComponent = (
                    <LiveBlogMilestoneRow
                        content={content}
                        isVrTest={isVrTest}
                        isLastRow={size - 1 === index}
                        hasBackground={hasBackground}
                    />
                )
                return getLinkComponentWithChildren
                    ? getLinkComponentWithChildren({
                          to: content.link,
                          key: content.postId,
                          children: RowComponent,
                      })
                    : RowComponent
            })}
        </StyledLatestLiveBlogMilestonesContainer>
    )
}

const LiveBlogMilestoneRow = ({
    content,
    isVrTest,
    isLastRow,
    hasBackground,
}: {
    content: LatestLiveBlogContent
    isVrTest?: boolean
    isLastRow: boolean
    hasBackground?: boolean
}) => {
    const { title, publishedDate, isPinned } = content
    const truncTitle = isPinned ? `Pinned: ${title}` : title
    const product = useProduct()

    const publicationDateMessage = getReadableTimeSince({
        publicationDate: publishedDate,
        comparisonDate: new Date().toISOString(),
    })
    const timeOnlyText = format(new Date(publishedDate), 'hh:mm a')

    return (
        <StyledLiveBlogMilestoneRow>
            {/* Styling for the bullet point at the start of the post */}
            <StyledLiveBlogBulletPoint>
                <StyledLiveBlogBulletPointContainer isVrTest={isVrTest}>
                    <IconBulletPoint
                        fill={
                            product === Product.PerthNow ? '#888888' : undefined
                        }
                    />
                </StyledLiveBlogBulletPointContainer>
                {!isPinned && (
                    <StyledLiveBlogBulletMilestoneMark
                        hasBackground={hasBackground}
                    />
                )}
            </StyledLiveBlogBulletPoint>
            {/* Styling the content for row */}
            <StyledLiveBlogRowContentContainer isLastRow={isLastRow}>
                <StyledLiveBlogRowTitle hasBackground={hasBackground}>
                    {truncTitle}
                </StyledLiveBlogRowTitle>
                <StyledLiveBlogRowTimestamp hasBackground={hasBackground}>
                    {publicationDateMessage} - {timeOnlyText}
                </StyledLiveBlogRowTimestamp>
            </StyledLiveBlogRowContentContainer>
        </StyledLiveBlogMilestoneRow>
    )
}
