import { Product, Profile } from '@news-mono/web-common'
import React from 'react'
import { IconPersonSilhouette, useProduct } from '../..'
import { StyledButtonContainer } from '../LiveEvent/LiveEvent.styled'
import {
    StyledLiveEventAuthor,
    StyledLiveEventByline,
    StyledLiveEventBylineText,
    StyledLiveEventBylineTextLink,
    StyledLiveEventHeadshot,
    StyledLiveEventHeadshotLink,
    StyledLiveEventHeadshotWrapper,
    StyledLiveEventTimestamp,
} from './EventPostByline.styled'
import { getEventPostChip, pinnedChipTypes } from './helpers/getEventPostChip'

export interface EventPostBylineProps {
    text?: string
    profile?: Profile[]
    timestamp?: string
    /** Pinned post chip display style. 'full' displays text and icon */
    pinnedPost?: pinnedChipTypes
    /** Happening post label to build chip from */
    happeningPost?: string | null
    entryType?: string
}

export const EventPostByline: React.FC<EventPostBylineProps> = ({
    text,
    profile,
    timestamp,
    pinnedPost,
    happeningPost,
    entryType,
}) => {
    const product = useProduct()

    /* Only create a link if one author exists */
    const authorProfilePage =
        profile?.length === 1 ? `/profile/${profile[0].slug}` : ''

    /* Only show a headshot if one author exists */
    const authorHeadshot =
        profile?.length === 1 ? profile[0]?.thumbnailPhoto : undefined

    const authorName =
        profile && profile.length >= 1
            ? profile.map((profile) => profile.name).join(' &')
            : text

    const timestampFormat =
        product === Product.TheNightly
            ? 'relativeLongFormatAndTimestamp'
            : 'relativeLongFormat'

    switch (product) {
        case Product.SevenNews:
            return (
                <StyledLiveEventByline>
                    {authorHeadshot ? (
                        <StyledLiveEventHeadshotLink to={authorProfilePage}>
                            <StyledLiveEventHeadshot
                                src={authorHeadshot}
                                alt={'Author Headshot'}
                            />
                        </StyledLiveEventHeadshotLink>
                    ) : (
                        <StyledLiveEventHeadshotWrapper>
                            <IconPersonSilhouette />
                        </StyledLiveEventHeadshotWrapper>
                    )}

                    {/* Custom Byline Text */}
                    <StyledLiveEventBylineText>
                        {timestamp && (
                            <StyledLiveEventTimestamp
                                timestamp={timestamp}
                                timestampFormat={'relativeLongFormat'}
                            />
                        )}
                        <StyledLiveEventAuthor>
                            {authorName}
                        </StyledLiveEventAuthor>
                        {/* )} */}
                    </StyledLiveEventBylineText>

                    {/* Container for buttons and pinned post identifier  */}
                    <StyledButtonContainer>
                        {getEventPostChip(pinnedPost, happeningPost, entryType)}
                    </StyledButtonContainer>
                </StyledLiveEventByline>
            )

        case Product.TheWest:
        default:
            return (
                <StyledLiveEventByline>
                    {/* Profile Headshot */}
                    {authorHeadshot && (
                        <StyledLiveEventHeadshotLink to={authorProfilePage}>
                            <StyledLiveEventHeadshot
                                src={authorHeadshot}
                                alt={'Author Headshot'}
                            />
                        </StyledLiveEventHeadshotLink>
                    )}
                    {/* Custom Byline Text */}
                    <StyledLiveEventBylineText>
                        {authorProfilePage ? (
                            <StyledLiveEventBylineTextLink
                                to={authorProfilePage}
                            >
                                <StyledLiveEventBylineText>
                                    {timestamp && (
                                        <StyledLiveEventTimestamp
                                            timestamp={timestamp}
                                            timestampFormat={timestampFormat}
                                        />
                                    )}
                                    <StyledLiveEventAuthor>
                                        {authorName}
                                    </StyledLiveEventAuthor>
                                </StyledLiveEventBylineText>
                            </StyledLiveEventBylineTextLink>
                        ) : (
                            <StyledLiveEventBylineText>
                                {timestamp && (
                                    <StyledLiveEventTimestamp
                                        timestamp={timestamp}
                                        timestampFormat={timestampFormat}
                                    />
                                )}
                                <StyledLiveEventAuthor>
                                    {authorName}
                                </StyledLiveEventAuthor>
                            </StyledLiveEventBylineText>
                        )}
                    </StyledLiveEventBylineText>
                    {/* Container for buttons and pinned post identifier  */}
                    <StyledButtonContainer>
                        {getEventPostChip(pinnedPost, happeningPost, entryType)}
                    </StyledButtonContainer>
                </StyledLiveEventByline>
            )
    }
}
