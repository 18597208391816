/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import {
    DataLayerEventName,
    GalleryEvent,
    ImageSourceWithCaptions,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledThumbnailContainer,
    StyledThumbnailLink,
    StyledThumbnailSection,
} from '../../../content/Gallery/Gallery.styled'
import { ResponsivePicture } from '../../../content/Picture/Picture'
import { ResponsivePictureLayout } from '../../../content/Picture/ResponsivePictureLayouts'
import { themedValue } from '../../../__styling/themed-value'
import { useHistory } from 'react-router'

export interface GalleryThumbnailsProps {
    images: ImageSourceWithCaptions[]
    onEvent: (event: GalleryEvent) => void
    scrollTo: (imageIndex: number) => void
    toggleThumbnails: () => void
    paginationIndex?: number
}

export const GalleryThumbnails: React.FC<GalleryThumbnailsProps> = ({
    images,
    onEvent,
    scrollTo,
    toggleThumbnails,
    paginationIndex = 0,
}) => {
    const theme = useTheme()
    const isV2ArticleEnabled = useFeature('7-news-2-article-page')
    const history = useHistory()
    return (
        <StyledThumbnailContainer>
            <StyledThumbnailSection>
                {images.map((img, i) => (
                    <StyledThumbnailLink
                        key={i}
                        onClick={() => {
                            onEvent({
                                type: DataLayerEventName.galleryThumbnailClick,
                                originator: 'Gallery',
                                payload: {
                                    imageIndex: i + 1 + paginationIndex,
                                },
                            })
                            scrollTo(i + paginationIndex)
                            if (theme.kind === 'sevennews') {
                                history.push(`#${i + 1 + paginationIndex}`)
                            }
                        }}
                        isSevenNewsV2={isV2ArticleEnabled}
                    >
                        <ResponsivePicture
                            layout={ResponsivePictureLayout.ObjectFitContain}
                            image={img}
                            fixedRatio={img.crops['16:9'] ? '16:9' : 'original'}
                            imageWidths={themedValue(theme, {
                                perthnow: {
                                    mobile: '14vw',
                                    tablet: '16vw',
                                    desktop: '106px',
                                    fallbackWidth: 106,
                                },
                                sevennews: {
                                    mobile: '100vw',
                                    tablet: '50vw',
                                    desktop: '110px',
                                    fallbackWidth: 110,
                                },
                                thewest: {
                                    mobile: '40vw',
                                    tablet: '20vw',
                                    desktop: '110px',
                                    fallbackWidth: 110,
                                },
                                fallback: {
                                    mobile: '40vw',
                                    tablet: '20vw',
                                    desktop: '110px',
                                    fallbackWidth: 110,
                                },
                            })}
                        />
                    </StyledThumbnailLink>
                ))}
            </StyledThumbnailSection>
        </StyledThumbnailContainer>
    )
}
