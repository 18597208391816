import {
    DataLayerEventName,
    LiveBlogEvents,
    Product,
    SharingEvent,
    WebLink,
} from '@news-mono/web-common'
import React, { FC, useState } from 'react'
import {
    StyledMilestone,
    StyledMilestonesContainer,
    StyledMilestonesTimeline,
    StyledMilestonesTitle,
    StyledMilestoneTimestamp,
    StyledMilestoneTitle,
} from './LiveEventMilestones.styled'
import { TruncatingContainer } from './utility/TruncatingContainer'
import { useTheme } from '@emotion/react'
import { calcRem } from '../../../__styling'
import { StyledMobilePaddingContainer } from '../../../templates/Publication/TheNightlyPublication/TheNightlyPublication.styled'
import { ConditionalWrapper } from '../../../__helpers/ConditionalWrapper'
import { TNLiveComponent } from '../../../content'
import { EventPostV4DTO } from '@west-australian-newspapers/publication-types'

export interface LiveEventMilestonesProps {
    milestones: EventPostV4DTO[]
    enableTruncation?: boolean
    onEvent: (event: LiveBlogEvents | SharingEvent) => void
    isActiveEvent?: boolean
}

export const LiveEventMilestones: FC<LiveEventMilestonesProps> = ({
    milestones,
    onEvent,
    isActiveEvent = false,
}) => {
    const [dimMilestones, setDimMilestones] = useState(false)
    const theme = useTheme()
    const isTheNightly = theme.kind === Product.TheNightly

    return (
        //add padding to thenightly blocks to offset the luna container
        <ConditionalWrapper
            condition={isTheNightly}
            Wrapper={({ children }) => (
                <StyledMobilePaddingContainer>
                    {children}
                </StyledMobilePaddingContainer>
            )}
        >
            {isTheNightly && isActiveEvent && (
                <TNLiveComponent padding={calcRem(18, 0, 0)} />
            )}
            <TruncatingContainer customStyle={'milestones'}>
                <StyledMilestonesContainer>
                    <StyledMilestonesTitle>Key Events</StyledMilestonesTitle>
                    <StyledMilestonesTimeline>
                        {milestones.map((m) => (
                            <WebLink
                                key={m.id}
                                to={m._self}
                                openExternalInNewWindow={false}
                            >
                                <StyledMilestone
                                    dimmed={!isTheNightly && dimMilestones}
                                    onMouseEnter={() => setDimMilestones(true)}
                                    onMouseLeave={() => setDimMilestones(false)}
                                    onClick={() => {
                                        onEvent({
                                            type: DataLayerEventName.milestoneClick,
                                            originator: 'LiveEvent',
                                            payload: {
                                                publicationId: m.eventId,
                                                postId: m.id,
                                            },
                                        })
                                    }}
                                >
                                    <StyledMilestoneTimestamp
                                        timestampStyle="relative"
                                        time={m.publishedDate}
                                    />
                                    <StyledMilestoneTitle>
                                        {m.title}
                                    </StyledMilestoneTitle>
                                </StyledMilestone>
                            </WebLink>
                        ))}
                    </StyledMilestonesTimeline>
                </StyledMilestonesContainer>
            </TruncatingContainer>
        </ConditionalWrapper>
    )
}
