import React from 'react'
import { IconBlockQuoteNGN } from '@news-mono/component-library'
import {
    Attribution,
    StyledPNPullQuote,
    StyledQuoteText,
} from './PNPullQuote.styled'

export interface PNPullQuoteProps {
    text: string
    attribution?: string
}

export const PNPullQuote: React.FC<PNPullQuoteProps> = ({
    text,
    attribution,
}) => {
    const quote = text.replace(/[“”]/g, '')
    return (
        <StyledPNPullQuote>
            <IconBlockQuoteNGN />
            <StyledQuoteText>{quote}</StyledQuoteText>
            {attribution && (
                <Attribution className="attribution">{attribution}</Attribution>
            )}
        </StyledPNPullQuote>
    )
}
export default PNPullQuote
