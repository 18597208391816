import {
    AllEvents,
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import {
    InlineContentTypes,
    InlineFederalElectionNeedleWidget,
} from '../SharedPublication.routing'
import FederalElectionNeedle from '../../../FederalElectionNeedle/FederalElectionNeedle'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export interface InlineFederalElectionNeedleWidgetProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isInlineFederalElectionNeedleWidget(
    content: InlineContentTypes,
): content is InlineFederalElectionNeedleWidget {
    return content.kind === 'inline-federal-election-needle-widget'
}

export function getInlineFederalElectionNeedleWidget({
    publication,
    inlinePublicationContent,
}: InlineFederalElectionNeedleWidgetProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineFederalElectionNeedleWidget)
        .filter((content) => content.toggle)
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: (
                    <QueryClientProvider client={queryClient}>
                        <FederalElectionNeedle />
                    </QueryClientProvider>
                ),
            }
            return mappedContent
        })
}
