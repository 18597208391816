import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { breakpoint } from '../../../__styling/style-functions/breakpoint'
import { CardMedia } from '../../CardMedia/CardMedia'
import { StyledHeadlineText } from '../../CardText/CardText.styled'
import { StyledLink } from '../../CardText/CardTextInteractions.styled'
import { calcRem } from '../../../__styling'
import { StyledLiveBlogRowTitle } from '../../LiveBlogs/components/LatestLiveBlogMilestones.styled'

interface HeroCardProps {
    mediaPosition: 'left' | 'right'
    isLarge: boolean
    hideBorder: boolean
    bottomPadding: number
}

interface HeroCardChildProps {
    mediaPosition: 'left' | 'right'
    cardPosition: 'left' | 'right'
}

export const StyledHeroCardContainer = styled('div')<{
    cardPosition: 'left' | 'right'
}>(({ theme, cardPosition }) => ({
    width: '100%',
    display: 'block',
    paddingLeft: cardPosition === 'right' ? theme.margins.md : undefined,
    [breakpoint('md')]: {
        gridColumn: cardPosition === 'left' ? '1/4' : '2/5',
    },
}))
export const StyledHeroCardLinkContainer = styled(StyledLink)<{
    cardPosition: 'left' | 'right'
}>(({ theme, cardPosition }) => ({
    width: '100%',
    display: 'block',
    paddingLeft: cardPosition === 'right' ? theme.margins.md : undefined,
    [breakpoint('md')]: {
        gridColumn: cardPosition === 'left' ? '1/4' : '2/5',
    },
}))

export const StyledHeroCardLink = styled(StyledLink)(({ theme }) => {
    return [
        {
            display: 'block',
            width: '100%',

            [`&:is(:focus, :focus-within) ${StyledLiveBlogRowTitle}`]: {
                color: theme.colors.actions.link.hover,
            },
        },
    ]
})

export const TheWestHeroCardClassName = 'StyledTheWestHeroCard'

export const StyledHeroCard = withClass(TheWestHeroCardClassName)(
    styled('div')<HeroCardProps>(
        ({ theme, mediaPosition, isLarge, hideBorder, bottomPadding }) => ({
            textDecoration: 'none',
            display: 'grid',
            gridTemplateColumns: '1fr',
            width: '100%',
            borderBottom: isLarge
                ? `1px solid ${theme.colors.borders.primary}`
                : `none`,
            [breakpoint('sm')]: {
                gridTemplateColumns:
                    mediaPosition === 'right' ? '45% 55%' : '55% 45%',
            },
            [breakpoint('md')]: {
                height: bottomPadding ? '100%' : 'auto',
                paddingBottom:
                    bottomPadding && isLarge ? calcRem(bottomPadding) : 0,
                borderBottom: hideBorder
                    ? 'none'
                    : isLarge
                    ? `1px solid ${theme.colors.borders.primary}`
                    : 'none',
            },
        }),
    ),
)

export const StyledMediaWrapper = styled('div')<HeroCardChildProps>(
    ({ mediaPosition }) => ({
        width: '100%',
        order: mediaPosition === 'right' ? 2 : 1,
    }),
)
export const StyledHeroCardMedia = styled(CardMedia)(({ theme }) => ({
    marginTop: theme.margins.sm,
    marginBottom: 0,
    width: '100%',
    [breakpoint('sm')]: {
        marginTop: 0,
    },
}))
export const StyledContent = styled('div')<HeroCardChildProps>(
    ({ theme, mediaPosition }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: theme.margins.sm,

        paddingRight: mediaPosition === 'right' ? theme.margins.md : 0,
        paddingLeft: mediaPosition === 'left' ? theme.margins.md : 0,
        paddingBottom: theme.margins.sm,
        order: mediaPosition === 'right' ? 1 : 2,
        [`& ${StyledHeadlineText}`]: {
            fontWeight: 400,
        },
    }),
)
