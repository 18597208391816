import { useTheme } from '@emotion/react'
import { LinkDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { PerthNowButton } from '../../buttons/PerthnowButton/PerthnowButton'
import { StyledBuyNowWrapper, StyledBuyNowLink } from './BuyNow.styled'

export interface BuyNowProps {
    text: string
    link: LinkDTO
}
const BuyNow: React.FC<BuyNowProps> = ({ text, link }) => {
    const theme = useTheme()
    const isPerthNow = theme.kind === 'perthnow'

    return (
        <StyledBuyNowWrapper as={isPerthNow ? 'p' : 'div'}>
            {isPerthNow ? (
                <PerthNowButton
                    type={'primary'}
                    size={'S'}
                    action={{
                        type: 'link',
                        to: link.href,
                        target: link.target || '_blank',
                    }}
                    text={text}
                />
            ) : (
                <StyledBuyNowLink
                    to={link.href}
                    target={link.target || '_blank'}
                >
                    {text}
                </StyledBuyNowLink>
            )}
        </StyledBuyNowWrapper>
    )
}

export default BuyNow
