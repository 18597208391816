import { AllEvents, Block } from '@news-mono/web-common'
import React from 'react'
import { Logger } from 'typescript-log'
import { GetVideoQueue } from '../../../../../content/Video/PlayerInterface'
import { StyledPNListItem, StyledPNTextListItem } from './PNList.styled'
import { PNArticleBlock } from '../PNArticleBlock'

export function PNListItem({
    blocks,
    onEvent,
    adUnitPath,
    publicationKind,
    publicationPathname,
    getVideoQueue,
    showInlineVideoTitles,
    log,
}: {
    blocks: Block[]
    onEvent: (event: AllEvents) => void
    adUnitPath: string
    getVideoQueue: GetVideoQueue | undefined
    showInlineVideoTitles: boolean | undefined
    publicationKind?: string
    publicationPathname: string | undefined
    log: Logger
}) {
    return (
        <>
            {blocks.map((block, index) =>
                block.kind === 'text' ? (
                    <StyledPNTextListItem
                        key={index}
                        text={block.text}
                        intentions={block.intentions}
                        tag="li"
                        onEvent={onEvent}
                    />
                ) : (
                    <StyledPNListItem key={index}>
                        <PNArticleBlock
                            key={index}
                            block={block}
                            adUnitPath={adUnitPath}
                            getVideoQueue={getVideoQueue}
                            log={log}
                            onEvent={onEvent}
                            publicationKind={publicationKind}
                            publicationPathname={publicationPathname}
                            showInlineVideoTitles={showInlineVideoTitles}
                        />
                    </StyledPNListItem>
                ),
            )}
        </>
    )
}
