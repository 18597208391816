import {
    isPublicationCardItemWithVideo,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'

import React from 'react'
import { BaseCardProps } from '../Card'
import {
    CardLinkWrapper,
    StyledCardContentContainer,
    StyledHeadline,
} from './ArticleCard.styled'
import {
    CardBodyWrapper,
    LargeCardMedia,
    CardContainer,
    Byline,
    Teaser,
} from '../components'
import { getByline, getTopicLink } from '../utils'
import { getEditorialType, getFundingType } from '../../../templates'
import { TNLiveComponent } from '../../../content'
import { FundingLabel } from '../../../advertising/FundingLabel/FundingLabel'
import { BylineFundingTypeWrapper } from '../../../publication/Byline/components/TheNightly/Byline.styled'
import { PublishedTime } from '../components/card-body/PublishedTime'
import { StyledReadMoreWrapper } from '../components/card-body/ReadMore.styled'
import { CardContentWithLiveBlogs } from '../../LiveBlogs'
import { ReadMoreWithLiveBlogs } from '../components/ReadMoreWithLiveBlogs'

export interface ArticleCardProps extends BaseCardProps<'article-card'> {
    isStandalonePage?: boolean
    isStandaloneCard?: boolean
}

export const ArticleCard = ({
    item,
    onEvent,
    isStandalonePage,
    adTargeting,
    disableImageLazyLoad,
}: Omit<ArticleCardProps, 'kind'>) => {
    const classificationEnabled = true

    const { loaded: isLoaded } = item
    const result = isLoaded ? item.result : undefined
    const articleLink = toLinkWithHint(item)
    const byline = getByline(item)
    const topicLink = getTopicLink(item)
    const editorialType = isLoaded
        ? getEditorialType(item.result, classificationEnabled)
        : undefined
    const fundingType = isLoaded
        ? getFundingType(item.result, classificationEnabled)
        : undefined
    const showNormalClassification = !!editorialType
    const inlineVideoEnabled = useFeature('tn-inline-video-enabled')
    const willPlayVideoInline =
        isLoaded && inlineVideoEnabled
            ? isPublicationCardItemWithVideo(item.result)
            : false
    const timestamp = result?.lastUpdated ?? result?.publicationDate
    const isUpdated = timestamp !== result?.publicationDate

    return (
        <CardContainer>
            {willPlayVideoInline ? (
                <LargeCardMedia
                    item={item}
                    onEvent={onEvent}
                    willPlayVideoInline={willPlayVideoInline}
                    adUnitPath={adTargeting?.adUnitPath}
                    disableImageLazyLoad={disableImageLazyLoad}
                />
            ) : (
                <CardLinkWrapper to={articleLink}>
                    {editorialType && !showNormalClassification && (
                        <TNLiveComponent
                            overlayOnImage={true}
                            showBackground={true}
                        />
                    )}
                    <LargeCardMedia
                        item={item}
                        onEvent={onEvent}
                        willPlayVideoInline={willPlayVideoInline}
                        adUnitPath={adTargeting?.adUnitPath}
                        disableImageLazyLoad={disableImageLazyLoad}
                    />
                </CardLinkWrapper>
            )}

            {result && (
                <CardBodyWrapper>
                    <BylineFundingTypeWrapper>
                        <Byline
                            editorialType={editorialType}
                            topicLink={topicLink}
                            topicTitle={result.primaryTopic.title}
                            bylineText={byline.text}
                            bylineLink={byline.link}
                            allowCommenting={result.allowCommenting}
                            articleUrl={result._self}
                        />
                        {fundingType && (
                            <FundingLabel
                                fundingType={fundingType}
                                linkToDisclaimer={true}
                            >
                                {fundingType}
                            </FundingLabel>
                        )}
                    </BylineFundingTypeWrapper>
                    <StyledCardContentContainer>
                        <CardLinkWrapper to={articleLink}>
                            <StyledHeadline
                                as={isStandalonePage ? 'h2' : undefined}
                            >
                                {result.shortHeadline}
                            </StyledHeadline>
                        </CardLinkWrapper>
                        <CardContentWithLiveBlogs
                            result={result}
                            getLinkComponentWithChildren={(props) => (
                                <CardLinkWrapper {...props} />
                            )}
                            DefaultComponent={<Teaser>{result.teaser}</Teaser>}
                            onEvent={onEvent}
                        />
                        <CardLinkWrapper to={articleLink}>
                            <StyledReadMoreWrapper>
                                <PublishedTime
                                    isUpdatedTimestamp={isUpdated}
                                    publicationDate={timestamp}
                                />
                                <ReadMoreWithLiveBlogs
                                    readTime={result.readTimeMinutes}
                                    postCount={result.entries?.total}
                                />
                            </StyledReadMoreWrapper>
                        </CardLinkWrapper>
                    </StyledCardContentContainer>
                </CardBodyWrapper>
            )}
        </CardContainer>
    )
}
