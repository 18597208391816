import { colorsPN } from '@news-mono/design-tokens'
import React from 'react'
import { StyledMessageIcon } from './IconNextGenPerthNowComment.styled'

export const IconNextGenPerthNowComment: React.FC<{ color?: string }> = ({
    color = colorsPN.text.primary,
}) => (
    <StyledMessageIcon
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.5 8.16669C14.5023 9.0466 14.2967 9.91461 13.9 10.7C13.4296 11.6412 12.7065 12.4328 11.8116 12.9862C10.9168 13.5396 9.8855 13.8329 8.83333 13.8334C7.95342 13.8356 7.08541 13.6301 6.3 13.2334L2.5 14.5L3.76667 10.7C3.36995 9.91461 3.16437 9.0466 3.16667 8.16669C3.16707 7.11452 3.46041 6.08325 4.01381 5.18839C4.56722 4.29352 5.35884 3.5704 6.3 3.10002C7.08541 2.70331 7.95342 2.49772 8.83333 2.50002H9.16667C10.5562 2.57668 11.8687 3.16319 12.8528 4.14726C13.8368 5.13132 14.4233 6.44379 14.5 7.83335V8.16669Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </StyledMessageIcon>
)
IconNextGenPerthNowComment.displayName = 'IconNextGenPerthNowComment'

export default IconNextGenPerthNowComment
