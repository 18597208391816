import styled from '@emotion/styled'
import { metrics, ThemeMargins } from '../../../__styling/settings/metrics'
import { breakpoint, calcRem } from '../../../__styling/style-functions'
import { StyledTimeToRead } from '../../cards/ContentCard/ContentCard.styled'
import { tokens } from '@news-mono/design-tokens'
import { colors } from '../../../__styling'

export interface GridProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const StyledSixCardCollectionWrapper = styled('div')(() => [
    {
        '& button': {
            textTransform: 'lowercase',
            background: colors.sevennews.nero50,
            height: calcRem(40),
            width: calcRem(300),
        },
    },
])

export const StyledSixCardCollection = styled('div')<GridProps>(
    ({ theme, verticalSpacing, verticalGutters }) => [
        {
            margin: '0 auto',
            width: '100%',
            display: 'grid',
            gridRowGap: calcRem(16),
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `
            "one"
            "one"
            "two"
            "two"
            "three"
            "four"
            "five"
            "six"
            `,
            [breakpoint('sm')]: {
                gridColumnGap: calcRem(16),
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '2fr 2fr 1fr 1fr',
                gridTemplateAreas: `
                "one one"
                "two two"
                "three four"
                "five six"
                `,
            },

            [breakpoint('md')]: {
                '& :nth-of-type(n+3):nth-of-type(-n+5)': {
                    [`${StyledTimeToRead}`]: {
                        position: 'relative',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            left: 0,
                            bottom: calcRem(-10),
                            width: '30%',
                        },
                    },
                },

                gridTemplateColumns: `4fr 2fr`,
                gridTemplateRows: `1fr 1fr 1fr 1fr`,
                gridTemplateAreas: `
            "one three"
            "one four"
            "two five"
            "two six"
            `,
            },
            marginBottom:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
            marginTop:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),

            paddingTop:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[0]]),
            paddingBottom:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[1]]),
            '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]': {
                border: `${calcRem(1.92)} solid ${colors.white}`,
                top: 'unset',
                left: 'unset',
                bottom: calcRem(16),
                right: calcRem(16),
                width: calcRem(24),
                height: calcRem(24),
                '&::before': {
                    left: '41%',
                    top: '59%',
                    backgroundSize: ` ${calcRem(5.53)} ${calcRem(9.76)}`,
                },
            },

            '& :nth-of-type(n+3)': {
                '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]':
                    {
                        border: `${calcRem(1.92)} solid ${colors.white}`,
                        top: 'unset',
                        left: 'unset',
                        bottom: calcRem(10),
                        right: calcRem(10),
                        width: calcRem(16),
                        height: calcRem(16),
                        '&::before': {
                            left: '41%',
                            top: '79%',
                            backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
                        },
                    },
            },
            '& [class*="StyledMediaIndicator"][class*="media_indicator_gallery_icon"]':
                {
                    border: 'none',
                    '&::before': {
                        backgroundSize: 'contain',
                        width: '100%',
                        height: '100%',
                    },
                },
        },
    ],
)
export const PaginationLinksWrapperStyled = styled('div')(() => [
    {
        position: 'fixed',
        bottom: calcRem(70),
        height: calcRem(40),
        zIndex: 1,
        left: '50%',
        [breakpoint('sm')]: { bottom: 0 },
    },
])
export const PaginationLinkStyled = styled('a')(() => [
    {
        color: colors.black,
    },
])
export const CurrentPageStyled = styled('span')(() => [
    {
        color: colors.black,
        paddingLeft: calcRem(4),
        paddingRight: calcRem(4),
    },
])
