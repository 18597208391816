import { GptAdSlotDefinition } from './gpt-ad-slot-definintion'
import { adsDebug } from './ads-debug'
import { AdRefreshConfigSection } from './AdRefreshConfig'

const refreshDebug = adsDebug.extend('ad-slot-refresh')
const adRefreshDebug = refreshDebug.extend('general')
const adRefreshingDebug = refreshDebug.extend('refresh')

export class AdSlotRefresh {
    private slot: GptAdSlotDefinition
    private refreshCallback: () => void
    private stopRefreshIds: string[]

    private refreshCount = 0
    private visible = true
    private hovered = false
    private interacted = false
    private inBackground = false

    private observer: IntersectionObserver | null = null
    private adElement: HTMLElement | null = null

    private visibilityInterval: number | null = null
    private clickInterval: number | null = null

    constructor(
        slot: GptAdSlotDefinition,
        refreshCallback: () => void,
        private adRefreshValues: AdRefreshConfigSection,
        stopRefreshIds: string[],
    ) {
        this.slot = slot
        this.refreshCallback = refreshCallback
        this.stopRefreshIds = stopRefreshIds
    }

    /**
     * Return a boolean value depending on if an element id
     * ad slot is already registered.
     * @param elementId - the elementId to check by.
     */
    public isRegistered(elementId: string) {
        return this.adElement?.id === elementId
    }

    /**
     * Register a new slot for 'refreshing' capabilities.
     * @param elementId the element id to find the slot by.
     * @returns
     */
    public registerSlotRefresh(elementId: string) {
        this.adElement = document.getElementById(elementId)
        if (!this.adElement) return

        adRefreshDebug(`Initialising slot refresh for slot id: ${this.slot.id}`)

        const handleVisibilityChange = (
            entries: IntersectionObserverEntry[],
        ) => {
            const [entry] = entries
            if (entry.isIntersecting) {
                adRefreshDebug(
                    `Updating visibility to TRUE for slot id: ${this.slot.id}`,
                )
                this.onVisible()
            } else {
                adRefreshDebug(
                    `Updating visibility to FALSE for slot id: ${this.slot.id}`,
                )
                this.onHidden()
            }
        }

        this.observer = new IntersectionObserver(handleVisibilityChange, {
            threshold: 0.5,
        })

        this.observer.observe(this.adElement)

        // Use mouseover/mouseout for event delegation
        this.adElement.addEventListener('mouseover', () => {
            adRefreshDebug(`Setting hover to TRUE for slot id: ${this.slot.id}`)
            this.hovered = true
        })

        this.adElement.addEventListener('mouseout', () => {
            adRefreshDebug(
                `Setting hover to FALSE for slot id: ${this.slot.id}`,
            )
            this.hovered = false
        })

        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                adRefreshDebug(
                    `Updating inBackground to TRUE for slot id: ${this.slot.id}`,
                )
                this.inBackground = true
            } else {
                adRefreshDebug(
                    `Updating inBackground to FALSE for slot id: ${this.slot.id}`,
                )
                this.inBackground = false
            }
        })

        // TODO: Handle Ad Clicking
    }

    /**
     * The ad slot has come into the viewframe, so let's set handle the presentation of it,
     * including beginning a visibility check refresh loop.
     */
    private onVisible() {
        this.visible = true
        if (this.visibilityInterval === null) {
            this.startVisibilityCheck()
        }
    }

    /**
     * Will start the interval checks to refresh the ad slot. If the ad is currently being hovered,
     * has been interacted with (clicked) or is not visible, then this will not allow a refresh. Otherwise
     * it will send off to `this.refreshAd()` function
     */
    private startVisibilityCheck() {
        this.visibilityInterval = window.setInterval(() => {
            adRefreshingDebug(
                `Slot: ${this.slot.id}, Hovered: ${this.hovered}, Interacted: ${this.interacted}, Visible: ${this.visible}, Refresh Count: ${this.refreshCount}, In Background: ${this.inBackground}`,
            )
            if (
                !this.hovered &&
                !this.interacted &&
                !this.inBackground &&
                this.visible &&
                this.refreshCount < this.adRefreshValues.maximumAdRefreshes &&
                !this.stopRefreshIds.includes(this.slot.id) //check the list of slotIDs not to refresh. See "Check if Advertiser Repeat" in gpt-api.ts
            ) {
                this.refreshAd()

                // Now that refresh is complete, let's just disable the interval to stop continuous tasks.
                if (
                    this.refreshCount >= this.adRefreshValues.maximumAdRefreshes
                ) {
                    this.disconnect()
                }
            } else {
                adRefreshingDebug(`Slot: ${this.slot.id} not refreshed`)
            }
        }, this.adRefreshValues.adRefreshRateMs)
    }

    /**
     * The ad slot has come out of the viewframe, so let's set handle the hiding of it.
     */
    private onHidden() {
        this.visible = false
        if (this.visibilityInterval) {
            clearInterval(this.visibilityInterval)
            this.visibilityInterval = null
        }
    }

    /**
     * Increase the refresh count, so that ads can only be refreshed to a certain amount, and
     * also call the refresh callback, which will fire it off to get another ad bidding session.
     */
    private refreshAd() {
        this.refreshCallback()
        this.refreshCount += 1
        adRefreshingDebug(`Refreshing the ad for slot id: ${this.slot.id}`)
    }

    /**
     * Disconnect the ad from the window, this will unhook all the event listeners, and delete
     * any current observers watching for hover, etc.
     */
    public disconnect() {
        adRefreshDebug(
            `Disconnecting slot refresh for slot id: ${this.slot.id}`,
        )
        if (this.observer) {
            this.observer.disconnect()
        }
        if (this.visibilityInterval) {
            clearInterval(this.visibilityInterval)
            this.visibilityInterval = null
        }

        if (this.adElement) {
            this.adElement.removeEventListener('mouseover', () => {
                this.hovered = true
            })
            this.adElement.removeEventListener('mouseout', () => {
                this.hovered = false
            })
            this.adElement.removeEventListener('click', () => {
                this.interacted = false
            })
            this.adElement.removeEventListener('visibilitychange', () => {
                this.inBackground = false
            })
        }
    }
}
