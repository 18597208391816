import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { ThemeMargins } from '../__styling'
import FederalElectionNeedle from './FederalElectionNeedle'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export interface FederalElectionNeedleRouteProps {}

export const FederalElectionNeedleRegistration = createRegisterableComponent(
    'federal-election-needle',
    (props: FederalElectionNeedleRouteProps) => {
        return (
            <QueryClientProvider client={queryClient}>
                <FederalElectionNeedle />
            </QueryClientProvider>
        )
    },
)
