import { SevenNewsSections } from '@west-australian-newspapers/publication-types'
import { BaseSectionValues } from '.'
import { SocialLinkValues } from '../../../navigation'
import { colors } from '../colors'

export type SevenNewsSection = keyof SevenNewsSections

interface Colors {
    primaryColor: string
    secondaryColor?: string
}

interface DesktopNavColors extends Colors {
    primaryColor: string
    hoverColour: string
    textColor: string
    hoverTextColor: string
    boxShadow?: string
    backgroundColor?: string
    stickyText?: string
}

interface ColorsWithHover extends Colors {
    hoverColor: string
}

interface CardsColors {
    kicker: ColorsWithHover
    headline: ColorsWithHover
    list: ColorsWithHover
    timestamp: ColorsWithHover
}

interface LocationSelectorColors {
    headerBackground: string
    dropdownBackground: string
    hoverColor: string
    focusColor: string
    selectedColor: string
    textColor: string
}

interface FlyoutNavColors {
    primaryColour: string
    background: string
    mainSectionColour: string
    mainSectionTextColour: string
    mainSectionHoverColour: string
    mainSectionActiveColour: string
    mainSectionFocusColour: string
    hoverColour: string
    focusColour: string
    textColour: string
    scrollBarBase: string
    scrollBarThumb: string
    linkColour?: string
}

export interface SevenNewsSectionValues extends BaseSectionValues {
    focusColor: string
    flyoutNav: FlyoutNavColors
    footerNav: Colors
    desktopNav: DesktopNavColors
    locationSelector: LocationSelectorColors
    sectionHeader: { element: ColorsWithHover; text: ColorsWithHover }
    button: ColorsWithHover
    video: Colors & { playOverlay: string; focusColor: string }
    breadCrumb: ColorsWithHover
    cards: CardsColors
    tag: Colors
    socialLinks?: SocialLinkValues
    title: string
    link: Colors
}

export type SevenNewsSectionProps = {
    [section in SevenNewsSection]: SevenNewsSectionValues
}

export const sevenNewsSocialLinks = {
    twitter: 'https://x.com/7NewsAustralia/',
    facebook: 'https://facebook.com/7NewsAustralia/',
    instagram: 'https://instagram.com/7newsaustralia',
    email: 'mailto:7ndtips@seven.com.au',
    youtube: 'https://www.youtube.com/@7news',
    tiktok: 'https://www.tiktok.com/@7newsaustralia?lang=en',
}

export const sevenNewsSectionDefinitions: SevenNewsSectionProps = {
    default: {
        primaryColor: colors.sevennews.red,
        focusColor: colors.sevennews.charade,
        flyoutNav: {
            primaryColour: colors.sevennews.red,
            background: colors.white,
            hoverColour: colors.sevennews.darkRed,
            mainSectionColour: colors.sevennews.red,
            mainSectionTextColour: colors.white,
            mainSectionHoverColour: colors.sevennews.darkRed,
            mainSectionActiveColour: colors.sevennews.darkRed,
            mainSectionFocusColour: colors.white,
            focusColour: colors.white,
            textColour: colors.white,
            scrollBarBase: 'rgba(182,9,32,0.03)',
            scrollBarThumb: colors.sevennews.darkRed,
            linkColour: colors.sevennews.flyoutLinkRed,
        },
        footerNav: {
            primaryColor: colors.sevennews.red,
        },
        desktopNav: {
            primaryColor: colors.white,
            hoverColour: colors.sevennews.red,
            textColor: colors.sevennews.charade,
            hoverTextColor: colors.white,
            boxShadow: `0px 10px 16px ${colors.sevennews.boxShadow}`,
            backgroundColor: colors.sevennews.gray98,
        },
        locationSelector: {
            headerBackground: colors.sevennews.red,
            dropdownBackground: colors.sevennews.red,
            hoverColor: colors.sevennews.darkRed,
            textColor: colors.white,
            focusColor: colors.white,
            selectedColor: colors.sevennews.red,
        },
        sectionHeader: {
            element: {
                primaryColor: colors.sevennews.red,
                hoverColor: colors.sevennews.red,
            },
            text: {
                primaryColor: colors.black,
                hoverColor: colors.sevennews.red,
            },
        },
        button: {
            primaryColor: colors.sevennews.red,
            hoverColor: colors.sevennews.darkRed,
        },
        video: {
            primaryColor: colors.sevennews.red,
            playOverlay: colors.sevennews.red,
            focusColor: colors.sevennews.darkRed,
        },
        breadCrumb: {
            primaryColor: colors.sevennews.red,
            hoverColor: colors.sevennews.darkRed,
        },
        cards: {
            kicker: {
                primaryColor: colors.sevennews.red,
                hoverColor: colors.sevennews.red,
            },
            timestamp: {
                primaryColor: colors.sevennews.red,
                hoverColor: colors.sevennews.red,
            },
            headline: {
                primaryColor: colors.sevennews.charade,
                hoverColor: colors.sevennews.red,
            },
            list: {
                primaryColor: colors.sevennews.charade,
                hoverColor: colors.sevennews.red,
            },
        },
        tag: {
            primaryColor: colors.sevennews.red,
        },
        link: {
            primaryColor: colors.sevennews.red,
        },
        socialLinks: sevenNewsSocialLinks,
        title: '7NEWS',
    },
}
