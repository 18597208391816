import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { Link } from 'react-router-dom'
import {
    mapTitleMixin,
    perthNowNGNTextMixin,
} from '../../../mixins/TextMixin/TextMixin.theme'
import {
    Breakpoints,
    calcRem,
    visuallyhidden,
    breakpoint,
    visuallyhiddenOff,
    easing,
} from '../../../__styling'
import { zIndex } from '../../../__styling/settings/z-index'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { TopicPageType } from './PNSectionHeader'

export interface SectionHeaderWrapperProps {
    marginBottom: number
    invisibleHeaderBreakpoint?: keyof Breakpoints
    topicHeaderType?: TopicPageType
}

export const SectionHeaderContainer = styled.div<SectionHeaderWrapperProps>(
    ({ invisibleHeaderBreakpoint, marginBottom, topicHeaderType }) => {
        return [
            {
                display: 'flex',
                flex: '1 0 auto',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                maxWidth: '100%',
                marginBottom: marginBottom,
                transition: 'margin 0.25s, padding 0.25s',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                flexDirection: 'column',
                gap: calcRem(metricsPN.spacing['4XS']),
            },
            invisibleHeaderBreakpoint !== undefined && {
                ...visuallyhidden,

                [breakpoint(invisibleHeaderBreakpoint || 'md')]: {
                    ...visuallyhiddenOff,
                },
            },
            ({ topicHeaderType }) =>
                topicHeaderType
                    ? {
                          margin: calcRem(
                              metricsPN.spacing['L'],
                              0,
                              metricsPN.spacing['XL'],
                          ),
                          gap:
                              topicHeaderType === 'secondary' ||
                              topicHeaderType === 'tertiary'
                                  ? 0
                                  : calcRem(metricsPN.spacing['4XS']),

                          [breakpoint('sm')]: {
                              margin: calcRem(
                                  metricsPN.spacing['L'],
                                  0,
                                  metricsPN.layout.section['tablet'],
                              ),
                          },
                          [breakpoint('lg')]: {
                              margin: calcRem(
                                  metricsPN.layout.section['desktop'],
                                  0,
                              ),
                          },
                      }
                    : {},
        ]
    },
)
export interface StyledHeadingProps {
    mainSection?: boolean
    hasPadding?: boolean
    hasLogo?: boolean
    isTopicPageHeader?: boolean
}

export const Heading = styled.h2<StyledHeadingProps>(
    ({ mainSection, hasLogo, isTopicPageHeader }) => {
        const headerMixin = mapTitleMixin(mainSection ? '2XL' : 'M')
        return [
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                margin: 0,
                position: 'relative',
                color: isTopicPageHeader
                    ? colorsPN.text.primary
                    : colorsPN.text.brand,
                flex: 1,
                svg: hasLogo
                    ? {
                          height: calcRem(22),
                          marginLeft: calcRem(3),
                          transform: 'translateY(1px)',
                      }
                    : undefined,
                ...headerMixin.small.bold,
                [breakpoint('sm')]: headerMixin.large.bold,
            },
        ]
    },
)

export const HeadingWrapper = styled.div(() => {
    return {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        gap: calcRem(metricsPN.spacing['4XS']),
        alignItems: 'center',
        minHeight: calcRem(44),
        width: '100%',
        justifyContent: 'space-between',
    }
})

export const HeadingLink = styled(WebLink)<{ hideOnMobile?: boolean }>(
    ({ hideOnMobile = 'false' }) => {
        return {
            textDecoration: 'none',
            zIndex: zIndex.common.link,
            cursor: 'pointer',
            transition: `background-color ${easing.easeOut.fast}`,
            color: colorsPN.text.brand,
            display: hideOnMobile ? 'none' : 'flex',
            alignItems: 'center',
            ...perthNowNGNTextMixin.label.L.medium,

            '&:focus-visible': {
                outline: 'none',
                textDecoration: 'underline',
            },

            '&:hover': {
                textDecoration: 'underline',
            },

            [breakpoint('md')]: {
                display: 'flex',
            },
        }
    },
)

export const StyledNav = styled.nav(() => {
    const sideMargins = metricsPN.spacing['S'] * 2

    return {
        // Extend the width of the nav to account for the side paddings
        width: `calc(100% + ${calcRem(sideMargins)})`,
        overflowX: 'scroll',
        margin: calcRem(0, -metricsPN.spacing['S']),
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [breakpoint('md')]: {
            width: '100%',
            margin: 0,
            overflowX: 'unset',
        },
    }
})

export const NavLinkList = styled.ul({
    listStyle: 'none',
    margin: 0,
    width: '100%',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    gap: `0 ${calcRem(metricsPN.spacing['XL'])}`,
    flexWrap: 'unset',
    paddingLeft: calcRem(metricsPN.spacing['S']),
    paddingTop: calcRem(metricsPN.spacing['3XS']),
    paddingBottom: calcRem(metricsPN.spacing['3XS']),

    [breakpoint('md')]: {
        paddingLeft: 0,
        flexWrap: 'wrap',
        overflowX: 'unset',
    },
})

export const NavLinkWrapper = styled.li({
    width: 'auto',
    flex: '0 1 auto',
    textAlign: 'center',
})

export const LocalNavLink = styled(Link)(({}) => {
    return [
        {
            display: 'block',
            whiteSpace: 'nowrap',
            position: 'relative',
            textDecoration: 'none',
            height: '100%',
            paddingTop: calcRem(metricsPN.spacing['3XS']),
            paddingBottom: calcRem(metricsPN.spacing['3XS']),
            zIndex: zIndex.common.link,

            '&:focus-visible': {
                textDecoration: 'underline',
                outline: 'revert',
                outlineOffset: calcRem(metricsPN.spacing['4XS']),
                borderRadius: `${metricsPN.radius['S']}px`,
            },

            color: colorsPN.text.primary,

            '&:hover': {
                textDecoration: 'underline',
            },

            '&:active': {
                color: colorsPN.text.brand,
            },
        },
        perthNowNGNTextMixin.label.L.medium,
    ]
})

export const WebNavLink = LocalNavLink.withComponent(WebLink)
