import React, { useContext, useEffect } from 'react'
import { useToggleState, ScriptLoadResult } from '@news-mono/web-common'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TaboolaContext } from '../TaboolaScriptProvider'
import { StyledInlineTaboola } from './InlineTaboola.styled'

const InlineTaboola: React.FC = () => {
    const toggles = useToggleState()
    const isEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7news-taboola-midarticle',
    )

    return isEnabled ? (
        <StyledInlineTaboola id="taboola-mid-article-thumbnails" />
    ) : (
        <></>
    )
}

export default InlineTaboola
