import {
    breakpointSwitch,
    getComponentTheme,
    Theme,
    ThemeConfig,
} from '../../__styling'

export const textMixin = <T extends ThemeConfig>(
    theme: Theme<T>,
    textType: T['textType'],
) => {
    const textMixinTheme = getComponentTheme(theme, 'textMixin')

    const textStyles = textMixinTheme[textType]

    if (!textStyles) {
        return {}
    }

    return breakpointSwitch(textStyles, (fontRules) => fontRules)
}
