import { AdState, AllEvents, TogglesReduxState } from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import { getInlinePositionedAds } from '../../../templates/Publication/lib/get-inline-positioned-ads'
import { getInlinePositionedImageLink } from '../../../templates/Publication/lib/get-inline-positioned-imagelink'
import { getInlinePositionedPointer } from '../../../templates/Publication/lib/get-inline-positioned-pointer'
import { getInlinePositionedTaboolaAmp } from '../lib/get-inline-positioned-taboola.amp'
import { getInlinePositionedTaboola } from '../lib/get-inline-positioned-taboola.web'
import { MetaTypes, PublicationTypes } from '../SharedPublication.routing'
import { getInlinePositionedContextualLink } from './contextual-link/get-contextual-link-seven'
import { seoTestArticleIds7News } from '../../../advertising'
import { getInlinedPositionedNightlyPromoBanner } from '../lib/get-inline-positioned-nightly-promo-banner'
import { getInlineFederalElectionNeedleWidget } from '../lib/get-inline-positioned-federal-election-needle-widget'

export function getSevenNewsInlinePositionedContent(props: {
    adState: AdState
    publication: PublicationTypes
    meta: MetaTypes
    log: Logger
    toggles: TogglesReduxState
    onEvent: (event: AllEvents) => void
}) {
    if (!props.publication) {
        return []
    }

    // inline content should not be rendered on gallery publications
    if (
        props.publication.kind === 'gallery' ||
        props.meta.kind === 'gallery' ||
        !props.meta.inlinePublicationContent
    ) {
        return []
    }

    return [
        ...(seoTestArticleIds7News.some(
            (articleId) => articleId === props.publication?.id.toLowerCase(),
        )
            ? []
            : getInlinePositionedAds({
                  adState: props.adState,
                  publication: props.publication,
                  inlinePublicationContent: props.meta.inlinePublicationContent,
                  onEvent: props.onEvent,
              })),
        ...getInlinePositionedPointer({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedImageLink({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedContextualLink({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            toggles: props.toggles,
        }),
        ...getInlinePositionedTaboolaAmp({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedTaboola({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinedPositionedNightlyPromoBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlineFederalElectionNeedleWidget({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
    ]
}
