import {
    Profile,
    PublicationEvents,
    SavedArticlesSaveEvent,
    SavedArticlesUnsaveEvent,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledBylineFooter,
    StyledBylineHeading,
    StyledBylineSubHeading,
    StyledBylineWrapper,
    StyledBylineHeadingLink,
    StyledBylineContainer,
    BylineFundingTypeWrapper,
    StyledBylineAsRowWrapper,
} from './Byline.styled'
import { getProfileLink } from '../../helpers/getProfileLink'
import { Bullet } from '../../../../cards/TheNightly/components'
import { CommentButtonTN } from '../../../../coral/CommentButtonTN'
import { getReadTimeForDisplay } from '../../../../__helpers/get-read-time'
import { FundingLabel } from '../../../../advertising/FundingLabel/FundingLabel'
import { FundingType } from '../../../../templates'
import { isMobileViewport } from '../../../../__helpers'
import { SaveArticleToggle } from '../../../../user-interactions/SaveArticleToggle/SaveArticleToggle'
import { FeatureToggle } from '../../../../feature-toggling'
import { PublishedTime } from '../../../../cards/TheNightly/components/card-body/PublishedTime'
import { isLiveEvent } from '../../../../__helpers/is-live-event'

type BylineTextProps = {
    profile: Profile[] | undefined
    source: string | undefined
    timestamp?: string | undefined
    timestampUpdated?: string | undefined
    publicationKind: string | undefined
    text: string | undefined
    isContentPartner: boolean
    allowCommenting: boolean
    url?: string
    topic?: string
    readTimeMinutes?: number
    isLink?: boolean
    fundingType?: FundingType | undefined
    id?: string
    onEvent?: (
        event:
            | PublicationEvents
            | SavedArticlesSaveEvent
            | SavedArticlesUnsaveEvent,
    ) => void
}
export const BylineText: React.FC<BylineTextProps> = ({
    profile,
    source,
    timestamp,
    timestampUpdated,
    publicationKind,
    text,
    allowCommenting,
    url,
    topic,
    readTimeMinutes,
    isLink,
    fundingType,
    id,
    onEvent,
}) => {
    const profileLink = profile && getProfileLink(profile)
    const isMobile = isMobileViewport()

    /**                   ↓----Byline Text----↓
     *  |~~~~~~~~~~~|~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~~~~~~~~~~~~~~~|
     *  | profile   |   Top Row // Name             |               |
     *  | pic       |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| Container     |
     *  |           |   Middle Row // Publisher     |               |
     *  |___________|~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| Container     |
     *              |   Bottom Row // Min read/Date |               |
     *              |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|_______________|
     */

    return (
        <StyledBylineContainer>
            <StyledBylineWrapper>
                {/* Use a clickable link if one exists for the profile */}
                {profileLink && isLink ? (
                    <StyledBylineHeadingLink to={profileLink}>
                        {text}
                    </StyledBylineHeadingLink>
                ) : (
                    <StyledBylineHeading>{text}</StyledBylineHeading>
                )}
                {source && (
                    <StyledBylineSubHeading>{source}</StyledBylineSubHeading>
                )}
                <BylineFundingTypeWrapper>
                    <StyledBylineFooter>
                        {topic}
                        {readTimeMinutes !== undefined && (
                            <span>
                                {getReadTimeForDisplay(readTimeMinutes)} Min
                                Read
                            </span>
                        )}
                        {(readTimeMinutes || topic) && timestamp && <Bullet />}
                        {/* Regular timestamp to be displayed */}
                        <PublishedTime publicationDate={timestamp} />
                        {/* Updated timestamp to be displayed, only when it's actually updated */}
                        {timestampUpdated && (
                            <>
                                {timestamp && <Bullet />}
                                <PublishedTime
                                    isUpdatedTimestamp={true}
                                    publicationDate={timestampUpdated}
                                />
                            </>
                        )}
                    </StyledBylineFooter>
                    {fundingType && (
                        <FundingLabel
                            fundingType={fundingType}
                            linkToDisclaimer={true}
                        />
                    )}
                </BylineFundingTypeWrapper>
            </StyledBylineWrapper>
            {
                <StyledBylineAsRowWrapper allowCommenting={allowCommenting}>
                    {allowCommenting && <CommentButtonTN url={url} />}
                    <FeatureToggle
                        feature={'save-article-to-profile'}
                        on={() => (
                            <SaveArticleToggle
                                publicationId={id}
                                onEvent={onEvent}
                            />
                        )}
                    />
                </StyledBylineAsRowWrapper>
            }
        </StyledBylineContainer>
    )
}
