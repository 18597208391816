import { toFeatureState } from '@etrigan/feature-toggles-client'
import {
    AdDefinition,
    FeatureConfig,
    isComponentEnabled,
    TogglesReduxState,
} from '@news-mono/web-common'
import {
    CheckedCompositionInformation,
    ComponentInformation,
    getComponentsInCompositions,
} from 'json-react-layouts'
import { isInlineAdContent } from '../templates/Publication/lib/get-inline-positioned-ads'
import { InlineAdContent } from '../templates/Publication/SharedPublication.routing'
import {
    isAdUnitComponent,
    isAmpAdUnitComponent,
    isArticleComponent,
    isComponentWithInlineContentAds,
    isEventComponent,
    isFeatureComponent,
    isGalleryComponent,
    isPublicationComponent,
} from '../__App/component-rendering/helpers'
import {
    fourCardCollectionAdStateDefinition,
    sixCardCollectionAdStateDefinition,
} from '../SevenNewsV2'
import { getTopicSectionAdUnitDefinitions } from '../collections/TheNightly/TopicSection/TopicSectionArticleCardList'
import { getJournoProfileAdUnitDefinitions } from '../collections/TheNightly/JournoProfile/JournoProfileArticleCardList'
import { getPNTopicSectionAdUnitDefinitions } from '../collections/PNTopicCardList/PNTopicCardList'

export function getRouteInfoAdUnits(
    toggles: TogglesReduxState,
    compositions: Array<CheckedCompositionInformation>,
) {
    const adUnits: AdDefinition[] = []
    const featureState = toFeatureState(toggles)
    function addAdUnits(
        component: ComponentInformation<any, any>,
        ...ads: AdDefinition[]
    ) {
        if (isComponentEnabled(featureState, component as FeatureConfig)) {
            adUnits.push(...ads)
        }
    }

    // TODO We are filtering top level compositions, but this will *not* filter nested compositions
    // doing this will require modifying the react-json-layouts library
    for (const contentArea of getComponentsInCompositions(
        compositions.filter((composition) =>
            isComponentEnabled(featureState, composition as FeatureConfig),
        ),
        '',
    )) {
        const component: ComponentInformation<any, any> = contentArea

        if (isAdUnitComponent(component) || isAmpAdUnitComponent(component)) {
            const adUnitProps = component.props
            addAdUnits(component, adUnitProps.slot)
        }

        // Required to pickup the ad within the west live player page
        if (component.type === 'the-west-live-player-page') {
            const adUnitProps = component.props.adUnit
            const adUnitMobileProps = component.props.adUnitMobile
            addAdUnits(component, adUnitProps.slot)
            addAdUnits(component, adUnitMobileProps.slot)
        }

        //six card collection load more ads
        if (component.type === 'six-card-collection') {
            addAdUnits(component, ...sixCardCollectionAdStateDefinition)
        }

        //four card collection load more ads
        if (component.type === 'four-card-collection') {
            addAdUnits(component, ...fourCardCollectionAdStateDefinition)
        }

        // Add adverts to Custom Card Lists in The Nightly
        if ('topic-section-card-list' === component.type) {
            addAdUnits(
                component,
                ...getTopicSectionAdUnitDefinitions(component.props.adUnits),
            )
        }

        if (component.type === 'journo-profile-card-list') {
            addAdUnits(
                component,
                ...getJournoProfileAdUnitDefinitions(component.props.adUnits),
            )
        }

        // Add adverts to topic card lists in Perth Now NGN
        if ('pn-topic-card-list' === component.type) {
            addAdUnits(
                component,
                ...getPNTopicSectionAdUnitDefinitions(component.props.adUnits),
            )
        }

        // The publication component uses a more comprehensive "inlinePublicationContent" strategy
        if (
            isPublicationComponent(component) ||
            isArticleComponent(component) ||
            isGalleryComponent(component) ||
            isFeatureComponent(component) ||
            isEventComponent(component)
        ) {
            const publicationPropsMeta = component.props.meta

            if (publicationPropsMeta.kind === 'gallery') {
                addAdUnits(
                    component,
                    ...publicationPropsMeta.galleryAds.map((ad) => ad.slot),
                )
                return adUnits
            }

            const inlineContentAds =
                publicationPropsMeta.inlinePublicationContent
                    ? publicationPropsMeta.inlinePublicationContent
                          .filter(isInlineAdContent)
                          .reduce(
                              (
                                  acc: AdDefinition[],
                                  contentBlock: InlineAdContent,
                              ) => {
                                  acc.push(
                                      ...contentBlock.content.map(
                                          (ad) => ad.slot,
                                      ),
                                  )
                                  return acc
                              },
                              [],
                          )
                    : undefined

            if (inlineContentAds) {
                addAdUnits(component, ...inlineContentAds)
            }
        }

        if (isComponentWithInlineContentAds(component)) {
            const inlineContentAds = component.props.inlineContentAds.map(
                (ad) => ad.slot,
            )
            addAdUnits(component, ...inlineContentAds)
        }
    }

    return adUnits
}
