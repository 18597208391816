import {
    CandidateDto,
    SeatDto,
} from '@west-australian-newspapers/election-api-types'
import {
    BaseClientConfig,
    ElectionDefinition,
    getElectionData,
    LoadedElectionDefinition,
} from '@news-mono/web-common'
import {
    ExtendedInterknowlogyData,
    ExtendedInterknowlogyParty,
    shortPartyNames,
    shortStateNames,
} from '.'
import placeholderImg from '../components/assets/placeholder-circle-min.png'
import * as log from 'typescript-log'
import _ from 'lodash'
import { tokens } from '@news-mono/design-tokens'

// Top Party Data for the provided Election Data

export interface TopPartyDataProps {
    partiesToShow: number
    dataFeed: ExtendedInterknowlogyData
    sortByPredicted?: boolean
}

export interface TopParty {
    code: string
    name: string
    seats: number
    seatsPredicted: number
    colors: {
        primary: string
        pale10: string
        pale20: string
        light: string
        dark: string
    }
    swingPct: number | null
    seatsChanged: number
    votePct: number
    voteCount: number
}
export interface CombinedTopParty extends TopParty {
    combinedPartyCodes: string[]
}
export interface TopPartyResults {
    parties: TopParty[]
    sort: 'ascending' | 'descending'
    pctCounted: number
    lastUpdated: string
}

export type PartyColors = {
    primary: string
    pale10: string
    pale20: string
    light: string
    dark: string
}

type PartyPalette = Record<string, PartyColors>

const { neutral } = tokens.thenightly.colors.palette

export const partyColors: PartyPalette = {
    //Coalition, used in The Race component and HeadToHeadSeatCountWidget
    COA: {
        primary: '#0008E1',
        pale10: '#0008E11A',
        pale20: '#0008E133',
        dark: '#0005A5',
        light: '#878BFF',
    },
    COL: {
        primary: '#0008E1',
        pale10: '#0008E11A',
        pale20: '#0008E133',
        dark: '#0005A5',
        light: '#878BFF',
    },
    LIB: {
        primary: '#0008E1',
        pale10: '#0008E11A',
        pale20: '#0008E133',
        dark: '#0005A5',
        light: '#878BFF',
    },
    // Liberal-National Party, used in The Seats Widget and HeadToHeadSeatCountWidget
    LNP: {
        primary: '#0008E1',
        pale10: '#0008E11A',
        pale20: '#0008E133',
        dark: '#0005A5',
        light: '#878BFF',
    },
    // Australian Labor Party
    ALP: {
        primary: '#E10004',
        pale10: '#E100041A',
        pale20: '#E1000433',
        dark: '#870002',
        light: '#FF696C',
    },
    //Greens
    GRN: {
        primary: '#76B500',
        pale10: '#76B5001A',
        pale20: '#76B50033',
        dark: '#466C00',
        light: '#CBFF6A',
    },
    //Independent
    IND: {
        primary: '#00CBBD',
        pale10: '#00CBBD1A',
        pale20: '#00CBBD33',
        dark: '#006660',
        light: '#67FFF5',
    },
    //National Party
    NP: {
        primary: '#3B794A',
        pale10: '#3B794A1A',
        pale20: '#3B794A33',
        dark: '#0A531C',
        light: '#64E082',
    },
    NAT: {
        primary: '#3B794A',
        pale10: '#3B794A1A',
        pale20: '#3B794A33',
        dark: '#0A531C',
        light: '#64E082',
    },
    //Other Parties
    OTH: {
        primary: '#8E6B8F',
        pale10: '#8E6B8F1A',
        pale20: '#8E6B8F33',
        dark: '#6B2A6D',
        light: '#E58FE7',
    },
    // No Party (used for 0 data states)
    NONE: {
        primary: neutral[30],
        pale10: neutral[10],
        pale20: neutral[0],
        dark: neutral[70],
        light: neutral[20],
    },
}

export const getPreloadedElectionDefinition = (
    electionDefinition: ElectionDefinition,
): LoadedElectionDefinition | false => {
    if (electionDefinition.electionData) {
        return electionDefinition
    } else {
        return false
    }
}

export const loadElectionDefinition = async (
    electionDefinition: ElectionDefinition,
    config: BaseClientConfig,
): Promise<LoadedElectionDefinition> => {
    const logger = log.consoleLogger()

    if (electionDefinition.electionData) {
        return electionDefinition
    } else {
        const electionId = electionDefinition.electionId
        const electionData = await getElectionData(
            logger,
            config.electionApi,
            config.apiCallerHeader,
            electionId,
        )

        return {
            ...electionDefinition,
            electionData,
        }
    }
}

export const getElectionPartyData = ({
    partiesToShow,
    dataFeed,
}: TopPartyDataProps): TopPartyResults => {
    const sort = 'descending'

    // TODO: Do we need to filter to the specific area
    // Create a deep copy of the parties array to avoid mutations
    let parties = _.cloneDeep(dataFeed.areas[0].parties)

    const coalitionPartyResults = getCombinedTopParty(
        'COL',
        'Coalition',
        parties,
        (party) =>
            party.combinedPartyCodes &&
            party.isCoalition &&
            party.combinedCode === 'COL',
        getPartyColors('COL'),
        true,
    )

    // If results were found, let's clear out the other coalition parties
    if (coalitionPartyResults) {
        parties = coalitionPartyResults.parties.filter(
            (party) => !party.isCoalition,
        )
    }

    // Combine all the other parties into the party code provided!
    const otherPartyResults = getCombinedTopParty(
        'OTH',
        'Other',
        parties,
        (party) =>
            party.partyCode === 'OTH' ||
            Object.keys(partyColors).indexOf(party.partyCode) === -1,
        getPartyColors('OTH'),
        true,
    )

    if (otherPartyResults) {
        parties = otherPartyResults.parties
    }

    // Now let's map all the other parties, yay!
    const mappedParties: TopParty[] = parties.map((party) => {
        return {
            code: party.partyCode,
            name: party.shortPartyName ?? party.partyName ?? party.partyCode,
            seats: party.seatsWon,
            seatsPredicted: party.seatsWon + (party.seatsPredicted ?? 0),
            colors: getPartyColors(party.partyCode) || '#000000',
            swingPct: party.swingPct,
            seatsChanged: party.seatsChanged,
            votePct: party.votePct,
            voteCount: party.voteCount,
        }
    })

    // Now add the extra data, if needed!
    if (coalitionPartyResults) {
        mappedParties.push(coalitionPartyResults.topParty)
    }
    if (otherPartyResults) {
        mappedParties.push(otherPartyResults.topParty)
    }

    return {
        // Now lets sort and return the data!
        parties: mappedParties
            .sort((a, b) => sortTopPartyResults(sort, a, b))
            .slice(0, partiesToShow),
        sort: sort,
        pctCounted: dataFeed.areas[0].pctCounted,
        lastUpdated: dataFeed.lastModified,
    }
}

export const getCombinedTopParty = (
    partyCode: string,
    partyName: string,
    parties: ExtendedInterknowlogyParty[],
    predicate: (
        value: ExtendedInterknowlogyParty,
        index: number,
        obj: ExtendedInterknowlogyParty[],
    ) => unknown,
    colors: PartyColors,
    clear?: boolean,
):
    | { topParty: CombinedTopParty; parties: ExtendedInterknowlogyParty[] }
    | undefined => {
    const topParty: CombinedTopParty = {
        code: partyCode,
        name: partyName,
        seats: 0,
        seatsPredicted: 0,
        colors,
        combinedPartyCodes: [],
        swingPct: null,
        seatsChanged: 0,
        votePct: 0,
        voteCount: 0,
    }

    // Combine any parties that match the predicate into 1 party
    parties.filter(predicate).forEach((party) => {
        topParty.seats += party.seatsWon
        topParty.seatsPredicted += party.seatsWon + (party.seatsPredicted ?? 0)
        topParty.combinedPartyCodes.push(party.partyCode)
        if (party.swingPct !== null) {
            // If we have an incoming swingPct, but the topParty is null, fallback to 0 then add it
            topParty.swingPct = (topParty.swingPct || 0) + party.swingPct
        }
        topParty.seatsChanged += party.seatsChanged
        topParty.votePct += party.votePct
        topParty.voteCount += party.voteCount
    })

    // if we're clearing, then run this!
    if (clear === true) {
        parties = parties.filter((party) => !predicate(party, 0, parties))
    }

    if (topParty.combinedPartyCodes.length > 0) {
        // Results found, return them!
        return {
            parties,
            topParty,
        }
    }

    return undefined
}

export const sortTopPartyResults = (
    sort: string,
    a: TopParty,
    b: TopParty,
): number => {
    const aSeat = a.seats
    const bSeat = b.seats

    return sort === 'descending' ? bSeat - aSeat : aSeat - bSeat
}

// Seats Data

export interface SeatData {
    seatId: string
    seatName: string
    incumbentParty: string | null
    state: string
    postcodes: readonly number[]
    candidates: SeatTwoCandidatePreferred[]
    allCandidates: SeatAllCandidates[]
    winningParty: string | undefined
    winningPartyDarkColor: string | undefined
    status: 'Retain' | 'Gain' | 'Not Called'
    lastUpdated: string | null
}

export interface SeatAllCandidates {
    currentVotesPercentage: number | null
    primaryVotes: number
    partyColor: PartyColors
    imageUrl: string
    candidateName: string
    partyName: string | null
    shortPartyName: string | null
    partyCode: string
    swing: number | null
    isIncumbent: boolean
    isIncumbentParty: boolean
}

export interface SeatTwoCandidatePreferred
    //Mmm, delicious hacks
    extends Omit<SeatAllCandidates, 'isIncumbent'> {
    incumbent: boolean
    awaitingResults?: boolean
}

export const getSeatsData = (
    dataFeed: ExtendedInterknowlogyData,
    imageBaseUrl?: string,
    imageWidth?: number,
): SeatData[] => {
    const seats: SeatDto[] = _.cloneDeep(dataFeed.seats)

    return seats.map((seat) => {
        const state = shortStateNames[seat.state] || seat.state

        const { incumbentParty, seatName, seatId } = seat

        const winningPartyCode = getWinningParty(seat)

        const status = determineSeatStatus(seat)

        const candidates: SeatTwoCandidatePreferred[] =
            seat.twoPartyPreferred.map((candidate) => {
                const candidateDetails = seat.candidates.find(
                    (c) => candidate.candidateId === c.candidateId,
                )
                const candidatePartyName = candidate.candidatePartyCode
                    ? shortPartyNames[candidate.candidatePartyCode] ??
                      candidate.candidatePartyName
                    : candidate.candidatePartyName ??
                      candidate.candidatePartyCode

                const assetImageUrl =
                    imageBaseUrl && candidateDetails?.photoUrl
                        ? `${imageBaseUrl}/${candidateDetails?.photoUrl}?imwidth=${imageWidth}`
                        : placeholderImg

                const incumbentPartyMember = getIncumbentParty(seat)

                return {
                    currentVotesPercentage: candidate.tcpVotesPctCurrent
                        ? Math.round(candidate.tcpVotesPctCurrent)
                        : null, //Round 57.43% to 57%
                    primaryVotes: candidate.primaryVotes,
                    partyColor: getPartyColors(candidate.partyCode),
                    imageUrl: assetImageUrl,
                    candidateName: `${
                        candidate.candidateFirstName
                    } ${normalizeName(candidate.candidateLastName)}`,
                    partyName:
                        candidate.candidatePartyName ??
                        candidate.candidatePartyCode,
                    shortPartyName: candidatePartyName,
                    partyCode: candidate.partyCode,
                    winner: candidate.winner,
                    incumbent: candidate.incumbent,
                    swing: candidateDetails ? candidateDetails.swingPct : null,
                    isIncumbentParty: incumbentPartyMember
                        ? incumbentPartyMember.candidateId ===
                          candidate.candidateId
                        : false,
                }
            })

        /**
         * Sort the candidates so that if either candidate is the incumbent,
         *  they are always first in the list
         */

        const sortedCandidates = candidates.sort((a, b) => {
            if (a.incumbent && !b.incumbent) return -1
            if (!a.incumbent && b.incumbent) return 1
            return 0 // Maintain order if neither is incumbent
        })

        const allCandidates: SeatAllCandidates[] = seat.candidates.map(
            (candidate) => {
                const candidateDetails = seat.candidates.find(
                    (c) => candidate.candidateId === c.candidateId,
                )

                const assetImageUrl =
                    imageBaseUrl && candidateDetails?.photoUrl
                        ? `${imageBaseUrl}/${candidateDetails?.photoUrl}?imwidth=${imageWidth}`
                        : placeholderImg

                const incumbentPartyMember = getIncumbentParty(seat)

                return {
                    currentVotesPercentage:
                        Math.round(candidate.primaryVotesPct * 10) / 10, //Round 57.43% to 57%
                    primaryVotes: candidate.primaryVotes,
                    partyColor: getPartyColors(candidate.partyCode),
                    imageUrl: assetImageUrl,
                    candidateName: `${candidate.firstName} ${normalizeName(
                        candidate.lastName,
                    )}`,
                    shortPartyName:
                        shortPartyNames[candidate.partyCode] ||
                        candidate.partyName ||
                        candidate.partyCode,
                    partyName: candidate.partyName ?? candidate.partyCode,
                    partyCode: candidate.partyCode,
                    swing: candidate.swingPct
                        ? Math.round(candidate.swingPct * 10) / 10
                        : null,
                    isIncumbent: candidate.isIncumbent,
                    isIncumbentParty: incumbentPartyMember
                        ? incumbentPartyMember.candidateId ===
                          candidate.candidateId
                        : false,
                }
            },
        )
        const sortedAllCandidates = allCandidates.sort((a, b) => {
            return a.primaryVotes > b.primaryVotes ? -1 : 1
        })

        return {
            seatId,
            seatName,
            incumbentParty,
            state,
            candidates: sortedCandidates,
            winningParty: winningPartyCode,
            winningPartyDarkColor: getPartyColors(winningPartyCode).dark,
            status,
            allCandidates: sortedAllCandidates,
            postcodes: seat.postcodes,
            lastUpdated: seat.lastUpdated,
        }
    })
}

// Change "NAME" to "Name" and "NAE NAE" to "Nae Nae"
const normalizeName = (name: string) =>
    name
        .split(' ')
        .map(
            (part) =>
                part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(),
        )
        .join(' ')

function determineSeatStatus(seat: SeatDto): 'Retain' | 'Gain' | 'Not Called' {
    const winningParty = seat.twoPartyPreferred.find((party) => party.winner)
    const predictedWinningParty = seat.predictionCertainty
        ? seat.predictionCertainty === 'Definite'
            ? seat.predictionParty
            : null
        : null

    if (predictedWinningParty) {
        return predictedWinningParty === seat.incumbentPartyCode
            ? 'Retain'
            : 'Gain'
    }

    if (!winningParty) {
        return 'Not Called'
    }

    return winningParty.candidatePartyName === seat.incumbentParty
        ? 'Retain'
        : 'Gain'
}

const getWinningParty = (seat: SeatDto): string | undefined => {
    const winningParty = seat.twoPartyPreferred.find(
        (party) => party.winner,
    )?.partyCode
    const predictedWinningParty = seat.predictionCertainty
        ? seat.predictionCertainty === 'Definite'
            ? seat.predictionParty
            : undefined
        : undefined
    return winningParty ?? predictedWinningParty
}

export const getPartyColors = (partyCode: string | undefined) =>
    partyColors[partyCode ?? 'OTH'] || partyColors.OTH

/** If the incumbent is not running for the given seat, this will return the candidate that is in the same party as the incumbent */
const getIncumbentParty = (seat: SeatDto): CandidateDto | undefined => {
    // If the incumbent is not a candidate, lets find their party and return the candidate for that party
    if (!isIncumbentCandidateRunning(seat)) {
        return seat.candidates.find(
            (candidate) => candidate.partyName === seat.incumbentParty,
        )
    }
}

/** Returns true if the incumbent candidate is re-running for the given seat */
const isIncumbentCandidateRunning = (seat: SeatDto) => {
    let isRunning = false
    seat.candidates.map((candidate) => {
        const incumbentName = seat.incumbentName
            ? seat.incumbentName.split(',').join('')
            : null

        if (incumbentName) {
            const candidateName = `${candidate.lastName} ${candidate.firstName}`
            if (incumbentName.toLowerCase() === candidateName.toLowerCase()) {
                isRunning = true
            }
        }
    })
    return isRunning
}
