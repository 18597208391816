import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakpoint } from '../../__styling'
import { metricsPN } from '@news-mono/design-tokens'
import { StyledGridItem } from '../PNRomeo/Romeo.styled'

export const StyledTopicListWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: calcRem(24),

    // Card before ad - accounting for inline ad units
    [`& ${StyledGridItem}:nth-child(6n + 5)`]: {
        paddingBottom: 0,
        [`&::after`]: {
            backgroundColor: 'transparent',
        },
    },

    // Card after ad - accounting for inline ad units
    [`& ${StyledGridItem}:nth-child(6n + 7)`]: {
        paddingTop: 0,
    },
})

export const AdContainer = styled.div(({}) => {
    return {
        margin: `${calcRem(metricsPN.layout.section.mobile)} auto`,
        width: '100%',

        [breakpoint('sm')]: {
            margin: `${calcRem(metricsPN.layout.section.tablet)} auto`,
        },

        [breakpoint('lg')]: {
            margin: `${calcRem(metricsPN.layout.section.desktop)} auto`,
        },
    }
})
