import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../../../../../design-tokens/src/thenightly/colors'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { breakpoint, TheNightlyTheme } from '../../../../__styling'

export const StyledBylineContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    gap: calcRem(16),

    [breakpoint('sm')]: {
        alignItems: 'center',
        flexDirection: 'row',
        gap: 0,
    },
}))

export const StyledBylineWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 0,
    width: '100%',
}))

export const StyledBylineAsRowWrapper = styled('div')<{
    allowCommenting?: boolean
}>(({ allowCommenting = false }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: allowCommenting ? 'space-between' : 'right',
    marginRight: 0,
    width: '100%',

    [breakpoint('sm')]: {
        justifyContent: 'right',
        gap: calcRem(16),
    },
}))

const getHeadingStyle = (theme: TheNightlyTheme) => [
    {
        color: colors.actions.link.default,
        fontWeight: 500,
    } as CSSObject,
    textMixin(theme, 'byline-primary-large'),
]

export const StyledBylineHeading = styled('div')(({ theme }) =>
    getHeadingStyle(theme as TheNightlyTheme),
)

export const StyledBylineHeadingLink = styled(WebLink)(({ theme }) => [
    getHeadingStyle(theme as TheNightlyTheme),
    {
        textDecoration: 'none',
        color: colors.palette.section.opinion.default,
        '&:hover, &:focus': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
])

export const StyledBylineSubHeading = styled('div')(({ theme }) => [
    {
        display: 'flex',
        color: colors.palette.neutral[100],
        textTransform: 'uppercase',
    },
    textMixin(theme as TheNightlyTheme, 'byline-secondary'),
])

export const StyledBylineFooter = styled('div')(({ theme }) => [
    {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',

        color: colors.palette.neutral[60],
        textTransform: 'uppercase',
    },
    textMixin(theme as TheNightlyTheme, 'byline-secondary'),
])

export const BylineFundingTypeWrapper = styled('div')(({ theme }) => [
    {
        display: 'flex',
        gap: '8px',
        alignItems: 'left',
        flexDirection: 'column',
        [breakpoint('sm')]: {
            alignItems: 'center',
            flexDirection: 'row',
        },
    },
])

export const StyledBylineBiography = styled.div(({ theme }) => [
    {
        color: colors.palette.neutral[60],
    },
    textMixin(theme as TheNightlyTheme, 'body-alt'),
])
