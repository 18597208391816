import React, { useEffect, useState } from 'react'
import { StyledPNVideoPlayButton } from './PNVideoPlayButton.styled'
import { IconVideoPause, IconVideoPlay } from '../../../icons'
import { PNCardOverlayButtonScale } from '../cards/PNArticleCard'

export type PNPlayButtonStatus = 'playing' | 'paused' | 'hidden' | 'static'

export interface PNVideoPlayButtonProps {
    overlayButtonScale?: PNCardOverlayButtonScale
    isCentred?: boolean
    status?: PNPlayButtonStatus
    isTouchDevice?: boolean | RegExpMatchArray | null
    // define the button behavior on article page
    playButtonProperty?: {}
}

export const PNVideoPlayButton: React.FC<PNVideoPlayButtonProps> = ({
    overlayButtonScale = 'M',
    isCentred,
    status = 'static',
    isTouchDevice = false,
}) => {
    const [show, setShow] = useState(status !== 'hidden')

    useEffect(() => {
        if (status === 'static') {
            setShow(true)
            return
        }

        if (status === 'hidden') {
            setShow(false)
            return
        }

        setShow(true)

        const displayTimer = setTimeout(() => {
            setShow(false)
        }, 30)
        return () => {
            clearTimeout(displayTimer)
        }
    }, [status])

    return (
        <StyledPNVideoPlayButton
            title="Video"
            overlayButtonScale={overlayButtonScale}
            tabIndex={-1}
            isCentred={isCentred}
            visible={show}
            status={status}
        >
            {status === 'paused' ? <IconVideoPause /> : <IconVideoPlay />}
        </StyledPNVideoPlayButton>
    )
}
