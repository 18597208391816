import styled from '@emotion/styled'
import { metrics, ThemeMargins } from '../../../__styling/settings/metrics'
import { breakpoint, calcRem } from '../../../__styling/style-functions'
import { tokens } from '@news-mono/design-tokens'
import { colors } from '../../../__styling/settings'

interface FourCardCollectionWrapperProps {
    isShowPage?: boolean
}

export const StyledFourCardCollectionWrapper = styled(
    'div',
)<FourCardCollectionWrapperProps>(({ theme, isShowPage }) => [
    {
        '& button': {
            textTransform: 'lowercase',
            background: colors.sevennews.nero50,
            height: calcRem(40),
            width: calcRem(300),
        },
        ...(isShowPage
            ? {
                  marginBottom: calcRem(metrics[theme.kind].margins['lg']),
              }
            : {}),
    },
])

export interface GridProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const StyledFourCardCollection = styled('div')<GridProps>(
    ({ theme, verticalSpacing, verticalGutters }) => [
        {
            margin: '0 auto',
            width: '100%',
            borderBottom: `1px solid ${tokens.sevennews.colors.palette.articlesV2.horizontalLine}`,
            display: 'grid',
            gridTemplateColumns: `1fr`,
            gridColumnGap: calcRem(20),
            gridRowGap: calcRem(16),
            [breakpoint('sm')]: {
                gridTemplateColumns: `1fr 1fr`,
            },
            [breakpoint('md')]: {
                gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                paddingBottom:
                    verticalGutters &&
                    calcRem(metrics.sevennews.margins[verticalGutters[1]]),
            },
            marginBottom:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
            marginTop:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
            paddingTop:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[0]]),
            paddingBottom:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[1]]),

            '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]': {
                top: 'unset',
                left: 'unset',
                bottom: calcRem(10),
                right: calcRem(10),
                width: calcRem(16),
                height: calcRem(16),
                border: `${calcRem(1.92)} solid ${colors.white}`,
                '&::before': {
                    left: '40%',
                    top: '78%',
                    backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
                },
                [breakpoint('sm')]: {
                    top: 'unset',
                    left: 'unset',
                    bottom: calcRem(16),
                    right: calcRem(16),
                    width: calcRem(24),
                    height: calcRem(24),
                    '&::before': {
                        left: '41%',
                        top: '59%',
                        backgroundSize: ` ${calcRem(5.53)} ${calcRem(9.76)}`,
                    },
                },
            },
            '& [class*="StyledMediaIndicator"][class*="media_indicator_gallery_icon"]':
                {
                    border: 'none',
                    '&::before': {
                        backgroundSize: 'contain',
                        width: '100%',
                        height: '100%',
                    },
                },
        },
    ],
)
