import { useTheme } from '@emotion/react'
import {
    isPublicationCardItemWithVideo,
    Product,
    PublicationCardItem,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import { CardHeadline } from '../../cards/CardText/CardHeadline'
import { CardTeaser, TeaserMode } from '../../cards/CardText/CardTeaser'
import {
    StyledText,
    StyledTimestamp,
} from '../../cards/CardText/CardText.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { getEditorialType } from '../../templates/Publication/lib/get-editorial-type'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { getLatestTime } from '../../__helpers/get-latest-time'
import { FontScales } from '../../__styling/settings/fontScale'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { KickerMode } from '../Kicker/Kicker'
import {
    CardContentWithLiveBlogs,
    GetLinkWithChildrenComponent,
} from '../LiveBlogs'
import { CardHeadlineSkeleton } from './CardHeadlineSkeleton'

export interface CardTextProps extends CommonCardProps {
    hasBackground?: boolean
    kickerMode?: KickerMode
    hasPadding?: boolean
    timestamp?: TimestampType
    verticalSpacing?: keyof ThemeMargins
    isLarge?: boolean
    fontScale: FontScales
    teaserMode: TeaserMode
    innerRef?: (ref: any) => void
    insideSponsoredCollection?: boolean
    item: MaybeLoaded<PublicationCardItem>
    kickerOverrideText?: string | false
    isLiveSportsTopic?: boolean
    hideEditorialType?: boolean
    headlineAsDiv?: boolean
    /** If setting isLiveBlog to true, make sure to pass through another prop for the `getLinkComponentWithChildren`. */
    liveBlogConfiguration?: {
        isEnabled: boolean
        isVrTest?: boolean
        getLinkComponentWithChildren: GetLinkWithChildrenComponent
        hasBackground?: boolean
    }
}

export function CardText(props: CardTextProps) {
    const {
        fontScale,
        verticalSpacing,
        hasBackground,
        hasPadding,
        className,
        timestamp,
        isLarge,
        item,
        innerRef,
        teaserMode,
        insideSponsoredCollection,
        kickerMode,
        isLiveSportsTopic,
        liveBlogConfiguration,
        kickerOverrideText,
        hideEditorialType,
        headlineAsDiv,
        onEvent,
    } = props

    const theme = useTheme()

    const storyClassification = true
    const isFunded =
        item.loaded && !!getFundingType(item.result, storyClassification)
    const editorialType =
        item.loaded && !hideEditorialType
            ? getEditorialType(item.result, storyClassification)
            : undefined

    const allowCommentingOnTheWest =
        useFeature('comments') && theme.kind === Product.TheWest

    const isLiveBlogEnabled = liveBlogConfiguration?.isEnabled

    // We store this here as it's referenced in two places
    const DefaultTeaserComponent: JSX.Element = (
        <>
            {teaserMode !== 'hidden' && (
                <CardTeaser
                    teaserOrSkeletonLines={item.loaded ? item.result.teaser : 1}
                    teaserMode={teaserMode}
                    hasBackground={hasBackground}
                />
            )}

            {timestamp !== undefined &&
                item.loaded &&
                item.result.publicationDate && (
                    <StyledTimestamp
                        isLarge={isLarge}
                        iconVisible
                        hasBackground={hasBackground}
                        timestampFormat="relative"
                        timestamp={getLatestTime(
                            theme,
                            item.result.publicationDate,
                            item.result.lastUpdated,
                        )}
                    />
                )}
        </>
    )
    const HeadlineComponent: JSX.Element = (
        <React.Fragment>
            {item.loaded ? (
                <CardHeadline
                    kickerText={kickerOverrideText || item.result.kicker}
                    kickerMode={kickerMode}
                    headlineText={item.result.shortHeadline}
                    hasBackground={hasBackground}
                    fontScale={fontScale}
                    showVideoIcon={isPublicationCardItemWithVideo(item.result)}
                    showCommentsIcon={
                        allowCommentingOnTheWest && item.result.allowCommenting
                    }
                    isSponsored={isFunded}
                    insideSponsoredCollection={insideSponsoredCollection}
                    publicationKind={item.result.publicationKind}
                    isLarge={isLarge}
                    requiredAccess={item.result.requiredAccess}
                    editorialType={editorialType}
                    isLiveSportsTopic={isLiveSportsTopic}
                    headlineAsDiv={headlineAsDiv}
                />
            ) : (
                <CardHeadlineSkeleton
                    hasBackground={hasBackground}
                    fontScale={fontScale}
                    isLarge={isLarge}
                />
            )}
        </React.Fragment>
    )

    return (
        <StyledText
            hasPadding={hasPadding}
            cardLoading={false}
            className={className}
            verticalSpacing={verticalSpacing}
            ref={innerRef}
            isLarge={isLarge}
        >
            {/* If the post is a live blog, we want to wrap the headline in a link component, and attach the liveblog stuff, else it's already got one on an upper level */}
            {isLiveBlogEnabled ? (
                <React.Fragment>
                    {liveBlogConfiguration.getLinkComponentWithChildren({
                        to: '',
                        key: 'hero-card-headline-link',
                        children: <>{HeadlineComponent}</>,
                    })}

                    <CardContentWithLiveBlogs
                        isVrTest={liveBlogConfiguration.isVrTest}
                        result={item.loaded ? item.result : undefined}
                        getLinkComponentWithChildren={
                            liveBlogConfiguration.getLinkComponentWithChildren
                        }
                        DefaultComponent={DefaultTeaserComponent}
                        onEvent={onEvent}
                        hasBackground={liveBlogConfiguration.hasBackground}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {HeadlineComponent}
                    {DefaultTeaserComponent}
                </React.Fragment>
            )}
        </StyledText>
    )
}
CardText.displayName = 'CardText'
