// A list of sources allowed to link through -> currently internal sources not including sub sources such as 'PerthNow Local News'
export const mastheadLinkThroughs = {
    '7NEWS': 'https://7news.com.au/',
    'The West Australian': 'https://thewest.com.au/',
    'The Nightly': 'https://thenightly.com.au/',
    // Regionals
    'Albany Advertiser': 'https://www.albanyadvertiser.com.au/',
    'Augusta Margaret River Times': 'https://www.amrtimes.com.au/',
    'Augusta-Margaret River Times': 'https://www.amrtimes.com.au/',
    'Broome Advertiser': 'https://www.broomead.com.au/',
    'Bunbury Herald': 'https://www.bunburyherald.com.au/',
    'Busselton Dunsborough Times': 'https://www.bdtimes.com.au/',
    'Busselton-Dunsborough Times': 'https://www.bdtimes.com.au/',
    Countryman: 'https://www.countryman.com.au/',
    'Geraldton Guardian': 'https://www.geraldtonguardian.com.au/',
    'Great Southern Herald': 'https://www.gsherald.com.au/',
    'Harvey Waroona Reporter': 'https://www.harveyreporter.com.au/',
    'Harvey-Waroona Reporter': 'https://www.harveyreporter.com.au/',
    'Kalgoorlie Miner': 'https://www.kalminer.com.au/',
    'The Kimberley Echo': 'https://www.kimberleyecho.com.au/',
    'Manjimup Bridgetown Times': 'https://www.mbtimes.com.au/',
    'Manjimup-Bridgetown Times': 'https://www.mbtimes.com.au/',
    'Midwest Times': 'https://www.midwesttimes.com.au/',
    'Narrogin Observer': 'https://www.narroginobserver.com.au/',
    'North West Telegraph': 'https://www.northwesttelegraph.com.au/',
    'Pilbara News': 'https://www.pilbaranews.com.au/',
    'Sound Telegraph': 'https://www.soundtelegraph.com.au/',
    'South Western Times': 'https://www.swtimes.com.au/',
} as const

export const getSourceLinkThrough = (source: string) => {
    if (mastheadLinkThroughs.hasOwnProperty(source)) {
        return mastheadLinkThroughs[source as keyof typeof mastheadLinkThroughs]
    } else {
        return
    }
}
