import {
    ConfigurationContext,
    ElectionDefinition,
    queries,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'
import { AllEvents } from 'libs/web-common/src/events'
import React, { useContext, useMemo } from 'react'
import {
    ExtendedInterknowlogyData,
    getPartyColors,
    PartyColors,
    transformAreaData,
} from '../data'
import {
    StyledBackgroundBar,
    StyledBarContent,
    StyledBarsContainer,
    StyledCenterLabel,
    StyledCenterLine,
    StyledContainer,
    StyledLoadingPlaceholder,
    StyledPartyBar,
    StyledPartyLabel,
    StyledPartyLabelSeatCount,
    StyledPredictedSeats,
    StyledSeatCount,
    StyledSeatCountContainer,
    StyledSeatCountErrorContainer,
    StyledSeatCountErrorText,
    StyledWonSeats,
    StyledWrapper,
} from './HeadToHeadSeatCountWidget.styled'
import { ElectionConfig } from '@west-australian-newspapers/election-api-types'
import { electionsDebug } from '../../../__helpers/elections-debug'
interface PartyResults {
    wonSeats: number
    predictedSeats: number
    partyName?: string
    partyColors: PartyColors
}

interface HeadToHeadSeatCountData {
    right: PartyResults
    left: PartyResults
    seatsToWin: number
    seatsTotal: number
}

const transformToSeatCountData = (
    data: ExtendedInterknowlogyData,
    config: ElectionConfig,
) => {
    const seatCountData: HeadToHeadSeatCountData = {
        right: {
            wonSeats: 0,
            predictedSeats: 0,
            partyColors: getPartyColors('OTH'),
        },
        left: {
            wonSeats: 0,
            predictedSeats: 0,
            partyColors: getPartyColors('OTH'),
        },
        seatsToWin: config.seatsToWin,
        seatsTotal: config.seatsTotal,
    }

    if (data.areas.length === 0) return

    /**
     * This is heavily presumes that we want to only display the ALP and COL/LIB parties
     * @todo we should add config item to the Election API, eg `"head2headParties": {"partyCode","partyCode"}`
     * to control what parties to show here
     */

    data.areas.forEach((area) => {
        area.parties.forEach((party) => {
            // add to labor seat count based on partyCode ALP
            if (party.partyCode === 'ALP') {
                seatCountData.left.partyName =
                    party.shortPartyName ?? party.partyName
                seatCountData.left.wonSeats += party.seatsWon
                seatCountData.left.predictedSeats += party.seatsPredicted ?? 0
                seatCountData.left.partyColors = getPartyColors(party.partyCode)
            }

            // In WA, the Liberals and Nationals are not a coalition, so we just retrieve Liberal seats
            if (config.electionType === 'state' && config.state === 'WA') {
                if (party.partyCode === 'LIB') {
                    seatCountData.right.partyName =
                        party.shortPartyName ?? party.partyName
                    seatCountData.right.wonSeats += party.seatsWon
                    seatCountData.right.predictedSeats +=
                        party.seatsPredicted ?? 0
                    seatCountData.right.partyColors = getPartyColors(
                        party.partyCode,
                    )
                }
            } else {
                // add to coalition seat count based on combinedCode COL
                if (party.combinedCode === 'COL') {
                    seatCountData.right.partyName =
                        party.shortPartyName ?? party.partyName
                    seatCountData.right.wonSeats += party.seatsWon
                    seatCountData.right.predictedSeats +=
                        party.seatsPredicted ?? 0
                    seatCountData.right.partyColors = getPartyColors(
                        party.partyCode,
                    )
                }
            }
        })
    })

    return seatCountData
}

export interface HeadToHeadSeatCountWidgetProps {
    electionDefinition: ElectionDefinition
    showPredictedSeats?: boolean
    onEvent: (event: AllEvents) => void
}

/**
 * Amount of total seats from s3 endpoint data needs to match election definition seatsTotal
 */
export const HeadToHeadSeatCountWidget = ({
    electionDefinition,
    showPredictedSeats = false,
    onEvent,
}: HeadToHeadSeatCountWidgetProps) => {
    const config = useContext(ConfigurationContext)
    const { isSuccess, isError, isLoading, data } = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    const seatCountData = useMemo(() => {
        if (!isSuccess || !data.electionData.data || !data.electionData.config)
            return
        const transformedData = transformToSeatCountData(
            transformAreaData(data.electionData.data),
            data.electionData.config,
        )

        return transformedData
    }, [data?.electionData.config, data?.electionData.data, isSuccess])

    // Error State (data has loaded, but there is no data)
    if (isError || (isSuccess && !seatCountData)) {
        electionsDebug(
            'Error loading HeadToHeadSeatCountWidget.}',
            isError,
            isSuccess,
            seatCountData,
        )
        return (
            <StyledSeatCountErrorContainer>
                <StyledSeatCountErrorText isBold={true}>
                    Error
                </StyledSeatCountErrorText>
                <StyledSeatCountErrorText>
                    Refresh the page or try again later.
                </StyledSeatCountErrorText>
            </StyledSeatCountErrorContainer>
        )
    }

    // Loading State
    if (isLoading || !seatCountData) {
        return <StyledLoadingPlaceholder />
    }

    return (
        <HeadToHeadChart
            showPredictedSeats={showPredictedSeats}
            data={seatCountData}
        />
    )
}

type HeadToHeadChartProps = {
    showPredictedSeats?: boolean
    data: HeadToHeadSeatCountData
}

const HeadToHeadChart = ({
    data,
    showPredictedSeats,
}: HeadToHeadChartProps) => {
    const rightPartyTotal = data.right.wonSeats + data.right.predictedSeats
    const leftPartyTotal = data.left.wonSeats + data.left.predictedSeats
    const totalSeats = data.seatsTotal

    // Width as percentage
    const rightPartyWidth = (rightPartyTotal / totalSeats) * 100
    const leftPartyWidth = (leftPartyTotal / totalSeats) * 100
    const rightPartySeatsWonWidth =
        (data.right.wonSeats / rightPartyTotal) * 100
    const leftPartySeatsWonWidth = (data.left.wonSeats / leftPartyTotal) * 100
    const rightPartySeatsPredictedWidth =
        (data.right.predictedSeats / rightPartyTotal) * 100
    const leftPartySeatsPredictedWidth =
        (data.left.predictedSeats / leftPartyTotal) * 100

    return (
        <StyledContainer>
            <StyledWrapper>
                {/* Left Party Seat Count */}
                <StyledSeatCountContainer>
                    <StyledSeatCount color={data.left.partyColors.primary}>
                        {data.left.wonSeats}
                    </StyledSeatCount>
                </StyledSeatCountContainer>

                <StyledBarsContainer>
                    {/* Center Line */}
                    <StyledCenterLine>
                        <StyledCenterLabel>
                            {data.seatsToWin} to win
                        </StyledCenterLabel>
                    </StyledCenterLine>

                    <StyledBackgroundBar />

                    {/* Left Party Bar */}
                    <StyledPartyBar width={leftPartyWidth}>
                        <StyledPartyLabel color={data.left.partyColors.primary}>
                            <StyledPartyLabelSeatCount>
                                {data.left.wonSeats}
                            </StyledPartyLabelSeatCount>{' '}
                            {data.left.partyName}
                        </StyledPartyLabel>
                        <StyledBarContent>
                            <StyledWonSeats
                                color={data.left.partyColors.primary}
                                width={leftPartySeatsWonWidth}
                            />
                            <StyledPredictedSeats
                                color={data.left.partyColors.light}
                                width={leftPartySeatsPredictedWidth}
                                show={showPredictedSeats}
                            />
                        </StyledBarContent>
                    </StyledPartyBar>

                    {/* Right Party Bar */}
                    <StyledPartyBar width={rightPartyWidth} isRight>
                        <StyledPartyLabel
                            color={data.right.partyColors.primary}
                            isRight
                        >
                            {data.right.partyName}{' '}
                            <StyledPartyLabelSeatCount>
                                {data.right.wonSeats}
                            </StyledPartyLabelSeatCount>
                        </StyledPartyLabel>
                        <StyledBarContent>
                            <StyledPredictedSeats
                                color={data.right.partyColors.light}
                                width={rightPartySeatsPredictedWidth}
                                show={showPredictedSeats}
                            />
                            <StyledWonSeats
                                color={data.right.partyColors.primary}
                                width={rightPartySeatsWonWidth}
                            />
                        </StyledBarContent>
                    </StyledPartyBar>
                </StyledBarsContainer>

                {/* Right Party Seat Count */}
                <StyledSeatCountContainer isRight>
                    <StyledSeatCount
                        color={data.right.partyColors.primary}
                        isRight
                    >
                        {data.right.wonSeats}
                    </StyledSeatCount>
                </StyledSeatCountContainer>
            </StyledWrapper>
        </StyledContainer>
    )
}
