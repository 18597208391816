import { CSSObject, keyframes } from '@emotion/css'
import { tokens } from '@news-mono/design-tokens'

const pulseAnimation = keyframes`
    0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
`

export const placeholderAnimation: CSSObject = {
    background: `linear-gradient(90deg, ${tokens.thenightly.colors.palette.neutral[20]} 0%, ${tokens.thenightly.colors.palette.neutral[10]} 50%, ${tokens.thenightly.colors.palette.neutral[20]} 100%)`,
    backgroundSize: '400% 100%',
    animation: `${pulseAnimation} 1.5s infinite linear`,
}
