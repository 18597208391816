const Palette = {
    transparent: 'transparent',
    mono: {
        100: '#000000',
        90: '#161616',
        80: '#252525',
        70: '#37393B',
        60: '#535556',
        50: '#666869',
        40: '#B9B9B9',
        30: '#DCDCDC',
        20: '#F1F1F1',
        10: '#F6F6F6',
        5: '#FDFDFD',
        0: '#FFFFFF',
        transparent: {
            75: 'rgba(0, 0, 0, 0.75)',
            45: 'rgba(0, 0, 0, 0.45)',
            35: 'rgba(0, 0, 0, 0.35)',
            25: 'rgba(0, 0, 0, 0.25)',
            15: 'rgba(0, 0, 0, 0.15)',
            8: 'rgba(0, 0, 0, 0.08)',
            2: 'rgba(0, 0, 0, 0.02)',
        },
    },
    brand: {
        90: '#370319',
        80: '#5D082B',
        70: '#7A0B38',
        60: '#9D0E48',
        50: '#AC225B',
        45: '#DD1466',
        40: '#E5317A',
        30: '#E86298',
        25: '#EF93B9',
        20: '#FFC4DB',
        15: '#FFD8E8',
        10: '#FCE8F0',
        5: '#FFF4F8',
        2: '#FFF9FB',
        transparent: {
            75: 'rgba(221, 20, 102, 0.75)',
            25: 'rgba(221, 20, 102, 0.25)',
            7: 'rgba(221, 20, 102, 0.07)',
            4: 'rgba(221, 20, 102, 0.04)',
            2: 'rgba(221, 20, 102, 0.02)',
        },
    },
    success: {
        90: '#3B7B02',
        10: '#ECF3E6',
    },
    error: {
        90: '#C12323',
        10: '#FBE9E9',
    },
    perthNowRed: '#D42626',
} as const

export const colorsPN = {
    text: {
        primary: Palette.mono[80],
        secondary: Palette.mono[60],
        inverse: Palette.mono[0],
        brand: Palette.brand[45],
        pressed: Palette.brand[50],
        disabled: Palette.mono.transparent[25],
        error: Palette.error[90],
        success: Palette.success[90],
    },
    icon: {
        primary: Palette.mono[80],
        secondary: Palette.mono[50],
        inverse: Palette.mono[0],
        brand: Palette.brand[45],
        pressed: Palette.mono.transparent[25],
        hover: Palette.mono.transparent[15],
        disabled: Palette.mono.transparent[25],
        error: Palette.error[90],
        success: Palette.success[90],
    },
    fill: {
        brand: {
            strong: Palette.brand[45],
            subtle: Palette.brand[10],
            weak: Palette.brand.transparent[4],
        },
        base: Palette.mono[0],
        strong: Palette.mono[90],
        weak: Palette.mono.transparent[45],
        success: {
            strong: Palette.success[90],
            weak: Palette.success[10],
        },
        error: {
            strong: Palette.error[90],
            weak: Palette.error[10],
        },
        hover: {
            brand: Palette.brand.transparent[4],
            default: Palette.mono.transparent[8],
        },
        pressed: {
            brand: Palette.brand.transparent[7],
            default: Palette.mono.transparent[15],
        },
        disabled: Palette.mono.transparent[15],
    },
    background: {
        brand: {
            strong: Palette.brand[45],
            weak: Palette.brand[10],
            veryWeak: Palette.brand[2],
        },
        base: Palette.mono[0],
        strong: Palette.mono[90],
        weak: Palette.mono[10],
    },
    stroke: {
        brand: {
            solid: Palette.brand.transparent[75],
            strong: Palette.brand[45],
            weak: Palette.brand[5],
        },
        base: Palette.mono[0],
        strong: Palette.mono[90],
        weak: Palette.mono[30],
        disabled: Palette.mono.transparent[15],
        error: Palette.error[90],
        success: Palette.success[90],
    },
    logo: {
        red: Palette.perthNowRed,
        black: Palette.mono[80],
        white: Palette.mono[0],
    },
    media: {
        hover: Palette.brand.transparent[25],
    },
    shadow: {
        blurs25: Palette.mono.transparent[25],
        blurs35: Palette.mono.transparent[35],
    },
}

export type PerthNowNGNPalette = typeof Palette
export type PNColorsType = typeof colorsPN
