import { ProfileV4DTO } from '@west-australian-newspapers/publication-types'

export const getProfilePageLdJson = (
    profile: ProfileV4DTO,
    publicUrl: string,
    mastheadName: string,
    organizationPublicUrl?: string,
) => {
    const profileImageLd = getProfileImageObjectLdJson(
        profile,
        publicUrl,
        mastheadName,
    )
    const result = [
        getProfileWebPageAndProfilePageLdJson(
            profile,
            publicUrl,
            mastheadName,
            organizationPublicUrl,
        ),
        getProfilePersonLd(profile, publicUrl, organizationPublicUrl),
        ...(profileImageLd ? [profileImageLd] : []),
        getProfileBreadcrumbListLdJson(profile, publicUrl, mastheadName),
    ]

    return result
}
const getProfileWebPageAndProfilePageLdJson = (
    profile: ProfileV4DTO,
    publicUrl: string,
    mastheadName: string,
    organizationPublicUrl?: string,
) => {
    const canonicalSite = profile.canonicalSite ?? publicUrl
    const profileUrl = `${canonicalSite}/profile/${profile.slug}`

    const workForPublicUrl = organizationPublicUrl
        ? organizationPublicUrl
        : publicUrl

    return {
        '@context': 'http://schema.org',
        '@type': ['WebPage', 'ProfilePage'],
        '@id': profileUrl,
        url: profileUrl,
        name: getProfileFullNameAndPosition(
            profile.name,
            mastheadName,
            profile.position,
        ),
        mainEntity: {
            '@type': 'Person',
            '@id': `${publicUrl}/#/schema/Person`,
        },
        isPartOf: {
            '@type': 'WebSite',
            '@id': `${publicUrl}/#/schema/WebSite`,
        },
        publisher: {
            '@id': `${workForPublicUrl}/#/schema/Organization`,
        },
        ...(profile.mainPhoto
            ? {
                  primaryImageOfPage: {
                      '@type': 'ImageObject',
                      '@id': `${publicUrl}/#/schema/PrimaryImage`,
                  },
              }
            : {}),
        ...(profile.mainPhoto
            ? {
                  image: {
                      '@type': 'ImageObject',
                      '@id': `${publicUrl}/#/schema/PrimaryImage`,
                  },
              }
            : {}),
        ...(profile.mainPhoto ? { thumbnailUrl: profile.mainPhoto } : {}),
        description: profile.biography,
        breadcrumb: {
            '@type': 'BreadcrumbList',
            '@id': `${publicUrl}/#/schema/BreadcrumbList`,
        },
        inLanguage: 'en-AU',
        potentialAction: [
            {
                '@type': 'ReadAction',
                target: profileUrl,
            },
        ],
    }
}

const getProfilePersonLd = (
    profile: ProfileV4DTO,
    publicUrl: string,
    organizationPublicUrl?: string,
) => {
    const socials = []
    if (profile.facebook) {
        socials.push(`https://www.facebook.com/${profile.facebook}`)
    }
    if (profile.instagram) {
        socials.push(`https://www.instagram.com/${profile.instagram}`)
    }
    if (profile.twitter) {
        socials.push(`https://x.com/${profile.twitter}`)
    }

    const workForPublicUrl = organizationPublicUrl
        ? organizationPublicUrl
        : publicUrl

    return {
        '@context': 'http://schema.org',
        '@type': 'Person',
        '@id': `${publicUrl}/#/schema/Person`,
        jobTitle: profile.position,
        name: profile.name,
        description: profile.biography,
        sameAs: socials,
        ...(profile.mainPhoto
            ? {
                  image: {
                      '@type': 'ImageObject',
                      '@id': `${publicUrl}/#/schema/PrimaryImage`,
                  },
              }
            : {}),
        worksFor: {
            '@id': `${workForPublicUrl}/#/schema/Organization`,
        },
    }
}

const getProfileImageObjectLdJson = (
    profile: ProfileV4DTO,
    publicUrl: string,
    mastheadName: string,
) => {
    const caption = profile.position
        ? `${profile.name}, ${profile.position} at ${mastheadName}`
        : `${profile.name}, ${mastheadName}`

    return profile.mainPhoto
        ? {
              '@context': 'http://schema.org',
              '@type': 'ImageObject',
              inLanguage: 'en-AU',
              '@id': `${publicUrl}/#/schema/PrimaryImage`,
              url: profile.mainPhoto,
              contentUrl: profile.mainPhoto,
              caption,
          }
        : undefined
}

const getProfileBreadcrumbListLdJson = (
    profile: ProfileV4DTO,
    publicUrl: string,
    mastheadName: string,
) => {
    return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        '@id': `${publicUrl}/#/schema/BreadcrumbList`,
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: publicUrl,
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: getProfileFullNameAndPosition(
                    profile.name,
                    mastheadName,
                    profile.position,
                ),
            },
        ],
    }
}

const getProfileFullNameAndPosition = (
    name: string,
    masthead: string,
    position?: string,
) => {
    return position
        ? `${name} | ${position} | ${masthead}`
        : `${name} | ${masthead}`
}
