import React from 'react'
import styled from '@emotion/styled'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { calcRem, TheNightlyThemeConfig } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

const { utility, neutral } = tokens.thenightly.colors.palette

type ElectionDataFetchErrorProps = {
    backgroundColor?: string
    minHeight?: number
}
export const ElectionDataFetchError = ({
    backgroundColor = neutral[10],
    minHeight = 280,
}: ElectionDataFetchErrorProps) => {
    return (
        <ElectionDataErrorContainer
            backgroundColor={backgroundColor}
            minHeight={minHeight}
        >
            <ElectionDataErrorHeading>
                Error fetching data
            </ElectionDataErrorHeading>
            <ElectionDataErrorText>
                Please refresh the page or try again later.
            </ElectionDataErrorText>
        </ElectionDataErrorContainer>
    )
}

export const ElectionDataErrorContainer = styled.div<{
    backgroundColor: string
    minHeight: number
}>(({ theme, backgroundColor, minHeight }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: calcRem(8),
        width: '100%',
        minHeight: minHeight,
        height: '100%',
        padding: calcRem(24),
        boxSizing: 'border-box',
        backgroundColor: backgroundColor,
        borderRadius: calcRem(12),
        border: `1px solid ${neutral[20]}`,
    }
})

const ElectionDataErrorHeading = styled.h2(({ theme }) => {
    return [
        {
            color: utility.error.default,
            margin: 0,
        },
        textMixin<TheNightlyThemeConfig>(theme, 'item-small'),
    ]
})
const ElectionDataErrorText = styled.p(({ theme }) => {
    return [
        {
            color: utility.error.default,
            margin: 0,
        },
        textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
    ]
})
