import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isWeekend from 'date-fns/isWeekend'

type DateTime = string | Date

const MINUTES_IN_DAY = 1440 /* 24 * 60 */

export const getTimeAgo = (time: DateTime) => {
    const seconds = differenceInSeconds(Date.now(), new Date(time))
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(minutes / MINUTES_IN_DAY)

    if (seconds < 60) {
        return `Just now`
    }

    if (minutes <= 2) {
        return `1 min ago`
    }

    if (minutes > 2 && minutes < 60) {
        return `${minutes} mins ago`
    }

    if (minutes >= 60 && minutes <= 119) {
        return `1 hr ago`
    }

    if (minutes >= 120 && minutes < MINUTES_IN_DAY) {
        return `${hours} hrs ago`
    }

    if (minutes >= MINUTES_IN_DAY && minutes < 48 * 60) {
        return `1 day ago`
    }

    return `${days} days ago`
}

export const getTimeAgoLong = (time: DateTime, trimAfterDay = false) => {
    const dateValue = new Date(time)
    const seconds = differenceInSeconds(Date.now(), dateValue)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(minutes / MINUTES_IN_DAY)

    if (seconds < 60) {
        return `Just now`
    }

    if (minutes <= 2) {
        return `1 minute ago`
    }

    if (minutes > 2 && minutes < 60) {
        return `${minutes} minutes ago`
    }

    if (minutes >= 60 && minutes <= 119) {
        return `1 hour ago`
    }

    if (minutes >= 120 && minutes < MINUTES_IN_DAY) {
        return `${hours} hours ago`
    }

    // We don't want to show days ago if true, we just want to show the date.
    if (trimAfterDay) {
        return format(dateValue, 'd MMM').toUpperCase()
    }

    if (minutes >= MINUTES_IN_DAY && minutes < 48 * 60) {
        return `1 day ago`
    }

    return `${days} days ago`
}

export const getEventPostTimeAgo = (time: DateTime) => {
    const createdDateTime = new Date(time)
    const seconds = differenceInSeconds(Date.now(), createdDateTime)
    const minutes = Math.round(seconds / 60)

    if (seconds < 60) {
        return `Just now`
    }

    if (seconds >= 60 && seconds < 120) {
        return `1 minute ago`
    }

    if (seconds >= 120 && seconds < 3600) {
        return `${minutes} minutes ago`
    }

    return format(createdDateTime, 'h:mm aa')
}

export const getTimeRoundedMax = (time: DateTime) => {
    const minutesAgo = differenceInMinutes(Date.now(), new Date(time))

    if (minutesAgo <= 1) {
        return '1 min ago'
    }

    if (minutesAgo <= 10) {
        return `${minutesAgo} mins ago`
    }

    if (minutesAgo <= 59) {
        return `${Math.round(minutesAgo / 5) * 5} mins ago`
    }

    if (minutesAgo <= 75) {
        return '1 hour ago'
    }

    if (minutesAgo <= 240) {
        return `${Math.round((minutesAgo / 60) * 2) / 2} hours ago`
    }

    return undefined
}

export const getTimeAgoPNLong = (time: DateTime) => {
    const dateValue = new Date(time)
    // Gets todays date
    // Set hours, min and seconds to 0 for start of day
    const today = new Date()
    today.setHours(0, 0, 0)
    // Get time now
    const now = new Date()

    // Calculate minutes difference between now and start of day
    const minutesToStartOfDay = differenceInMinutes(
        now.getTime(),
        today.getTime(),
    )
    const seconds = differenceInSeconds(Date.now(), dateValue)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(minutes / MINUTES_IN_DAY)

    if (minutes < 5) {
        return `Just now`
    }

    if (minutes >= 5 && minutes < 60) {
        return `${minutes} minutes ago`
    }

    if (minutes >= 60 && minutes <= 119) {
        return `1 hour ago`
    }

    if (minutes >= 120 && minutes < MINUTES_IN_DAY) {
        return `${hours} hours ago`
    }

    // Accomodate for time shifts where the date changes by 2
    // Avoids saying "Yesterday at 11:53pm" for 2 days ago as it can be less
    // than 24 hours
    if (
        minutes >= MINUTES_IN_DAY &&
        minutes < minutesToStartOfDay + MINUTES_IN_DAY
    ) {
        return `Yesterday at ${format(dateValue, 'h:mm a')}`
    }

    if (days < 7) {
        return `${days} days ago`
    }

    if (dateValue.getFullYear() === now.getFullYear()) {
        return format(dateValue, 'dd MMMM')
    }

    return format(dateValue, 'dd MMMM yyyy')
}

/** Used by the News Just In, to show timestamp < 24 hours,
 * yesterday for the day before then x days ago - when relative is set */
export const getTime = (
    time: DateTime,
    space?: boolean,
    relative?: boolean,
) => {
    const timeStamp = format(new Date(time), `h:mm${space ? ' ' : ''}a`)
    if (relative) {
        const seconds = differenceInSeconds(Date.now(), new Date(time))
        const minutes = Math.round(seconds / 60)
        const days = Math.round(minutes / MINUTES_IN_DAY)

        if (minutes < MINUTES_IN_DAY) {
            return timeStamp
        }

        if (minutes >= MINUTES_IN_DAY && minutes < 48 * 60) {
            return 'Yesterday'
        }

        return `${days} days ago`
    }
    return timeStamp
}

// added to the time tag as datetime
export const getDateTime = (time: DateTime) =>
    format(new Date(time), 'HH:mm:ss')

export function getDateStringInAWST(
    localDate: DateTime = new Date(Date.now()),
) {
    /* We return a US formatted date with a Perth timezone. This is because

     * JS Dates do not support timezones
     * We convert to a local perth time string, then pass to date-fns as a string
     * Because it's a local formatted date, not a ISO string it's assumed to be a US formatted date

        The desired effect is if I am in the US, I should be able to tune in on the weekend when
        it's a weekday in Australia
    */
    return new Date(localDate).toLocaleString('en-US', {
        timeZone: 'Australia/Perth',
    })
}

export function isWeekdayAWST(localDate: Date = new Date(Date.now())) {
    // Adding due to IE throwing on locale string check
    try {
        const dateString = getDateStringInAWST(localDate)
        return !isWeekend(new Date(dateString))
    } catch (err) {
        return false
    }
}

/**
 * isBetweenUTC
 *
 * Checks if the current time in UTC is between a block of time
 *
 * @param localDate The local Date object
 * @param start a number pair containing the hours and minutes of the start of the window e.g. 2:25pm = [14, 25]
 * @param end a number pair containing the hours and minutes of the end of the window e.g. 7:30am = [7, 30]
 */
export const isBetweenUTC = (
    localDate: Date,
    startTime: [number, number],
    endTime: [number, number],
) => {
    const startDate = getLocalTimeFromUTCAtTime(localDate, startTime)
    const endDate = getLocalTimeFromUTCAtTime(localDate, endTime)

    const started = isAfter(localDate, startDate)
    const ended = isAfter(localDate, endDate)

    return started && !ended
}

/**
 * getLocalTimeFromUTCAtTime
 *
 * Returns a localised date object containing the UTC time at the specified hours/minutes
 *
 * @param localDate A local date object to base calculations on
 * @param time a number pair containing the hours and minutes of the end of the window e.g. 7:30am = [7, 30]
 */
export const getLocalTimeFromUTCAtTime = (
    localDate: Date,
    [hours, minutes]: [number, number],
) =>
    new Date(
        Date.UTC(
            localDate.getUTCFullYear(),
            localDate.getUTCMonth(),
            localDate.getUTCDate(),
            hours,
            minutes,
        ),
    )

/**
 * humanReadableSeconds
 *
 * formats a number of seconds into minute:seconds
 *
 * @param seconds the number of seconds to transform
 */
export const humanReadableSeconds = (seconds: number) => {
    const leftpad = (value: number) => `0${Math.floor(value)}`.slice(-2)
    const minutes = (seconds % 3600) / 60
    return [minutes, seconds % 60].map(leftpad).join(':')
}
// Full date and time
export const getFullDateTime = (time: DateTime) =>
    format(new Date(time), 'EEE, d MMM yyyy h:mma')

type DateFormat = {
    timestamp: { dateOnly: string; dateTime: string }
    timezone: string
}

export const getShortDate = (time: DateTime) => {
    return format(new Date(time), 'EEE, MMMM d')
}
