import {
    ArticleLikeV4DTO,
    Asset_Image,
    BlockV4DTO,
    Block_InlineAssetV4DTO,
    Block_TextV4DTO,
    ListPublicationV4DTO,
} from '@west-australian-newspapers/publication-types'
import {
    Block,
    BlogBlock,
    BuyNowBlock,
    CompetitionBlock,
    EmbedBlock,
    InlineImageBlock,
    InlineRelatedContentBlock,
    InlineVideoBlock,
    Intention,
    OrderedListBlock,
    PullQuoteBlock,
    SubheadBlock,
    TextBlock,
    TwinImageBlock,
    UnorderedListBlock,
    BreakoutBoxBlock,
    ArticleSummaryBlock,
} from '../../../../client-data-types/blocks'
import { PublicationCardItem } from '../../../../client-data-types/card-types'
import { imageWithCropsDtoToImageCrop } from '../../lib/to-image'
import { mapListPublication } from '../../list-publication'

export function getContentBlocks(publication: ArticleLikeV4DTO): Block[] {
    const contentItem = publication.content

    if (contentItem && contentItem.kind === 'content') {
        return mapContentBlocks(contentItem.blocks)
    }

    return []
}

export function mapContentBlocks(blocks: BlockV4DTO[]) {
    const content: Block[] = []

    for (const apiBlock of blocks) {
        const block = mapContentBlock(apiBlock)

        if (block) {
            content.push(block)
        }
    }
    return content
}

export function mapContentBlock(block: BlockV4DTO): Block | undefined {
    if (block == undefined) {
        return undefined
    }
    switch (block.kind) {
        case 'embed': {
            const embedBlock: EmbedBlock = {
                kind: 'embed',
                link: block.reference,
            }
            return embedBlock
        }
        case 'adjacent-inlines': {
            const leftImageBlock = imageFromAsset(
                block.left,
                block.left.asset as Asset_Image,
            )
            const rightImageBlock = imageFromAsset(
                block.right,
                block.right.asset as Asset_Image,
            )

            const twinImage: TwinImageBlock = {
                kind: 'twin-image',
                left: leftImageBlock.inlineDetails,
                right: rightImageBlock.inlineDetails,
            }

            return twinImage
        }
        case 'inline': {
            const inlineBlock = inlineFromInlineAsset(block)

            return inlineBlock
        }
        case 'pull-quote': {
            const pullQuoteblock: PullQuoteBlock = {
                kind: block.kind,
                text: block.text,
                attribution: block.attribution || undefined,
            }
            return pullQuoteblock
        }
        case 'text': {
            const textBlock = mapTextBlock(block)
            return textBlock
        }
        case 'blog': {
            const blogBlock: BlogBlock = {
                kind: block.kind,
                blogId: block.blogId,
                provider: block.provider,
                blogState: block.blogState,
            }
            return blogBlock
        }
        case 'subhead': {
            const subheadBlock: SubheadBlock = {
                kind: block.kind,
                text: block.text,
                intentions: [],
            }
            return subheadBlock
        }
        case 'unordered-list': {
            const listBlock: UnorderedListBlock = {
                kind: block.kind,
                items: block.items
                    .map(mapContentBlock)
                    .filter((block): block is Block => !!block)
                    .map((block) => ({ kind: 'list-item', blocks: [block] })),
            }
            return listBlock
        }
        case 'ordered-list': {
            const listBlock: OrderedListBlock = {
                kind: block.kind,
                type: block.type,
                start: block.start,
                items: block.items
                    .map(mapContentBlock)
                    .filter((block): block is Block => !!block)
                    .map((block) => ({ kind: 'list-item', blocks: [block] })),
            }
            return listBlock
        }
        case 'unordered-list-v2': {
            const listBlock: UnorderedListBlock = {
                kind: 'unordered-list',
                items: block.items.map((item) => ({
                    kind: 'list-item',
                    blocks: item.blocks
                        .map(mapContentBlock)
                        .filter((block): block is Block => !!block),
                })),
            }
            return listBlock
        }
        case 'ordered-list-v2': {
            const listBlock: OrderedListBlock = {
                kind: 'ordered-list',
                type: block.type,
                start: block.start,
                items: block.items.map((item) => ({
                    kind: 'list-item',
                    blocks: item.blocks
                        .map(mapContentBlock)
                        .filter((block): block is Block => !!block),
                })),
            }
            return listBlock
        }
        case 'competition': {
            const compBlock: CompetitionBlock = {
                kind: block.kind,
                id: block.id,
            }
            return compBlock
        }
        case 'inline-related': {
            const inlineRelatedBlock: InlineRelatedContentBlock = {
                kind: block.kind,
                publications: mapInlineRelatedPublications(block.publications),
            }
            return inlineRelatedBlock
        }
        case 'buy-now': {
            const buyNowBlock: BuyNowBlock = {
                kind: block.kind,
                link: block.link,
                text: block.text,
            }
            return buyNowBlock
        }
        case 'breakout-box': {
            const breakoutBoxBlock: BreakoutBoxBlock = {
                kind: block.kind,
                content: block.content
                    .map(mapContentBlock)
                    .filter((block): block is Block => !!block)
                    .map((block) => ({ ...block })),
            }
            return breakoutBoxBlock
        }
        case 'article-summary': {
            const articleSummaryBlock: ArticleSummaryBlock = {
                kind: block.kind,
                items: block.items
                    .map(mapContentBlock)
                    .filter((block): block is Block => !!block)
                    .map((block) => ({ kind: 'list-item', blocks: [block] })),
            }
            return articleSummaryBlock
        }
        default:
            return undefined
    }
}

function mapInlineRelatedPublications(
    publications: ListPublicationV4DTO[],
): PublicationCardItem[] {
    const mappedPublications = publications.map<PublicationCardItem>((item) =>
        mapListPublication(item),
    )

    return mappedPublications
}

function mapTextBlock(block: Block_TextV4DTO): TextBlock | EmbedBlock {
    const embedIntention = block.intentions.find(
        (intention) =>
            intention.kind === 'link' &&
            intention.href.startsWith('embeds://elections-electorate'),
    )

    // Hacky workaround for elections-electorate embeds to be mapped properly for live blogs
    if (embedIntention) {
        const embedBlock: EmbedBlock = {
            kind: 'embed',
            link: embedIntention.kind === 'link' ? embedIntention.href : '',
        }
        return embedBlock
    }

    return {
        kind: block.kind,
        text: block.text,
        intentions: block.intentions.map<Intention>((intention) => {
            if (intention.kind === 'link') {
                return {
                    kind: intention.kind,
                    href: intention.href,
                    index: getMatchIndex(
                        block.text,
                        intention.match,
                        intention.index,
                    ),
                    length: intention.match.length,
                }
            }
            return {
                kind: intention.kind,
                index: getMatchIndex(
                    block.text,
                    intention.match,
                    intention.index,
                ),
                length: intention.match.length,
            }
        }),
    }
}

function imageFromAsset(
    inlineAsset: Block_InlineAssetV4DTO,
    imageAsset: Asset_Image,
) {
    const imageWithCrops = imageWithCropsDtoToImageCrop(imageAsset)
    if (imageAsset.userSelectedCrop) {
        imageWithCrops.userSelectedCrop = imageAsset.userSelectedCrop
    }

    const imageBlock: InlineImageBlock = {
        kind: inlineAsset.kind,
        inlineKind: imageAsset.kind,
        align: inlineAsset.align,
        inlineDetails: imageWithCrops,
        link: imageAsset.link,
    }

    return imageBlock
}

function inlineFromInlineAsset(inlineAsset: Block_InlineAssetV4DTO) {
    const asset = inlineAsset.asset
    if (!inlineAsset) {
        return
    }

    if (asset.kind === 'image' && asset.original) {
        const imageBlock = imageFromAsset(inlineAsset, asset)

        return imageBlock
    }

    if (asset.kind === 'video') {
        const videoBlock: InlineVideoBlock = {
            kind: inlineAsset.kind,
            inlineKind: asset.kind,
            inlineDetails: {
                captionText: asset.videoDescription,
            },
            inlineVideo: {
                id: asset.referenceId,
                accountId: asset.accountId,
                heading: asset.videoDescription,
                videoType: asset.videoType,
                posterImage: asset.posterImage
                    ? {
                          altText: asset.videoDescription,
                          crops:
                              typeof asset.posterImage.ratio === 'string'
                                  ? {
                                        [asset.posterImage.ratio]: {
                                            width: asset.posterImage.width,
                                            height: asset.posterImage.height,
                                            reference:
                                                asset.posterImage.reference,
                                        },
                                    }
                                  : {
                                        original: {
                                            width: asset.posterImage.width,
                                            height: asset.posterImage.height,
                                            reference:
                                                asset.posterImage.reference,
                                            ratio: asset.posterImage.ratio,
                                        },
                                    },
                      }
                    : undefined,
            },
        }
        return videoBlock
    }

    return
}

export function getMatchIndex(text: string, match: string, index: number) {
    const trimmedMatch = match.trim()
    let pos = text.indexOf(trimmedMatch)
    let count = 0

    while (pos !== -1 && count < index) {
        count++
        pos = text.indexOf(trimmedMatch, pos + 1)
    }
    return pos
}
