import styled, { CSSObject } from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { breakpoint } from '../../../../__styling'
import { IconPNCamera } from '../../../../icons'
import { IconPNVideo } from '../../../../icons'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../__styling/style-mixins'
import { Theme } from '../../../../__styling/themes'
import { PNFigCaption } from './PNFigCaption'
import { perthNowNGNTextMixin } from '../../../../mixins'
import { StyledPNFigureProps } from '../PNArticleImage/PNArticleImage.styled'

const { spacing } = metricsPN
const { text } = colorsPN

const iconWidth = spacing['S']
const iconMarginRight = spacing['2XS']
const iconMarginTop = spacing['5XS']

export const StyledPNCaption = styled('figcaption')(() => [
    {
        breakWords,
        '&::after': {
            content: `''`,
            display: 'table',
            clear: 'both',
        },
    },
])

export const StyledPNFigCaptionText = styled('span')(() => ({
    ...breakWords,
    display: 'block',
    float: 'left',
    maxWidth: '100%', // needed to break long words in IE and Edge
    width: `calc(100% - (${calcRem(iconWidth)} + ${calcRem(iconMarginRight)}))`,
    ...perthNowNGNTextMixin.body.S.regular,
}))

export const PNCaption = styled('span')({
    display: 'inline',
})
PNCaption.displayName = 'PNCaption'

const iconStyles: CSSObject = {
    width: calcRem(iconWidth),
    height: calcRem(iconWidth),
    display: 'block',
    verticalAlign: 'middle',
    float: 'left',
    margin: calcRem(iconMarginTop, iconMarginRight, 0, 0),
}

export const StyledIconPNImage = styled(IconPNCamera)(iconStyles)

export const StyledIconPNVideo = styled(IconPNVideo)(iconStyles)

export const StyledPNFigcaption = styled(PNFigCaption)<StyledPNFigureProps>(
    ({ shouldIndent }) => ({
        color: text.primary,
        padding: calcRem(spacing['XS'], shouldIndent ? spacing['S'] : 0, 0),

        [breakpoint('lg')]: {
            padding: calcRem(spacing['S'], 0, 0),
        },
    }),
)
