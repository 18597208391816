/** @jsx jsx */
import { jsx } from '@emotion/react'
import { AdDefinition, AdState, AllEvents } from '@news-mono/web-common'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import { AdContainer } from './PNTopicCardList.styled'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { Romeo, RomeoProps } from '../PNRomeo/Romeo'
import { AdUnitWrapper } from '../../advertising'

export interface PNTopicCardListProps
    extends RomeoProps,
        CommonCardProps,
        ResponsiveContainer {
    adUnits: number
    adState: AdState
    onEvent: (event: AllEvents) => void
}

const INITIAL_AD_OFFSET = 5
const CARDS_BETWEEN_ADS = 5
const getAdUnitId = (index: number) => `pn-topic-article-list-${index}`

// Builds an array of the AdUnitDefinitions.
export const getPNTopicSectionAdUnitDefinitions = (adUnitAmount: number) =>
    Array.from(
        { length: adUnitAmount },
        (_, i) => INITIAL_AD_OFFSET + i * CARDS_BETWEEN_ADS,
    ).map<AdDefinition>((index) => ({
        id: getAdUnitId(index),
        size: 'leaderboardAbove768MrecBelow',
    }))

export const PNTopicCardList: React.FC<PNTopicCardListProps> = (props) => {
    return (
        <Romeo
            {...props}
            interlacedComponents={{
                adverts: {
                    component: (index) => (
                        <AdContainer>
                            <AdUnitWrapper
                                adState={props.adState}
                                onEvent={props.onEvent}
                                noticePosition={'none'}
                                adType={'inline'}
                                unitId={getAdUnitId(index)}
                            />
                        </AdContainer>
                    ),
                    initialOffset: INITIAL_AD_OFFSET,
                    cardsBetween: CARDS_BETWEEN_ADS,
                    repetitions: props.adUnits,
                },
            }}
        />
    )
}
PNTopicCardList.displayName = 'PNTopicCardList'
