import React from 'react'
import { Ellipses } from '../Pagination.styled'
import { PaginationItem } from './PaginationItem'

interface WrapperProps {
    ellipsesPosition: 'before' | 'after' | 'none'
    path: string
    isDisabled: boolean
    onPageChange: (x: number) => void
    onEvent: (event: any) => void
    currentPage: number
    params?: string
    nextPage: number
    hideItemBorder?: boolean
}
export const WrapperPages = ({
    ellipsesPosition,
    path,
    isDisabled,
    onPageChange,
    currentPage,
    nextPage,
    params,
    hideItemBorder = true,
    onEvent,
}: WrapperProps) => {
    return (
        <>
            {ellipsesPosition === 'before' && <Ellipses>...</Ellipses>}
            <PaginationItem
                isDisabled={isDisabled}
                layout={{
                    showText: true,
                    display: {
                        default: {
                            display: 'none',
                        },
                        sm: {
                            display: 'flex',
                        },
                    },
                }}
                onClick={onPageChange}
                routePath={path}
                page={{ currentPage, nextPage }}
                params={params}
                onEvent={onEvent}
                hideItemBorder={hideItemBorder}
            />
            {ellipsesPosition === 'after' && <Ellipses>...</Ellipses>}
        </>
    )
}
