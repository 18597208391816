import styled from '@emotion/styled'
import { ImageSource, WebLink } from '@news-mono/web-common'
import { metricsPN } from '@news-mono/design-tokens'
import { ResponsivePicture } from '../../../../content/Picture/Picture'
import { easing } from '../../../../__styling/settings/easing'
import { breakpoint } from '../../../../__styling/style-functions'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { transition } from '../../../../__styling/style-mixins'
import { StyledPNFigcaption } from '../PNFigCaption/PNFigCaption.styled'
import {
    insetMarginDesktop,
    insetMarginTablet,
} from '../../../../templates/Publication/PerthNowPublication/PNPublicationNGN.styled'

const { spacing, radius } = metricsPN

export interface StyledPNFigureProps {
    isHero?: boolean
    align?: 'right' | 'left'
    isTwin?: boolean
    imageRenderType?: string
    shouldIndent?: boolean
}

export const StyledPNFigure = styled('figure')<StyledPNFigureProps>(
    ({ isHero, align, imageRenderType }) => {
        return [
            {
                display: 'block',
                clear: 'both',

                '& + [class*="StyledPNFigure"]': {
                    marginTop: calcRem(metricsPN.spacing['L']),

                    [breakpoint('lg')]: {
                        marginTop: calcRem(metricsPN.spacing['XL']),
                    },
                },

                [breakpoint('sm')]: {
                    marginLeft: isHero ? 'auto' : insetMarginTablet,
                    marginRight: isHero ? 'auto' : insetMarginTablet,
                    width: isHero ? '100%' : undefined,
                },

                [breakpoint('lg')]: {
                    marginLeft: isHero ? undefined : insetMarginDesktop,
                    marginRight: isHero ? undefined : insetMarginDesktop,
                },
            },

            /**
             * DPT-2738: fix alignment at smaller breakpoints
             */
            imageRenderType === 'feature' && {
                marginLeft: 0,
                marginRight: 0,
                maxWidth: '100%',
            },

            // Determines whether the image is left or right aligned and sets appropriate padding/margin
            align !== undefined && {
                [breakpoint('sm')]: {
                    clear: align,
                    float: align,
                    width: calcRem(397),
                    marginTop: 'unset',
                    marginRight:
                        align === 'left' ? calcRem(metricsPN.spacing['S']) : 0,
                    marginLeft:
                        align === 'right' ? calcRem(metricsPN.spacing['S']) : 0,
                },

                [breakpoint('md')]: {
                    marginRight:
                        align === 'left'
                            ? calcRem(metricsPN.spacing['S'])
                            : insetMarginTablet,
                    marginLeft:
                        align === 'right'
                            ? calcRem(metricsPN.spacing['S'])
                            : insetMarginTablet,
                },

                [breakpoint('lg')]: {
                    marginRight:
                        align === 'left' ? calcRem(metricsPN.spacing['S']) : 0,
                    marginLeft:
                        align === 'right' ? calcRem(metricsPN.spacing['S']) : 0,
                },
            },
        ]
    },
)

export interface StyledPNArticleImageProps {
    isHeroImage?: boolean
    imageRenderType?: 'feature'
    isHeroImageAmp?: boolean
    image?: ImageSource
}

export const StyledPNArticleImage = styled(
    ResponsivePicture,
)<StyledPNArticleImageProps>(
    ({ isHeroImage, imageRenderType, isHeroImageAmp, renderPicture }) => ({
        display: 'block',
        width: '100%',
        maxWidth: 'none',
        borderRadius: calcRem(radius.M),
        overflow: isHeroImage ? 'hidden' : undefined,

        // Allows the image to stretch full height of the figure
        // only want to strech on breakpoints > sm as below that we show a 4:3 for feature
        // logic in ArticleImage.tsx
        [breakpoint('sm')]: {
            height: imageRenderType === 'feature' ? '100%' : undefined,
        },

        '& img': {
            width: '100%',
            maxWidth: 'none',
            borderRadius: calcRem(radius.M),
        },
    }),
)

export const StyledPNImageLink = styled(WebLink)(() => ({
    position: 'relative',
    display: 'block',

    [`${StyledPNFigcaption} span`]: {
        transition: `color ${easing.easeOut.fast}`,
    },

    'picture::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderWidth: `${spacing['4XS']}px`,
        borderStyle: 'solid',
        borderColor: 'transparent',
        ...transition({ properties: ['border-color'] }),
    },

    '&:focus, &:active': {
        [`${StyledPNFigcaption} span`]: 'underline',
    },
}))
