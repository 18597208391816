import { ColorsType } from '../types'

const Palette = {
    // TODO: Investigate removing these.
    white: '#FFFFFF',
    black: '#000000',
    deepPurple: '#4B006E',
    // Actual palette begins here:
    uplate: {
        default: '#31173D',
        light: '#DDD9E5',
        accent: '#310048',
        hover: '#4A2562',
    },
    starlight: {
        default: '#FFE4D3',
        light: '#FFF9F4',
        accent: '#4D393E',
    },
    neutral: {
        100: '#161616',
        90: '#262626',
        80: '#393939',
        70: '#525252',
        60: '#6F6F6F',
        50: '#8D8D8D',
        40: '#A8A8A8',
        30: '#C6C6C6',
        20: '#E0E0E0',
        10: '#F4F4F4',
        5: '#FAFAFA',
        0: '#FFFFFF',
    },
    section: {
        australia: {
            default: '#00045D',
            10: '#000416',
            20: '#00063D',
            30: '#00045D',
            40: '#232379',
            50: '#464693',
            60: '#6969AB',
            70: '#8C8CC1',
            80: '#AFAFD6',
            90: '#D2D2E9',
            97: '#F5F5FA',
        },
        opinion: {
            default: '#4B006E',
            10: '#09001A',
            20: '#260049',
            30: '#4B006E',
            40: '#662388',
            50: '#8046A1',
            60: '#9A69B7',
            70: '#B38CCB',
            80: '#CBAFDD',
            90: '#E2D2ED',
            97: '#F9F5FB',
        },
        world: {
            default: '#A663CC',
            10: '#1B102D',
            20: '#5C3581',
            30: '#A663CC',
            40: '#B472D9',
            50: '#C184E4',
            60: '#CE97ED',
            70: '#DAACF5',
            80: '#E6C3FA',
            90: '#F1DCFE',
            97: '#FCF7FF',
        },
        politics: {
            default: '#0024A7',
            10: '#000F28',
            20: '#001F6E',
            30: '#0024A7',
            40: '#233DBD',
            50: '#4657CF',
            60: '#6973DF',
            70: '#8C91EC',
            80: '#AFB0F5',
            90: '#D2D2FB',
            97: '#F5F5FF',
        },
        business: {
            default: '#FF6300',
            10: '#330000',
            20: '#992100',
            30: '#FF6300',
            40: '#FF7523',
            50: '#FF8846',
            60: '#FF9C69',
            70: '#FFB28C',
            80: '#FFC8AF',
            90: '#FFDFD2',
            97: '#FFF8F5',
        },
        sport: {
            default: '#0064FF',
            10: '#002433',
            20: '#005099',
            30: '#0064FF',
            40: '#2376FF',
            50: '#4689FF',
            60: '#699DFF',
            70: '#8CB2FF',
            80: '#AFC9FF',
            90: '#D2E0FF',
            97: '#F5F8FF',
        },
        society: {
            default: '#EB1320',
            10: '#33000E',
            20: '#99001B',
            30: '#EB1320',
            40: '#FA2734',
            50: '#FF464B',
            60: '#FF6969',
            70: '#FF8C8C',
            80: '#FFAFAF',
            90: '#FFD2D2',
            97: '#FFF5F5',
        },
        culture: {
            default: '#B10055',
            10: '#2B001D',
            20: '#750043',
            30: '#B10055',
            40: '#C6236C',
            50: '#D84683',
            60: '#E6699A',
            70: '#F18CB1',
            80: '#F9AFC9',
            90: '#FED2E0',
            97: '#FFF5F8',
        },
        lifestyle: {
            default: '#F49100',
            10: '#290300',
            20: '#711D00',
            30: '#AB4C00',
            40: '#C06323',
            50: '#D37B46',
            60: '#E29369',
            70: '#EEAC8C',
            80: '#F7C5AF',
            90: '#FCDED2',
            97: '#FFF8F5',
        },
        from: {
            //TODO: Update with NYT palette
            default: '#161616',
            50: '#8D8D8D',
            97: '#F4F4F4',
        },
    },
    utility: {
        success: {
            default: '#20BA42',
            light: '#D0F9D9',
            dark: '#034211',
        },
        warning: {
            default: '#EBB018',
            light: '#FFECBD',
            dark: '#663700',
        },
        error: {
            default: '#9D0009',
            hover: '#B1141D',
            light: '#FFC3C7',
            dark: '#420000 ',
        },
    },
} as const

export type TheNightlyPalette = typeof Palette

export type TheNightlyColourMap = ColorsType<TheNightlyPalette>

export const colors: TheNightlyColourMap = {
    brand: Palette.neutral[100],
    section: Palette.starlight.default,
    background: {
        primary: Palette.neutral[100],
        secondary: Palette.starlight.default,
    },
    palette: Palette,
    text: {
        primary: Palette.neutral[100],
        secondary: Palette.neutral[60],
        tertiary: Palette.neutral[70],
        heading: Palette.neutral[100],
    },
    // These aren't actually used as the structure doesn't match what we need.
    actions: {
        link: {
            default: Palette.neutral[100],
            hover: Palette.starlight.accent,
            focus: Palette.starlight.accent,
            active: Palette.starlight.accent,
        },
        button: {
            primary: {
                text: {
                    default: Palette.neutral[10],
                    hover: Palette.neutral[10],
                    focus: Palette.neutral[10],
                    active: Palette.neutral[10],
                },
                background: {
                    default: Palette.neutral[100],
                    hover: Palette.neutral[70],
                    focus: Palette.neutral[70],
                    active: Palette.neutral[70],
                },
                border: {
                    default: Palette.neutral[100],
                    hover: Palette.neutral[70],
                    focus: Palette.neutral[70],
                    active: Palette.neutral[70],
                },
            },
            secondary: {
                text: {
                    default: Palette.neutral[100],
                    hover: Palette.neutral[70],
                    focus: Palette.neutral[70],
                    active: Palette.neutral[70],
                },
                background: {
                    default: Palette.white,
                    hover: Palette.neutral[70],
                    focus: Palette.neutral[70],
                    active: Palette.neutral[70],
                },
                border: {
                    default: Palette.neutral[100],
                    hover: Palette.neutral[70],
                    focus: Palette.neutral[70],
                    active: Palette.neutral[70],
                },
            },
        },
    },
    borders: {
        primary: Palette.neutral[100],
        secondary: Palette.neutral[20],
    },
    components: {
        summary: {
            background: Palette.starlight.light,
            text: Palette.starlight.accent,
        },
    },
}
