import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import {
    breakpointMax,
    breakpoint,
} from '../../../../../__styling/style-functions/breakpoint'
import { PNArticleCardTheme } from '../../PNCard.Theme'
import {
    CardType,
    ImageMode,
    KickerMode,
    PNArticleCardProps,
    PNFontScales,
} from '../PNArticleCard'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { calcRem } from '@news-mono/component-library'
import { desktopToMobileTitleMap } from '../../../../../mixins/TextMixin/TextMixin.theme'
import { StyledReportingLiveBubble } from '../../../../../publication/LiveEvent/LiveEvent.styled'

const { text, stroke, background } = colorsPN
/**
 * Retrieves the styles for the article card header based on the card type, image mode, and font scale.
 *
 * @param {CardType} cardType - The type of the card ('vertical' or 'horizontal').
 * @param {ImageMode} imageMode - The mode of the image ('left' or 'right').
 * @param {PNFontScale} fontScale - The font scale for the card header.
 * @returns {object} - The styles for the article card header.
 */
const getArticleCardHeaderStyles = (
    cardType: CardType,
    imageMode: ImageMode,
    fontScale: PNFontScales,
) => {
    const fontStyle = {
        span: {
            ...PNArticleCardTheme?.[fontScale]?.tag,
            [breakpointMax('xs')]: {
                ...PNArticleCardTheme?.S?.tag,
            },
        },
    }
    switch (cardType) {
        case 'vertical':
            return {
                gridColumn: '1',
                gridRow: '2',
                marginTop: calcRem(metricsPN.spacing['S']),
                ...fontStyle,
            }
        case 'horizontal':
            return {
                gridColumn:
                    imageMode === 'left'
                        ? '2'
                        : imageMode === 'none'
                        ? '1/3'
                        : '1/2',
                gridRow: '1/2',
                padding:
                    imageMode === 'left'
                        ? calcRem(0, 0, 0, metricsPN.spacing['2XS'])
                        : calcRem(0, metricsPN.spacing['2XS'], 0, 0),
                ...fontStyle,
            }
        default:
            return {}
    }
}

export const StyledHeader = styled.header(
    (props: {
        'data-card-type'?: CardType
        'data-image-mode'?: ImageMode
        'data-font-scale'?: PNFontScales
    }) => {
        const {
            'data-card-type': cardType,
            'data-image-mode': imageMode,
            'data-font-scale': fontScale,
        } = props
        return {
            ...getArticleCardHeaderStyles(
                (cardType as CardType) ?? 'horizontal',
                (imageMode as ImageMode) ?? 'right',
                (fontScale as PNFontScales) ?? 'M',
            ),
        }
    },
)

export const StyledHeaderMeta = styled.div<{ fontScale: PNFontScales }>(
    ({ fontScale }) => ({
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: calcRem(
            ['XL', '2XL'].includes(fontScale)
                ? metricsPN.spacing['XS']
                : metricsPN.spacing['3XS'],
        ),
    }),
)

export const StyledBadgeSpan = styled.span(() => ({
    color: `${text.brand}`,
    textAlign: 'center',

    padding: calcRem(
        metricsPN.spacing['4XS'],
        metricsPN.spacing['2XS'],
        metricsPN.spacing['4XS'],
        0,
    ),
    margin: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    width: 'fit-content',

    svg: {
        marginRight: calcRem(metricsPN.spacing['5XS']),
    },

    [`${StyledReportingLiveBubble}`]: {
        background: background.brand.strong,
        width: calcRem(metricsPN.spacing['2XS']),
        height: calcRem(metricsPN.spacing['2XS']),
        marginRight: calcRem(metricsPN.spacing['4XS']),
    },
}))

export const StyledTopicAnchor = styled(WebLink)<{ isTimestamped?: boolean }>(
    ({ isTimestamped }) => ({
        display: 'inline-block',
        color: text.secondary,
        gridColumn: '1 / 2',
        textDecoration: 'none',
        alignSelf: 'center',
        borderRadius: `${metricsPN.radius['M']}px`,
        margin: 0,
        ...PNArticleCardTheme?.M?.topic,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:focus-visible': {
            outline: 'revert',
            textDecoration: 'none',
        },
        '&:active': {
            color: `${text.brand}`,
            textDecoration: 'none',
        },

        [breakpoint('lg')]: {
            padding: isTimestamped ? calcRem(metricsPN.spacing['3XS'], 0) : 0,
        },
    }),
)

export const StyledKicker = styled.p<{ kickerMode?: KickerMode }>(
    ({ kickerMode = 'middle' }) => {
        const topKickerStyling = {
            position: 'absolute',
            margin: 0,
            top: calcRem(metricsPN.spacing['XS']),
            left: calcRem(metricsPN.spacing['XS']),
            padding: calcRem(
                metricsPN.spacing['5XS'],
                metricsPN.spacing['2XS'],
            ),
            ...PNArticleCardTheme.M.kicker,
        } as const

        const inlineKickerStyling = {
            ...PNArticleCardTheme.S.kicker,
            padding: calcRem(
                metricsPN.spacing['5XS'],
                metricsPN.spacing['3XS'],
            ),
        } as const

        return {
            display: 'inline-block',
            background: background.brand.veryWeak,
            color: text.brand,
            borderRadius: `${metricsPN.radius['S']}px`,
            margin: calcRem(0, 0, metricsPN.spacing['2XS'], 0),

            ...(kickerMode === 'top' ? topKickerStyling : inlineKickerStyling),
        }
    },
)

export const StyledHeaderAnchor = styled(WebLink)(() => ({
    display: 'block',
    gridColumn: '1 / 2',
    textDecoration: 'none',
    alignSelf: 'end',
    '&:focus-visible': {
        outline: 'revert',
        borderRadius: `${metricsPN.radius['M']}px`,
    },
}))

interface StyledHeadlineProps {
    'data-font-scale'?: PNFontScales
    headerFontOverride?: PNArticleCardProps['headerFontOverride']
}

export const StyledHeadline = styled.h2<StyledHeadlineProps>(
    ({ 'data-font-scale': fontScale = 'M', headerFontOverride }) => {
        const mobileHeaderFontScale = headerFontOverride
            ? headerFontOverride.mobile
            : 'S'
        const tabletHeaderFontScale = headerFontOverride
            ? headerFontOverride.tablet
            : desktopToMobileTitleMap[fontScale]
        const desktopHeaderFontScale = headerFontOverride
            ? headerFontOverride.desktop
            : fontScale

        return {
            color: text.primary,
            display: 'inline-block',
            borderRadius: `${metricsPN.radius['M']}px`,
            margin: 0,
            ...PNArticleCardTheme?.[mobileHeaderFontScale]?.headLine,
            [breakpoint('sm')]: {
                ...PNArticleCardTheme?.[tabletHeaderFontScale]?.headLine,
            },
            [breakpoint('lg')]: {
                ...PNArticleCardTheme?.[desktopHeaderFontScale]?.headLine,
            },

            '&:hover': {
                textDecoration: 'underline',
            },
            '&:focus-visible': {
                outline: 'revert',
            },
            '&:active': {
                color: `${text.brand}`,
            },
        }
    },
)
