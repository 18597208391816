import { FilterQueryParam } from '../../../result-filters'

export const STATE_FILTER_QUERY_PARAM = 'state'

export const stateFilterItems: FilterQueryParam[] = [
    {
        displayName: 'All',
        paramName: 'all',
    },
    {
        displayName: 'ACT',
        paramName: 'act',
    },
    {
        displayName: 'NSW',
        paramName: 'nsw',
    },
    {
        displayName: 'NT',
        paramName: 'nt',
    },
    {
        displayName: 'QLD',
        paramName: 'qld',
    },
    {
        displayName: 'SA',
        paramName: 'sa',
    },
    {
        displayName: 'TAS',
        paramName: 'tas',
    },
    {
        displayName: 'VIC',
        paramName: 'vic',
    },
    {
        displayName: 'WA',
        paramName: 'wa',
    },
]

export type ElectionStateFilterOptions = typeof stateFilterItems
