import styled from '@emotion/styled'
import React from 'react'
import {
    getComponentTheme,
    breakpointSwitch,
    calcRem,
} from '../../../__styling'

export interface ElectionTextProps {
    children?: React.ReactNode
}

export const ElectionText = ({ children }: ElectionTextProps) => (
    <StyledElectionText>{children}</StyledElectionText>
)

// Container object, handles the background colours and the hover, & onClick!
export const StyledElectionText: React.FC = styled('p')(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return [
        {
            textAlign: 'start',
            margin: 0,

            lineHeight: '125%',
            letterSpacing: calcRem(-0.32),
        },
        electionTheme.text &&
            breakpointSwitch(electionTheme.text, (styles) => styles),
    ]
})
