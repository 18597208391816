import { PerthNowSections } from '@west-australian-newspapers/publication-types'
import { BaseSectionValues } from '.'
import { colors } from '../../../__styling/settings/colors'

export type PerthNowSection = keyof typeof PerthNowSections

export interface PerthNowSectionValues extends BaseSectionValues {}

export type PerthNowSectionProps = {
    [section in PerthNowSection]: PerthNowSectionValues
}

export const perthNowSectionDefinitions: PerthNowSectionProps = {
    default: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    generic: {
        primaryColor: colors.perthnow.greyBasalt,
    },
    news: {
        primaryColor: colors.perthnow.blueSapphire,
    },
    classifieds: {
        primaryColor: colors.perthnow.blueKyanite,
    },
    sport: {
        primaryColor: colors.perthnow.orangeCitrine,
    },
    entertainment: {
        primaryColor: colors.perthnow.purpleAmethyst,
    },
    business: {
        primaryColor: colors.perthnow.tealTopaz,
    },
    lifestyle: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    video: {
        primaryColor: colors.perthnow.greenJade,
    },
    politics: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    travel: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    technology: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    opinion: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'community-news': {
        primaryColor: colors.perthnow.blueCerulean,
    },
    'local-news': {
        primaryColor: colors.perthnow.blueCerulean,
    },
    'read-your-local-paper': {
        primaryColor: colors.perthnow.blueKyanite,
    },
    'shop-now': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'top-stories': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'wa-news': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    food: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'celebrity-gossip': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'over-east': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    crime: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'best-eats': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    'west-coast': {
        primaryColor: colors.perthnow.pinkThulite,
    },
    fremantle: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    weather: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    traffic: {
        primaryColor: colors.perthnow.pinkThulite,
    },
    lotto: {
        primaryColor: colors.perthnow.pinkThulite,
    },
}
