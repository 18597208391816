import React from 'react'
import { createContext, ReactNode } from 'react'
import { useQueryParams } from '@news-mono/web-common'
import { FilterQueryParam } from '../../result-filters'
import {
    sortMethods,
    SORT_FILTER_QUERY_PARAM,
    stateFilterItems,
    STATE_FILTER_QUERY_PARAM,
} from './filters'

export const SEARCH_ELECTION_QUERY_PARAM = 'search'

export interface ElectionFilterValue<T> {
    id: string
    initialValue: T
    allValues: T[]
    value: T
    setValue: (newValue: T) => void
}

type ElectionFilterContextType = {
    search: ElectionFilterValue<string>
    sort: ElectionFilterValue<FilterQueryParam>
    state: ElectionFilterValue<FilterQueryParam>
}
export const ElectionFilterContext = createContext<ElectionFilterContextType>({
    search: {
        id: 'search',
        initialValue: '',
        value: '',
        setValue: () => {
            throw new Error('setSearch function must be overridden')
        },
        allValues: [],
    },
    sort: {
        id: 'sort',
        initialValue: sortMethods[0],
        value: sortMethods[0],
        setValue: () => {
            throw new Error('setSort function must be overridden')
        },
        allValues: sortMethods,
    },
    state: {
        id: 'state',
        initialValue: stateFilterItems[0],
        value: stateFilterItems[0],
        setValue: () => {
            throw new Error('setState function must be overridden')
        },
        allValues: stateFilterItems,
    },
})

/**
 * ElectionFilterContextProvider is used to provide the election context to the children components
 */
export const ElectionFilterContextProvider = ({
    children,
}: {
    children?: ReactNode
}): JSX.Element => {
    const { queryParams, pathname } = useQueryParams()
    const search = useSearchElectionFilterValue(queryParams, pathname)
    const sort = useSortElectionFilterValue(queryParams, pathname)
    const state = useStateElectionFilterValue(queryParams, pathname)

    const electionFilterContextValues: ElectionFilterContextType = {
        search: search,
        sort: sort,
        state: state,
    }

    return (
        <ElectionFilterContext.Provider value={electionFilterContextValues}>
            {children}
        </ElectionFilterContext.Provider>
    )
}

const useSortElectionFilterValue = (
    queryParams: URLSearchParams,
    pathname: string,
): ElectionFilterValue<FilterQueryParam> => {
    const id = 'sort'
    const initialValue = sortMethods[0]

    const [sort, setSort] = React.useState(() => {
        const sortParam = queryParams.get(SORT_FILTER_QUERY_PARAM)
        const foundSortMethod = sortMethods.find(
            (method) => method.paramName === sortParam,
        )

        return foundSortMethod || initialValue
    })
    return {
        id,
        initialValue,
        allValues: sortMethods,
        value: sort,
        setValue: (newValue: FilterQueryParam) => {
            setSort(newValue)
            setQueryParams(
                queryParams,
                pathname,
                initialValue.paramName,
                id,
                newValue.paramName,
            )
        },
    }
}
const useStateElectionFilterValue = (
    queryParams: URLSearchParams,
    pathname: string,
): ElectionFilterValue<FilterQueryParam> => {
    const id = 'state'
    const initialValue = stateFilterItems[0]

    const [state, setState] = React.useState(() => {
        const stateParam = queryParams.get(STATE_FILTER_QUERY_PARAM)
        const foundState = stateFilterItems.find(
            (method) => method.paramName === stateParam,
        )

        return foundState || initialValue
    })

    return {
        id,
        initialValue,
        allValues: stateFilterItems,
        value: state,
        setValue: (newValue: FilterQueryParam) => {
            setState(newValue)
            setQueryParams(
                queryParams,
                pathname,
                initialValue.paramName,
                id,
                newValue.paramName,
            )
        },
    }
}
const useSearchElectionFilterValue = (
    queryParams: URLSearchParams,
    pathname: string,
): ElectionFilterValue<string> => {
    const id = 'search'
    const initialValue = ''

    const [search, setSearch] = React.useState(
        queryParams.get(SEARCH_ELECTION_QUERY_PARAM) || initialValue,
    )

    return {
        id,
        initialValue,
        allValues: [],
        value: search,
        setValue: (newValue: string) => {
            setSearch(newValue)
            setQueryParams(queryParams, pathname, initialValue, id, newValue)
        },
    }
}

const setQueryParams = (
    queryParams: URLSearchParams,
    pathname: string,
    initialValue: string,
    id: string,
    value: string,
) => {
    if (value === initialValue) {
        queryParams.delete(id)
    } else {
        queryParams.set(id, value)
    }

    const url = pathname + '?' + queryParams.toString()
    window.history.pushState({ path: url }, '', url)
}
