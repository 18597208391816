import React from 'react'
import {
    PNTopicAccordionContainer,
    StyledChevron,
    StyledTopic,
    TopicDivider,
    TopicContainer,
    SecondaryTopicsContainer,
    ChevronContainer,
    TopicChevronAccordion,
    SecondaryTopicsItem,
    SecondaryTopicsWrapper,
    StyledLink,
    AccordionWrapper,
} from './PNTopicAccordion.styled'
import { PNTopicAccordionProps } from './PNTopicAccordion.routing'
import { RoutablePerthNowSection } from '@west-australian-newspapers/publication-types'
import { NavItem } from '@news-mono/web-common'
import { PerthNowNavItem } from '../../../../routing'

export const PNTopicAccordion: React.FC<PNTopicAccordionProps> = ({
    topics,
    perthNowSectionNavItems,
    subTopicNavItems,
    topicHeaderType,
    parentTopicId,
}) => {
    const [showSecondaryTopics, setShowSecondaryTopics] = React.useState(false)
    const { primary } = topics

    const primaryTopic = primary.parent ?? primary

    const hasSecondaryTopic = primary.parent !== undefined

    let parentTopic: PerthNowNavItem | undefined
    let subTopic: NavItem | undefined
    // current topic on a topic page
    let currentPageTopic: NavItem | undefined
    let secondaryTopics: NavItem[] = []

    // strip out the hyphen from the primary topic id
    const primaryTopicId = primaryTopic.id.replace(/-/g, '')
    if (primaryTopicId in subTopicNavItems) {
        parentTopic =
            perthNowSectionNavItems[primaryTopicId as RoutablePerthNowSection]
        secondaryTopics = parentTopic?.subNavLinks ?? []

        if (hasSecondaryTopic) {
            subTopic = subTopicNavItems[
                primaryTopicId as RoutablePerthNowSection
            ].find((item) => item.link === `/${primary.id}`)
        }
    }

    // On tertiary topic page, the dropdown should show tertiary topics
    if (topicHeaderType === 'tertiary') {
        subTopic = secondaryTopics.find(
            (topic) => topic.link === `/${parentTopicId}`,
        )
        secondaryTopics =
            secondaryTopics.find((topic) => topic.link === `/${parentTopicId}`)
                ?.subNavLinks ?? []
        currentPageTopic = secondaryTopics.find(
            (topic) => topic.link === `/${primary.id}`,
        )
    }

    const showSubTopic =
        subTopic &&
        (typeof topicHeaderType === 'undefined' ||
            topicHeaderType !== 'secondary')

    if (!parentTopic) {
        return null
    }

    return (
        <PNTopicAccordionContainer
            aria-label="Accordion Navigation"
            isTopicPage={!!topicHeaderType}
        >
            <AccordionWrapper>
                <TopicChevronAccordion
                    onClick={() => setShowSecondaryTopics(!showSecondaryTopics)}
                    tabIndex={0}
                    aria-expanded={showSecondaryTopics}
                >
                    <TopicContainer>
                        <StyledTopic isOpen={showSecondaryTopics}>
                            {parentTopic.name}
                        </StyledTopic>
                        {showSubTopic && subTopic && (
                            <>
                                <TopicDivider aria-hidden="true" />
                                <StyledTopic isOpen={showSecondaryTopics}>
                                    {subTopic.name}
                                </StyledTopic>
                            </>
                        )}
                    </TopicContainer>
                    {secondaryTopics.length > 0 && (
                        <ChevronContainer>
                            <StyledChevron isOpen={showSecondaryTopics} />
                        </ChevronContainer>
                    )}
                </TopicChevronAccordion>
            </AccordionWrapper>
            {secondaryTopics && (
                <SecondaryTopicsWrapper isOpen={showSecondaryTopics}>
                    <SecondaryTopicsContainer isOpen={showSecondaryTopics}>
                        {secondaryTopics.map((topic, index) => {
                            const currentTopic =
                                topicHeaderType === 'tertiary'
                                    ? currentPageTopic
                                    : subTopic
                            const isCurrent = topic.name === currentTopic?.name

                            return (
                                <SecondaryTopicsItem
                                    isCurrent={isCurrent}
                                    key={index}
                                >
                                    <StyledLink
                                        to={`${topic.link}`}
                                        aria-current={
                                            isCurrent ? 'location' : undefined
                                        }
                                        tabIndex={showSecondaryTopics ? 0 : -1}
                                    >
                                        {topic.name}
                                    </StyledLink>
                                </SecondaryTopicsItem>
                            )
                        })}
                    </SecondaryTopicsContainer>
                </SecondaryTopicsWrapper>
            )}
        </PNTopicAccordionContainer>
    )
}
