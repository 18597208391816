import React, { useState, useEffect } from 'react'
import {
    StyledErrorContainer,
    StyledErrorText,
    StyledLoadingContainer,
    StyledMeterBackgroundImage,
    StyledNeedleContainer,
    StyledNeedleImage,
    StyledSpinner,
    StyledWidgetContainer,
    StyledWidgetInnerContainer,
} from './FederalElectionNeedle.styled'
import { useElectionNeedleData } from './helpers/useElectionNeedleData'
import { ConfigurationContext } from '@news-mono/web-common'

const arcBackground = require('./assets/needle_bg.png')
const needleImage = require('./assets/needle.png')

interface FederalElectionNeedleProps {}

const CustomAlertIcon: React.FC = () => (
    <svg
        width="20"
        height="20"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
    >
        <circle cx="12" cy="12" r="10" strokeWidth="2" />
        <path strokeLinecap="round" strokeWidth="2" d="M12 8v4m0 4h.01" />
    </svg>
)

const preloadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve()
        img.onerror = () => reject()
        img.src = src
    })
}

const FederalElectionNeedle: React.FC<FederalElectionNeedleProps> = ({}) => {
    const config = React.useContext(ConfigurationContext)

    const {
        data,
        isLoading: dataLoading,
        error,
    } = useElectionNeedleData(config.contentfulStaticSiteEndpoint)
    const [needleRotation, setNeedleRotation] = useState<number>(0)
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false)

    // Preload images
    useEffect(() => {
        const loadImages = async () => {
            try {
                await Promise.all([
                    preloadImage(arcBackground),
                    preloadImage(needleImage),
                ])
                setImagesLoaded(true)
            } catch (err) {
                console.error('Failed to load images:', err)
                // Still set images as loaded to prevent infinite loading state
                setImagesLoaded(true)
            }
        }

        loadImages()
    }, [])

    // Combined loading state
    const loading = dataLoading || !imagesLoaded

    // Analytics
    useEffect(() => {
        // Track impression
    }, [])

    // Animation effect
    useEffect(() => {
        if (
            data !== null &&
            (data?.needlePosition || data?.needlePosition === 0)
        ) {
            const targetRotation = (data.needlePosition / 100) * 90
            const wobbleSteps = 5
            const wobbleDuration = 1000
            const finalAnimationDuration = 500

            Array.from({ length: wobbleSteps }).forEach((_, i) => {
                setTimeout(() => {
                    const wobbleAmount = 10 * (1 - i / wobbleSteps)
                    const wobbleRotation =
                        targetRotation + (Math.random() * 2 - 1) * wobbleAmount
                    setNeedleRotation(wobbleRotation)
                }, (i * wobbleDuration) / wobbleSteps)
            })

            setTimeout(() => {
                setNeedleRotation(targetRotation)
            }, wobbleDuration + finalAnimationDuration)
        }
    }, [data])

    if (loading) {
        return (
            <StyledLoadingContainer>
                <StyledSpinner />
            </StyledLoadingContainer>
        )
    }

    if (error) {
        return (
            <StyledErrorContainer>
                <CustomAlertIcon />
                <StyledErrorText>Error loading widget</StyledErrorText>
            </StyledErrorContainer>
        )
    }

    return (
        <StyledWidgetContainer>
            <StyledWidgetInnerContainer role="meter">
                {/* Background Image */}
                <StyledMeterBackgroundImage
                    src={arcBackground}
                    alt="Election needle background"
                />

                {/* Needle */}
                <StyledNeedleContainer
                    style={{
                        transform: `translateX(-50%) rotate(${needleRotation}deg)`,
                    }}
                >
                    <StyledNeedleImage
                        src={needleImage}
                        alt="Election needle"
                    />
                </StyledNeedleContainer>
            </StyledWidgetInnerContainer>
        </StyledWidgetContainer>
    )
}

export default FederalElectionNeedle
