import React from 'react'
import { BylineProps } from './Byline'
import { BylineProfile } from './components/BylineProfile'
import { BylineText } from './components/TheNightly/BylineText'
import { StyledByline } from './Byline.styled'
import { isContentPartner } from './helpers/isContentPartner'
import { FundingType } from '../../templates'
import {
    PublicationEvents,
    SavedArticlesSaveEvent,
    SavedArticlesUnsaveEvent,
} from '@news-mono/web-common'

export type TheNightlyBylineProps = Pick<
    BylineProps,
    | 'text'
    | 'isFullWidth'
    | 'profile'
    | 'source'
    | 'timestamp'
    | 'topic'
    | 'readTimeMinutes'
    | 'url'
    | 'timestampUpdated'
    | 'publicationKind'
> & {
    isLink?: boolean
    allowCommenting: boolean
    fundingType?: FundingType | undefined
    id?: string
    onEvent?: (
        event:
            | PublicationEvents
            | SavedArticlesSaveEvent
            | SavedArticlesUnsaveEvent,
    ) => void
}

export const TheNightlyByline: React.FC<TheNightlyBylineProps> = ({
    text,
    isFullWidth,
    profile,
    source,
    timestamp,
    timestampUpdated,
    publicationKind,
    topic,
    readTimeMinutes,
    url,
    isLink,
    allowCommenting,
    fundingType,
    id,
    onEvent,
}) => {
    return (
        <StyledByline isFullWidth={isFullWidth}>
            {profile && (
                <BylineProfile profile={profile} profilePageLink={isLink} />
            )}
            <BylineText
                text={text}
                profile={profile}
                source={source}
                timestamp={timestamp}
                timestampUpdated={timestampUpdated}
                publicationKind={publicationKind}
                topic={topic}
                readTimeMinutes={readTimeMinutes}
                isContentPartner={isContentPartner(source)}
                isLink={isLink}
                allowCommenting={allowCommenting}
                url={url}
                fundingType={fundingType}
                id={id}
                onEvent={onEvent}
            />
        </StyledByline>
    )
}
