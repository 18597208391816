import {
    RequiredAccessLevel,
    SectionMetaInfoDTO,
} from '@west-australian-newspapers/publication-types'
import { slug } from 'cuid'
import { Request } from 'express'
import queryString from 'query-string'
import { Dispatch } from 'redux'
import { Logger } from 'typescript-log'
import { BreachScreenType, testSiteClientConfig } from '..'
import { ArticleLikePublication } from '../client-data-types'
import { BaseClientConfig } from '../client/BaseClientConfig'
import {
    authCheckAccessToken,
    LoginDetails,
    SubscriptionType,
} from '../data/authentication/reducer'
import { retrieveCookie, storeCookie } from '../data/cookies/cookies'
import { isServerEnvironment } from '../environment'
import { LoginEvent, LoginRefreshEvent } from '../events/user-event-types'
import { getBaseUrl } from '../routing/url'
import { click_origin_subscribe } from '../ssw'
import { decode, verify } from 'jsonwebtoken'
import jwkToPem from 'jwk-to-pem'

export const authCallbackPath = '/connect/callback'
export const authRefreshPath = '/connect/refresh'
export const authRegisterPath = '/connect/register'
export const authRegisterSuccessPath = '/connect/register-success'
export const authLoginPath = '/connect/login'
export const authLogoutPath = '/connect/logout'
export const authEndPath = '/connect/end'
export const auth0ResetPasswordPath = '/connect/auth0-reset-password'
export const appLoginPath = '/app-login'
export const appRefreshPath = '/app/refresh'
export const appLogoutPath = '/app/logout'
export const authAccountDeletedPath = '/connect/account-deleted'
export const tokenExchangePath = '/connect/token-exchange'
export const getEmailTokenPath = '/connect/email-token'
export const getEmailVerificationPath = '/connect/verify-email'
export const getVerificationTokenPath = '/connect/verify-token'
export const salesforceRedirectPath = '/connect/email-preferences'

export const sessionStateKey = 'session_stateKey'
export const sessionAccessTokenKey = 'session_access_token'
export const stopGoogleAutoLogin = 'stop_google_auto_login'
export const stopGoogleAccountLinking = 'stop_google_account_linking'
export const sessionLegacyKey = '_session.legacy'
export const sessionLegacySigKey = '_session.legacy.sig'

export const sessionNonceKey = 'session_nonce'
export const sessionCodeVerifierKey = 'session_code_verifier'

export const isProduction = process.env.NODE_ENV === 'production'

export interface AuthSiteUserInfoResponse {
    sub: string
    email: string
    email_verified: boolean
    family_name: string | undefined // not sure how it gets in this state.
    given_name: string | undefined
    updated_at: number
    'swm:subscription:entitlements': string
    'swm:subscription:occupant_id': string
    'swm:subscription:subscription_id': string
    'swm:subscription:coral_id'?: string
    'swm:subscription:subscription_type'?: string
    registered: string
    login_count?: number
    newsletterSignup?: boolean
    newsletterOptIn?: boolean
}

export function isAuthSiteUserInfoResponse(
    x: unknown,
): x is AuthSiteUserInfoResponse {
    return (
        typeof x === 'object' &&
        x !== null &&
        'swm:subscription:entitlements' in x
    )
}

export interface Auth0UserInfoResponse {
    sub: string
    email: string
    email_verified: boolean
    // Sign in with Apple doesn't provide family_name and given_name
    family_name: string | undefined // not sure how it gets in this state.
    given_name: string | undefined
    name: string
    nickname: string
    picture: string
    updated_at: string
    login_count?: number
    newsletterSignup?: boolean
    newsletterOptIn?: boolean
    'swm.subscription.entitlements': string[] // renamed from `swm:subscription:entitlements` in id.thewest.com.au
    'swm.subscription.occupant_id': string // renamed from `swm:subscription:occupant_id` in id.thewest.com.au
    'swm.subscription.subscription_id': string // renamed from `swm:subscription:subscription_id` in id.thewest.com.au
    'swm.subscription.coral_id'?: string // renamed from `swm:subscription:coral_id` in id.thewest.com.au
    'swm.subscription.subscription_type'?: string // renamed from `swm:subscription:subscription_type` in id.thewest.com.au
    'swm.registered': string // renamed from `registered` in id.thewest.com.au
    'swm.identifier': string // renamed from `sub` in id.thewest.com.au
}

export function isAuth0UserInfoResponse(
    x: unknown,
): x is Auth0UserInfoResponse {
    if (typeof x !== 'object' || x === null) {
        return false
    }

    const isAuth0UserInfo = Object.keys(x).some((key) => key.startsWith('swm.'))
    return isAuth0UserInfo
}

export type AuthUserInfoResponse =
    | AuthSiteUserInfoResponse
    | Auth0UserInfoResponse

export interface SSWRedirectAuthParams {
    redirect_uri: string
    state: string
}

export interface IdTokenState {
    idToken: string
}

export interface IdTokenDecodedJWT {
    iss: string
    iat: number
    exp: number
    sub: string
    aud: string
    at_hash: string
}

// uri safe base 64 string that can be used in query params
function toURLSafeBase64(str: string) {
    let base64 = null

    if (typeof window === 'undefined') {
        base64 = Buffer.from(str).toString('base64')
    } else {
        base64 = btoa(str)
    }

    return encodeURIComponent(base64)
}

export function urlSafeBase64ToObj<T>(uriSafeBase64: string) {
    const base64 = decodeURIComponent(uriSafeBase64)
    let decodedBase64 = null
    decodedBase64 = Buffer.from(base64, 'base64').toString()
    return JSON.parse(decodedBase64) as T
}

export function getOidcCallbackURI(
    publicUrl: string,
    sectionMeta?: SectionMetaInfoDTO,
) {
    const url = getBaseUrl(publicUrl, sectionMeta)
    return `${url}${authCallbackPath}`
}

export function getOidcRegisterCallbackURI(
    publicUrl: string,
    sectionMeta?: SectionMetaInfoDTO,
) {
    const url = getBaseUrl(publicUrl, sectionMeta)
    return `${url}${authRegisterSuccessPath}`
}

export const getOidcSessionEndURI = (
    publicUrl: string,
    sectionMeta?: SectionMetaInfoDTO,
) => {
    const url = getBaseUrl(publicUrl, sectionMeta)
    return `${url}${authEndPath}`
}

interface loginProperties {
    login_hint?: string
    prompt?: string
}

export function getLoginUrl(
    loginProperties?: loginProperties,
    componentOrigin?: SignupOrigin,
) {
    if (isServerEnvironment()) {
        throw new Error('getLoginUrl should not be called during SSR')
    }

    return `${authLoginPath}?state=${getEncodedState({
        originUrl: window.location.href,
        componentOrigin,
    })}${loginProperties ? '&' + queryString.stringify(loginProperties) : ''}`
}

export type SignupOrigin =
    /** The login modal triggered by the header bar */
    | 'login-modal'
    /** A footer banner cta */
    | 'footer-banner'
    /** A header banner cta */
    | 'header-banner'
    /** The coral comments box cta */
    | 'article-comments'
    /** Inline cta in articles */
    | 'article-inline'
    /** Inline cta in the homepage */
    | 'homepage-cta'
    /** The cta on the puzzles page */
    | 'puzzles-cta'
    /** The nightly save-article button when not logged is */
    | 'save-article'
    /** Pollie rater paragraph buttons */
    | 'pollie-rater-link'
    /** Pollie rater candidate buttons */
    | 'pollie-rater-button'
    /** Sports breach screen on the west */
    | 'sports-breach-screen'
    /** From the email-verified screen. */
    | 'email-verified'
    /** From the breach screen component. */
    | 'breach-screen'
    /** The header of the website */
    | 'header'
    /** The flyout navigation of the website */
    | 'flyout-nav'

export function getRegisterUrl(
    componentOrigin: SignupOrigin,
    platform: 'app' | 'web',
) {
    if (isServerEnvironment()) {
        throw new Error('getLoginUrl should not be called during SSR')
    }

    //to pass parameters to auth0 we encode them into the state
    return `${authRegisterPath}?state=${getEncodedState({
        originUrl: window.location.href,
        componentOrigin: componentOrigin,
        platform: platform,
    })}`
}

export const getAuthParams = (params: {
    sectionMeta?: SectionMetaInfoDTO
    stateGenerator?: () => string
    originUrl?: string
}): SSWRedirectAuthParams => {
    const { sectionMeta, stateGenerator, originUrl } = params
    return {
        redirect_uri: getOidcCallbackURI(location.origin, sectionMeta),
        state: stateGenerator
            ? stateGenerator()
            : getEncodedState({ originUrl }),
    }
}

interface SubscribeLinkOptions {
    config: BaseClientConfig
    pageUrl: string
    campaign?: string
    source?: string
    productName: string
    auth: Pick<SSWRedirectAuthParams, 'redirect_uri' | 'state'>
    packagePath?: string
    callToAction?: string
    requiredAccessLevel?: RequiredAccessLevel
    additionalParams?: { [key: string]: string | undefined }
    offerCode?: string
    breachScreenType?: BreachScreenType
    returningUser?: boolean
    article?: ArticleLikePublication
}

export const getSubscribeUrl = ({
    config,
    pageUrl,
    auth,
    campaign = 'nav-subscription-button',
    source = 'thewest.com.au',
    productName = 'The West Australian',
    packagePath = 'subscription-packages',
    callToAction = click_origin_subscribe,
    requiredAccessLevel,
    breachScreenType = undefined,
    additionalParams,
    offerCode,
    returningUser,
    article,
}: SubscribeLinkOptions) => {
    const subscriberLink =
        config.subscribeTheWestUrl || 'https://subscriber.thewest.com.au'
    const { redirect_uri, state } = auth
    const isReturningUser = returningUser ? 'true' : undefined

    const params: { [key: string]: string | undefined } = {
        utm_source: source,
        utm_medium: callToAction,
        utm_campaign: campaign,
        redirect_origin: pageUrl,
        redirect_uri,
        state,
        ...additionalParams,
        ...(offerCode && { offerCode }),
        ...(offerCode && { redirect: 'true' }), // This tells SSW to redirect to the form with the offer code applied
        returningUser: isReturningUser,
        swm_page_title: article?.heading,
        swm_page_slug: article?.slug,
        swm_page_byline: article?.byline,
        swm_page_source: article?.source,
        swm_breachscreen_version: campaign,
        swm_breachscreen_type: breachScreenType,
        swm_call_to_action: callToAction,
        swm_product_name: productName,
        swm_required_access_level: requiredAccessLevel,
    }

    const path = !offerCode ? packagePath : 'offercheck/thewest'

    Object.keys(params).forEach(
        (key) =>
            (params[key] === undefined || params[key] === '') &&
            delete params[key],
    )

    return `${subscriberLink}/${path}?${queryString.stringify(params)}`
}

export interface StateTokenCookie {
    stateToken: string
    originUrl?: string

    //used to store registration information on first log-in
    componentOrigin?: SignupOrigin
    platform?: 'web' | 'app'

    /** To be used to bypass a state check on the return, query string will be decoded instead - should encrypt this / whole state for future security */
    bypassStateCheck?: boolean
}

/**
 * Creates, stores and returns a State token
 */
export function getEncodedState(
    params: {
        originUrl?: string
        bypassStateCheck?: boolean
        componentOrigin?: SignupOrigin
        platform?: 'web' | 'app'
    },
    store = storeCookie,
): string {
    const { originUrl, bypassStateCheck, componentOrigin, platform } = params
    const state: StateTokenCookie = {
        stateToken: generateRandomAuthString(),
        originUrl,
        bypassStateCheck,
        componentOrigin,
        platform,
    }

    if (!bypassStateCheck) {
        store(getAuthStateTokenKey(state), state, {
            secure: isProduction,
            sameSite: 'lax',
            expires: new Date(Date.now() + 10 * 60 * 1000),
        })
    }

    const stringToken = JSON.stringify(state)
    return toURLSafeBase64(stringToken) // getEncodedState used Client side
}

/**
 * Generates a random string that fits within OAuth spec
 */
export const generateRandomAuthString = () =>
    Array.from(Array(7)).map(slug).join('')

export function getAuthStateTokenKey(state: StateTokenCookie): string {
    // The user can open multiple premium articles and go to the login form in multiple tabs
    // Each article tab creates its own state token before sending the user to the login page on the auth site
    // Incorporate the state token into the cookie key so the tabs dont override each other's state token
    return `${sessionStateKey}-${state.stateToken.substring(0, 8)}`
}

export interface AuthTokenResponseError {
    success: false
    status: number
    error: {
        error: string
        errorDescription: string
    }
}

export function getAuthStateFromUserInfoToken(
    userInfo: AuthUserInfoResponse,
    onEvent: (loginEvent: LoginEvent | LoginRefreshEvent) => void,
    hashedSophiUserID: string | undefined,
    hashedLiveRampEmail?: string | undefined,
    hashedUserEmail?: string | undefined,
): LoginDetails & {
    onEvent: (loginEvent: LoginEvent | LoginRefreshEvent) => void
} {
    const firstName = userInfo.given_name
    const lastName = userInfo.family_name
    const userEmail = userInfo.email
    const emailVerified = userInfo.email_verified
    const hasSignedupToNewsletter = userInfo.newsletterSignup ?? false
    const hasOptedInNewsletter = userInfo.newsletterOptIn ?? false
    const entitlements = isAuth0UserInfoResponse(userInfo)
        ? userInfo['swm.subscription.entitlements']
        : userInfo['swm:subscription:entitlements']
    const occupantId = isAuth0UserInfoResponse(userInfo)
        ? userInfo['swm.subscription.occupant_id']
        : userInfo['swm:subscription:occupant_id']
    const wanUserId = isAuth0UserInfoResponse(userInfo)
        ? userInfo['swm.identifier']
        : userInfo.sub
    const auth0UserId = isAuth0UserInfoResponse(userInfo)
        ? userInfo.sub
        : undefined
    const subscriptionType = ((isAuth0UserInfoResponse(userInfo)
        ? userInfo['swm.subscription.subscription_type']
        : userInfo['swm:subscription:subscription_type']) ||
        'none') as SubscriptionType
    const subscriptionId = isAuth0UserInfoResponse(userInfo)
        ? userInfo['swm.subscription.subscription_id']
        : userInfo['swm:subscription:subscription_id']
    const registrationTimestamp = Math.round(
        new Date(
            isAuth0UserInfoResponse(userInfo)
                ? userInfo['swm.registered']
                : userInfo.registered,
        ).getTime() / 1000,
    )
    const loginProvider = getLoginProvider(userInfo.sub)

    return {
        entitlements:
            (typeof entitlements === 'string'
                ? entitlements.split('|')
                : entitlements) ?? [],
        wanUserId,
        auth0UserId,
        occupantId,
        loginProvider,
        onEvent,
        socialProviders: '',
        userName: [firstName, lastName].filter((x) => !!x).join(' '),
        hasUserName: !!firstName && !!lastName,
        firstName,
        lastName,
        userEmail,
        subscriptionType,
        subscriptionId,
        hashedSophiUserID,
        hashedLiveRampEmail,
        hashedUserEmail,
        registrationTimestamp,
        emailVerified,
        hasSignedupToNewsletter,
        hasOptedInNewsletter,
    }
}

export function daysToMs(days: number) {
    return days * 24 * (60 * 60 * 1000)
}

/**
 * Used by smedia to refresh the web view client side.
 */
export function setupAuthRefreshCallback(
    dispatch: Dispatch<any>,
    onEvent: (event: any) => void,
    logger: Logger,
) {
    // Calling this will return nothing in the app webview evaluation but it should dispatch authCheckIdToken
    window.smediaAuthRefresh = function (accessToken?: string) {
        const fetchUrl = accessToken
            ? `/app-login?noRedirect&accessToken=${accessToken}`
            : '/app-login?noRedirect'

        const fetchData = (retries: number) => {
            const controller = new AbortController()
            const id = setTimeout(() => controller.abort(), 8000)

            fetch(fetchUrl, {
                method: 'GET',
                credentials: 'include', // Don't forget to specify this if you need cookies
                signal: controller.signal,
            })
                .then((res) => {
                    clearTimeout(id)
                    if (res.status === 200) {
                        dispatch(
                            authCheckAccessToken({
                                onEvent,
                                config: testSiteClientConfig,
                                log: logger,
                                invocation: 'manual',
                                onInvocationEvent: onEvent,
                            }),
                        )

                        logger.info('smediaAuthRefresh success')
                    } else if (res.status === 401) {
                        throw new Error(
                            'accessToken not present on authentication response or expired, unable to get user Info',
                        )
                    } else {
                        throw new Error('Call to login on the app failed')
                    }
                })
                .catch((error: Error) => {
                    logger.warn(
                        {
                            error,
                            test: error.name,
                            message: error.message,
                            retries,
                        },
                        'smediaAuthRefresh failure',
                    )

                    // If the error is that the user cancelled the fetch, that means that we have a timeout and should retry
                    if (error.name === 'AbortError' && retries > 0) {
                        fetchData(retries - 1)
                    }

                    throw new Error(error.message)
                })
        }
        // We define 4 retries as that's a decent number to try before failing.
        fetchData(3)
    }
}

export type AuthIssuer = 'idthewest' | 'auth0'

export interface AccessTokenState {
    expiryDate: number
    accessToken: string
    issuedBy?: AuthIssuer | undefined
}

export function retrieveAccessToken(
    req?: Request,
): AccessTokenState | undefined {
    return retrieveCookie<AccessTokenState>(sessionAccessTokenKey, req)
}

export function getLoginProvider(sub: string) {
    const prefix = sub.split('|')[0]
    switch (prefix) {
        case 'auth0':
            return 'Auth0'
        case 'google-oauth2':
            return 'Google'
        case 'apple':
            return 'Apple'
        default:
            return 'Auth'
    }
}

interface DecodedToken {
    header: {
        kid: string
    }
    payload: {
        exp: number
    }
}

interface KeyStore {
    keys: Key[]
}

interface Key {
    e: string
    n: string
    kty: 'RSA'
    kid: string
}

export async function handleBearerToken(
    req: Request,
    issuer: string,
    audience: string,
): Promise<AccessTokenState | undefined> {
    const { log } = req

    const authorizationHeader = req.headers?.authorization

    if (!authorizationHeader || !authorizationHeader.startsWith('Bearer ')) {
        return undefined
    }

    const token = authorizationHeader.split(' ')[1]

    try {
        const validatedToken = await validateToken(token, issuer, audience, log)
        return validatedToken
    } catch (err) {
        log.warn(
            {
                err,
            },
            'Unable to validate Bearer Token',
        )
        return undefined
    }
}
async function validateToken(
    token: string,
    issuer: string,
    audience: string,
    log: Logger,
): Promise<AccessTokenState | undefined> {
    const jwksUrl = `${issuer}/.well-known/jwks.json`

    try {
        const decodedToken = decode(token, { complete: true }) as DecodedToken

        if (!decodedToken || !decodedToken.header || !decodedToken.header.kid) {
            throw new Error('Invalid token')
        }

        const key = await retrievePEMKey(jwksUrl, decodedToken.header.kid)

        const verifiedToken = verify(token, key, {
            algorithms: ['RS256'],
            audience,
        }) as AccessTokenState

        return verifiedToken
    } catch (err) {
        throw new Error('Unable to verify Bearer Token')
    }
}

async function retrievePEMKey(jwksUri: string, kid: string) {
    const result = await fetch(jwksUri)

    const keyStore = (await result.json()) as KeyStore

    const key: Key | undefined = keyStore.keys.find((key) => key.kid === kid)

    if (!key) {
        throw new Error('Key could not be retrieved from the keyStore')
    }

    return jwkToPem(key)
}
