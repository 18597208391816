import React, { ReactElement } from 'react'
import { StyledButton, StyledWebLink } from './PerthnowButton.styled'

export type PerthNowButtonProps = {
    type: 'primary' | 'secondary' | 'tertiary'
    size: 'S' | 'M' | 'L'
    text: string
    action:
        | {
              type: 'button'
              onClick: () => void
          }
        | {
              type: 'link'
              to: string
              target?: string
          }
    icon?: {
        iconPosition: 'left' | 'right'
        IconElement: ReactElement
    }
    id?: string
    tabIndex?: number
}

/** A regular button, which can take an optional icon to place to the left or right of text. */
export const PerthNowButton = ({
    text,
    action,
    icon,
    type,
    size,
    id,
    tabIndex,
}: PerthNowButtonProps) => {
    const content = (
        <>
            {icon?.iconPosition === 'left' && icon.IconElement}
            {text}
            {icon?.iconPosition === 'right' && icon.IconElement}
        </>
    )

    const isLink = action.type === 'link'

    if (isLink) {
        return (
            <StyledWebLink
                id={id}
                buttonType={type}
                buttonSize={size}
                to={action.to}
                target={action.target}
                tabIndex={tabIndex}
            >
                {content}
            </StyledWebLink>
        )
    } else {
        return (
            <StyledButton
                id={id}
                buttonType={type}
                buttonSize={size}
                onClick={action.onClick}
                tabIndex={tabIndex}
            >
                {content}
            </StyledButton>
        )
    }
}
