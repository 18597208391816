import { ShareOption } from '@news-mono/web-common'
import { IconEmail } from '../../../icons/IconEmail/IconEmail'
import { IconFacebook } from '../../../icons/IconFacebook/IconFacebook'
import { IconLink } from '../../../icons/IconLink/IconLink'
import { IconReddit } from '../../../icons/IconReddit/IconReddit'
import { IconTwitter } from '../../../icons/IconTwitter/IconTwitter'
import { IconWhatsapp } from '../../../icons/IconWhatsapp/IconWhatsapp'
import { IconFacebookRounded, IconX } from '../../../icons'

export const getIconComponent = (shareOption: ShareOption) => {
    switch (shareOption) {
        case 'facebook':
            return IconFacebook
        case 'twitter':
            return IconX
        case 'whatsapp':
            return IconWhatsapp
        case 'email':
            return IconEmail
        case 'reddit':
            return IconReddit
        case 'clipboard':
            return IconLink
        case 'x':
            return IconX
        case 'facebookRounded':
            return IconFacebookRounded
        default:
            return
    }
}
