import {
    AdEvent,
    AdState,
    AllEvents,
    EventPublication,
    LiveBlogEvents,
    SharingEvent,
    useFeature,
} from '@news-mono/web-common'
import { default as React, useCallback, useMemo, useRef } from 'react'
import { Logger } from 'typescript-log'
import {
    JumpToTopButton,
    LiveEventLoadButton,
    LiveEventTimeline,
} from './Components'
import { LiveEventMilestones } from './Components/LiveEventMilestones'
import {
    DebugPanel,
    liveEventDebug,
    scrollToElement,
    useDebugPanel,
    useIsComponentOnScreen,
} from './helpers'
import {
    StyledButtonContainer,
    StyledContainer,
    StyledJumpButtonContainer,
    StyledReportingLiveBubble,
    StyledReportingLiveContainer,
    StyledReportingLiveDivider,
    StyledReportingLiveText,
} from './LiveEvent.styled'
import { useTheme } from '@emotion/react'
import { useLiveEventPaginationController } from './hooks/useLiveEventPaginationController'
import { Pagination } from '../../data-controllers/Pagination/Pagination'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { PostState } from './hooks/PostState'
import { useDeepLinkHandler } from './hooks/useDeepLinkHandler'
import { useLiveEventLoadMoreController } from './hooks/useLiveEventLoadMoreController'
import { useInterlacedAdUnits } from './hooks/useInterlacedAdUnits'

const ADUNIT_SPACING = 4
const ADUNIT_OFFSET = 4
const INFINITE_SCROLL_ENABLED = false

type LiveEventProps = {
    publication: EventPublication
    log: Logger
    adState: AdState
    pageSize?: number
    maxPages?: number
    onEvent: (event: AllEvents) => void
    onLiveEventUpdate?: (event: KilledEventPostV4DTO | EventPostV4DTO) => void
}

export const LiveEvent = (props: LiveEventProps) => {
    const isLiveEventPaginationEnabled = useFeature('live-event-pagination')

    return isLiveEventPaginationEnabled ? (
        <PaginatedLiveEvent {...props} />
    ) : (
        <LoadMoreLiveEvent {...props} />
    )
}

export const PaginatedLiveEvent = ({
    publication,
    log,
    adState,
    pageSize = 10,
    maxPages = 100,
    onEvent,
    onLiveEventUpdate,
}: LiveEventProps) => {
    const { isVisible, componentRef: containerRef } = useIsComponentOnScreen()
    const { debugPanelProps, addDebugEvent } = useDebugPanel('event')

    const {
        isFetching,
        postState,
        paging,
        setPage,
        handleDeepLink,
        loadUpdates,
    } = useLiveEventPaginationController({
        publicationId: publication.id,
        pageSize,
        initialPostState: {
            activePosts: publication.entries.documents,
            stickyPosts: publication.entries.stickies,
            milestonePosts: publication.entries.milestones,
            queuedPosts: [],
            postCount: publication.entries.total,
        },
        onLiveEventUpdate,
        addDebugEvent,
    })

    const deepLinkPostId = useDeepLinkHandler({
        handleDeepLink,
        addDebugEvent,
    })

    const debugObject = useMemo(
        () =>
            debugPanelProps.isEnabled
                ? {
                      isVisible,
                      deepLinkId: deepLinkPostId,
                      activePosts: postState.activePosts.map((post) => post.id),
                      stickyPosts: postState.stickyPosts.map((post) => post.id),
                      milestonePosts: postState.milestonePosts.map(
                          (post) => post.id,
                      ),
                      queuedPosts: postState.queuedPosts.map((post) => post.id),
                      totalPostCount: postState.postCount,
                      isFetching,
                      paging,
                  }
                : {},
        [
            debugPanelProps.isEnabled,
            deepLinkPostId,
            isFetching,
            isVisible,
            paging,
            postState,
        ],
    )

    /**
     * Jump to top
     */
    const jumpContainer = useRef(null)

    const handleJumpToTop = useCallback(() => {
        liveEventDebug('Jumping to top')
        scrollToElement(jumpContainer.current)
        loadUpdates()
    }, [loadUpdates])

    return (
        <StyledContainer ref={containerRef}>
            <StyledJumpButtonContainer ref={jumpContainer}>
                <JumpToTopButton
                    onClick={handleJumpToTop}
                    inViewport={isVisible}
                    isLoading={isFetching}
                    newPostCount={postState.queuedPosts.length}
                    defaultLabel={'BACK TO LATEST'}
                />
            </StyledJumpButtonContainer>
            <LiveEventContent
                publication={publication}
                postState={postState}
                deepLinkPostId={deepLinkPostId}
                adState={adState}
                onEvent={onEvent}
                log={log}
            />
            {postState.postCount > paging.pageSize && (
                <Pagination
                    itemCount={postState.postCount}
                    maxPages={maxPages}
                    setPage={setPage}
                    paging={paging}
                    onEvent={onEvent}
                />
            )}
            <DebugPanel {...debugPanelProps} debugObject={debugObject} />
        </StyledContainer>
    )
}

export const LoadMoreLiveEvent = ({
    publication,
    log,
    adState,
    onEvent,
    onLiveEventUpdate,
}: LiveEventProps) => {
    const { isVisible, componentRef: containerRef } = useIsComponentOnScreen()
    const { debugPanelProps, addDebugEvent } = useDebugPanel('event')

    const {
        isFetching,
        postState,
        handleDeepLink,
        loadMore,
        loadUpdates,
        canLoadMore,
    } = useLiveEventLoadMoreController({
        publicationId: publication.id,
        initialPostState: {
            activePosts: publication.entries.documents,
            stickyPosts: publication.entries.stickies,
            milestonePosts: publication.entries.milestones,
            queuedPosts: [],
            postCount: publication.entries.total,
        },
        onLiveEventUpdate,
        onEvent,
        addDebugEvent,
    })

    const deepLinkPostId = useDeepLinkHandler({
        handleDeepLink,
        addDebugEvent,
    })

    const debugObject = useMemo(
        () =>
            debugPanelProps.isEnabled
                ? {
                      isVisible,
                      deepLinkId: deepLinkPostId,
                      activePosts: postState.activePosts.map((post) => post.id),
                      stickyPosts: postState.stickyPosts.map((post) => post.id),
                      milestonePosts: postState.milestonePosts.map(
                          (post) => post.id,
                      ),
                      queuedPosts: postState.queuedPosts.map((post) => post.id),
                      totalPostCount: postState.postCount,
                      isFetching,
                      canLoadMore,
                  }
                : {},
        [
            canLoadMore,
            debugPanelProps.isEnabled,
            deepLinkPostId,
            isFetching,
            isVisible,
            postState,
        ],
    )
    /**
     * Jump to top
     */
    const jumpContainer = useRef(null)

    const handleJumpToTop = useCallback(() => {
        liveEventDebug('Jumping to top')
        scrollToElement(jumpContainer.current)
        loadUpdates()
    }, [loadUpdates])

    return (
        <StyledContainer ref={containerRef}>
            <StyledJumpButtonContainer ref={jumpContainer}>
                <JumpToTopButton
                    onClick={handleJumpToTop}
                    inViewport={isVisible}
                    isLoading={isFetching}
                    newPostCount={postState.queuedPosts.length}
                />
            </StyledJumpButtonContainer>
            <LiveEventContent
                publication={publication}
                postState={postState}
                deepLinkPostId={deepLinkPostId}
                adState={adState}
                onEvent={onEvent}
                triggerLazyLoad={INFINITE_SCROLL_ENABLED ? loadMore : undefined}
                log={log}
            />
            <StyledButtonContainer>
                {canLoadMore && (
                    <LiveEventLoadButton
                        isLoading={isFetching}
                        onClick={loadMore}
                    />
                )}
            </StyledButtonContainer>
            <DebugPanel {...debugPanelProps} debugObject={debugObject} />
        </StyledContainer>
    )
}

type LiveEventDisplayProps = {
    publication: EventPublication
    postState: PostState
    deepLinkPostId?: string
    triggerLazyLoad?: () => void
    adState: AdState
    onEvent: (event: AllEvents) => void
    log: Logger
}

const LiveEventContent = ({
    publication,
    postState: { activePosts, stickyPosts, milestonePosts },
    deepLinkPostId,
    triggerLazyLoad,
    adState,
    onEvent,
    log,
}: LiveEventDisplayProps) => {
    const hasMilestones = milestonePosts.length > 0
    const isActiveEvent = publication.classification?.label === 'live'
    const theme = useTheme()
    const isTheNightly = theme.kind === 'thenightly'

    const adUnits = useInterlacedAdUnits({
        listSize: activePosts.length,
        spacing: ADUNIT_SPACING,
        offset: ADUNIT_OFFSET,
    })

    if (activePosts.length === 0) {
        return null
    }

    return (
        <>
            {hasMilestones && (
                <LiveEventMilestones
                    milestones={milestonePosts}
                    onEvent={onEvent}
                    isActiveEvent={isActiveEvent}
                />
            )}
            {/* Don't show the reporting live container & divider if it's on TheNightly */}
            {!isTheNightly && (
                <StyledReportingLiveContainer isActive={isActiveEvent}>
                    {isActiveEvent && (
                        <>
                            <StyledReportingLiveBubble />
                            <StyledReportingLiveText>
                                Reporting
                                <span> LIVE</span>
                            </StyledReportingLiveText>
                        </>
                    )}
                </StyledReportingLiveContainer>
            )}
            {!isTheNightly && isActiveEvent && <StyledReportingLiveDivider />}
            <LiveEventTimeline
                posts={activePosts}
                adUnits={adUnits}
                pinnedPosts={stickyPosts}
                eventTitle={publication.heading}
                log={log}
                triggerLazyLoad={triggerLazyLoad}
                onEvent={onEvent}
                adState={adState}
                deepLinkedPostID={deepLinkPostId}
                entryType={publication.entryType}
            />
        </>
    )
}
