import { SizeMapping } from '../advertising/size-mapping'

export interface SizeMappingProps {
    /** Each ad slot needs a category, pos values will increment within a category */
    adPosCategory: string
    sizeMapping: SizeMapping[]
    companion?: boolean
    forceSafeFrame?: boolean
    outOfPage?: boolean
}

const createAdSize = (props: SizeMappingProps): SizeMappingProps => props

/**
 * Placeholder info: Placeholder height will be defaulted to the size of the first slot
 * e.g.  slot: [300, 250] will set minHeight of 250
 * e.g.  slot: [300, 600], [300, 250] will set minHeight of 600 - X Don't want the larger value as the minHeight
 * Can explicitly declare placeholderHeight for any sizeMapping object which will be used instead
 * {
 *      viewport: [0, 0],
 *      slot: [300, 250], [300, 600]
 *      placeholderHeight: 250
 *  },
 */
export const rawSizeProps = {
    billboardAbove1280leaderboardAbove768MrecBelow: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [300, 250],
            },
            {
                viewport: [768, 0],
                slot: [728, 90],
            },
            {
                viewport: [1280, 0],
                slot: [970, 250],
            },
        ],
    }),
    leaderboardAbove768MrecBelow: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [300, 250],
            },
            {
                viewport: [768, 0],
                slot: [728, 90],
            },
        ],
    }),
    leaderboardBillboard: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            {
                viewport: [970, 0],
                slot: [
                    [728, 90],
                    [970, 250],
                ],
                placeholderHeight: 90,
            },
            { viewport: [768, 0], slot: [728, 90] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
    }),

    leaderboard768Above: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [768, 0], slot: [728, 90] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    leaderboard768Above1000Below: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [1000, 0], slot: [] },
            { viewport: [768, 0], slot: [728, 90] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    skins: createAdSize({
        adPosCategory: 'breakout',
        sizeMapping: [
            { viewport: [1366, 0], slot: [[1, 1]] },
            { viewport: [0, 0], slot: [] },
        ],
        forceSafeFrame: false,
        outOfPage: true,
    }),
    gumgum: createAdSize({
        adPosCategory: 'breakout',
        sizeMapping: [
            { viewport: [1366, 0], slot: [[1, 1]] },
            { viewport: [0, 0], slot: [] },
        ],
        forceSafeFrame: false,
        outOfPage: true,
    }),
    outstream: createAdSize({
        adPosCategory: 'breakout',
        sizeMapping: [{ viewport: [0, 0], slot: [2, 2] }],
        forceSafeFrame: false,
        outOfPage: false,
    }),
    teads: createAdSize({
        adPosCategory: 'breakout',
        sizeMapping: [{ viewport: [0, 0], slot: [4, 4] }],
        forceSafeFrame: false,
        outOfPage: false,
    }),
    ['2x1_test_pixel']: createAdSize({
        adPosCategory: 'breakout',
        sizeMapping: [{ viewport: [0, 0], slot: [2, 1] }],
        forceSafeFrame: false,
        outOfPage: false,
    }),
    windowShade: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            { viewport: [1020, 0], slot: [1020, 100] },
            { viewport: [1000, 0], slot: [1000, 100] },
            { viewport: [0, 0], slot: [] },
        ],
        forceSafeFrame: false,
    }),
    mobileMrec: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [1020, 0], slot: [] },
            { viewport: [0, 0], slot: [300, 250] },
        ],
        forceSafeFrame: false,
    }),
    mrec768below: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [768, 0], slot: [] },
            { viewport: [0, 0], slot: [300, 250] },
        ],
        forceSafeFrame: false,
    }),
    leaderboard: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [768, 0], slot: [728, 90] },
            { viewport: [0, 0], slot: [320, 50] },
        ],
        forceSafeFrame: false,
    }),
    leaderboardResponsive: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [1100, 0], slot: [728, 90] },
            { viewport: [1024, 0], slot: [320, 100] },
            { viewport: [768, 0], slot: [728, 90] },
            { viewport: [0, 0], slot: [320, 100] },
        ],
        forceSafeFrame: false,
    }),
    desktopMrecHalfPage: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            {
                viewport: [1020, 0],
                slot: [
                    [300, 250],
                    [300, 600],
                ],
                placeholderHeight: 250,
            },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    desktopMrec: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [1020, 0], slot: [300, 250] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    desktopLeaderboardBillboard: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            {
                viewport: [1020, 0],
                slot: [
                    [728, 90],
                    [970, 250],
                ],
                placeholderHeight: 90,
            },
            { viewport: [0, 0], slot: [] },
        ],
    }),
    leaderboardBillboard1280Above: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            {
                viewport: [1280, 0],
                slot: [
                    [970, 250],
                    [728, 90],
                ],
                placeholderHeight: 90,
            },
            { viewport: [0, 0], slot: [] },
        ],
    }),
    billboardLeaderboard1280BelowMrec768Below: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [300, 250],
            },
            {
                viewport: [768, 0],
                slot: [728, 90],
            },
            {
                viewport: [1280, 0],
                slot: [],
            },
        ],
    }),
    theWestLiveLogo: createAdSize({
        adPosCategory: 'west-live',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [100, 40],
                placeholderHeight: 40,
            },
        ],
    }),
    marketing300x70: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [{ viewport: [0, 0], slot: [300, 70] }],
        forceSafeFrame: false,
    }),
    marketing300x70desktop: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [{ viewport: [1020, 0], slot: [300, 70] }],
        forceSafeFrame: false,
    }),
    header: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [1000, 0], slot: [728, 90] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    /**
     * Bonzai Parallax ad slots
     * Often the parallax is in a spot where we want an ad unit. If this is the case these sizes
     * need to be behind a toggle which switches the parallax slots and the normal ones.
     * This is to solve a chicken and egg problem, where if this slot returns an ad, don't show another slot.
     * If we did that client side, then the viewability of the now hidden slots would be reduced because we would
     * make the ad call but never show the ad.
     */
    /**
     * Generic parallax: desktop and mobile combined. Use for parallax slots that don't have to move around the page.
     */
    parallax: createAdSize({
        adPosCategory: 'parallax',
        sizeMapping: [
            {
                viewport: [1020, 0],
                slot: [[1, 1]],
            },
            {
                viewport: [0, 0],
                slot: [[1, 1]],
            },
        ],
        forceSafeFrame: false,
    }),
    desktopParallax: createAdSize({
        adPosCategory: 'parallax',
        sizeMapping: [
            {
                viewport: [1020, 0],
                slot: [[1, 1]],
            },
            {
                viewport: [0, 0],
                slot: [],
            },
        ],
        forceSafeFrame: false,
    }),
    mobileParallax: createAdSize({
        adPosCategory: 'parallax',
        sizeMapping: [
            {
                viewport: [1020, 0],
                slot: [],
            },
            {
                viewport: [0, 0],
                slot: [[1, 1]],
            },
        ],
        forceSafeFrame: false,
    }),
    ramp: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            { viewport: [1240, 0], slot: [1220, 60] },
            { viewport: [912, 0], slot: [910, 60] },
            { viewport: [728, 0], slot: [728, 60] },
            { viewport: [0, 0], slot: [320, 50] },
        ],
        forceSafeFrame: false,
    }),
    articleBanner: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [1120, 0], slot: [662, 90] },
            {
                viewport: [0, 0],
                slot: [],
            },
        ],
        forceSafeFrame: false,
    }),
    mobileLeaderboard: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [768, 0], slot: [] },
            { viewport: [0, 0], slot: [320, 50] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    // 7news
    below768Mrec: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [768, 0], slot: [] },
            { viewport: [0, 0], slot: [300, 250] },
        ],
        forceSafeFrame: false,
    }),
    alwaysMrecMultiSize: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [
                    [300, 250],
                    [640, 480],
                ],
                placeholderHeight: 250,
            },
        ],
        forceSafeFrame: false,
    }),
    alwaysMrec: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [{ viewport: [0, 0], slot: [300, 250] }],
        forceSafeFrame: false,
    }),
    above768Mrec: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [1020, 0], slot: [300, 250] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    above1280Mrec: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [1280, 0], slot: [300, 250] },
            { viewport: [0, 0], slot: [] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    above1280MrecHalfPage: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            {
                viewport: [1280, 0],
                slot: [
                    [300, 250],
                    [300, 600],
                ],
                placeholderHeight: 250,
            },
            { viewport: [0, 0], slot: [] },
        ],
        forceSafeFrame: false,
    }),
    mobileNativeMrec: createAdSize({
        // Special 7News marketing not quite mrec
        adPosCategory: 'marketing-mrec',
        sizeMapping: [
            { viewport: [768, 0], slot: [] },
            { viewport: [0, 0], slot: [300, 260] },
        ],
        forceSafeFrame: false,
    }),
    nativeMrec: createAdSize({
        // Special 7News marketing not quite mrec
        adPosCategory: 'marketing-mrec',
        sizeMapping: [
            { viewport: [768, 0], slot: [300, 260] },
            { viewport: [0, 0], slot: [] },
        ],
        forceSafeFrame: false,
    }),
    mrec320x50: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            { viewport: [768, 0], slot: [300, 250] },
            { viewport: [0, 0], slot: [320, 50] },
        ],
        forceSafeFrame: false,
    }),
    above768MrecHalfPage: createAdSize({
        adPosCategory: 'mrec',
        sizeMapping: [
            {
                viewport: [1020, 0],
                slot: [
                    [300, 250],
                    [300, 600],
                ],
                placeholderHeight: 250,
            },
            { viewport: [0, 0], slot: [] },
        ],
        forceSafeFrame: false,
    }),
    belowDesktopLeaderboard: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            { viewport: [1020, 0], slot: [] },
            { viewport: [0, 0], slot: [320, 50] },
        ],
        companion: true,
        forceSafeFrame: false,
    }),
    billboardLeaderboardAbove1280leaderboardMrecAbove768MrecBelow: createAdSize(
        {
            adPosCategory: 'marketing',
            sizeMapping: [
                {
                    viewport: [0, 0],
                    slot: [300, 250],
                },
                {
                    viewport: [768, 0],
                    slot: [
                        [728, 90],
                        [300, 250],
                    ],
                    placeholderHeight: 90,
                },
                {
                    viewport: [1280, 0],
                    slot: [
                        [728, 90],
                        [970, 250],
                    ],
                    placeholderHeight: 90,
                },
            ],
        },
    ),
    // adSlot in sidebar in PerthNow Top Story section
    mrecAbove1280LeaderboardMrecAbove768MrecBelow: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [300, 250],
            },
            {
                viewport: [768, 0],
                slot: [
                    [728, 90],
                    [300, 250],
                ],
                placeholderHeight: 90,
            },
            {
                viewport: [1280, 0],
                slot: [[300, 250]],
            },
        ],
    }),
    // adSlot in sidebar in PerthNow Daily sections
    mrecAbove1280LeaderboardMrecAbove768NoneBelow: createAdSize({
        adPosCategory: 'marketing',
        sizeMapping: [
            {
                viewport: [768, 0],
                slot: [
                    [728, 90],
                    [300, 250],
                ],
                placeholderHeight: 90,
            },
            {
                viewport: [1280, 0],
                slot: [[300, 250]],
            },
        ],
    }),
    // adSlot on NGN PerthNow topic page
    billBoardAbove1280: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [],
            },
            {
                viewport: [768, 0],
                slot: [],
            },
            {
                viewport: [1280, 0],
                slot: [970, 250],
                placeholderHeight: 250,
            },
        ],
    }),

    leaderBoardAbove768: createAdSize({
        adPosCategory: 'leaders',
        sizeMapping: [
            {
                viewport: [0, 0],
                slot: [],
            },
            {
                viewport: [768, 0],
                slot: [728, 90],
                placeholderHeight: 90,
            },
            {
                viewport: [1280, 0],
                slot: [],
            },
        ],
    }),
}

export const sizeProps: Record<keyof typeof rawSizeProps, SizeMappingProps> =
    rawSizeProps

export type SizePropsKey = keyof typeof rawSizeProps
