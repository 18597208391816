import { useTheme } from '@emotion/react'
import { useFeature, Features } from '@news-mono/web-common'
import { TheWestRegionalSections } from '@west-australian-newspapers/publication-types'
import React from 'react'

export interface FeatureToggleProps {
    feature: Features
    on: () => React.ReactElement<any> | null
    off?: () => React.ReactElement<any> | null
}

export const FeatureToggle: React.FC<FeatureToggleProps> = ({
    feature,
    on,
    off,
}) => {
    const isEnabled = useFeature(feature)

    if (isEnabled) {
        return on()
    }

    if (off) {
        return off()
    }

    return null
}
FeatureToggle.displayName = 'FeatureToggle'

export const useFeatureForSection = (options: {
    feature: Features
    sections: string | string[]
    fallback?: boolean
}) => {
    const { section } = useTheme()
    const featureState = useFeature(options.feature, options.fallback)
    if (typeof options.sections === 'string') {
        options.sections = [options.sections]
    }

    if (section != null && options.sections.includes(section)) {
        return featureState
    }

    return false
}

export const useFeatureForRegionalSections = (
    feature: Features,
    section: string,
) => {
    const featureState = useFeature(feature)

    if (section != null && section in TheWestRegionalSections) {
        return featureState
    }
    return false
}
