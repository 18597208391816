import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    adsDebug,
    AppState,
    ConsentLevel,
    getDefaultConsentLevel,
    GptLibrary,
    hasConsentLevel,
    isServerEnvironment,
    isTestEnvironment,
    LocationState,
    RenderState,
    getAdfixusId,
} from '@news-mono/web-common'
import { adBlockEnabled } from './utils'

export let scriptLoaded: Promise<GptLibrary | false> | false = false
const scriptLoaderDebug = adsDebug.extend('gpt-script-loader')
const gptDebug = adsDebug.extend('gpt')

/** Returns a promise which when resolved, the value can be inspected to find if the script load succeeded/failed */
export function loadGptLibrary(
    appState: Pick<AppState, 'consent' | 'geoLocation' | 'toggles' | 'render'>,
): Promise<GptLibrary | false> {
    if (scriptLoaded === false) {
        scriptLoaded = new Promise<GptLibrary | false>((resolve, reject) => {
            if (isServerEnvironment()) {
                reject(
                    new Error(
                        'Cannot inject a script in server-side render mode!',
                    ),
                )
                return
            }
            if (isTestEnvironment()) {
                resolve(false)
                scriptLoaderDebug('Skipped loading due to running in test env')
                return
            }
            if (adBlockEnabled()) {
                resolve(false)
                scriptLoaderDebug('Skipped loading due to ad blocker')
                return
            }
            if (
                !isFeatureEnabled(toFeatureState(appState.toggles), 'gpt-ads')
            ) {
                resolve(false)
                scriptLoaderDebug('Skipped loading due to feature toggle off')
                return
            }
            const companionAdsEnabled = isFeatureEnabled(
                toFeatureState(appState.toggles),
                'companion-ads',
            )
            const protocol =
                typeof window !== 'undefined'
                    ? window.location.protocol
                    : 'https:'
            const url = `${protocol}//www.googletagservices.com/tag/js/gpt.js`

            // Checks the type of ads we can show an individual user
            const advertisingConsentLevel = determineAdvertisingTargeting(
                appState.consent.consentLevel ||
                    getDefaultConsentLevel(appState.geoLocation),
                appState.geoLocation,
                appState.render,
            )

            // If no advertising permission, don't load the gpt script
            if (advertisingConsentLevel === AdvertisingConsentLevel.None) {
                return resolve(false)
            }

            const script = document.createElement('script')
            script.src = url
            script.type = 'text/javascript'
            script.async = true
            script.onerror = (err: any) => {
                console.warn({ err }, `Failed to load GPT script`)
                resolve(false)
            }
            script.onload = () => {
                if (typeof googletag === 'undefined') {
                    // Tag has been blocked
                    return resolve(false)
                }

                googletag.cmd.push(async () => {
                    try {
                        const pubAds = googletag.pubads()
                        // Can be undefined with ad blockers
                        if (!pubAds) {
                            return resolve(false)
                        }

                        if (companionAdsEnabled) {
                            gptDebug('pubAds.enableVideoAds()=enabled')
                            pubAds.enableVideoAds()
                            googletag
                                .companionAds()
                                .addEventListener('slotRequested', (e) => {
                                    gptDebug('companion.slotRequested: %o', e)
                                })
                            googletag
                                .companionAds()
                                .addEventListener('slotRenderEnded', (e) => {
                                    gptDebug('companion.slotRenderEnded: %o', e)
                                })
                        }
                        if (
                            advertisingConsentLevel ===
                            AdvertisingConsentLevel.Generic
                        ) {
                            pubAds.setRequestNonPersonalizedAds(1)
                        }

                        pubAds.setForceSafeFrame(false)
                        pubAds.enableSingleRequest()
                        // disableInitialLoad 'should' be off when autoplay is not enabled // TODO 7NEWS
                        pubAds.disableInitialLoad()

                        // Get AdFixus ID using the utility function
                        const adfixusId = getAdfixusId()
                        if (adfixusId) {
                            pubAds.setPublisherProvidedId(adfixusId)
                        }

                        googletag.enableServices()
                        await waitUntilReady()

                        resolve(googletag)
                    } catch (err) {
                        console.error({ err }, 'Failed to load gpt')
                        return resolve(false)
                    }
                })
            }
            document.getElementsByTagName('head')[0].appendChild(script)
        })
    }
    return scriptLoaded
}

async function waitUntilReady() {
    // Polls the GPT API and PubAds ready status
    do {
        const isReady = googletag.apiReady && googletag.pubadsReady
        if (isReady) {
            break
        }

        scriptLoaderDebug('Waiting for GPT to be ready')
        await new Promise<void>((resolve) => setTimeout(resolve, 100))
        // eslint-disable-next-line no-constant-condition
    } while (true)
}

const enum AdvertisingConsentLevel {
    None = 0,
    Generic = 1,
    Full = 2,
}
/** Determines the type of advertising to show to a user based on their cookie consent level and geolocation 
 ``` 
                   Platform:          iOS      |    Android   |       Web
     ------------------------------------------+--------------+-------------------
        Australia Consented:          Ads      |      Ads     |       Ads
       Australia No Consent:     Generic Ads   |  Generic Ads |   Generic Ads
              International:          Ads      |    No Ads    |       Ads
   International No Consent:       No Ads     |    No Ads    |      No Ads
    ```
    See [PNA-227](https://wanews.atlassian.net/browse/PNA-227) for more information
*/
function determineAdvertisingTargeting(
    consentLevel: ConsentLevel,
    geoLocation: LocationState,
    render: RenderState,
): AdvertisingConsentLevel {
    const hasConsented = hasConsentLevel(consentLevel, ConsentLevel.Advertising)
    const { renditionType, appPlatform } = render
    const inApp = renditionType === 'app'

    if (hasConsented && geoLocation.countryCode === 'AU') {
        // Australia Consented
        return AdvertisingConsentLevel.Full
    } else if (geoLocation.countryCode === 'AU') {
        // Australia no Consent
        if (inApp && appPlatform === 'android') {
            console.warn('Invalid ad state encountered.', {
                appPlatform,
                consentLevel,
                renditionType,
            })
        }
        return AdvertisingConsentLevel.Generic
    } else if (hasConsented) {
        // International Consented
        if (inApp && appPlatform === 'android') {
            return AdvertisingConsentLevel.None
        }
        return AdvertisingConsentLevel.Full
    } else {
        // International No Consent
        return AdvertisingConsentLevel.None
    }
}
