import { Topic } from '../client-data-types/topic'
import { AdPathsConfig } from '../client/BaseClientConfig'
import { distinct, flatMap } from '../helpers/utils'

const getTopics = (topic: Topic, topics: string[] = []) => {
    topics.push(topic.title)

    if (topic.parent) {
        getTopics(topic.parent, topics)
    }

    return topics
}

export type StandardAdPageKind =
    | 'home'
    | 'article'
    | 'profile'
    | 'error'
    | 'rss'
    | 'liveblog'
    | 'gallery'
/**
 * Used in static/custom routes such as the World Cup Quiz page.
 * The current convention is to use the route's slug here.
 */
export type CustomAdPageKind =
    /**
     * Cricket custom static routes
     */
    | 'live-scores'
    /**
     * horoscopes custom static route
     */
    | 'horoscopes'
    /**
     * The west search page
     */
    | 'search'
    /**
     * The west live page
     */
    | 'the-west-live'
    /**
     * 7news match centre pages
     */
    | 'match-centre'
    /**
     * 7news fixtures pages
     */
    | 'fixtures'
    /**
     * 7news groups & standings page
     */
    | 'groups-and-standings'
    /**
     * 7news schedule page
     */
    | 'schedule'
    /**
     * 7news tournament bracket page
     */
    | 'bracket'
    /**
     * 7news stats page
     */
    | 'stats'
    /**
     * Feature articles/videos page
     */
    | 'video-series'
    /**
     * 190 Years of The West route
     */
    | 'News/190yearsofTheWestAustralian'
    /**
     * WA Election 2025 Ad Unit Pages
     */
    | 'Politics/WAelection2025/home'
    | 'Politics/FederalElection2025/home'

/**
 * This string will be the last segment in the ad unit path.
 * Make sure that the full path exists in our ad unit inventory on DFP when adding a new kind:
 * https://www.google.com/dfp/60035833#inventory/inventory/adSlotId=64939953
 * Don't add it yourself; ask someone in #topic-adops on Slack.
 */
export type AdPageKind = StandardAdPageKind | CustomAdPageKind

export function getAdTargetingPath(
    config: AdPathsConfig,
    adPageKind: AdPageKind,
    section: string,
    primaryTopic?: Topic,
    ...secondaryTopics: Topic[]
) {
    // special handling for articles in the 190years stories section, for ad targeting
    const relativeTopic =
        primaryTopic && primaryTopic.title === 'The Stories that make us'
            ? primaryTopic.parent
            : primaryTopic

    const productAdPathname =
        config.sectionAdPathMapping[section] ||
        config.sectionAdPathMapping['default']
    const adUnitPath: string[] = [config.baseAdUnitPath, productAdPathname]
    const ssAdUnits: string[] = [...config.baseAdUnits, productAdPathname]
    const topics: string[] = []

    if (relativeTopic) {
        const topicSection = relativeTopic.parent
            ? [relativeTopic.parent.title, relativeTopic.title]
            : [relativeTopic.title]
        const sectionAdUnitPathPart = topicSection
            .join('/')
            .replace(/[^a-z0-9A-Z/]/g, '')
        const secondaryTopicNames = flatMap(secondaryTopics, (topic) =>
            getTopics(topic),
        )

        topics.push(
            ...distinct(getTopics(relativeTopic).concat(secondaryTopicNames)),
        )
        adUnitPath.push(sectionAdUnitPathPart)
        ssAdUnits.push(...topicSection)
    }

    adUnitPath.push(adPageKind)
    ssAdUnits.push(adPageKind)

    return {
        adUnitPath: adUnitPath.join('/'),
        ssAdUnits,
        topics,
    }
}
