// If this list updates we will want to update the migration scripts for materialized views
// e.g. migration scripts 2554-2557
export const allowedSources = {
    perthnow: [
        'PerthNow',
        'AAP/PerthNow',
        'PerthNow Local News',
        'PerthNow - Western Suburbs',
        'PerthNow - Stirling',
        'PerthNow - Central',
        'PerthNow - Joondalup',
        'PerthNow - Wanneroo',
        'PerthNow - Canning',
        'PerthNow - Cockburn',
        'PerthNow - Fremantle',
        'PerthNow - Melville',
        'PerthNow - Southern',
        'Mandurah Times',
    ],
    thewest: ['The West Australian', 'STM', 'Best Australian Yarn'],
    sevennews: [
        '7NEWS',
        'AAP/7NEWS',
        'Sunrise',
        'The Morning Show',
        '7LIFE',
        '7NEWS Sport',
        '7NEWS Spotlight',
        'CNN/7NEWS',
        'NBC/7NEWS',
        'Today Tonight',
        'Sunday Night',
        'The Daily Edition',
        '7travel',
        '7Lifestyle',
        '7Tech',
        '7Motoring',
        '7Entertainment',
        '7Food',
        '7Finance',
        '7Business',
    ],
    thenightly: ['The Nightly', 'AAP/The Nightly', 'AAP', 'AP', 'Reuters'],
    regionals: [
        'Albany Advertiser',
        'Augusta Margaret River Times',
        'Augusta-Margaret River Times',
        'Broome Advertiser',
        'Bunbury Herald',
        'Busselton Dunsborough Times',
        'Busselton-Dunsborough Times',
        'Countryman',
        'Geraldton Guardian',
        'Great Southern Herald',
        'Harvey Waroona Reporter',
        'Harvey-Waroona Reporter',
        'Kalgoorlie Miner',
        'The Kimberley Echo',
        'Manjimup Bridgetown Times',
        'Manjimup-Bridgetown Times',
        'Midwest Times',
        'Narrogin Observer',
        'North West Telegraph',
        'Pilbara News',
        'Sound Telegraph',
        'South Western Times',
    ],
}
