import pino from 'pino'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { BaseClientConfig } from '../..'
import { debugExtendedAccess } from '../../authentication/debug'
import {
    ExtendedAccessEvent,
    SubscribeWithGoogleEvent,
} from '../../events/extended-access-events'
import { AddToCartEvent } from '../../events/subscribeEventTypes'
import { UserAuthEvents } from '../../events/user-event-types'
import { AppState } from '../../store'
import {
    AuthenticationActions,
    AUTH_GOOGLE_LOGIN,
    AUTH_GRANT_ENTITLEMENTS,
    AUTH_PAYWALL_BYPASS,
} from '../authentication/reducer'
import { handleReturningGoogleUser, showGoogleRegwall } from './extended-access'
import { Entitlements, GoogleState, Subscriptions } from './swg-api'

export interface SubscribeWithGoogleItems {
    subscriptions: Subscriptions | undefined
    entitlementsPromise: Promise<Entitlements> | undefined
}

export const SET_SWG_TYPE = 'SET_SWG_TYPE'

export interface SET_SWG_TYPE_ACTION extends Action {
    type: typeof SET_SWG_TYPE
    payload: SubscribeWithGoogleItems
}

export const setSwGState = (subscriptionsItems: SubscribeWithGoogleItems) => ({
    type: SET_SWG_TYPE,
    payload: subscriptionsItems,
})

export const REQUEST_SWG_ENTITLEMENTS = 'REQUEST_SWG_ENTITLEMENTS'

export interface REQUEST_SWG_ENTITLEMENTS_ACTION extends Action {
    type: typeof REQUEST_SWG_ENTITLEMENTS
    payload: {
        requested: boolean
    }
}

export const RequestSwGEntitlements = (requested: boolean) => ({
    type: REQUEST_SWG_ENTITLEMENTS,
    payload: {
        requested,
    },
})

export const EVENT_MANAGER_LOADED = 'EVENT_MANAGER_LOADED'
export interface EVENT_MANAGER_LOADED_ACTION extends Action {
    type: typeof EVENT_MANAGER_LOADED
    payload: {
        isLoaded: boolean
    }
}

export const UpdateEventManagerLoaded = (isLoaded: boolean) => ({
    type: EVENT_MANAGER_LOADED,
    payload: {
        isLoaded,
    },
})

export const SWG_ENTITLEMENTS_VERIFIED = 'SWG_ENTITLEMENTS_VERIFIED'

export interface UPDATE_SWG_ENTITLEMENTS_VERIFIED_ACTION extends Action {
    type: typeof SWG_ENTITLEMENTS_VERIFIED
    payload: {
        isVerified: boolean
    }
}

export const UpdateSwGEntitlementsVerified = (isVerified: boolean) => ({
    type: SWG_ENTITLEMENTS_VERIFIED,
    payload: {
        isVerified,
    },
})

export const USER_SWG_STATE = 'USER_SWG_STATE'

export interface UPDATE_USER_SWG_STATE_ACTION extends Action {
    type: typeof USER_SWG_STATE
    payload: GoogleState
}

export const UpdateUserSwGState = (swgState: GoogleState) => ({
    type: USER_SWG_STATE,
    payload: swgState,
})

export const triggerEA = async (
    dispatch: ThunkDispatch<
        any,
        any,
        | REQUEST_SWG_ENTITLEMENTS_ACTION
        | AUTH_PAYWALL_BYPASS
        | AUTH_GOOGLE_LOGIN
        | AUTH_GRANT_ENTITLEMENTS
    >,
    getState: () => AppState,
    config: BaseClientConfig,
    onEvent: (
        event:
            | UserAuthEvents
            | ExtendedAccessEvent
            | SubscribeWithGoogleEvent
            | AddToCartEvent,
    ) => void,
) => {
    const logger = pino()

    // We know at this point that the user is definitely logged in - so there's only a few things we need to check for.
    try {
        const { isEntitledChecked, articleSlug, requiredAccess } =
            getState().authentication

        if (!isEntitledChecked || !articleSlug || !requiredAccess) {
            debugExtendedAccess(
                'Abort metering: Entitled Status not captured %o',
                {
                    isEntitledChecked,
                    articleSlug,
                    requiredAccess,
                },
            )
            return
        }

        // Get the current Google Login status of the user - we probably want to refactor this to also have it inside the same reducer in the future.
        const { googleLogin, isLoggedIn } = getState().authentication
        // We only want to trigger EA if an entitlement check occured (I.E: We have hit a breach screen)
        if (!googleLogin) {
            if (!isLoggedIn) {
                dispatch({
                    type: AuthenticationActions.GOOGLE_LOGIN,
                    payload: { googleLogin: 'relinking' },
                })

                // @todo This might be deprecated?
                const returndGoogleUser = await handleReturningGoogleUser(
                    dispatch,
                    logger,
                    config,
                )
                debugExtendedAccess(
                    'get awaited returned Google user',
                    returndGoogleUser,
                )
                if (returndGoogleUser) {
                    debugExtendedAccess('Returning user logged in')
                } else {
                    debugExtendedAccess(
                        'Initiating extended access intervention for google login %o',
                        {
                            authentication: getState().authentication,
                            SwG: getState().SwG,
                        },
                    )

                    dispatch({
                        type: AuthenticationActions.GOOGLE_LOGIN,
                        payload: { googleLogin: 'intervention' },
                    })
                }
            }
            await showGoogleRegwall(
                dispatch,
                logger,
                config,
                articleSlug ?? '',
                getState().authentication,
                onEvent,
            )
        }
    } catch (err) {
        logger.error({ err }, 'SwG unhandled error')
    }
}

export interface SwGState {
    subscriptions: Subscriptions | undefined
    entitlementsPromise: Promise<Entitlements> | undefined
    entitlementsRequested?: boolean
    LoggedInThroughSwG?: boolean
    entitlementsAreVerified?: boolean
    userSWGState?: GoogleState
    eventManagerLoaded?: boolean
}

const defaultState: SubscribeWithGoogleItems = {
    entitlementsPromise: undefined,
    subscriptions: undefined,
}

export const SwGReducer = (
    state = defaultState,
    action:
        | SET_SWG_TYPE_ACTION
        | REQUEST_SWG_ENTITLEMENTS_ACTION
        | UPDATE_SWG_ENTITLEMENTS_VERIFIED_ACTION
        | UPDATE_USER_SWG_STATE_ACTION
        | EVENT_MANAGER_LOADED_ACTION
        | { type: '@@INIT' /** This represents all other actions */ },
): SwGState => {
    switch (action.type) {
        case SET_SWG_TYPE:
            return {
                ...state,
                entitlementsPromise: action.payload.entitlementsPromise,
                subscriptions: action.payload.subscriptions,
            }
        case REQUEST_SWG_ENTITLEMENTS:
            return {
                ...state,
                entitlementsRequested: action.payload.requested,
            }
        case SWG_ENTITLEMENTS_VERIFIED:
            return {
                ...state,
                entitlementsAreVerified: action.payload.isVerified,
            }
        case USER_SWG_STATE:
            return {
                ...state,
                userSWGState: action.payload,
            }
        case EVENT_MANAGER_LOADED:
            return {
                ...state,
                eventManagerLoaded: action.payload.isLoaded,
            }
        default:
            return state
    }
}
