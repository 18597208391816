import React from 'react'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardBreakpointRatios,
    FixedRatio,
    PublicationCardItem,
    CollectionEvent,
    returnCardInformation,
    raiseSelectItemEvent,
    getTimeAgoPNLong,
} from '@news-mono/web-common'
import {
    StyledArticleCard,
    StyledAnchor,
    StyledCardMedia,
    StyledTeaser,
    StyledTeaserAnchor,
    StyledTimestampedWrapper,
    StyledTimestamp,
} from './PNArticleCard.styled'
import { PNArticleCardHeader } from './PNArticleCardHeader/PNArticleCardHeader'
import { PNArticleCardFooter } from './PNArticleCardFooter/PNArticleCardFooter'
import { TeaserMode } from '../../../../cards/CardText/CardTeaser'
import { CommonCardProps } from '../../../../cards/CardItem.Props'
import { doesParticularRatioExist } from '../../../../cards/CardMedia/image-helpers'
import { isVideoClick } from '../../../../__helpers/video-card-helpers'
import { StyledKicker } from './PNArticleCardHeader/PNArticleCardHeader.styled'
import { MixinSize } from '@news-mono/component-library'
import { ConditionalWrapper } from '../../../../__helpers/ConditionalWrapper'

/**
 * The type of the card.
 */
export type ImageMode = 'right' | 'left' | 'none'
export type KickerMode = 'top' | 'middle' | 'none'
export type PNFontScales = MixinSize
export type HeaderSectionTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type CardType =
    | 'vertical' // Vertical Card
    | 'horizontal' // Horizontal Card
export interface BreakpointMixinSize {
    mobile: MixinSize
    tablet: MixinSize
    desktop: MixinSize
}
/**
 * The properties for the PNArticleCard component.
 */
export interface PNArticleCardProps extends CommonCardProps {
    innerRef?: React.RefObject<any>
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    cardNumber: number
    item: MaybeLoaded<PublicationCardItem & { publications?: any }>
    teaserMode: TeaserMode
    imageMode?: ImageMode
    kickerMode?: KickerMode
    fontScale: PNFontScales
    headerFontScale?: PNFontScales
    teaserFontScale?: PNFontScales
    disableImageLazyLoad?: boolean
    onEvent: (event: CollectionEvent) => void
    cardType: CardType
    canPlayVideoInline?: boolean
    showAuthor?: boolean
    hideFooter?: boolean
    showTopic?: boolean
    headerSectionTag?: HeaderSectionTagType
    headerFontOverride?: BreakpointMixinSize
    imageSizeOverride?: BreakpointMixinSize
    isTimestamped?: boolean
}

/**
 * Renders a PerthNow article card component.
 *
 * @param {PNArticleCardProps} props - The props for the PNArticleCard component.
 * @param {ArticleItem} props.item - The article item to be displayed.
 * @param {string} props.cardType - The type of the card.
 * @param {string} [props.imageMode='right'] - The mode of the image.
 * @returns {React.ReactNode} The rendered PNArticleCard component.
 */
export const PNArticleCard: React.FC<PNArticleCardProps> = (props) => {
    const {
        item,
        cardType,
        fixedRatio,
        onEvent,
        disableImageLazyLoad,
        teaserMode,
        imageMode = 'right',
        fontScale = 'M',
        teaserFontScale = fontScale,
        cardNumber,
        canPlayVideoInline = false,
        showAuthor = false,
        kickerMode = 'middle',
        hideFooter = false,
        imageSizeOverride,
        isTimestamped = false,
    } = props

    // Check if the item is loaded
    if (item.loaded) {
        const { result } = item
        // Extract the short headline from the item result
        const { shortHeadline = '', link, allowCommenting } = result

        // Helper that fires the select_item list event
        const cardInfo = returnCardInformation(result)
        const linkClicked = (event: React.MouseEvent<HTMLElement>) => {
            if (
                props.canPlayVideoInline &&
                props.fixedRatio &&
                doesParticularRatioExist(props.fixedRatio, '16:9') &&
                isVideoClick(event.nativeEvent.target, [])
            ) {
                event.preventDefault()
                return
            } else {
                raiseSelectItemEvent(
                    onEvent,
                    'pn-article-card-media',
                    cardInfo,
                    result,
                    cardNumber,
                )
            }
        }

        const isVideoCard = item.loaded && item.result.hasOwnProperty('video')

        // Check if the footer should be displayed
        const showFooter = !hideFooter && (allowCommenting || showAuthor)
        const getStyledAnchor = () =>
            imageMode !== 'none' && (
                <StyledAnchor
                    to={link}
                    onClick={linkClicked}
                    aria-label={shortHeadline}
                    tabIndex={0}
                    data-card-type={cardType}
                    data-image-mode={imageMode}
                    isTimestamped={isTimestamped}
                >
                    <StyledCardMedia
                        item={item}
                        imageWidths={
                            cardType === 'vertical'
                                ? {
                                      mobile: '100vw',
                                      tablet: '100vw',
                                      desktop: '810px',
                                      fallbackWidth: 810,
                                      mode: 'fixed',
                                  }
                                : {
                                      mobile: '194px',
                                      tablet: '277px',
                                      desktop: '277px',
                                      fallbackWidth: 194,
                                      mode: 'fixed',
                                  }
                        }
                        fixedRatio={isVideoCard ? '16:9' : fixedRatio}
                        onEvent={onEvent}
                        disableImageLazyLoad={disableImageLazyLoad}
                        mediaMode={'default'}
                        willPlayVideoInline={canPlayVideoInline}
                        overlayButtonScale={getOverlayButtonScale(
                            fontScale,
                            cardType,
                        )}
                    />
                    {kickerMode === 'top' && item.result.kicker && (
                        <StyledKicker kickerMode={kickerMode}>
                            {item.result.kicker}
                        </StyledKicker>
                    )}
                </StyledAnchor>
            )
        const getPNArticleCardHeader = () => <PNArticleCardHeader {...props} />

        const getArticleTimestamp = (isDesktop: boolean) => {
            if (!item.result.publicationDate) {
                return null
            }

            return (
                <StyledTimestamp isDesktop={isDesktop}>
                    {getTimeAgoPNLong(new Date(item.result.publicationDate))}
                </StyledTimestamp>
            )
        }

        // Render the publication article card
        return (
            <ConditionalWrapper
                condition={isTimestamped}
                Wrapper={({ children }) => (
                    <StyledTimestampedWrapper>
                        {getArticleTimestamp(true)}
                        {children}
                    </StyledTimestampedWrapper>
                )}
            >
                <StyledArticleCard
                    ref={props.innerRef}
                    aria-label={shortHeadline}
                    data-card-type={cardType}
                    data-image-mode={imageMode}
                    data-font-scale={fontScale}
                    imageSizeOverride={imageSizeOverride}
                    isTimestamped={isTimestamped}
                >
                    {imageMode === 'right' && cardType === 'horizontal' ? (
                        <>
                            {getPNArticleCardHeader()}
                            {getStyledAnchor()}
                        </>
                    ) : (
                        <>
                            {getStyledAnchor()}
                            {getPNArticleCardHeader()}
                        </>
                    )}
                    {teaserMode === 'visible' && (
                        <StyledTeaserAnchor
                            to={link}
                            aria-label={shortHeadline}
                            tabIndex={0}
                            data-card-type={cardType}
                            data-font-scale={teaserFontScale}
                            isTimestamped={isTimestamped}
                        >
                            <StyledTeaser>{item?.result?.teaser}</StyledTeaser>
                        </StyledTeaserAnchor>
                    )}
                    {isTimestamped && getArticleTimestamp(false)}
                    {showFooter && <PNArticleCardFooter {...props} />}
                </StyledArticleCard>
            </ConditionalWrapper>
        )
    }

    // Return null if the item is not loaded
    return null
}

export type PNCardOverlayButtonScale = 'L' | 'M' | 'S'

function getOverlayButtonScale(
    scale: PNFontScales,
    cardType: CardType,
): PNCardOverlayButtonScale {
    switch (true) {
        case ['2XL', 'XL', 'L'].includes(scale):
        case scale === 'M' && cardType === 'vertical':
            return 'L'
        case ['S', 'XS', '2XS'].includes(scale):
        case scale === 'M' && cardType === 'horizontal':
            return 'S'
        default:
            return 'M'
    }
}
