import { AllEvents, ListBlock } from '@news-mono/web-common'
import React, { OlHTMLAttributes } from 'react'
import { Logger } from 'typescript-log'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import {
    StyledOrderedList,
    StyledUnorderedList,
} from '../../typography/List/List.styled'
import { ListItem } from './ListItem'

export interface ListProps {
    list: ListBlock
    onEvent: (event: AllEvents) => void
    adUnitPath: string
    getVideoQueue: GetVideoQueue | undefined
    showInlineVideoTitles: boolean | undefined
    publicationKind?: string | undefined
    publicationPathname: string | undefined
    log: Logger
}

export const List: React.FC<ListProps> = (props) => {
    if (props.list.kind === 'ordered-list') {
        return (
            <StyledOrderedList
                type={getTypeMapping(props.list.type)}
                start={
                    props.list.start
                        ? parseInt(props.list.start, 10)
                        : undefined
                }
            >
                {props.list.items.map((item, index) => {
                    return (
                        <ListItem
                            key={index}
                            blocks={item.blocks}
                            log={props.log}
                            adUnitPath={props.adUnitPath}
                            getVideoQueue={props.getVideoQueue}
                            onEvent={props.onEvent}
                            publicationPathname={props.publicationPathname}
                            showInlineVideoTitles={props.showInlineVideoTitles}
                        />
                    )
                })}
            </StyledOrderedList>
        )
    }
    return (
        <StyledUnorderedList>
            {props.list.items.map((item, index) => {
                return (
                    <ListItem
                        key={index}
                        blocks={item.blocks}
                        log={props.log}
                        adUnitPath={props.adUnitPath}
                        getVideoQueue={props.getVideoQueue}
                        onEvent={props.onEvent}
                        publicationKind={props.publicationKind}
                        publicationPathname={props.publicationPathname}
                        showInlineVideoTitles={props.showInlineVideoTitles}
                    />
                )
            })}
        </StyledUnorderedList>
    )
}
List.displayName = 'List'

// Ensure that props.type meets valid HTML spec
export function getTypeMapping(value: any): OlHTMLAttributes<any>['type'] {
    if (typeof value === 'string') {
        return value as OlHTMLAttributes<any>['type']
    }

    return undefined
}
