import { PageLoadComplete } from 'page-lifecycle-provider'
import { Topic } from '../client-data-types/topic'
import { GtmEvent } from '../events/gtm-event-types'

import debug from 'debug'
import { RenderTarget } from '../context-providers'
import { DataLayerEventName } from '../helpers'
import { AuthenticationState, SubscriptionType } from '../data'
const chartbeatEventsDebug = debug('chartbeat-events')

let chartbeatInitialised = false

type ChartbeatUserType = 'anon' | 'lgdin' | 'paid'

type ChartbeatContentType =
    | 'article'
    | 'feature article'
    | 'sponsored article'
    | 'live blog'
    | 'photo gallery'
    | 'video'

export function getContentType({
    event,
}: {
    event: GtmEvent
}): ChartbeatContentType | null {
    const {
        publicationKind,
        articleKind,
        classification,
        layoutView,
        location,
        pageType,
    } = (event as PageLoadComplete)?.payload ?? {}

    if (
        pageType === 'publication' &&
        location?.pathname?.startsWith('/video/')
    ) {
        return 'video'
    }

    const rawContentType = publicationKind || articleKind
    if (!rawContentType) {
        return null
    }

    let contentType: ChartbeatContentType | null = null
    switch (rawContentType) {
        case 'article':
            contentType = 'article'
            break
        case 'feature-article':
            contentType = 'feature article'
            break
        case 'gallery':
            contentType = 'photo gallery'
            break
        case 'event':
            contentType = 'live blog'
            break
        case 'video':
            contentType = 'video'
            break
    }

    if (contentType === 'article' && layoutView === 'feature') {
        contentType = 'feature article'
    }

    if (contentType === 'article' && classification?.kind === 'sales') {
        contentType = 'sponsored article'
    }

    return contentType
}

export function getAuthors({ byline }: { byline: string | undefined }) {
    return (
        byline
            ?.replace(/\s+and\s+/gi, ',')
            .replace(/\s*&amp;\s*/g, ',')
            .replace(/\s*&\s*/g, ',') || ''
    )
}

export function getChartbeatDomain({
    hostname,
    fallback,
}: {
    hostname?: string | undefined
    fallback: string
}) {
    return (hostname || fallback).replace(/^www\./, '')
}

export function getChartbeatSections(props: Record<string, unknown>): string {
    if (!props) {
        return ''
    }

    if (!props || typeof props !== 'object') {
        return ''
    }

    const { primaryTopic, secondaryTopics } = props as {
        primaryTopic?: Topic
        secondaryTopics?: Topic[]
    }

    const topics: Topic[] = [
        ...(primaryTopic ? [primaryTopic] : []),
        ...(secondaryTopics || []),
    ]
        .filter((topic) => typeof topic === 'object')
        .filter((topic) => topic?.id)
        .filter(Boolean)

    if (topics.length > 0) {
        return (
            topics
                .map((topic) => topic?.id)
                .reduce(
                    // remove duplicates
                    (acc: string[], cur: string) => [
                        ...acc,
                        ...(acc.indexOf(cur) === -1 ? [cur] : []),
                    ],
                    [],
                )
                .join(',') || ''
        )
    }

    const { pageType, location } = props as {
        pageType?: string
        location?: { pathname?: string }
    }

    if (
        pageType &&
        ['topic', 'static', 'video'].includes(pageType) &&
        location?.pathname
    ) {
        return location.pathname.replace(/^\//, '')
    }

    return ''
}

export function handleChartbeatTracking(
    event: GtmEvent,
    chartbeatId: number,
    publicHostname: string,
    renderTarget: string,
    authState: AuthenticationState,
    headlineTestingEnabled: boolean,
) {
    chartbeatEventsDebug(
        'handleChartbeatTracking called with event: %o, chartbeatId: %o, publicHostname: %o, renderTarget: %o, authState: %o',
        event,
        chartbeatId,
        publicHostname,
        renderTarget,
        authState,
    )

    const userType = getChartbeatUserType(event, authState)
    if (userType !== null) {
        setChartbeatUserType(userType)
    }

    if (event.type === 'page-load-complete') {
        const { location, title, byline, entitlements, hostname } =
            event.payload

        const contentType = getContentType({ event })
        const path = location.pathname
        const chartbeatSections = getChartbeatSections(event.payload)
        const authors = getAuthors({ byline })
        const sponsorName = contentType === 'sponsored article' ? byline : null
        const isMobileApp = (renderTarget as RenderTarget) === 'app'
        const chartbeatDomain = getChartbeatDomain({
            hostname,
            fallback: publicHostname,
        })

        if (!chartbeatInitialised) {
            initChartbeat(
                chartbeatId,
                chartbeatDomain,
                authors,
                chartbeatSections,
                contentType,
                sponsorName,
                isMobileApp,
                headlineTestingEnabled,
            )
        } else {
            trackChartbeatPageview(
                title,
                chartbeatDomain,
                byline,
                chartbeatSections,
                path,
                contentType,
                sponsorName,
                isMobileApp,
            )
        }
    }
}

export function initChartbeat(
    id: number,
    domain: string,
    authors: string,
    sections: string,
    contentType: ChartbeatContentType | null,
    sponsorName: string | null,
    isMobileApp: boolean | null,
    isChartbeatHeadlineTestingEnabled: boolean,
) {
    chartbeatEventsDebug(
        'initChartbeat called with id: %o, domain: %o, authors: %o, sections: %o, contentType: %o, sponsorName: %o, isMobileApp: %o, isChartbeatHeadlineTestingEnabled: %o',
        id,
        domain,
        authors,
        sections,
        contentType,
        sponsorName,
        isMobileApp,
        isChartbeatHeadlineTestingEnabled,
    )

    const _sf_async_config: ChartbeatAsyncConfig = (window._sf_async_config =
        window._sf_async_config || {})
    _sf_async_config.uid = id
    _sf_async_config.domain = domain
    _sf_async_config.useCanonical = true
    _sf_async_config.useCanonicalDomain = false
    _sf_async_config.sections = sections
    _sf_async_config.authors = authors
    _sf_async_config.flickerControl = false

    if (isMobileApp !== null) {
        _sf_async_config.mobileApp = isMobileApp
    }

    if (contentType) {
        _sf_async_config.type = contentType
    }

    if (sponsorName) {
        _sf_async_config.sponsorName = sponsorName
    }

    // note: on initial pageload, we let chartbeat derive the title
    // from the `<title>` element on the page

    addScript()

    if (isChartbeatHeadlineTestingEnabled) {
        addHeadlineTestingScript()
    }

    chartbeatInitialised = true
}

export function addScript() {
    const e = document.createElement('script')
    const n = document.getElementsByTagName('script')[0]
    e.type = 'text/javascript'
    e.async = true
    e.src = '//static.chartbeat.com/js/chartbeat.js'
    n.parentNode?.insertBefore(e, n)
}
export function addHeadlineTestingScript() {
    const e = document.createElement('script')
    const n = document.getElementsByTagName('title')[0] // Load after page title
    e.type = 'text/javascript'
    e.async = true
    e.src = '//static.chartbeat.com/js/chartbeat_mab.js'
    n.parentNode?.insertBefore(e, n.nextSibling)
}

export function trackChartbeatPageview(
    title: string,
    domain: string,
    authors: string,
    sections: string,
    path: string,
    contentType: ChartbeatContentType | null,
    sponsorName: string | null,
    isMobileApp: boolean | null,
) {
    chartbeatEventsDebug(
        'trackChartbeatPageView called with title: %o, domain: %o, authors: %o, sections: %o, path: %o, contentType: %o, sponsorName: %o, isMobileApp: %o',
        title,
        domain,
        authors,
        sections,
        path,
        contentType,
        sponsorName,
        isMobileApp,
    )

    if (typeof pSUPERFLY !== 'undefined') {
        pSUPERFLY.virtualPage({
            domain,
            sections: sections || '',
            authors: authors || '',
            path: path || '',
            title: title || '',
            type: contentType || '',
            sponsorName: sponsorName || '',
            mobileApp: isMobileApp || false,
        })
    }
}

export function getChartbeatUserType(
    event: GtmEvent,
    authState: AuthenticationState,
): ChartbeatUserType | null {
    chartbeatEventsDebug(
        'getChartbeatUserType called with event: %o, authState: %o',
        event,
        authState,
    )

    if (event.type === DataLayerEventName.authLogout) {
        chartbeatEventsDebug(
            `getChartbeatUserType event:${event.type} => "anon"`,
        )
        return 'anon'
    }

    if (
        event.type === DataLayerEventName.authLogin ||
        event.type === DataLayerEventName.authLoginRefresh ||
        event.type === 'page-load-complete'
    ) {
        chartbeatEventsDebug(
            `getChartbeatUserType event:${event.type} => authstate: .subscriptionType: %o, .isLoggedIn: %o`,
            authState?.subscriptionType,
            authState?.isLoggedIn,
        )

        const subscriptionType: SubscriptionType | undefined =
            authState?.subscriptionType

        switch (subscriptionType) {
            case 'subscriber':
                return 'paid'
            case 'corporate':
                return 'paid'
            case 'corporate-school':
                return 'paid'
            case 'corporate-site':
                return 'paid'
            case 'swg':
                return 'paid'
            case 'none':
                // fall through
                break
        }

        const isLoggedIn: boolean | undefined = authState.isLoggedIn

        if (isLoggedIn) {
            return 'lgdin'
        }

        if (event.type === 'page-load-complete') {
            return 'anon'
        }
    }

    return null
}

/**
 * Chartbeat looks for a global _cbq variable containing user data
 * @param userType
 */
export function setChartbeatUserType(userType: ChartbeatUserType) {
    chartbeatEventsDebug(
        'setChartbeatUserType called with userType: %o',
        userType,
    )
    const _cbq = (window._cbq = window._cbq || [])
    _cbq.push(['_acct', userType])
}
