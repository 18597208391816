import { Theme } from '@emotion/react'

export const metrics = {
    baseFontSize: 16, // TODO Remove and reference theme fontsize

    // TODO Clarify "The West" metrics
    globalPadding: 20,
    globalMargin: 20,

    thewest: {
        featureContentWidth: 1220 as const,
        ampContentWidth: 950 as const,
        baseFontSize: 16,
        textMargin: 32,

        siteMetrics: {
            mainContentWidth: 1250 as const,
        },

        margins: {
            /** Extra Small: 4 */
            xs: 4,
            /** Small: 8 */
            sm: 8,
            /** Medium: 16 */
            md: 16,
            /** Large: 32 */
            lg: 32,
            /** Extra Large: 36 */
            xl: 36,
            /** Extra Extra Large: 40 */
            xxl: 40,
            /** 3x Large: 48 */
            xxxl: 48,
            /** Reset the value: useful for breakpoints */
            unset: 0,
            /** Mobile Small: 6 */
            mobSm: 6,
            /** Outer Margin: 15 */
            outerMargin: 15,
            gridGap: 12,
            gridGapMobile: 16,
        },

        cards: {
            textOffset: 8,
            outerMargin: 8,
            outerMarginLarge: 16,
            headingFontSize: 18,
        },

        componentMetrics: {},
    },

    perthnow: {
        baseFontSize: 16,
        textMargin: 18,
        baselineOffset: 2,
        textOffset: 5,

        /** Custom outer margin for PerthNow of 20px */
        outerMargin: 20,

        siteMetrics: {
            mainContentWidth: 1020 as const,
            mainContentWidthNGN: 1192 as const,
        },

        /** Margins are typically multiples of 9 */
        margins: {
            /** Extra Small: 5 */
            xs: 5,
            /** Small: 9 */
            sm: 9,
            /** Medium: 18 */
            md: 18,
            /** Large: 36 */
            lg: 36,
            /** Extra Large: 45 */
            xl: 45,
            /** Extra Extra Large: 54 */
            xxl: 54,
            /** Extra Extra Large: 54 NB Placeholder */
            xxxl: 63,
            /** Reset the value: useful for breakpoints */
            unset: 0,
            mobSm: 13,
            outerMargin: 20,
            gridGap: 18,
        },

        cards: {
            spacing: 12,
            textOffset: 5,
            outerMargin: 12,
            outerMarginLarge: 18,
        },

        heights: {
            sm: 9,
            md: 18,
            lg: 36,
            xl: 45,
        },

        componentMetrics: {},
    },

    sevennews: {
        // currently duplicated from the west will update later
        ampContentWidth: 950 as const,
        outerMargin: 15,

        baseFontSize: 15, // base font size
        textMargin: 18,

        siteMetrics: {
            mainContentWidth: 1100 as const,
            sevenEntMainContentWidth: 1440 as const,
            sevenV2MainContentWidth: 1440 as const,
        },

        margins: {
            /** Extra Small: 5 */
            xs: 5,
            /** Small: 9 */
            sm: 9,
            /** Medium: 15 */
            md: 15,
            /** Large: 24 */
            lg: 24,
            /** Extra Large: 36 */
            xl: 36,
            /** Extra Extra Large: 45 */
            xxl: 45,
            /** 3X Large: 54 */
            xxxl: 54,
            /** Reset the value: useful for breakpoints */
            unset: 0,
            /** Mobile Small: 13 */
            mobSm: 13,
            /** Outer Margin: 15 */
            outerMargin: 15,
            gridGap: 15,
        },

        cards: {
            textOffset: 5,
            outerMargin: 9,
            outerMarginLarge: 16,
        },

        componentMetrics: {},
    },
    thenightly: {
        baseFontSize: 16,
        textMargin: 20,
        articleBlockMargin: 40,
        // Todo - update this when design is available
        siteMetrics: {
            mainContentWidth: 1140 as const,
            mainContentBodyWidth: 770 as const,
            articleWidth: 770 as const,
            condensedBody: 650 as const,
        },
        margins: {
            /** Extra Small: 4 */
            xs: 4,
            /** Small: 8 */
            sm: 8,
            /** Small: 12 */
            sm2l: 12,
            /** Medium: 16 */
            md: 16,
            /** Medium To Large: 24 */
            m2l: 24,
            /** Large: 32 */
            lg: 32,
            /** Extra Large: 36 */
            xl: 36,
            /** Extra Extra Large: 40 */
            xxl: 40,
            /** 3X Large: 48 */
            xxxl: 48,
            /** 4X Large: 80 */
            xxxxl: 80,
            /** Reset the value: useful for breakpoints */
            unset: 0,
            /** Mobile Small: 6 */
            mobSm: 6,
            /** Outer Margin: 24 */
            outerMargin: 24,
            gridGap: 12,
        },
        cards: {
            textOffset: 5,
            outerMargin: 8,
            outerMarginLarge: 16,
        },
        componentMetrics: {
            imageWidth: {
                lg: 770,
                md: 770,
                sm: 390,
            },
        },
    },
}

export interface ThemeMargins {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    xxl: number
    xxxl: number
    unset: number
    mobSm: number
    outerMargin: number
    gridGap: number
}

export type ThemeMarginKeys = keyof ThemeMargins

export const isMarginKey = (
    theme: Theme,
    key: string,
): key is ThemeMarginKeys => theme.margins[key as ThemeMarginKeys] !== undefined

export interface CardMargins {
    textOffset: number
    outerMargin: number
    outerMarginLarge: number
}

export interface ComponentMetrics {
    breakingNewsHeight?: number
    imageWidth?: any
}

export interface SiteMetrics {
    mainContentWidth: number
}

/**
 * PerthNow: 1020, The West: 1250, Seven News: 1000, Seven News Increased: 1100, full width: 100%,
 * The West Feature: 1220, Seven News Ent: 1440, TheNightly: 1140, Seven News Ent Taboola: 650
 */
export type ContainerWidth =
    | '100%'
    | 1000
    | 1020
    | 1100
    | 1140
    | 1220
    | 1250
    | 1440
    | 770
    | 650

export type Metrics = typeof metrics
