import { AllEvents, Block } from '@news-mono/web-common'
import React from 'react'
import { Logger } from 'typescript-log'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import { ArticleBlock } from '../../publication/ArticleBlockContent/ArticleBlock'
import {
    StyledListItem,
    StyledTextListItem,
} from '../../typography/List/List.styled'

export function ListItem({
    blocks,
    onEvent,
    adUnitPath,
    publicationKind,
    publicationPathname,
    getVideoQueue,
    showInlineVideoTitles,
    log,
}: {
    blocks: Block[]
    onEvent: (event: AllEvents) => void
    adUnitPath: string
    getVideoQueue: GetVideoQueue | undefined
    showInlineVideoTitles: boolean | undefined
    publicationKind?: string
    publicationPathname: string | undefined
    log: Logger
}) {
    return (
        <React.Fragment>
            {blocks.map((block, index) =>
                block.kind === 'text' ? (
                    <StyledTextListItem
                        key={index}
                        text={block.text}
                        intentions={block.intentions}
                        tag="li"
                        onEvent={onEvent}
                    />
                ) : (
                    <StyledListItem key={index}>
                        <ArticleBlock
                            key={index}
                            block={block}
                            adUnitPath={adUnitPath}
                            getVideoQueue={getVideoQueue}
                            log={log}
                            onEvent={onEvent}
                            publicationKind={publicationKind}
                            publicationPathname={publicationPathname}
                            showInlineVideoTitles={showInlineVideoTitles}
                        />
                    </StyledListItem>
                ),
            )}
        </React.Fragment>
    )
}
