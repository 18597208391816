import React from 'react'
import {
    StyledChevronIcon,
    StyledDoubleChevronIcon,
    StyledPaginationList,
} from '../Pagination.styled'
import { getNearbyPages, range } from '../helpers'
import { PaginationItem } from './PaginationItem'
import { NumberedPages } from './NumberedPages'
import { WrapperPages } from './WrapperPages'
import { useWindowWidth } from '../../../__helpers'
import { breakpoints } from '../../../__styling'

interface PaginationContentProps {
    currentPage: number
    publicationCount: number
    pageSize: number
    maxPages?: number
    setPage?: (page: number) => void
    path: string
    params?: string
    onEvent: (event: any) => void
    hideNavText?: boolean
    hideItemBorder?: boolean
    hideJumpButton?: boolean
}
export const PaginationContent: React.FC<PaginationContentProps> = ({
    currentPage,
    publicationCount,
    pageSize,
    maxPages = 10,
    setPage,
    path,
    params,
    onEvent,
    hideNavText,
    hideItemBorder = true,
    hideJumpButton,
}) => {
    const windowWidth = useWindowWidth() ?? 0
    const isMobile = windowWidth < breakpoints.sm

    const pagesCount = Math.ceil(
        Math.min(publicationCount / pageSize, maxPages),
    ) // Hard cap of 10 pages
    const pagesToDisplay = getNearbyPages(
        pagesCount,
        currentPage,
        isMobile ? 4 : 6,
    )
    const pages = range(pagesToDisplay.start, pagesToDisplay.end)
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pagesCount
    const showEllipses = pagesCount > 6
    const showStartEllipses = currentPage > 4
    const showEndEllipses = currentPage < pagesCount - 3
    const isNearStart = currentPage > 3
    const isNearEnd = currentPage < pagesCount - 3

    const handleOnClick = (page: number) => {
        if (setPage) {
            setPage(page)
            resetFocusPoint()
        }
    }

    // Reset focus point after page is changed
    function resetFocusPoint() {
        setTimeout(() => {
            ;(document.activeElement as HTMLElement)?.blur()
            document.body.setAttribute('tabindex', '-1')
            document.body.focus()
        }, 10)
    }

    return (
        <nav role="navigation" aria-label="Pagination Navigation">
            <StyledPaginationList>
                <PaginationItem
                    text={'First'}
                    isDisabled={isFirstPage}
                    layout={{
                        showText: false,
                        display: {
                            default: {
                                display: hideJumpButton ? 'none' : 'flex',
                            },
                            sm: {
                                display: 'none',
                            },
                        },
                    }}
                    onClick={handleOnClick}
                    routePath={path}
                    icon={{
                        iconElement: (
                            <StyledDoubleChevronIcon
                                isLeft
                                isActive={isFirstPage}
                            />
                        ),
                        iconPosition: 'before',
                    }}
                    page={{ currentPage, nextPage: 1 }}
                    params={params}
                    onEvent={onEvent}
                />
                <PaginationItem
                    text={'Previous'}
                    isDisabled={isFirstPage}
                    layout={{
                        showText: !hideNavText,
                        hideTextAtMobile: true,
                        display: {
                            default: {
                                display: 'flex',
                            },
                        },
                    }}
                    onClick={handleOnClick}
                    routePath={path}
                    icon={{
                        iconElement: (
                            <StyledChevronIcon isLeft isActive={isFirstPage} />
                        ),
                        iconPosition: 'before',
                    }}
                    page={{ currentPage, nextPage: currentPage - 1 }}
                    params={params}
                    onEvent={onEvent}
                />
                {showEllipses && isNearStart && (
                    // Only rendered on desktop, shows First/Last page and ellipses
                    <WrapperPages
                        ellipsesPosition={showStartEllipses ? 'after' : 'none'}
                        path={path}
                        isDisabled={isFirstPage}
                        onPageChange={handleOnClick}
                        currentPage={currentPage}
                        nextPage={1}
                        params={params}
                        onEvent={onEvent}
                        hideItemBorder={hideItemBorder}
                    />
                )}
                <NumberedPages // Numbered pages that show between First / Last page buttons
                    pages={pages}
                    currentPage={currentPage}
                    path={path}
                    onPageChange={handleOnClick}
                    onEvent={onEvent}
                    params={params}
                    hideItemBorder={hideItemBorder}
                />
                {showEllipses && isNearEnd && (
                    // Only rendered on desktop, shows First/Last page and ellipses
                    <WrapperPages
                        ellipsesPosition={showEndEllipses ? 'before' : 'none'}
                        path={path}
                        isDisabled={isLastPage}
                        onPageChange={handleOnClick}
                        currentPage={currentPage}
                        nextPage={pagesCount}
                        params={params}
                        onEvent={onEvent}
                        hideItemBorder={hideItemBorder}
                    />
                )}
                <PaginationItem
                    text={'Next'}
                    isDisabled={isLastPage}
                    layout={{
                        showText: !hideNavText,
                        hideTextAtMobile: true,
                        display: {
                            default: {
                                display: 'flex',
                            },
                        },
                    }}
                    onClick={handleOnClick}
                    routePath={path}
                    icon={{
                        iconElement: (
                            <StyledChevronIcon isActive={isLastPage} />
                        ),
                        iconPosition: 'after',
                    }}
                    page={{ currentPage, nextPage: currentPage + 1 }}
                    params={params}
                    onEvent={onEvent}
                />
                <PaginationItem
                    text={'Last'}
                    isDisabled={isLastPage}
                    layout={{
                        showText: false,
                        display: {
                            default: {
                                display: hideJumpButton ? 'none' : 'flex',
                            },
                            sm: {
                                display: 'none',
                            },
                        },
                    }}
                    onClick={handleOnClick}
                    routePath={path}
                    icon={{
                        iconElement: (
                            <StyledDoubleChevronIcon isActive={isLastPage} />
                        ),
                        iconPosition: 'after',
                    }}
                    page={{ currentPage, nextPage: pagesCount }}
                    params={params}
                    onEvent={onEvent}
                />
            </StyledPaginationList>
        </nav>
    )
}
