import styled from '@emotion/styled'
import { metricsPN } from '@news-mono/design-tokens'
import { breakpoint, calcRem } from '../../../../../__styling'

export const StyledAside = styled('aside')({
    marginBottom: calcRem(metricsPN.spacing['L']),

    [breakpoint('sm')]: {
        marginBottom: calcRem(metricsPN.spacing['XL']),
    },

    [breakpoint('lg')]: {
        marginBottom: calcRem(metricsPN.spacing['L']),
    },
})
