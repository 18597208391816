import { CSSObject } from '@emotion/css'
import styled from '@emotion/styled'
import { Product } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { zIndex } from '../../__styling/settings/z-index'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { StyledGptAd } from '../gpt/gpt-ad-slot'
import { teadsAdSlotID } from '../../__helpers'

export interface VisiblityBreakpoint {
    breakpoint: number
    visible: boolean
    placeholderHeight: number | undefined
}

export type Padding =
    | [number]
    | [number, number]
    | [number, number, number, number]
export type AdNoticePosition =
    | 'below-right'
    | 'below-center'
    | 'top-right'
    | 'above-center'
    | 'none'

function getBreakpointVisibilities(
    visibilityBreakpoints?: VisiblityBreakpoint[],
): CSSObject | {} {
    if (!visibilityBreakpoints) {
        return {}
    }

    const styles: CSSObject = {}

    visibilityBreakpoints.sort((a, b) => {
        if (a.breakpoint > b.breakpoint) {
            return 1
        }
        if (a.breakpoint < b.breakpoint) {
            return -1
        }
        return 0
    })

    for (const visibilityBreakpoint of visibilityBreakpoints) {
        const breakpointKey = `@media (min-width: ${visibilityBreakpoint.breakpoint}px)`

        styles[breakpointKey] = {
            display: visibilityBreakpoint.visible ? 'block' : 'none',
            minHeight:
                visibilityBreakpoint.visible &&
                visibilityBreakpoint.placeholderHeight
                    ? visibilityBreakpoint.placeholderHeight
                    : undefined,
        }
    }

    return styles
}

// The following would be simpler to express as calcRem(...padding)
// however typescript does not currently correctly handle spreading
// tuples as function args.
// https://github.com/Microsoft/TypeScript/issues/4130
const getPadding = (padding: Padding) =>
    padding.map((item) => calcRem(item)).join(' ')

export interface BreakoutMargins {
    left: number | string
    right: number | string
}

export interface StyledAdUnitProps {
    visibilityBreakpoints?: VisiblityBreakpoint[]
    hidden?: boolean
    breakoutMargins?: BreakoutMargins
    showBackground?: boolean
    removeAdContainerStyles: boolean
}

export const StyledAdUnit = styled('div')<StyledAdUnitProps>(
    {
        display: 'block',
        margin: 0,
        fontSize: 0,
        textAlign: 'center',
    },
    ({ removeAdContainerStyles, theme }) => {
        /**
         * DPO-217
         * If ad unit contains bonzai scrollx, dont apply the translate
         * hack used to center ad units. Also needs to be applied to the
         * StyledNotice component.
         */
        if (removeAdContainerStyles || theme.kind === Product.SevenNews) {
            //DPT-3148 - remove all container styles on 7news so that scrollX always works nicely
            // ran through with Steve and happy for this compromise
            return
        }

        return {
            '@media (max-width: 430px)': {
                /**
                 * SWM-5397 & DPT-11
                 * Center ad unit inside grid item
                 */
                maxWidth: 254,
                position: 'relative',
                transform: 'translate(-50%)',
                left: '50%',
                margin: 0,
            },

            [`& ${StyledGptAd} *`]: {
                display: 'block',
                margin: '0 auto',
            },

            '& div > *:not(style)': {
                display: 'block',
                margin: '0 auto',

                '@media (max-width: 430px)': {
                    margin: 0,
                    transform: 'translate(-50%)',
                    left: '50%',
                    position: 'relative',
                },
            },
        }
    },
    ({ hidden, visibilityBreakpoints }) =>
        hidden
            ? { display: 'none' }
            : getBreakpointVisibilities(visibilityBreakpoints),
    ({ breakoutMargins }) => {
        if (!breakoutMargins) {
            return {}
        }

        return {
            marginLeft: breakoutMargins.left,
            marginRight: breakoutMargins.right,
        }
    },
    ({ showBackground }) => {
        if (!showBackground) {
            return {}
        }

        return {
            backgroundColor: colors.thewest.greyKoala,
        }
    },
)

StyledAdUnit.displayName = 'StyledAdUnit'

export interface StyledCenterProps {
    unitId: string
    breakoutRef: React.MutableRefObject<HTMLDivElement | null>
    hidden?: boolean
    padding?: Padding
    forcePadding?: boolean
    visibilityBreakpoints: VisiblityBreakpoint[]
}

const unitIdPaddingMinHeight: Record<string, number> = {
    ['outstream2x2']: 2,
    [teadsAdSlotID]: 4,
}

export const StyledCenter = styled('div')<StyledCenterProps>(
    {},
    ({ padding, unitId, breakoutRef, forcePadding = false }) => {
        let showPadding = padding !== undefined

        // We only want to show the padding on the specific slots
        // when the container is actually filled with an ad, otherwise
        // it adds unnecessary padding and causes large empty spaces
        if (
            padding !== undefined &&
            unitId !== undefined &&
            unitId in unitIdPaddingMinHeight
        ) {
            const minHeight = unitIdPaddingMinHeight[unitId]

            // We know it's a slot that needs a minimum height, now let's check
            // if the container is larger than this minimum height, otherwise
            // disable it if it hasn't rendered that far yet.
            if (breakoutRef && breakoutRef.current) {
                showPadding =
                    breakoutRef.current.getBoundingClientRect().height >
                    minHeight
            } else {
                showPadding = false
            }
        }

        if (padding !== undefined && forcePadding) {
            showPadding = true
        }

        return {
            textAlign: 'center',
            margin: showPadding
                ? getPadding(padding!) + ' !important'
                : undefined,
        }
    },
    ({ hidden, visibilityBreakpoints }) =>
        hidden
            ? { display: 'none' }
            : getBreakpointVisibilities(visibilityBreakpoints),
)

StyledCenter.displayName = 'StyledCenter'

export interface StyledNoticeProps {
    noticePosition: AdNoticePosition
    noticeMessage: string
    placeholderHeight?: number
    removeAdContainerStyles?: boolean
}

const getPseudoSelector = (position: AdNoticePosition) =>
    position.startsWith('below') ? '&::after' : '&::before'

export const StyledNotice = styled('div')<StyledNoticeProps>(
    ({ removeAdContainerStyles, noticePosition, noticeMessage }) => {
        if (removeAdContainerStyles) {
            return {}
        }

        const noticePositionStyles: CSSObject = noticePosition.endsWith(
            'center',
        )
            ? {
                  textAlign: 'center',
                  [getPseudoSelector(noticePosition)]: {
                      paddingBottom: calcRem(3),
                      textTransform: 'none',
                  },
              }
            : {
                  [getPseudoSelector(noticePosition)]: {
                      textAlign: 'right',
                  },
              }

        return {
            ...noticePositionStyles,

            '@media (max-width: 320px)': {
                display: 'block',
                transform: 'translate(-50%)',
                left: '50%',
                position: 'relative',
            },

            [getPseudoSelector(noticePosition)]: {
                display: 'block',
                marginTop: noticePosition.startsWith('below') ? calcRem(8) : 0,
                marginBottom: noticePosition.startsWith('above')
                    ? calcRem(8)
                    : 0,
                width: '100%',
                fontSize: calcRem(12),
                color: colors.perthnow.greySlate,
                content: `'${noticeMessage}'`,
                textTransform: 'uppercase',
                fontFamily: fonts.perthnow.sansSerif,

                '@media (max-width: 320px)': {
                    textAlign: 'center',
                },
            },
        }
    },
)
StyledNotice.displayName = 'StyledNotice'

export const StyledStickyFooterAdUnit = styled('div')(({ theme }) => [
    {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: colors.white,
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, .2)',
    },
    theme.kind === 'perthnow' && {
        zIndex: zIndex.perthnow.stickyFooter,
    },
    theme.kind === 'thewest' && {
        zIndex: zIndex.thewest.stickyFooter,
    },
    theme.kind === 'thenightly' && {
        position: 'sticky',
        zIndex: zIndex.thenightly.stickyFooter,
    },
])

StyledStickyFooterAdUnit.displayName = 'StyledStickyFooter'

interface StyledAdUnitWrapperProps {
    stickyOffset?: number | string
}

export const StyledAdUnitWrapper = styled('div')<StyledAdUnitWrapperProps>(
    ({ stickyOffset }) => [
        stickyOffset !== undefined && {
            position: 'sticky',
            top: stickyOffset,

            transition: 'top 0.25s',
        },
    ],
)
StyledAdUnitWrapper.displayName = 'StyledAdUnitWrapper'
