import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AllEvents,
    Block,
    useToggleState,
    VideoDetails,
} from '@news-mono/web-common'
import React from 'react'
import { Logger } from 'typescript-log'
import { Blog } from '../../../../content/Blog/Blog'
import { PNInlineRelatedContent } from './PNInlineRelatedContent/PNInlineRelatedContent'
import {
    GetVideoQueue,
    VideoOptions,
} from '../../../../content/Video/PlayerInterface'
import { useGdprMode } from '../../../../content/Video/utils/gdpr-mode'
import { PNTwinImageWrapper } from '../../../../publication/ArticleBlockContent/ArticleBlockContent.styled'
import { Embed } from '../../../../publication/Embed/Embed'
import { Paragraph } from '../../../../publication/Paragraph/Paragraph'
import PNPullQuote from '../components/PNPullQuote/PNPullQuote'
import { VideoQueue } from '../../../../templates/Publication/lib/get-video-queue'
import BuyNow from '../../../../content/BuyNow/BuyNow'
import BuyNowV2 from '../../../../SevenNewsV2/BuyNowV2/BuyNowV2'
import BreakoutBox from '../../../../content/BreakoutBox/BreakoutBox'
import ArticleSummary from '../../../../content/ArticleSummary/ArticleSummary'
import { PNSubhead } from './PNSubhead/PNSubhead'
import { PNParagraph } from './PNParagraph/PNParagraph'
import { PNList } from './PNList/PNList'
import { PNArticleVideo } from '../PNArticleVideo/PNArticleVideo'
import { PNArticleImage } from '../PNArticleImage/PNArticleImage'

// We will need to create new ngn blocks and replace current article blocks one by one
export function PNArticleBlock({
    block,
    onEvent,
    log,
    adUnitPath,
    getVideoQueue,
    showInlineVideoTitles,
    publicationPathname,
    publicationKind,
}: {
    block: Block
    onEvent: (event: AllEvents) => void
    log: Logger
    adUnitPath: string
    getVideoQueue: GetVideoQueue | undefined
    showInlineVideoTitles: boolean | undefined
    publicationPathname: string | undefined
    publicationKind?: string
}) {
    const toggles = useToggleState()
    const gdprMode = useGdprMode()

    switch (block.kind) {
        case 'text':
            return (
                <PNParagraph
                    intentions={block.intentions}
                    text={block.text}
                    onEvent={onEvent}
                />
            )
        case 'pull-quote':
            return <PNPullQuote {...block} />
        case 'embed':
            return (
                <Embed
                    log={log}
                    onEvent={onEvent}
                    adUnitPath={adUnitPath}
                    {...block}
                />
            )
        case 'blog':
            return (
                <Blog
                    blogId={block.blogId}
                    provider={block.provider}
                    blogState={block.blogState}
                />
            )
        case 'twin-image': {
            return (
                // 'twin-image-wrapper' utility class required to fix a cascade issue outlined in SWM-4447
                <PNTwinImageWrapper className="twin-image-wrapper">
                    <PNArticleImage
                        key="1"
                        image={block.left}
                        figCaption={{
                            byline: block.left.byline,
                            captionText: block.left.captionText,
                            credit: block.left.credit,
                            kind: 'image',
                        }}
                    />
                    <PNArticleImage
                        key="2"
                        image={block.right}
                        figCaption={{
                            byline: block.right.byline,
                            captionText: block.right.captionText,
                            credit: block.right.credit,
                            kind: 'image',
                        }}
                    />
                </PNTwinImageWrapper>
            )
        }
        case 'inline': {
            switch (block.inlineKind) {
                case 'video': {
                    const heading = block.inlineDetails.captionText || ''
                    const posterImage = block.inlineVideo.posterImage
                    const videoId = block.inlineVideo.id
                    const accountId = block.inlineVideo.accountId
                    const videoType = block.inlineVideo.videoType
                    const videoDetails: VideoDetails = {
                        id: videoId,
                        accountId,
                        heading,
                        posterImage,
                        videoType,
                    }
                    const captionText = block.inlineDetails.captionText

                    const videoOptions: VideoOptions = {
                        adUnitPath,
                        canAutoplayVideos: false,
                        enableSnowplow: false,
                        enablePrebid: isFeatureEnabled(
                            toFeatureState(toggles),
                            'prebid-video',
                        ),
                        isMagniteEnabled: isFeatureEnabled(
                            toFeatureState(toggles),
                            'magnite-header-tag-wrapper',
                        ),
                        gdprMode,
                    }
                    const showPlaylistFeature = isFeatureEnabled(
                        toFeatureState(toggles),
                        'playlists-on-inline-videos',
                    )
                    const isPlaylistHidden = isFeatureEnabled(
                        toFeatureState(toggles),
                        '7-news-video-playlist-removal',
                    )
                    const showPlaylist =
                        showPlaylistFeature &&
                        publicationKind !== 'event' &&
                        !isPlaylistHidden

                    const isAutoPlayNext = isFeatureEnabled(
                        toFeatureState(toggles),
                        'can-autoplay-next',
                    )
                    const autoplayOptions = false
                    const autoplayNextOptions =
                        isAutoPlayNext !== false &&
                        showPlaylist &&
                        getVideoQueue
                            ? {
                                  delayDuration: 3,
                              }
                            : false

                    return (
                        <PNArticleVideo
                            captionText={captionText}
                            videoDetails={videoDetails}
                            showTitles={showInlineVideoTitles || false}
                            autoplayOptions={autoplayOptions}
                            autoplayNextOptions={autoplayNextOptions}
                            videoOptions={videoOptions}
                            pauseOverlay={false}
                            onEvent={onEvent}
                            getVideoQueue={
                                showPlaylist && getVideoQueue
                                    ? getVideoQueue
                                    : () =>
                                          Promise.resolve<VideoQueue>({
                                              items: [],
                                              type: 'not-playlist-video',
                                          })
                            }
                            publicationPathname={publicationPathname}
                        />
                    )
                }
                case 'image':
                    return (
                        <PNArticleImage
                            align={
                                block.align === 'center'
                                    ? undefined
                                    : block.align
                            }
                            image={block.inlineDetails}
                            figCaption={{
                                byline: block.inlineDetails.byline || undefined,
                                captionText: block.inlineDetails.captionText,
                                credit: block.inlineDetails.credit || undefined,
                                kind: 'image',
                            }}
                            link={block.link}
                        />
                    )
                default:
                    return null
            }
        }
        case 'ordered-list': {
            return (
                <PNList
                    list={block}
                    log={log}
                    onEvent={onEvent}
                    adUnitPath={adUnitPath}
                    getVideoQueue={getVideoQueue}
                    publicationKind={publicationKind}
                    publicationPathname={publicationPathname}
                    showInlineVideoTitles={showInlineVideoTitles}
                />
            )
        }
        case 'unordered-list': {
            return (
                <PNList
                    list={block}
                    log={log}
                    onEvent={onEvent}
                    adUnitPath={adUnitPath}
                    getVideoQueue={getVideoQueue}
                    publicationKind={publicationKind}
                    publicationPathname={publicationPathname}
                    showInlineVideoTitles={showInlineVideoTitles}
                />
            )
        }
        case 'subhead': {
            return (
                <PNSubhead
                    intentions={block.intentions}
                    text={block.text}
                    tag="h2"
                    onEvent={onEvent}
                />
            )
        }
        case 'competition': {
            return (
                <Paragraph
                    onEvent={onEvent}
                    intentions={[
                        { kind: 'emphasized', index: 0, length: 27 },
                        { kind: 'important', index: 0, length: 27 },
                    ]}
                    text="Competition has now closed."
                />
            )
        }
        case 'inline-related': {
            return (
                <PNInlineRelatedContent
                    onEvent={onEvent}
                    items={block.publications}
                    publicationPath={publicationPathname}
                    publicationKind={publicationKind}
                    isV2ArticlePageEnabled={isFeatureEnabled(
                        toFeatureState(toggles),
                        '7-news-2-article-page',
                    )}
                />
            )
        }
        case 'buy-now': {
            return isFeatureEnabled(
                toFeatureState(toggles),
                '7-news-2-article-page',
            ) ? (
                <BuyNowV2 text={block.text} link={block.link} />
            ) : (
                <BuyNow text={block.text} link={block.link} />
            )
        }
        case 'breakout-box': {
            return (
                <BreakoutBox>
                    {block.content.map((item) =>
                        PNArticleBlock({
                            block: item,
                            adUnitPath,
                            getVideoQueue,
                            log,
                            onEvent,
                            publicationPathname,
                            showInlineVideoTitles,
                        }),
                    )}
                </BreakoutBox>
            )
        }
        case 'article-summary': {
            return (
                <ArticleSummary>
                    <PNList
                        list={block}
                        log={log}
                        onEvent={onEvent}
                        adUnitPath={adUnitPath}
                        getVideoQueue={getVideoQueue}
                        publicationKind={publicationKind}
                        publicationPathname={publicationPathname}
                        showInlineVideoTitles={showInlineVideoTitles}
                    />
                </ArticleSummary>
            )
        }
        default:
            return null
    }
}
