import styled from '@emotion/styled'
import { zIndex } from '../../__styling/settings/z-index'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakpoint } from '../../__styling'
import { colorsPN } from '@news-mono/design-tokens'

export interface DisplayOnBreakpoint {
    breakpoint: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
    inverse?: boolean
}

export interface StyledRomeoProps {
    cardSpacing: number
    as?: string
    displayOnBreakpoint?: DisplayOnBreakpoint
    hideTopDivider?: boolean
    marginTop?: number
    marginBottom?: number
    centreOnTablet?: boolean
}

// To decide whether to display the element based on breakpoint
function getDisplayStyle(displayOnBreakpoint?: DisplayOnBreakpoint) {
    // always display by default
    if (!displayOnBreakpoint) {
        return {
            display: 'flex',
        }
    }

    return {
        display: displayOnBreakpoint.inverse ? 'none' : 'flex',
        [breakpoint(displayOnBreakpoint.breakpoint)]: {
            display: displayOnBreakpoint.inverse ? 'flex' : 'none',
        },
    }
}

export const StyledRomeoWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: calcRem(24),
})

export const StyledRomeo = styled('div')<StyledRomeoProps>(
    {
        margin: '0 auto',
        width: '100%',
        flexWrap: 'wrap',
    },
    ({
        cardSpacing,
        hideTopDivider = true,
        displayOnBreakpoint,
        marginTop,
        marginBottom = 32,
    }) => [
        {
            paddingTop: hideTopDivider ? undefined : calcRem(cardSpacing),
            marginTop: calcRem(marginTop || 0),
            marginBottom: calcRem(marginBottom),
            ...getDisplayStyle(displayOnBreakpoint),
        },
        ({ centreOnTablet }) => ({
            gridColumnStart: 1,
            gridColumnEnd: 6,
            [breakpoint('sm')]: centreOnTablet
                ? {
                      gridColumnStart: 2,
                      gridColumnEnd: 5,
                  }
                : undefined,
            [breakpoint('lg')]: {
                gridColumnStart: 1,
                gridColumnEnd: 6,
            },
        }),
    ],
)

export const StyledHeader = styled('div')({
    display: 'block',
    width: '100%',
})

export const StyledGridWrapper = styled('ul')({
    display: 'block',
    width: '100%',
    margin: 0,
    padding: 0,
})

export const StyledGridItem = styled('li')<{
    cardSpacing: number
    noHorizontalDividers?: boolean
    hideLastDivider?: boolean
    hideTopDivider?: boolean
    horizontalDividerColor?: string
    topBottomDividerColorOverride?: string
}>(
    {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexShrink: 1,
        width: '100%',
        maxWidth: '100%',
        alignItems: 'stretch',
        flex: '0 0 auto',
        position: 'relative',
    },
    ({
        horizontalDividerColor,
        cardSpacing,
        hideLastDivider = true,
        hideTopDivider = true,
        topBottomDividerColorOverride,
    }) => ({
        paddingTop: calcRem(cardSpacing),
        paddingBottom: calcRem(cardSpacing),

        '&:first-child': {
            marginTop: '0',
            paddingTop: hideTopDivider ? 0 : calcRem(cardSpacing),
        },

        '&:last-child': {
            marginBottom: '0',
            paddingBottom: hideLastDivider ? 0 : calcRem(cardSpacing),
        },

        // Adds horizontal dividers
        '&:first-child::before': hideTopDivider
            ? undefined
            : {
                  content: `''`,
                  backgroundColor:
                      topBottomDividerColorOverride ||
                      horizontalDividerColor ||
                      colorsPN.stroke.brand.strong,
                  width: '100%',
                  height: calcRem(1),
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: zIndex.common.collectionDividers,
              },
        '&::after': {
            content: `''`,
            backgroundColor:
                horizontalDividerColor || colorsPN.stroke.brand.strong,
            width: '100%',
            height: calcRem(1),
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: zIndex.common.collectionDividers,
        },

        '&:last-child::after': hideLastDivider
            ? {
                  content: undefined,
                  height: 0,
              }
            : {
                  backgroundColor:
                      topBottomDividerColorOverride ||
                      horizontalDividerColor ||
                      colorsPN.stroke.brand.strong,
              },

        '> .PortraitCard': {
            alignItems: 'flex-start',
        },
    }),
)
