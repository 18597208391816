import styled, { Interpolation } from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { zIndex } from '../../__styling/settings/z-index'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { hexToRGBA } from '../../__styling/style-mixins'
import { MediaIndicatorProps, MediaType } from './MediaIndicator'
import { breakpoint } from '../../__styling'
import React from 'react'

const sevennewsPlayArrow = require('./assets/7news-play-arrow.svg')
const sevennewsGalleryIcon = require('./assets/7news-gallery.svg')

export const mediaIndicatorIconClass = 'media_indicator_icon'

const getIconSource = (mediaType: MediaType = 'video') => {
    return mediaType === 'video' ? sevennewsPlayArrow : sevennewsGalleryIcon
}

const getIconStyles = (isSmall?: boolean, mediaType: MediaType = 'video') => {
    return {
        left: calcRem(12),
        transition: `background ${easing.easeOut.fast}`,
        background: `url(${getIconSource(mediaType)})`,
        backgroundRepeat: 'no-repeat',
        width: isSmall ? 32 : 60,
        height: isSmall ? calcRem(14) : calcRem(29),
        backgroundSize: isSmall
            ? `${calcRem(12)} ${calcRem(14)}`
            : `${calcRem(25)} ${calcRem(29)}`,
    }
}

const getNonCenteredBackgroundStyles = (isSmall: boolean) => {
    return {
        top: 'auto',
        bottom: calcRem(16),
        left: calcRem(16),

        [breakpoint('sm')]: {
            bottom: calcRem(20),
            left: calcRem(23),
            width: calcRem(64),
            height: calcRem(64),
        },

        '&::before': {
            [breakpoint('sm')]: {
                left: isSmall ? 12 : 27,
            },
        },
    }
}

interface LeftSize {
    left: number
}

type BorderWidthAndLeftSize = {
    borderWidth?: string
} & LeftSize

interface BreakpointSizeIcon {
    sm: BorderWidthAndLeftSize
    default: BorderWidthAndLeftSize
}

interface BreakpointSize {
    normal: {
        sm: number
        default: number
    }
    small: number
}

interface IconSize {
    container: BreakpointSize
    iconCentered: {
        normal: BreakpointSizeIcon
        small: BorderWidthAndLeftSize
        withBorder?: BorderWidthAndLeftSize
    }
    icon: {
        normal: BreakpointSizeIcon
        small: BorderWidthAndLeftSize
    }
}

const sizes: IconSize = {
    container: {
        normal: { sm: 70, default: 60 },
        small: 32,
    },
    iconCentered: {
        normal: {
            sm: {
                left: 27,
            },
            default: { left: 21 },
        },
        small: { left: 12 },
        withBorder: { left: 10 },
    },
    icon: {
        normal: {
            sm: {
                left: 27,
            },
            default: { left: 21 },
        },
        small: { left: 5 },
    },
}

export const StyledMediaElementStyles: Interpolation<MediaIndicatorProps>[] = [
    () => ({
        position: 'absolute',
        zIndex: zIndex.common.videoPlayButton,
        margin: 0,
        padding: 0,
        width: calcRem(44),
        height: calcRem(44),
        fontSize: 0,
        lineHeight: 1,
        transition: `opacity ${easing.easeOut.med}, background-color ${easing.easeOut.fast}`,
        cursor: 'pointer',
        '&::before': {
            position: 'absolute',
            top: '50%',
            display: 'block',
            content: `''`,
            transform: 'translateY(-50%)',
        },

        '& > span': {
            display: 'hidden',
        },
    }),
    ({ isSmall, mediaType }) => ({
        backgroundColor: hexToRGBA(colors.black, 0.05),
        opacity: 1,
        borderRadius: mediaType === 'video' ? '100%' : 0,
        border: mediaType === 'video' ? 'inherit' : 'none',
        backgroundSize: mediaType === 'video' ? 'inherit' : 'contain',
        backdropFilter: mediaType === 'video' ? 'blur(13.5914px)' : 'none',

        '&::before': getIconStyles(isSmall, mediaType),
    }),
    ({ isCentered, isSmall }) => {
        if (!isCentered) {
            return getNonCenteredBackgroundStyles(isSmall || false)
        }

        const defaultSize = isSmall
            ? sizes.container.small
            : sizes.container.normal.default
        const defaultMargin = defaultSize / 2

        const smSize = isSmall
            ? sizes.container.small
            : sizes.container.normal.sm
        const smMargin = smSize / 2

        const beforeSizing = isSmall
            ? {
                  left: sizes.iconCentered.small.left,
              }
            : {
                  left: calcRem(sizes.iconCentered.normal.default.left),

                  [breakpoint('sm')]: {
                      left: sizes.iconCentered.normal.sm.left,
                  },
              }

        return {
            top: '50%',
            left: '50%',
            marginLeft: `-${calcRem(defaultMargin)}`,
            marginTop: `-${calcRem(defaultMargin)}`,
            width: calcRem(defaultSize),
            height: calcRem(defaultSize),

            [breakpoint('sm')]: {
                top: '50%',
                left: '50%',
                marginLeft: `-${calcRem(smMargin)}`,
                marginTop: `-${calcRem(smMargin)}`,
                width: calcRem(smSize),
                height: calcRem(smSize),
            },

            '&::before': beforeSizing,
        }
    },
]

const getMediaIndicatorClass = (mediaType: MediaType) => {
    return `media_indicator_${mediaType}_icon`
}

const withMediaClass = (
    Component: React.ComponentType<MediaIndicatorProps>,
) => {
    return (props: MediaIndicatorProps) => {
        const StyledWithClass = withClass(
            getMediaIndicatorClass(props.mediaType ?? 'video'),
        )(Component)
        return <StyledWithClass {...props} />
    }
}

export const StyledMediaIndicator = withMediaClass(
    styled('div')<MediaIndicatorProps>(...StyledMediaElementStyles),
)
