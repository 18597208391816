import styled from '@emotion/styled'
import { CardText } from '../../../cards/CardText/CardText'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { WebLink } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, colors } from '../../../__styling'

export const StyledLinkWrapper = styled(WebLink)(() => [
    {
        textDecoration: 'none',
    },
])
export const StyledContentCard = styled('div')(() => [
    {
        display: 'grid',
        gridTemplateColumns: '2fr 3fr',
        columnGap: calcRem(13),
        gridAutoColumns: 'center',
        marginBottom: calcRem(16),
        marginTop: calcRem(16),
        [breakpoint('xs')]: {
            gridTemplateColumns: '1fr 3fr',
        },
        [breakpoint('mlg')]: {
            gridTemplateColumns: '1fr 7fr',
        },
        '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]': {
            border: `${calcRem(1.92)} solid ${colors.white}`,
            top: 'unset',
            left: 'unset',
            bottom: calcRem(10),
            right: calcRem(10),
            width: calcRem(16),
            height: calcRem(16),
            '&::before': {
                left: '42.5%',
                top: '78.5%',
                backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
            },
        },
        '& [class*="StyledMediaIndicator"][class*="media_indicator_gallery_icon"]':
            {
                border: 'none',
                '&::before': {
                    backgroundSize: 'contain',
                    width: '100%',
                    height: '100%',
                },
            },
    },
])

export const StyledTextContainer = styled('div')(() => [
    {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& [class*="StyledHeadline"]': {
            fontWeight: 500,
            fontSize: calcRem(14),
            lineHeight: 'normal',

            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            overflow: 'hidden',

            [breakpoint('md')]: {
                fontSize: calcRem(18),
            },
        },
    },
])

export const StyledCardImageContainer = styled('div')(({}) => [])

export const StyledText = styled(CardText)(({}) => [{}])

export const StyledCardImage = styled(CardMedia)(() => [
    {
        margin: 0,
        '& [class*="image"]': {
            aspectRatio: '3/2',
            '& img': {
                width: 'auto',
                height: '100%',
            },
        },
    },
])

export const StyledTimeToRead = styled('p')(() => [
    {
        color: colors.sevennews.nero30,
        fontSize: calcRem(12),
    },
])

export const StyledLiveStatus = styled('p')(() => [
    {
        display: 'flex',
        alignItems: 'center',
        marginBottom: calcRem(6),
        fontSize: calcRem(12),
    },
])

export const StyledDotLogo = styled('div')({
    background: tokens.sevennews.colors.palette.red,
    height: calcRem(5),
    width: calcRem(5),
    transform: 'translateX(-0%) translateY(-0%)',
    marginBottom: 0,
    marginRight: calcRem(4),
    borderRadius: '50%',
})
export const StyledLiveLabel = styled('p')(() => [
    {
        color: colors.sevennews.red,
        fontSize: calcRem(12),
        margin: 0,
    },
])

export const StyledCTAButton = styled('button')({
    fontSize: calcRem(12),
    fontWeight: 600,
    lineHeight: calcRem(15),
    color: tokens.sevennews.colors.palette.red,
    padding: calcRem(8, 22),
    border: `${calcRem(2)} solid ${tokens.sevennews.colors.palette.red}`,
    borderRadius: calcRem(25),
    background: 'transparent',
    marginTop: calcRem(12),
    cursor: 'pointer',
    '&:hover': {
        color: tokens.sevennews.colors.palette.white,
        background: tokens.sevennews.colors.palette.red,
    },
})
export const StyledTimeToReadWrapper = styled('div')(() => [
    {
        display: 'flex',
        gap: calcRem(8),
        alignItems: 'center',
    },
])

export const StyledTimeToReadAndTypeWrap = styled('div')(() => [
    {
        display: 'flex',
        gap: calcRem(8),
        alignItems: 'center',
        height: calcRem(30),
    },
])

export const StyledPublicatioType = styled('p')(() => [
    {
        color: colors.sevennews.red,
        fontSize: calcRem(12),
    },
])

export const StyledVideoBadge = styled('p')(() => [
    {
        display: 'flex',
        alignItems: 'center',
        marginBottom: calcRem(6),
        fontSize: calcRem(12),
        color: colors.sevennews.red,
    },
])
