import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { ResolveSlugV4DTO } from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../data/resources'
import { httpGet } from '../../helpers/http'

export function resolveSlug(
    services: DataLoaderGlobalParams,
    // The original path, which has not yet been altered by getSiteSection
    originalPath: string,
    // Should be passed in if the section was prepended, otherwise undefined
    domainedSection: string | undefined,
    hostname: string | undefined,
) {
    const includePremiumQuery = isFeatureEnabled(
        toFeatureState(services.store.getState().toggles),
        'digital-subscriptions',
        false,
    )
        ? '&include_premium=true'
        : ''

    const expectedSectionQuery = domainedSection
        ? `&section=${domainedSection}`
        : ''
    const expectedHostnameQuery = hostname ? `&hostname=${hostname}` : ''

    const slug = originalPath[0] === '/' ? originalPath : `/${originalPath}`
    const path = `v4/resolve?slug=${encodeURIComponent(
        slug,
    )}${includePremiumQuery}${expectedSectionQuery}${expectedHostnameQuery}&include_meta`
    return httpGet({
        log: services.log,
        validate: validateResolveRouteResponse,
        baseUrl: services.config.contentApi,
        path,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}

function validateResolveRouteResponse(value: any): ResolveSlugV4DTO {
    return value
}
