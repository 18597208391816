import loadable from '@loadable/component'
import React, { Suspense } from 'react'

const ReactQueryDevtoolsProduction = loadable(() =>
    import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
        (d) => ({
            default: d.ReactQueryDevtools,
        }),
    ),
)

export const RQDevtools = () => {
    const debug = localStorage.getItem('debug')
    const showDevtools = debug === 'react-query' || debug === '*'

    if (!showDevtools) return null

    return (
        <>
            <Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
            </Suspense>
        </>
    )
}
