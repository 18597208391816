import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem, colors, metrics } from '../../__styling'

export const StyledBuyNowWrapper = styled('div')(({ theme }) => [
    {
        display: 'flex',
        marginTop: calcRem(-18),
        marginBottom: calcRem(16),
    },
    // Styled for PerthNow
    theme.kind === 'perthnow' && {
        margin: `0 0 ${calcRem(metrics.perthnow.textMargin)} 0`,
        display: 'block',
    },
])

export const StyledBuyNowLink = styled(WebLink)(({ theme }) => ({
    width: '100%',
    background: theme.sectionValues.primaryColor,
    textAlign: 'center',
    padding: calcRem(8, 10),
    fontSize: calcRem(14),
    color: colors.white,
    borderRadius: calcRem(4),
    textDecoration: 'none',
    letterSpacing: calcRem(2),
    textTransform: 'uppercase',
}))
