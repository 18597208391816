import {
    AreaDto,
    PartyCode,
    PartyDto,
    SeatDto,
    TransformedData,
} from '@west-australian-newspapers/election-api-types'
import { electionsDebug } from '../../../__helpers/elections-debug'

export type ExtendedInterknowlogyPartyCode = PartyCode | 'COL' | 'COA'

export interface ExtendedInterknowlogyParty extends PartyDto {
    combinedCode?: ExtendedInterknowlogyPartyCode
    combinedPartyCodes?: PartyCode[]
    shortPartyName?: string
    combinedStates?: string[]
    combinedPct?: number
}

export interface ExtendedInterknowlogyArea extends AreaDto {
    parties: ExtendedInterknowlogyParty[]
}

export type ExtendedInterknowlogyData = {
    areas: ExtendedInterknowlogyArea[]
    seats: SeatDto[]
    lastModified: string
}

export const shortStateNames: Record<string, string> = {
    'Australian Capital Territory': 'ACT',
    'New South Wales': 'NSW',
    'Norhtern Territory': 'NT',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Tasminia: 'TAS',
    Victoria: 'VIC',
    'Western Australia': 'WA',
}

export const getFullStateName = (stateInitials: string): string => {
    for (const [fullName, shortName] of Object.entries(shortStateNames)) {
        if (shortName.toLowerCase() === stateInitials.toLowerCase()) {
            return fullName
        }
    }
    return stateInitials.toUpperCase() // If the state is not found, return the initials (capitalised)
}

export const shortPartyNames: Record<string, string> = {
    LNP: 'Liberals',
    LIB: 'Liberals',
    ALP: 'Labor',
    NP: 'Nationals', //Seems to be interchangeable with NAT
    NAT: 'Nationals',
    GRN: 'Greens',
    ONP: 'One Nation',
    COA: 'Liberal', // TODO: Confirm with Dacien
    SFFP: 'SFFPWA',
    SPPk: 'SPPk',
}

// This is a helper function to transform the data from the interknowlogy API.
export const transformAreaData = (
    data: TransformedData,
): ExtendedInterknowlogyData => {
    electionsDebug('perfoming area transform on election data', data)
    const transformedAreaData: ExtendedInterknowlogyData = {
        ...data,
        lastModified: new Date().toISOString(),
        // Transform the Area data to include the short party names and add the Coalition party
        areas: data.areas.map((area) => {
            let coalitionParty: ExtendedInterknowlogyParty | undefined =
                undefined

            const parties = area.parties.map((party) => {
                return {
                    ...party,
                    shortPartyName:
                        shortPartyNames[party.partyCode] ?? party.partyName,
                }
            })

            // Filter coalition party data to combine all into one party
            parties.filter(isCoalitionParty).forEach((party) => {
                if (!coalitionParty) {
                    // If it's not set, then set it
                    coalitionParty = {
                        ...party,
                        partyName: 'Coalition',
                        shortPartyName: 'Coalition',
                        combinedCode: 'COL',
                        combinedPartyCodes: [party.partyCode as PartyCode],
                    }
                } else {
                    // Else, add the values to the existing object
                    coalitionParty.seatsWon += party.seatsWon
                    coalitionParty.seatsAhead += party.seatsAhead
                    coalitionParty.seatsChanged += party.seatsChanged
                    coalitionParty.votePct += party.votePct // This might need some consideration
                    coalitionParty.voteCount += party.voteCount

                    // Only add the swing percentage if it's not null (component can be disabled if null swing)
                    if (party.swingPct) {
                        ;(coalitionParty.swingPct ?? 0) + party.swingPct // This might also need some consideration
                    }

                    // Add the party code to the combined party codes
                    coalitionParty.combinedPartyCodes?.push(
                        party.partyCode as PartyCode,
                    )
                }
            })

            return {
                ...area,
                parties:
                    coalitionParty !== undefined
                        ? [
                              coalitionParty,
                              ...parties.filter(
                                  (party) => !isCoalitionParty(party),
                              ),
                          ]
                        : parties,
            }
        }),
    }
    electionsDebug('Transformed elections data', transformedAreaData)
    return transformedAreaData
}

export const isCoalitionParty = (party: ExtendedInterknowlogyParty): boolean =>
    party.combinedCode === 'COL' ||
    party.combinedCode === 'COA' ||
    party.isCoalition
