import { Profile } from '@news-mono/web-common'
import React, { Fragment } from 'react'
import {
    StyledAuthor,
    StyledBioBlock,
    StyledBioText,
    StyledEmailIcon,
    StyledImage,
    StyledImageContainer,
    StyledName,
    StyledPosition,
    StyledProfileEmailButton,
    StyledSocialLink,
    StyledText,
    StyledXIcon,
} from './BioBlock.styled'

interface BioBlockProps {
    profile?: Profile
}

export const BioBlock: React.FC<BioBlockProps> = ({ profile }) => {
    if (!profile) {
        return null
    }

    const { name, position, mainPhoto, biography } = profile

    const MainPhoto = () =>
        mainPhoto ? (
            <StyledImageContainer>
                <StyledImage src={mainPhoto} alt="" />
            </StyledImageContainer>
        ) : null

    const TwitterLink = () => (
        <Fragment>
            {profile['twitter'] ? (
                <StyledSocialLink
                    openExternalInNewWindow
                    to={`http://x.com/${profile['twitter']}`}
                >
                    <StyledXIcon />@{profile['twitter']}
                </StyledSocialLink>
            ) : null}
        </Fragment>
    )

    return (
        <StyledBioBlock>
            <MainPhoto />
            <StyledAuthor>
                <StyledName>{name}</StyledName>
                <StyledPosition>{position}</StyledPosition>
                <StyledProfileEmailButton to={`mailto:7ndtips@seven.com.au`}>
                    <StyledEmailIcon />
                </StyledProfileEmailButton>
                <TwitterLink />
            </StyledAuthor>
            <StyledText>
                <StyledBioText>{biography}</StyledBioText>
            </StyledText>
        </StyledBioBlock>
    )
}
BioBlock.displayName = 'BioBlock'
