import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    SevenNewsSection,
    isTabletOrMobileViewport,
    metrics,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Store,
} from '@news-mono/web-common'
import { TopicV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../App.routing'
import { breakingNewsComponent } from '../shared-components/ad-and-breaking-news'
import { box } from '../shared-components/compositions'
import { mobileAd } from '../shared-components/mobile-ads'

export const getV2VideoHomePageRoute = ({
    config,
    getAdTargeting,
    topic,
    store,
}: {
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    topic: TopicV4DTO
    store: Store
}): PageType<SevenNewsSection> => {
    const section = 'default'
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )
    // override ad unit path to allow video hub specific sponsorships
    const updatedAdUnitPath = adUnitPath.replace('7news', '7news/video')
    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath: updatedAdUnitPath,
        ssAdUnits,
        topics,
    }
    const isV2LeaderBoardBilbordToggleEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-2-top-leaderboard-ad',
    )
    const isElectionHeaderWidgetEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-wa-election-widgets',
    )

    const metaTitle = 'Videos - Watch the Latest Breaking News Videos | 7News'
    const metaDescription =
        "Watch the latest news videos from Australia and around the world on 7News. Stay up to date with all of the breaking sport, politics, entertainment, finance, weather and business headlines. Today's news, live updates & all the latest breaking stories from 7News."

    return {
        kind: 'page',
        heading: metaTitle,
        hideHeading: true,
        pageType: 'publication',
        pageMeta: {
            title: metaTitle,
            description: metaDescription,
        },
        socialMeta: {
            title: metaTitle,
            description: metaDescription,
        },
        noMetaTitleTemplate: false,
        adTargeting,
        section: section,
        additionalPageProperties: {
            primaryTopic: topic,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    id: '7newsHeaderAd',
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition(
                            box({
                                main: [
                                    ...(isV2LeaderBoardBilbordToggleEnabled
                                        ? []
                                        : [
                                              layout.component({
                                                  type: 'ad-unit',
                                                  props: {
                                                      noticePosition:
                                                          'above-center',
                                                      hiddenUntilLoaded: false,
                                                      preventCollapse: true,
                                                      padding: [
                                                          metrics.sevennews
                                                              .margins.lg,
                                                          0,
                                                          metrics.sevennews
                                                              .margins.sm,
                                                          0,
                                                      ],
                                                      slot: {
                                                          id: 'leaderboard-billboard-one',
                                                          size: 'leaderboardBillboard',
                                                      },
                                                      adType: 'inline',
                                                  },
                                              }),
                                          ]),
                                ],
                            }),
                        ),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'page-header-v2',
                            props: {
                                sectionHeader: {
                                    heading: 'Videos',
                                },
                            },
                        }),
                    ],
                },
            }),

            layout.composition({
                type: 'box',
                props: {
                    verticalGutters: ['lg', 'unset'],
                    horizontalGutters: 'md',
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },

                contentAreas: {
                    main: [
                        breakingNewsComponent,
                        ...(isElectionHeaderWidgetEnabled
                            ? [
                                  layout.component({
                                      type: 'election-head-to-head-seat-count-widget',
                                      props: {
                                          electionDefinition: {
                                              electionId: 'wa-election-2025',
                                              electionData: undefined,
                                              logo: {
                                                  mobile: '',
                                              },
                                              meta: {},
                                          },
                                      },
                                  }),
                              ]
                            : []),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'video',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'video-hub',
                            props: {
                                adUnitPath: updatedAdUnitPath,
                                verticalGutters: ['unset', 'lg'],
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'entertainment',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['entertainment'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'lifestyle',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['lifestyle'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'promo-cards-seven',
                            props: {
                                verticalGutters: ['md', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'video-hub-promo-cards',
                                    offset: 0,
                                    pageSize: 3,
                                },
                            },
                        }),

                        //first ad
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'above-center',
                                            hiddenUntilLoaded: false,
                                            padding: [
                                                0,
                                                0,
                                                metrics.sevennews.margins.lg,
                                                0,
                                            ],
                                            slot: {
                                                id: 'leaderboard-billboard-two',
                                                size: 'leaderboardBillboard',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                    mobileAd({
                                        id: 'one',
                                        mrec: true,
                                        topPadding: 'unset',
                                        bottomPadding: 'lg',
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'sport',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['sport'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'news',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['news'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'promo-cards-seven',
                            props: {
                                verticalGutters: ['md', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'video-hub-promo-cards',
                                    offset: 3,
                                    pageSize: 3,
                                },
                            },
                        }),
                        //second ad
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'above-center',
                                            hiddenUntilLoaded: false,
                                            padding: [
                                                0,
                                                0,
                                                metrics.sevennews.margins.lg,
                                                0,
                                            ],
                                            slot: {
                                                id: 'leaderboard-billboard-three',
                                                size: 'leaderboardBillboard',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                    mobileAd({
                                        id: 'two',
                                        mrec: true,
                                        topPadding: 'unset',
                                        bottomPadding: 'lg',
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'business',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['business'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'politics',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['politics'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),

                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'technology',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['technology'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),

                        //third ad
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'above-center',
                                            hiddenUntilLoaded: false,
                                            padding: [
                                                0,
                                                0,
                                                metrics.sevennews.margins.lg,
                                                0,
                                            ],
                                            slot: {
                                                id: 'leaderboard-billboard-four',
                                                size: 'leaderboardBillboard',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                    mobileAd({
                                        id: 'three',
                                        mrec: true,
                                        topPadding: 'unset',
                                        bottomPadding: 'lg',
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'travel',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['travel'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'sunrise',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['sunrise'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: 'the-morning-show',
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'four-card-collection',
                            props: {
                                verticalGutters: ['unset', 'lg'],
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['the-morning-show'],
                                    paging: {
                                        page: 1,
                                        pageSize: 4,
                                    },
                                },
                                shouldHideTeaser: true,
                            },
                        }),
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                                isFourColumns: isTabletOrMobileViewport()
                                    ? false
                                    : true,
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
