import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from 'react'
import {
    ElectionDataFetchError,
    ElectionText,
    ElectionTitle,
} from '../components'
import { AllEvents } from 'libs/web-common/src/events'
import {
    LoadingPlaceholder,
    MapOverviewContainer,
    MapOverviewIframe,
    MapOverviewIframeContainer,
} from './MapOverviewWidget.styled'
import {
    ConfigurationContext,
    DataLayerEventName,
    ElectionDefinition,
    LoadedElectionDefinition,
} from '@news-mono/web-common'
import { useImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { getPreloadedElectionDefinition, loadElectionDefinition } from '../data'

export interface MapOverviewProps {
    electionDefinition: ElectionDefinition
    onEvent?: (event: AllEvents) => void
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
    descriptionText?: string
    mapUrl?: string
    hasSidebar?: boolean
}

const DEFAULT_DESCRIPTION =
    'Important topics for voters will include economic recovery, the cost of living crisis, and how the government addresses climate change.'

export const MapOverview = ({
    electionDefinition,
    onEvent,
    headingLevel,
    titleText = 'Map overview',
    descriptionText = DEFAULT_DESCRIPTION,
    hasSidebar = false,
}: MapOverviewProps): JSX.Element => {
    const [isError, setIsError] = useState<boolean>(false)
    const [isIframeAvailable, setIsIframeAvailable] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const preloadedDefinition =
        getPreloadedElectionDefinition(electionDefinition)
    const [loadedData, setLoadedData] = React.useState<
        LoadedElectionDefinition | undefined
    >(preloadedDefinition || undefined)

    const config = React.useContext(ConfigurationContext)

    const mapUrl = loadedData?.electionData.config?.mapApiUrl ?? undefined

    useEffect(() => {
        const load = async () => {
            try {
                const loadedDefinition = await loadElectionDefinition(
                    electionDefinition,
                    config,
                )
                setLoadedData(loadedDefinition)
            } catch (error) {
                setIsError(true)
            }
        }

        if (!loadedData) {
            load()
        }
    }, [config, electionDefinition, loadedData])

    /** Checks that the Interknowlogy map is available.
     * Even on successful loads, the mapUrl always seems to return a 404 status code.
     * The `onLoad` event of the iframe was not firing consistently so this check ensures that
     * the map is at least reachable and not returning a 5xx error
     */
    useEffect(() => {
        const checkMapUrlAvailability = async (
            url: string | null | undefined,
        ) => {
            if (
                !url ||
                (!url.startsWith('http://') && !url.startsWith('https://'))
            ) {
                return false
            }
            try {
                const response = await fetch(url, { method: 'HEAD' })
                return response.ok || response.status === 404
            } catch {
                return false
            }
        }

        const validateUrl = async () => {
            if (mapUrl) {
                const isAvailable = await checkMapUrlAvailability(mapUrl)
                if (isAvailable) {
                    setIsIframeAvailable(true)
                } else {
                    setIsError(true)
                }
            }
            setIsLoading(false)
        }

        validateUrl()
    }, [mapUrl])

    const wrapperProps = {
        headingLevel,
        titleText,
        descriptionText,
        mapUrl,
    }

    const handleError = () => {
        setIsError(true)
    }

    // If there is an issue contacting our Election API to get the map url, or if the map fails to load or no url is provided
    if ((loadedData && !mapUrl) || isError) {
        return (
            <MapOverviewWrapper {...wrapperProps}>
                <IframeWrapper key={'Error'} hasSidebar={hasSidebar}>
                    <ElectionDataFetchError />
                </IframeWrapper>
            </MapOverviewWrapper>
        )
    }

    // Loading state for our Elections API, or the map availability hasn't been validated yet
    if (isLoading || !loadedData || !isIframeAvailable) {
        return (
            <MapOverviewWrapper {...wrapperProps}>
                <IframeWrapper key={'Loading'} hasSidebar={hasSidebar}>
                    <LoadingPlaceholder />
                </IframeWrapper>
            </MapOverviewWrapper>
        )
    }

    return (
        <MapOverviewWrapper {...wrapperProps}>
            <IframeWrapper
                key={mapUrl}
                mapUrl={mapUrl}
                onEvent={onEvent}
                hasSidebar={hasSidebar}
            >
                <MapOverviewIframe
                    src={mapUrl}
                    onError={handleError}
                    aria-label="Election Map Overview"
                    title="Election Map Overview"
                />
            </IframeWrapper>
        </MapOverviewWrapper>
    )
}

const MapOverviewWrapper = ({
    children,
    headingLevel,
    titleText,
    descriptionText,
}: PropsWithChildren<{
    onEvent?: (event: AllEvents) => void
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
    descriptionText?: string
}>) => {
    return (
        <MapOverviewContainer>
            {titleText && (
                <ElectionTitle as={headingLevel || undefined}>
                    {titleText}
                </ElectionTitle>
            )}
            {descriptionText && <ElectionText>{descriptionText}</ElectionText>}
            {children}
        </MapOverviewContainer>
    )
}

const IframeWrapper = ({
    children,
    mapUrl,
    onEvent,
    hasSidebar,
}: PropsWithChildren<{
    mapUrl?: string
    onEvent?: (event: AllEvents) => void
    hasSidebar?: boolean
}>) => {
    const impressionAvailableRef = useImpressionAvailable({
        loading: false,
        available: useCallback(() => {
            onEvent?.({
                type: DataLayerEventName.embedViewed,
                originator: 'ElectionMapOverview',
                payload: {
                    url: window.location.href,
                    embedUrl: mapUrl || '',
                },
            })
        }, [onEvent, mapUrl]),
    })

    return (
        <MapOverviewIframeContainer
            ref={mapUrl && impressionAvailableRef}
            hasSidebar={hasSidebar}
        >
            {children}
        </MapOverviewIframeContainer>
    )
}
