import styled from '@emotion/styled'
import {
    PNButtonSize,
    PNButtonStyles,
    PNButtonType,
} from './PerthnowButtonStyles'
import { calcRem, fonts } from '../../__styling'
import { WebLink } from '@news-mono/web-common'

export const StyledButton = styled.button<{
    buttonType: PNButtonType
    buttonSize: PNButtonSize
}>(({ buttonType, buttonSize }) => getStyles(buttonType, buttonSize))

export const StyledWebLink = styled(WebLink)<{
    buttonType: PNButtonType
    buttonSize: PNButtonSize
}>(({ buttonType, buttonSize }) => {
    return {
        ...getStyles(buttonType, buttonSize),
        textDecoration: 'none',
    }
})

const getStyles = (buttonType: PNButtonType, buttonSize: PNButtonSize) => {
    const colors = PNButtonStyles.colors[buttonType]
    const size = PNButtonStyles.size[buttonSize]

    return {
        //general styling
        display: 'flex',
        gap: calcRem(8),
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: fonts.perthnow.sansSerif,
        fontWeight: 600,
        cursor: 'pointer',

        // Remove default border.
        borderStyle: 'none',
        borderWidth: 0,

        //spread the button styles
        ...size,
        ...colors.default,
        '&:hover': {
            ...colors.hover,
        },
        '&:focus': {
            ...colors.focus,
        },
    }
}
