import styled from '@emotion/styled'
import { metrics, ThemeMargins } from '../../../__styling/settings/metrics'
import { breakpoint, calcRem } from '../../../__styling/style-functions'
import { tokens } from '@news-mono/design-tokens'
import { colors } from '../../../__styling'

export interface GridProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    itemCount: number
    isSkeleton?: boolean
}

const getTabletGridTemplateRows = (itemCount: number) => {
    if (itemCount <= 2) {
        return '1fr'
    }
    if (itemCount > 2 && itemCount <= 4) {
        return '1fr 1fr'
    }
    return '1fr 1fr 1fr'
}

export const StyledFiveCardCollectionWrapper = styled('div')(() => [
    {
        '& button': {
            textTransform: 'lowercase',
            background: colors.sevennews.nero50,
            height: calcRem(40),
            width: calcRem(300),
        },
    },
])

export const StyledFiveCardCollection = styled('div')<GridProps>(
    ({ theme, verticalSpacing, verticalGutters, itemCount, isSkeleton }) => [
        {
            margin: '0 auto',
            width: '100%',
            borderBottom: `${calcRem(1)} solid ${
                tokens.sevennews.colors.palette.articlesV2.horizontalLine
            }`,
            display: 'grid',
            gridRowGap: calcRem(16),
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `
            "one"
            "two"
            "three"
            "four"
            "five"
            `,
            '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]': {
                border: `${calcRem(1.92)} solid ${colors.white}`,
                top: 'unset',
                left: 'unset',
                bottom: calcRem(16),
                right: calcRem(16),
                width: calcRem(24),
                height: calcRem(24),
                '&::before': {
                    left: '42%',
                    top: '59%',
                    backgroundSize: ` ${calcRem(5.53)} ${calcRem(9.76)}`,
                },
            },
            '& :nth-of-type(n+4)': {
                '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]':
                    {
                        border: `${calcRem(1.92)} solid ${colors.white}`,
                        top: 'unset',
                        left: 'unset',
                        bottom: calcRem(10),
                        right: calcRem(10),
                        width: calcRem(16),
                        height: calcRem(16),
                        '&::before': {
                            left: '42.5%',
                            top: '78.5%',
                            backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
                        },
                    },
            },
            [breakpoint('sm')]: {
                gridColumnGap: calcRem(18),
                gridRowGap: calcRem(8),
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: getTabletGridTemplateRows(itemCount),
                gridTemplateAreas: `
                "one one"
                "two two"
                "three three"
                "h-line h-line"
                "four five"
                `,
            },
            [breakpoint('md')]: {
                paddingBottom: 0,
                gridColumnGap: calcRem(16),
                gridRowGap: calcRem(16),

                gridTemplateColumns: '2fr 1fr 1fr 2fr',
                gridTemplateRows: `1fr 1px`,
                gridTemplateAreas: `
                "one one two two"
                "h-line h-line h-line h-line"
                "three four four five"
                `,
                '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]':
                    {
                        top: 'unset',
                        left: 'unset',
                        bottom: calcRem(16),
                        right: calcRem(16),
                        width: calcRem(24),
                        height: calcRem(24),
                        '&::before': {
                            left: '42%',
                            top: '61%',
                            backgroundSize: ` ${calcRem(5.53)} ${calcRem(
                                9.76,
                            )}`,
                        },
                    },
                '& :nth-of-type(n+3)': {
                    '& [class*="StyledPlayIcon"], & [class*="StyledMediaIndicator"]':
                        {
                            top: 'unset',
                            left: 'unset',
                            bottom: calcRem(10),
                            right: calcRem(10),
                            width: calcRem(16),
                            height: calcRem(16),
                            '&::before': {
                                left: '42.5%',
                                top: '78%',
                                backgroundSize: ` ${calcRem(3.5)} ${calcRem(
                                    6,
                                )}`,
                            },
                        },
                },
            },
            '& [class*="StyledMediaIndicator"][class*="media_indicator_gallery_icon"]':
                {
                    border: 'none',
                    '&::before': {
                        width: '100%',
                        height: '100%',
                        backgroundSize: 'contain',
                    },
                },
            [breakpoint('mlg')]: {
                paddingBottom:
                    verticalGutters &&
                    calcRem(metrics.sevennews.margins[verticalGutters[1]]),
            },
            marginBottom:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
            marginTop:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
            paddingTop:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[0]]),
            paddingBottom:
                verticalGutters &&
                calcRem(metrics.sevennews.margins[verticalGutters[1]]),
        },
        !isSkeleton && {
            [breakpoint('md')]: {
                paddingBottom: 0,
                gridColumnGap: calcRem(16),
                gridRowGap: calcRem(16),
                '& :nth-child(n+4)': {
                    paddingTop: calcRem(16),
                    paddingBottom: calcRem(16),
                },

                '& :nth-of-type(3)': {
                    marginRight: calcRem(22),
                },
                '& :nth-of-type(4)': {
                    marginLeft: calcRem(19),
                    marginRight: calcRem(19),
                },
                '& :nth-of-type(5)': {
                    marginLeft: calcRem(22),
                },

                '& :nth-of-type(n+3):nth-of-type(-n+4)': {
                    '&': {
                        position: 'relative',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            height: '50%',
                            width: calcRem(1),
                            background:
                                tokens.sevennews.colors.palette.articlesV2
                                    .horizontalLine,
                            top: '25%',
                            right: calcRem(-30),
                        },
                    },
                },
            },
        },
    ],
)

interface HorizontalLineProps {
    itemCount: number
}
export const StyledHorizontalLine = styled('hr')<HorizontalLineProps>(
    ({ itemCount }) => [
        {
            display: 'none',
            gridArea: 'h-line',
            border: 0,
            width: '100%',
            height: '1px',
            background:
                tokens.sevennews.colors.palette.articlesV2.horizontalLine,
            [breakpoint('sm')]: {
                display: itemCount <= 3 ? 'none' : 'block',
            },
            [breakpoint('md')]: {
                display: itemCount <= 2 ? 'none' : 'block',
            },
        },
    ],
)
