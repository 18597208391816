import React from 'react'
import { ReadMore } from '.'

export interface ReadMoreWithLiveBlogsProps {
    readTime: number | null
    postCount?: number
}

export const ReadMoreWithLiveBlogs = ({
    readTime,
    postCount,
}: ReadMoreWithLiveBlogsProps) => {
    return (
        <ReadMore
            readTime={postCount ? null : readTime}
            postCount={postCount}
        />
    )
}
