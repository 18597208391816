import {
    CardBreakpointRatios,
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
    FixedRatio,
    Product,
    WebLink,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { Button } from '../../buttons/Button/Button'
import { MediaMode } from '../../cards/CardMedia/CardMedia'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { KickerMode } from '../../cards/Kicker/Kicker'
import { Landscape } from '../../cards/Landscape/Landscape'
import {
    getLandscapeCardItemProps,
    LandscapeLayout,
} from '../../cards/Landscape/Landscape.layouts'
import { LandscapeStackedLayout } from '../../cards/LandscapeStacked/LandscapeStacked.layouts'
import { CardOrientationOptions, Portrait } from '../../cards/Portrait/Portrait'
import {
    getCardItemProps,
    LayoutName,
} from '../../cards/Portrait/Portrait.layouts'
import {
    DisclaimerWrapper,
    StyledGridItem,
    StyledHeader,
    StyledLima,
    StyledLinkRow,
} from '../../collections/Lima/Lima.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import {
    ResponsiveContainer,
    ResponsivePictureSizes,
} from '../../content/Picture/responsive'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product/useProduct'
// Themes, Metrics, Events
import { FontScales } from '../../__styling/settings/fontScale'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { ThemeOverrideTypes } from '../../__styling/themes'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { calculateLayoutsLength, renderCardLayout } from './helpers/layouts'
import { useTheme } from '@emotion/react'
import { LandscapeNightly } from '../../cards/LandscapeNightly/LandscapeNightly'

export type ListLength = 1 | 2 | 3 | 4 | 5 | 6

export interface NovemberLayout {
    type: 'november'
    listLength: ListLength

    /**
     * If minimum number of cards is not met, november is collapsed
     *
     * The cards will not continue to remainingCards
     **/
    minimumCards?: number
    timestamp?: TimestampType
    sectionHeader?: SectionHeaderProps
    noStretch?: boolean
    verticalSpacing?: keyof ThemeMargins | undefined
}

export interface LimaHeroLayout {
    type: 'hero'
    mediaMode?: MediaMode
}

export interface LimaPromoLayout {
    type: 'hero'
}

export type LimaLayout =
    | (LayoutName | LandscapeLayout | LandscapeStackedLayout)[]
    | NovemberLayout

export type LimaLayouts =
    | [LimaLayout, LimaLayout, LimaLayout, LimaLayout]
    | [LimaLayout, LimaLayout, LimaLayout]
    | [LimaLayout, LimaLayout]
    | [LimaLayout]
    | LimaHeroLayout
    | LimaPromoLayout

export type RemainingLayouts = LayoutName | LandscapeLayout

export interface LimaProps extends ResponsiveContainer {
    className?: string
    hideByline?: boolean
    hasBorder?: boolean
    hideBottomBorder?: boolean
    teaserMode?: TeaserMode
    kickerMode?: KickerMode
    timestamp?: TimestampType
    sectionHeader?: SectionHeaderProps
    hasBackground?: boolean
    fixedRatios?: FixedRatio[] | CardBreakpointRatios
    displayPublicationDate?: boolean
    onEvent: (event: CollectionEvent) => void

    // Make this into breakpoint definitions
    initialColumns?: number
    intermediateColumns?: number
    finalColumns?: number
    fontScale?: FontScales
    verticalSpacing?: keyof ThemeMargins | undefined
    disableImageLazyLoad?: boolean
    isSponsoredCollection?: boolean
    cardOrientation?: CardOrientationOptions
    cardLayout?: LimaLayouts
    remainingCardLayout?: RemainingLayouts
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
    stretchSelf?: boolean
    hasSeparator?: boolean
    minimumNumberOfCards?: number
    imageWidths?: ResponsivePictureSizes
    isInlineRelatedCollection?: boolean
    viewMoreLinkUrl?: string
    hideEditorialType?: boolean
    canPlayVideoInline?: boolean
    marginBottom?: number
    hideSeparators?: boolean
    disclaimerText?: string
    anchorId?: string
    hasContextMenu?: boolean
    allowLiveBlogMilestoneTeaser?: boolean
}

export interface CardOverrideOptions {
    fontScale: FontScales
    kickerMode?: KickerMode
    hideByline?: boolean
    isSponsoredCollection?: boolean
    teaserMode: TeaserMode
    disableImageLazyLoad?: boolean
    isInlineRelatedCollection?: boolean
    hideEditorialType?: boolean
    canPlayVideoInline?: boolean
}

export const Lima: React.FC<LimaProps> = (props) => {
    const {
        className,
        sectionHeader,
        hasBackground,
        onEvent,
        initialColumns = 1,
        intermediateColumns = 2,
        finalColumns = 4,
        verticalSpacing,
        cardOrientation,
        cardLayout,
        remainingCardLayout = 'common',
        teaserMode = 'visible',
        kickerMode,
        hasBorder = true,
        hideBottomBorder,
        fontScale,
        isSponsoredCollection,
        disableImageLazyLoad,
        expectedCards,
        stretchSelf,
        minimumNumberOfCards = 1,
        hideEditorialType,
        canPlayVideoInline,
        marginBottom,
        hideSeparators,
        disclaimerText,
        anchorId,
        displayPublicationDate = false,
        hasContextMenu = false,
    } = props

    // NOTE: If you wish to pass any props to the Portrait/Landscape cards below, note that
    // they need to be defined inside the cardOptionOverrides object below and not passed directly to the cards
    // like a normal prop.

    const theme = useTheme()
    const product = useProduct()
    const cardOptionOverrides: CardOverrideOptions = {
        fontScale: fontScale || 1,
        hideByline: product === 'sevennews' || props.hideByline,
        isSponsoredCollection,
        teaserMode: product === 'sevennews' ? 'hidden' : teaserMode,
        disableImageLazyLoad: disableImageLazyLoad,
        isInlineRelatedCollection: props.isInlineRelatedCollection,
        kickerMode,
        hideEditorialType,
        canPlayVideoInline,
    }

    const items = invertMaybeLoadedItems(props.items, expectedCards)

    const remainingItems = () => {
        return cardLayout !== undefined
            ? items.slice(
                  items.length -
                      (items.length - calculateLayoutsLength(cardLayout)),
              )
            : items.slice(0, items.length)
    }

    const hasItems = items.length >= minimumNumberOfCards

    const containerValues = props.containerWidthRatios
        ? props.containerWidthRatios
        : { desktop: 1, tablet: 1, mobile: 1 }

    return (
        <>
            {anchorId && hasItems && (
                <a id={anchorId} style={{ position: 'absolute' }}></a>
            )}
            {sectionHeader && hasItems && (
                <StyledHeader>
                    <SectionHeader {...sectionHeader} />
                </StyledHeader>
            )}
            {disclaimerText && (
                <DisclaimerWrapper>{disclaimerText}</DisclaimerWrapper>
            )}
            <ImpressionAvailable
                loading={!props.items.loaded}
                available={() => {
                    if (!props.items.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }
                    props.onEvent(
                        createCollectionAvailableEvent(
                            props.items.result,
                            'Lima',
                            product,
                            props.onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <StyledLima
                        ref={ref}
                        className={className}
                        initialColumns={initialColumns}
                        intermediateColumns={intermediateColumns}
                        finalColumns={finalColumns}
                        verticalSpacing={hasItems ? verticalSpacing : 'unset'}
                        stretchSelf={stretchSelf}
                        hasBorder={hasBorder}
                        hideBottomBorder={hideBottomBorder}
                        hasBackground={hasBackground}
                        hasItems={hasItems}
                        marginBottom={marginBottom}
                    >
                        {hasItems &&
                            cardLayout &&
                            renderCardLayout(
                                cardLayout,
                                cardOptionOverrides,
                                props,
                                items,
                                getImageWidth,
                                product,
                            )}
                        {hasItems &&
                            remainingItems().map((item, index) => {
                                const numberOfItems = cardLayout
                                    ? remainingItems().length
                                    : items.length

                                // if the above renders a card then we should increment by 2, as the above
                                // card will be 1
                                const cardNumber = cardLayout
                                    ? index + 2
                                    : index + 1

                                return (
                                    <StyledGridItem
                                        key={index}
                                        numberOfItems={numberOfItems}
                                        intermediateColumns={
                                            intermediateColumns
                                        }
                                        initialColumns={initialColumns}
                                        finalColumns={finalColumns}
                                        hideSeparators={hideSeparators}
                                    >
                                        {typeof remainingCardLayout ===
                                        'object' ? (
                                            item && (
                                                <CardSwitcher
                                                    onEvent={props.onEvent}
                                                    key={index}
                                                    item={item}
                                                    cardContext="lima-remaining"
                                                    cardNumber={cardNumber}
                                                    publicationCard={(
                                                        publicationItem,
                                                    ) => {
                                                        return theme.kind ===
                                                            'thenightly' ? (
                                                            <LandscapeNightly
                                                                {...getLandscapeCardItemProps(
                                                                    publicationItem,
                                                                    {
                                                                        type: 'landscape',
                                                                        format: 'landscape-common',
                                                                    },
                                                                    { onEvent },
                                                                    cardNumber,
                                                                )}
                                                                imageWidths={
                                                                    props.imageWidths
                                                                }
                                                                hideByline={
                                                                    props.hideByline
                                                                }
                                                                disableImageLazyLoad={
                                                                    props.disableImageLazyLoad
                                                                }
                                                                displayPublicationDate={
                                                                    displayPublicationDate
                                                                }
                                                                contentPosition="center"
                                                                renderLightHeadline
                                                                renderTeaser
                                                                useMainHeadline
                                                                hasContextMenu={
                                                                    hasContextMenu
                                                                }
                                                            />
                                                        ) : (
                                                            <Landscape
                                                                key={index}
                                                                disableImageLazyLoad={
                                                                    disableImageLazyLoad
                                                                }
                                                                timestamp={
                                                                    props.timestamp
                                                                }
                                                                {...getLandscapeCardItemProps(
                                                                    publicationItem,
                                                                    remainingCardLayout,
                                                                    {
                                                                        onEvent,
                                                                    },
                                                                    cardNumber,
                                                                )}
                                                                isInlineRelatedCollection={
                                                                    cardOptionOverrides.isInlineRelatedCollection
                                                                }
                                                            />
                                                        )
                                                    }}
                                                />
                                            )
                                        ) : (
                                            <CardSwitcher
                                                onEvent={props.onEvent}
                                                key={index}
                                                item={item}
                                                cardContext="lima-remaining"
                                                cardNumber={cardNumber}
                                                publicationCard={(
                                                    publicationItem,
                                                ) => (
                                                    <Portrait
                                                        key={index}
                                                        data-layout={
                                                            remainingCardLayout
                                                        }
                                                        containerWidthRatios={{
                                                            mobile:
                                                                (1 /
                                                                    initialColumns) *
                                                                containerValues.mobile,
                                                            tablet:
                                                                (1 /
                                                                    intermediateColumns) *
                                                                containerValues.tablet,
                                                            desktop:
                                                                (1 /
                                                                    finalColumns) *
                                                                containerValues.desktop,
                                                        }}
                                                        hasBackground={
                                                            hasBackground
                                                        }
                                                        fixedRatio={
                                                            props.fixedRatios
                                                        }
                                                        allowLiveBlogMilestoneTeaser={
                                                            props.allowLiveBlogMilestoneTeaser
                                                        }
                                                        {...{
                                                            teaserMode:
                                                                props.teaserMode,
                                                        }}
                                                        {...getCardItemProps(
                                                            publicationItem,
                                                            remainingCardLayout,
                                                            {
                                                                onEvent,
                                                            },
                                                            cardNumber,
                                                            cardOrientation,
                                                        )}
                                                        {...cardOptionOverrides}
                                                    />
                                                )}
                                            />
                                        )}
                                    </StyledGridItem>
                                )
                            })}
                    </StyledLima>
                )}
            </ImpressionAvailable>

            {props.viewMoreLinkUrl && hasItems ? (
                <StyledLinkRow>
                    <WebLink to={props.viewMoreLinkUrl}>
                        <Button>More</Button>
                    </WebLink>
                </StyledLinkRow>
            ) : undefined}
        </>
    )
}
Lima.displayName = 'Lima'

/**
 * This determines the initial value of the card width, which also affects the srcSet generated etc
 * so for Lima collections with items < 2 a larger image width will be served
 */
function getImageWidth(itemsCount: number, product: Product) {
    switch (itemsCount) {
        case 1:
        case 2:
            switch (product) {
                case Product.TheWest:
                    return 500
                case Product.SevenNews:
                    return 650
            }
            return 650
    }
    switch (product) {
        case Product.TheWest:
            return 268
        case Product.SevenNews:
            return 320
    }
    return 320
}
