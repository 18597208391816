import { toFeatureState } from '@etrigan/feature-toggles-client'
import {
    SpaInitialisedEvent,
    TogglesReduxState,
    Features,
} from '@news-mono/web-common'
import { adBlockEnabled } from '../../advertising/gpt/utils'

/** This works with the event buffer, until this event, events will be buffered
 *
 * Once this event is raised it will be raised first, then any buffered events
 * will be raised after.
 */
export const createSpaInitialisedEvent = (
    toggles: TogglesReduxState,
): SpaInitialisedEvent => {
    return {
        type: 'spa.initialised',
        originator: 'Features',
        payload: {
            enabledFeatures: getEnabledFeatures(toggles),
            adBlockEnabled: adBlockEnabled(),
        },
    }
}

function getEnabledFeatures(toggles: TogglesReduxState) {
    return Object.keys(toggles).filter((key) => {
        const feature = key as Features
        const featureState = toFeatureState(toggles)[feature]
        try {
            return featureState && featureState.value.boolean()
        } catch {
            return false
        }
    })
}
