import styled from '@emotion/styled'
import { IconX } from '../../icons'
import { calcRem, metrics, themedValue } from '../../__styling'
import { StyledProfileEmailButton } from '../ProfileEmailButton/ProfileEmailButton.styled'

export const SyledProfileTwitterButton = styled(StyledProfileEmailButton)({
    marginLeft: metrics.thewest.margins.xs,
})

export const StyledIconTwitter = styled(IconX)(
    {
        fill: 'currentcolor',
    },
    ({ theme }) => ({
        width: calcRem(12),
        height: themedValue(theme, {
            fallback: calcRem(8),
            perthnow: calcRem(11),
        }),
        marginRight: themedValue(theme, {
            fallback: calcRem(metrics.thewest.margins.xs),
            perthnow: calcRem(metrics.perthnow.margins.xs),
        }),
    }),
)
