import styled from '@emotion/styled'

export const CardContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.margins.md,
    width: '100%',
    maxWidth: 770,
    alignSelf: 'center',
}))
