import styled from '@emotion/styled'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { TextItem } from '../../../../../typography/TextItem/TextItem'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../../__styling/style-mixins'
import { perthNowNGNTextMixin } from '../../../../../mixins'
import { breakpoint } from '../../../../../__styling'

export const StyledPNSubhead = styled(TextItem)({
    ...breakWords,
    clear: 'both',
    color: colorsPN.text.primary,
    textTransform: 'capitalize',
    margin: calcRem(metricsPN.spacing['L'], 0),
    ...perthNowNGNTextMixin.title['2XS'].semibold,

    [breakpoint('sm')]: {
        ...perthNowNGNTextMixin.title['XS'].semibold,
    },
    [breakpoint('lg')]: {
        ...perthNowNGNTextMixin.title['S'].semibold,
    },
})
