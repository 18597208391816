import { MaybeLoaded } from 'json-react-layouts-data-loader'
import {
    CardItem,
    PublicationCardItem,
    PublicationCardItemWithoutVideo,
} from '@news-mono/web-common'

const mastHeads = [
    'The New York Times',
    'The Economist',
    'The Washington Post',
    'CNBC',
]

export function getByline(item: MaybeLoaded<PublicationCardItem>): {
    link?: string
    text: string
} {
    if (!item.loaded) {
        return { link: undefined, text: '' }
    }

    const card = item.result

    // If the source is one of the special mastheads, use that as the byline text
    if (card.source && mastHeads.includes(card.source)) {
        return { link: undefined, text: card.source }
    }

    // If the card has a profile, use that for the byline
    if (
        isPublicationCardItemWithoutVideo(card) &&
        card.profiles &&
        card.profiles.length > 0
    ) {
        const profile = card.profiles[0]
        return { link: `/profile/${profile.slug}`, text: profile.name }
    }

    // Fallback to the default card byline
    return { link: undefined, text: card.byline }
}

export function getTopicLink(item: MaybeLoaded<CardItem>): string {
    if (!item.loaded) {
        return ''
    }

    return `/${item.result.primaryTopic.id}` ?? ''
}

function isPublicationCardItemWithoutVideo(
    card: CardItem,
): card is PublicationCardItemWithoutVideo {
    return (<PublicationCardItemWithoutVideo>card).profiles !== undefined
}
