import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    ClassificationV4DTO,
    PublicationExpandedListingV4ResponseDTO,
    PublicationListingV4ResponseDTO,
} from '@west-australian-newspapers/publication-types'
import { DataLoaderGlobalParams } from '../../../data/resources'
import { httpGet } from '../../../helpers/http'
import { validateKeyTypes } from '../../../helpers/validation'
import { Paging } from '../../../__content-api/v4/paging'

export function validateTopicListingResponse(
    value: any,
): PublicationListingV4ResponseDTO {
    const errors = validateKeyTypes('topic-list', value, {
        page_size: 'number',
        page: 'number',
        total: 'number',
        documents: [{}],
        morePublicationsAvailable: 'boolean',
    })

    if (errors) {
        throw new Error(
            'Topic List response invalid\n    ' + errors.join('    \n'),
        )
    }

    return value
}

export const validateExpanded = (
    value: any,
): PublicationExpandedListingV4ResponseDTO => {
    const errors = validateKeyTypes('topic-list', value, {
        page_size: 'number',
        page: 'number',
        total: 'number',
        documents: [{}],
        morePublicationsAvailable: 'boolean',
    })

    if (errors) {
        throw new Error(
            'Topic Expanded List response invalid\n    ' +
                errors.join('    \n'),
        )
    }

    return value
}

export interface RandomisationOptions {
    random_size?: number
    random_offset?: number
    seed?: string
}

export interface TopicListingOptions {
    isSponsored?: boolean
    topics?: string[]
    parentTopics?: string[]
    excludeTopics?: string[]
    authors?: string[]
    publicationKind?: 'article' | 'gallery'
    paging?: Paging
    source?: string
    publishedSince?: string
    profiles?: boolean
    includePremium?: boolean
    classification?: ClassificationV4DTO
    randomisation?: RandomisationOptions
    excludeSubTopics?: boolean
}

export const getTopicListing = (
    services: DataLoaderGlobalParams,
    options: TopicListingOptions,
) => {
    const include_premium =
        // option defaults to true, which is then confirmed/denied by the feature toggle
        // see commments in https://bitbucket.swmdigital.io/projects/PROD/repos/web/pull-requests/1501/overview
        options.includePremium !== false &&
        isFeatureEnabled(
            toFeatureState(services.store.getState().toggles),
            'digital-subscriptions',
            false,
        )

    const includeTotal = isFeatureEnabled(
        toFeatureState(services.store.getState().toggles),
        'tn-enable-pagination',
        false,
    )
    const classificationKind = options.classification?.kind
    const classificationLabel = options.classification?.label

    const { paging = {} } = options
    const query = {
        page: paging.page || 1,
        page_size: paging.pageSize || 6,
        page_offset: paging.pageOffset || 0,
        max_page_number: includeTotal
            ? paging.maxPageNumber || undefined
            : undefined,
        isSponsored: options.isSponsored,
        kind: options.publicationKind,
        topics: options.topics,
        parentTopic: options.parentTopics,
        excludeTopics: options.excludeTopics,
        authors: options.authors,
        source: options.source,
        publishedSince: options.publishedSince,
        profiles: options.profiles,
        include_premium: include_premium || undefined,
        excludeRegional: false,
        classificationKind,
        classificationLabel,
        random: !!options.randomisation,
        random_offset: options.randomisation?.random_offset,
        random_size: options.randomisation?.random_size,
        seed: options.randomisation?.seed,
        excludeSubTopics: options.excludeSubTopics,
        includeTotal,
    }

    return httpGet({
        log: services.log,
        validate: validateTopicListingResponse,
        baseUrl: services.config.contentApi,
        path: `v4/publication`,
        query,
        customHeaders: {
            caller: services.config.apiCallerHeader,
        },
    })
}
