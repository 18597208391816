import React from 'react'
import { StyledMediaIndicator } from './MediaIndicator.styled'

export type MediaType = 'video' | 'gallery'

export interface MediaIndicatorProps {
    isSmall?: boolean
    isCentered?: boolean
    mediaType: MediaType
    title?: string
}

export const MediaIndicator: React.FC<MediaIndicatorProps> = ({
    isSmall,
    isCentered,
    mediaType = 'video',
}) => {
    const title = mediaType === 'video' ? 'Video' : 'Gallery'

    return (
        <StyledMediaIndicator
            title={title}
            isSmall={isSmall}
            isCentered={isCentered}
            mediaType={mediaType}
        />
    )
}

MediaIndicator.displayName = 'MediaIndicator'

export default MediaIndicator
