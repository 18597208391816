import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'
import { sharedStyles } from '../SeatCard/SeatCard.styled'
import { placeholderAnimation } from '../components/placeholderAnimation'

export const MapOverviewContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(24),
    alignSelf: 'stretch',

    padding: calcRem(16, 0),

    [breakpoint('lg')]: {
        padding: calcRem(0),
    },
})

export const MapOverviewIframe = styled('iframe')({
    width: '100%',
    height: '100%',
    border: 'none',
})

export const LoadingPlaceholder = styled('div')(
    sharedStyles,
    placeholderAnimation,
    {
        height: '100%',

        [breakpoint('sm')]: {
            height: '100%',
        },
    },
)

export const MapOverviewIframeContainer = styled('div')<{
    hasSidebar?: boolean
}>(({ hasSidebar }) => ({
    width: '100%',
    height: calcRem(685),
    [breakpoint('md')]: {
        height: hasSidebar ? calcRem(449) : calcRem(685),
    },
}))
