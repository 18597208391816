import { Theme } from '@emotion/react'
import { isMarginKey } from './settings'
import { MarginProps } from './types'
import { breakpointSwitch, calcRem } from './style-functions'

export const createMarginBreakpoints = (margin: MarginProps, theme: Theme) => {
    return breakpointSwitch(margin, (styles) => {
        return {
            margin: styles.margin
                ? convertMargin(styles.margin, theme)
                : undefined,
            marginTop: styles.marginTop
                ? convertMargin(styles.marginTop, theme)
                : undefined,
            marginBottom: styles.marginBottom
                ? convertMargin(styles.marginBottom, theme)
                : undefined,
            marginLeft: styles.marginLeft
                ? convertMargin(styles.marginLeft, theme)
                : undefined,
            marginRight: styles.marginRight
                ? convertMargin(styles.marginRight, theme)
                : undefined,
        }
    })
}

const convertMargin = (
    margin: number | string,
    theme: Theme,
): string | number => {
    if (typeof margin === 'number') {
        return calcRem(margin)
    } else {
        if (isMarginKey(theme, margin)) {
            return theme.margins[margin]
        } else {
            return margin
        }
    }
}
