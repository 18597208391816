export const colors = {
    black: '#000',
    black80: 'rgba(0, 0, 0, .8)',
    black50: 'rgba(0, 0, 0, .5)',
    black35: 'rgba(0, 0, 0, .35)',
    black25: 'rgba(0, 0, 0, .25)',
    black20: 'rgba(0, 0, 0, .20)',
    black10: 'rgba(0, 0, 0, .10)',
    black15: 'rgba(0, 0, 0, .15)',
    black005: 'rgba(0, 0, 0, .05)',
    darkGrey: '#2B2C2D',
    white: '#fff',
    white20: 'rgba(255, 255, 255, .2)',
    white30: 'rgba(255, 255, 255, .3)',
    white50: 'rgba(255, 255, 255, .5)',
    white80: 'rgba(255, 255, 255, .8)',
    white90: 'rgba(255, 255, 255, .9)',
    redButton: '#E6001E',
    blackTextButton: '#141414',
    generalWOLilac: '#8F00CC',
    aussieYellow: '#FBF200',

    // Used in the Tokyo Olympics & Paris Olympics
    olympics: {
        background: '#06671E',
        link: '#FCBB01',
        border: 'rgba(210, 213, 214, 0.4)',

        text: '#FFF',
        kicker: {
            perthnow: '#0A5158',
            thewest: '#B4D1BB',
        },
        title: '#535556',
        icon: '#74787A',
        orange: '#E6A001',
    },

    designSystem: {
        greyMystic: '#d6d6d6',
        lightGrey: '#eee',
        darkGrey: '#333',
        opusGreen: '#009563',
    },

    thewest: {
        // Shades of grey
        greyGorilla: '#2b2c2d',
        greyRhino: '#3D3D3D',
        greyElephant: '#535556',
        greyKoala: '#74787a',
        greyHippo: '#888d90',
        greyQuokka: '#b5b9ba',
        greyDolphin: '#d2d5d6',
        greySeal: '#e5e5e5',
        greyMouse: '#eaeaea',
        greyErmine: '#f4f5f5',

        // Colours
        westblue: '#043de2',
        indigo: '#3c62c3',
        westblueTint: '#0a70f3',
        ladybird: '#ab2b2b',
        engineRed: '#C63400',
        cricketGreen: '#2c3e02',
        opinion: '#852BAB',
        java: '#13B4C3',
        persianGreen: '#02A2A3',
        teal: '#018384',
        hollywoodCerise: '#F400AB',
        electricViolet: '#AA01E1',
        breachNavy: '#001d56',
        pastelGreen: '#06671E',

        // The Game Pick 11
        sunshineYellow: '#E4C300',
        canaryYellow: '#FFB506',
        fantaOrange: '#FF8900',

        // Regionals
        albanyadvertiserBlue: '#000099',
        augustamargaretrivertimesOrange: '#d3420d',
        broomeadvertiserGreen: '#00856f',
        bunburyheraldBlue: '#0071bb',
        busseltondunsboroughtimesTeal: '#0080a3',
        countrymanRed: '#e01f2e',
        geraldtonguardianRed: '#c4161c',
        greatsouthernheraldBlue: '#006ad3',
        harveywaroonareporterGreen: '#008542',
        kalgoorlieminerRed: '#e22822',
        manjimupbridgetowntimesBlue: '#2478cc',
        midwesttimesRed: '#e7131a',
        narroginobserverBlue: '#007bc7',
        northwesttelegraphOcre: '#cc4928',
        pilbaranewsOrange: '#cd470e',
        soundtelegraphBlue: '#007acc',
        southwesterntimesRed: '#da2128',
        thekimberleyechoRed: '#e7131a',
        //using gradients on the flat blue and flat black because hover transition between flat colors and a gradient cause a flash
        grandfinalBlue:
            'linear-gradient(179.74deg, #001F54 0.25%, #003897 116.15%)',
        grandfinalBlack:
            'linear-gradient(179.75deg, #011A45 0.24%, #012A71 116.16%)',
        freoGrandFinalPurple:
            'linear-gradient(129.35deg, #48008F 15.06%, #1A013A 87.96%)',
        freoGrandFinalPurpleBlack:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), linear-gradient(129.35deg, #37016F 15.06%, #170232 87.96%)',

        //coronation
        coronationRed: '#8F0202',
        //matildas
        matildasGreen: '#006331',
    },

    sevennews: {
        // Common colors
        white: '#fff',
        red: '#E6001E',
        red50: 'rgba(182,0,30,0.5)',
        red40: 'rgba(182,0,30,0.4)',
        red20: '#FACCD2',
        darkRed: '#b60920',
        redRedLowOpac: '#b6092033',
        stormGrey: '#737480',
        tundora: '#4d4d4d',
        charade: '#292A33',
        charade70: '#68686f',
        gray98: '#FAFAFA',
        midGray: '#696A70',
        alto: '#dfdfdf',
        turbo: '#FBE400',
        greyMarble: '#ccc',
        greyErmine: '#f4f5f5',
        /* #000 30% opacity */
        boxShadow: '#0000001f',
        /* background color */
        seashell: '#f1f1f1',

        trendingGrey: '#616161',
        nero: '#272626',
        nero15: 'rgba(39,38,38,0.15)',
        nero30: 'rgba(39,38,38,0.3)',
        nero50: 'rgba(39,38,38,0.5)',
        nero60: 'rgba(39,38,38,0.6)',
        nero70: 'rgba(39,38,38,0.6)',
        nero80: 'rgba(39,38,38,0.8)',

        navigationBackground: '#FAF8F7',

        pillMenu: {
            color: '#5F5D5D',
            pillBackground: 'rgba(208,203,202,0.5)',
            background: '#FAF7F7',
            leftArrowOverlay:
                'linear-gradient(271deg, rgba(217, 217, 217, 0.00) -1.07%, #FFF 110.58%)',
            rightArrowOverlay:
                'linear-gradient(271deg, #FFF -1.07%, rgba(217, 217, 217, 0.00) 110.58%)',
        },
        // default
        /* Seven News blue */
        toreaBay: '#162EA6',
        resolutionBlue: '#002482',
        /* rollover colors */
        blueRibbon: '#2e49fe',
        gulfBlue: '#070966',
        chillBlue: '#8a97d2',
        plumFuzz: '#332D46',

        // sunrise
        piper: '#CF5226',
        piperDark: '#A5421E',
        blazeOrange: '#FF670A',
        chillOrange: '#e7a893',

        // The morning Show
        endeavour: '#0058AD',
        endeavourDark: '#003E79',
        azureRadiance: '#0777E5',
        chillEndeavour: '#80abd6',

        // Spotlight
        kashmirBlue: '#48639A',
        pickledBluewood: '#262F4B',
        pickledBluewoodDark: '#1B2135',
        chillPickledBluewood: '#9397a5',

        // Lifestyle
        lifestylePink: '#B04AE3',
        lifestylePurple:
            'linear-gradient(78.2deg, #9000CD 14.16%, #580F9B 74.67%)',
        lifestyleFlyoutPurple:
            'linear-gradient(169.32deg, #9000CD -2.65%, #580F9B 79.5%)',

        //7 Life Lifestyle
        sevenLifePurple: '#9000CD',

        //Top Story
        darkred: '#B6001E',

        //Trending
        trendingRed: '#E6001E',

        // Entertainment
        entertainmentPurple: '#A8328B',

        // Latest
        latestRed: '#FF0505',

        // Default theme
        defaultRed: '#BF001F',

        //SNEnt
        snentpurple: '#A583F1',

        flyoutLinkRed: '#B80018',
        flyoutLinkGrey: '#1F351B',
        flyoutV2Grey: '#FAF8F7',
        flyoutV2Text: '#4A4A4A',

        bauhausBlack: '#333333',
        bauhausBlack20: 'rgba(51,51,51,0.2)',
        bauhausBlack50: 'rgba(51,51,51,0.5)',
        bauhausBlack80: 'rgba(51,51,51,0.8)',
        bauhausBlack90: 'rgba(51,51,51,0.9)',
        graniteGray: '#5E5F61',

        neonSilver: '#d0cbca',
        neonSilver30: 'rgba(208, 203, 202,0.3)',
        softPeach: '#E5E1E0',

        dustyGray: '#979797',
        featherGrey: '#B7AD9F',

        breakingLive: '#9B0809',
        pulsingDot: '#FF0000',
        paleBrown: '#936E47',
        ivory: '#FFFEEA',

        election: {
            colectionBg:
                'linear-gradient(176.31deg, #1F05E1 3.44%, #E6001E 51.78%, rgba(230, 0, 30, 0) 83.71%)',
        },
    },

    // Colour styleguide at https://projects.invisionapp.com/boards/GR3B3B7J79BFN/
    perthnow: {
        // Shades of grey
        greyCoal: '#252525',
        greyMoonstone: '#777',
        greyStone: '#999',
        greyMarble: '#ccc',
        greyPyrite: '#e5e5e5',
        greyGlass: '#f3f3f3',
        greyElephant: '#535556',

        greyBasalt: '#1b262e',
        greyBasalt60: `rgba(27, 38, 46, .6)`,
        greyBasalt80: `rgba(27, 38, 46, .8)`,
        greyBasalt90: `rgba(27, 38, 46, .9)`,

        greySlate: '#32373b',
        greyFlint: '#3c525c',
        greyShale: '#8798a1',
        greyPebble: '#97a5ad',
        greyPumice: '#bdbde0',

        // Colours
        blueSapphire: '#0771be', // News
        blueKyanite: '#066cb6', // Hovers
        blueCerulean: '#009FDA', // Community News
        redRuby: '#d42626', //PerthNow Red
        orangeCitrine: '#c74705', // Sport
        purpleAmethyst: '#a762a6', // Entertainment
        tealTopaz: '#0b8478', // Business
        pinkThulite: '#dd1466', // Lifestyle and Default
        pinkThulite04: 'rgba(221, 20, 102, 0.04)', // Hovers and Active
        pinkThulite25: 'rgba(221, 20, 102, 0.25)', // PNArticle card Hovers and Active
        greenJade: '#418702', // Have you heard
        greenTourmaline: '#038674', // Promotional Cards
        pinkLace: '#fce8f0', // Focus and Active
    },
    // Todo - update this when available
    thenightly: {
        // TNI placeholder
        theNightlyPrimary: '#31173D',
        button: {
            hover: '#6F5D77',
            pressed: '#83748B',
        },
        link: {
            default: '#4B006E',
        },
        neutral: {
            100: '#161616',
            70: '#525252',
            0: '#FFFFFF',
        },
        video: {
            adPlaying: '#FFE400',
        },
    },

    // 2020 Election
    election: {
        redBlueGradient:
            'linear-gradient(90.01deg, rgb(30, 4, 226) 0.01%, rgb(176, 61, 40) 99.72%)',
    },

    // 2017 The Game
    theGame: {
        blue: '#3a3afc',
        yellow: '#ffe800',
    },

    // Social Media - used in Social buttons in The West and Perth Now
    social: {
        facebook: '#1877f2',
        facebookMessengerBlue: '#0084ff',
        twitter: '#14171a',
        whatsapp: '#25d366',
        email: '#066cb6',
        reddit: '#ff5700',
        clipboard: '#520e44',
        rss: '#f26522',
        instagram: '#EF0075',
        linkedIn: '#2f76af',
        youtube: '#ce1312',
    },

    sevennewsSocial: {
        twitter: '#55acee',
        email: '#162ea6',
        rss: '#f8991d',
        clipboard: '#5C5D66',
    },
}
