import { useTheme } from '@emotion/react'
import {
    AppState,
    AuthenticationState,
    CardBreakpointRatios,
    createCardClickedEvent,
    entitledToAll,
    FixedRatio,
    isLiveSportsTopic,
    isPublicationCardItemWithVideo,
    Product,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    StyledCardByline,
    StyledCardMedia,
    StyledCardText,
    StyledFundingLabel,
    StyledLink,
    StyledPortrait,
    StyledPortraitLiveBlogContainer,
} from '../../cards/Portrait/Portrait.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import {
    applyContainerRatio,
    ResponsiveContainer,
} from '../../content/Picture/responsive'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import { CommentButton } from '../../coral'
import { getFundingType } from '../../templates/Publication/lib/get-funding-type'
import { showBylineDateByTopic } from '../../__helpers/show-byline-date-by-topic'
import { isVideoClick } from '../../__helpers/video-card-helpers'
import { FontScales } from '../../__styling/settings/fontScale'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { themedValue } from '../../__styling/themed-value'
import { MediaMode } from '../CardMedia/CardMedia'
import { doesParticularRatioExist } from '../CardMedia/image-helpers'
import { TeaserMode } from '../CardText/CardTeaser'
import { VideoPlaybackRestrictionTypes } from '../HeroVideoCard/HeroVideoCard'
import { KickerMode } from '../Kicker/Kicker'
import { StyledHeroCardLink } from '../TheWest/HeroCard/HeroCard.styled'

export interface CardOrientationOptions {
    type: 'Landscape-Portrait'
    maxBreakpoint?: 'sm' | 'md'
}

export interface PortraitState {
    isPlaying?: boolean
}

export type ShouldVideoPlayInline = boolean
export type InlineVideoPlayerBreakpoints = 'initial' | 'xs' | 'sm' | 'md'
export type InlineVideoBreakpointOptions = Partial<
    Record<InlineVideoPlayerBreakpoints, ShouldVideoPlayInline>
>
export type InlineVideoPlayerMode = boolean

export interface PortraitProps extends CommonCardProps, ResponsiveContainer {
    hasPadding?: boolean
    hasBackground?: boolean
    hasSeparator?: boolean
    mediaMode?: MediaMode
    hideByline?: boolean
    showProfile?: boolean
    teaserMode: TeaserMode
    kickerMode?: KickerMode

    isLarge?: boolean
    verticalSpacing?: keyof ThemeMargins
    fullWidthOnMobile?: boolean
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    smallImage?: boolean
    fontScale?: FontScales
    isSponsoredCollection?: boolean
    disableImageLazyLoad?: boolean

    timestamp?: TimestampType
    cardNumber: number
    innerRef?: React.RefObject<any>
    cardOrientation?: CardOrientationOptions
    type?: string // Type of card

    adUnitPath?: string
    canPlayVideoInline?: InlineVideoPlayerMode
    restrictedVideoPlaybackMode?: VideoPlaybackRestrictionTypes
    item: MaybeLoaded<PublicationCardItem>
    getVideoQueue?: GetVideoQueue
    isInlineRelatedCollection?: boolean
    showVideoPlayButton?: boolean
    overrideRestrictedVideoPlayback?: boolean
    hideEditorialType?: boolean
    enforceCustomActionStateTreatment?: boolean
    // on PN, we hide the kicker if the article is a video (for the Hero Card) -> this overrides that behaviour.
    overrideHideKickerBehaviorOnPN?: boolean
    borderShadingOnPN?: boolean
    noRoundedCorners?: boolean
    allowLiveBlogMilestoneTeaser?: boolean
}

export const Portrait: React.FC<PortraitProps> = (props) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const theme = useTheme()

    function linkClicked(e: React.MouseEvent<HTMLElement>) {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }
        const isSevenHeroVideo =
            theme.kind === Product.SevenNews &&
            props.canPlayVideoInline === true
        const exclude = isSevenHeroVideo ? ['vjs-poster'] : []

        if (
            props.canPlayVideoInline &&
            props.fixedRatio &&
            doesParticularRatioExist(props.fixedRatio, '16:9') &&
            isVideoClick(e.nativeEvent.target, exclude)
        ) {
            setIsPlaying(true)
            e.preventDefault()
            return
        } else {
            props.onEvent(
                createCardClickedEvent(
                    props.onEvent,
                    props.item.result,
                    'InternalPortrait',
                    'Portrait',
                    props.cardNumber,
                    fundingType,
                    props.isInlineRelatedCollection
                        ? 'InlineRelatedCollection'
                        : undefined,
                ),
            )
        }
    }

    const {
        className,
        hasBackground,
        hasSeparator,
        verticalSpacing,
        isLarge,
        fixedRatio,
        cardOrientation,
        fullWidthOnMobile,
        containerWidthRatios,
        showProfile,
        showVideoPlayButton,
        restrictedVideoPlaybackMode,
        canPlayVideoInline = undefined,
        overrideRestrictedVideoPlayback,
        hideEditorialType,
        enforceCustomActionStateTreatment,
        overrideHideKickerBehaviorOnPN,
        borderShadingOnPN,
        noRoundedCorners,
        allowLiveBlogMilestoneTeaser = false,
    } = props

    const storyClassification = true

    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isEntitled = entitledToAll(authentication)

    const allowInlineVideoPlayback = overrideRestrictedVideoPlayback
        ? true
        : canPlayRestrictedVideo(restrictedVideoPlaybackMode, isEntitled) ||
          canPlayVideoInline

    // SWM-4731 When the page is sponsored, cards should never show the funding status
    const fundingType =
        !props.item.loaded || props.isSponsoredCollection
            ? undefined
            : getFundingType(props.item.result, storyClassification)

    const cardItem = props.item
    const getVideoQueue = !props.item.loaded ? undefined : props.getVideoQueue
    const primaryTopic = cardItem.loaded
        ? cardItem.result.primaryTopic.id
        : undefined
    const showPublicationDate = showBylineDateByTopic(primaryTopic)

    // determine if we are displaying an Opinion Card
    const isOpinionPublication =
        props.item.loaded &&
        props.item.result.primaryTopic.id.indexOf('opinion') > -1
    const isWestOpinionCard = theme.kind === 'thewest' && isOpinionPublication

    const showBylineProfile = themedValue(theme, {
        thewest: isWestOpinionCard && showProfile !== false,
        fallback: showProfile,
    })

    const fontScale = themedValue(theme, {
        thewest: props.fontScale || 0.88,
        perthnow: props.fontScale,
        sevennews: props.fontScale || 1.2,
        fallback: undefined,
    }) as FontScales

    const LandscapePortraitCard =
        cardOrientation && cardOrientation.type === 'Landscape-Portrait'

    const isSevenHeroVideo =
        theme.kind === Product.SevenNews && allowInlineVideoPlayback

    const hidePerthNowKicker =
        theme.kind === Product.PerthNow &&
        allowInlineVideoPlayback &&
        cardItem.loaded &&
        !overrideHideKickerBehaviorOnPN &&
        isPublicationCardItemWithVideo(cardItem.result)

    const showPerthNowComments =
        useFeature('comments') &&
        theme.kind === Product.PerthNow &&
        props.item.loaded &&
        props.item.result.allowCommenting

    const playButtonText =
        (isSevenHeroVideo && cardItem.loaded && cardItem.result.kicker) ||
        undefined

    const setKickerState =
        (isPlaying &&
            theme.kind != Product.TheWest &&
            !overrideHideKickerBehaviorOnPN) ||
        isSevenHeroVideo ||
        hidePerthNowKicker
            ? 'hidden'
            : props.kickerMode

    const isLiveBlogMilestonesTeaserEnabled = useFeature(
        'live-blog-milestones-teaser',
    )

    // Let's see if we want to use the live blog styling!
    const isLiveBlog =
        (allowLiveBlogMilestoneTeaser || props.isLarge) && // We can specify manually to enable live blogs, but all large cards should have it by default
        theme.kind === Product.PerthNow && // Limit to PerthNow as we dont want to mess up the Portrait styling for other components using the new feature
        props.item.loaded &&
        props.item.result.publicationKind === 'event' &&
        isLiveBlogMilestonesTeaserEnabled

    const portraitContent: JSX.Element = (
        <>
            {props.mediaMode !== 'hidden' && (
                <StyledCardMedia
                    item={cardItem}
                    fixedRatio={
                        LandscapePortraitCard
                            ? { default: '16:9', sm: '4:3' }
                            : fixedRatio
                    }
                    hasBackground={hasBackground}
                    imageWidths={applyContainerRatio(containerWidthRatios, {
                        mobile: '100vw',
                        tablet: '100vw',
                        desktop: `${theme.siteMetrics.mainContentWidth}px`,
                        fallbackWidth: theme.siteMetrics.mainContentWidth,
                    })}
                    onEvent={props.onEvent}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                    isLarge={isLarge}
                    cardOrientation={cardOrientation}
                    getVideoQueue={getVideoQueue}
                    willPlayVideoInline={allowInlineVideoPlayback}
                    adUnitPath={props.adUnitPath}
                    mediaMode={props.mediaMode}
                    fullWidthOnMobile={fullWidthOnMobile}
                    playButtonText={playButtonText}
                    borderShadingOnPN={borderShadingOnPN}
                />
            )}
            <StyledCardText
                item={cardItem}
                hasBackground={hasBackground}
                teaserMode={props.teaserMode}
                hideByline={props.hideByline}
                insideSponsoredCollection={props.isSponsoredCollection} // ITSD-49300
                isLiveSportsTopic={
                    props.item.loaded &&
                    props.item.result &&
                    isLiveSportsTopic(props.item.result.primaryTopic.id)
                }
                kickerMode={setKickerState}
                fontScale={fontScale}
                onEvent={props.onEvent}
                hasPadding={props.hasPadding}
                isLarge={isLarge}
                timestamp={props.timestamp}
                cardOrientation={cardOrientation}
                innerRef={undefined}
                disableImageLazyLoad={undefined}
                className={undefined}
                hideEditorialType={hideEditorialType}
                liveBlogConfiguration={
                    isLiveBlog
                        ? {
                              isEnabled: isLiveBlog,
                              isVrTest: false,
                              hasBackground,
                              getLinkComponentWithChildren: (liveBlogProps) => {
                                  const linkTo =
                                      liveBlogProps.to === ''
                                          ? toLinkWithHint(props.item)
                                          : liveBlogProps.to

                                  return (
                                      <StyledHeroCardLink
                                          to={linkTo}
                                          key={liveBlogProps.key}
                                          hasBackground={hasBackground}
                                          openExternalInNewWindow={false}
                                      >
                                          {liveBlogProps.children}
                                      </StyledHeroCardLink>
                                  )
                              },
                          }
                        : undefined
                }
            />

            {!props.hideByline &&
                !(fundingType && theme.kind === 'perthnow') && (
                    <StyledCardByline
                        item={cardItem}
                        onEvent={props.onEvent}
                        showProfile={showBylineProfile}
                        isEdgeToEdgeCard={isWestOpinionCard}
                        cardOrientation={cardOrientation}
                        mediaMode={props.mediaMode}
                        showFundingLabel={!!fundingType}
                        showPublicationDate={showPublicationDate}
                    />
                )}
            {fundingType && (
                <StyledFundingLabel
                    source={
                        theme.kind === 'perthnow' && cardItem.loaded
                            ? cardItem.result.byline
                            : undefined
                    }
                    fundingType={fundingType}
                    hasBackground={hasBackground}
                />
            )}
            {showPerthNowComments && (
                <CommentButton
                    isDarkBackground={hasBackground}
                    customLink={
                        cardItem.loaded
                            ? `${cardItem.result.link}#coral_thread`
                            : undefined
                    }
                />
            )}
        </>
    )
    return (
        <StyledPortrait
            ref={props.innerRef}
            className={className}
            data-topic={
                props.item.loaded
                    ? props.item.result.primaryTopic.id
                    : undefined
            }
            hasBackground={hasBackground}
            hasSeparator={hasSeparator}
            isLarge={isLarge}
            cardOrientation={cardOrientation}
            verticalSpacing={verticalSpacing}
            mediaMode={props.mediaMode}
            fullWidthOnMobile={fullWidthOnMobile}
            noRoundedCorners={noRoundedCorners}
        >
            {isLiveBlog ? (
                <StyledPortraitLiveBlogContainer
                    hasBackground={hasBackground}
                    isLarge={isLarge}
                    cardOrientation={cardOrientation}
                    hasPadding={props.hasPadding}
                    fullWidthOnMobile={fullWidthOnMobile}
                    isFunded={!!fundingType}
                    enforceCustomActionStateTreatment={
                        enforceCustomActionStateTreatment
                    }
                >
                    {portraitContent}
                </StyledPortraitLiveBlogContainer>
            ) : (
                <StyledLink
                    to={toLinkWithHint(props.item)}
                    onClick={linkClicked}
                    hasBackground={hasBackground}
                    isLarge={isLarge}
                    cardOrientation={cardOrientation}
                    hasPadding={props.hasPadding}
                    fullWidthOnMobile={fullWidthOnMobile}
                    isFunded={!!fundingType}
                    enforceCustomActionStateTreatment={
                        enforceCustomActionStateTreatment
                    }
                >
                    {portraitContent}
                </StyledLink>
            )}
        </StyledPortrait>
    )
}
Portrait.displayName = 'Portrait'

export function canPlayRestrictedVideo(
    restrictions: VideoPlaybackRestrictionTypes,
    isEntitled: boolean,
): boolean {
    switch (restrictions) {
        case 'all':
            return true
        case 'registered':
            return true
        case 'entitled':
            return isEntitled
        default:
            return false
    }
}
