import { ClassNames, useTheme } from '@emotion/react'
import {
    BreakpointRatios,
    FixedRatio,
    ImageRatio,
    ImageSource,
    RenderTargetContext,
    scaleImage,
    useFeature,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { BreakpointState, ThemeConfig } from '../../__styling'
import { pictureLayouts } from './layouts/layouts'
import {
    ImageDimensions,
    ImageSrcInfo,
    LazyLoadingPicture,
} from './LazyLoadingPicture'
import { ResponsivePictureSizes } from './responsive'
import { ResponsivePictureLayout } from './ResponsivePictureLayouts'
import { fixedRatioToBreakpointRatios, getCrops } from './utils'
import { FetchPriority } from './Image.web.types'

export interface ResponsivePictureProps {
    layout: ResponsivePictureLayout
    className?: string
    imageFetchPriority?: FetchPriority
    disableLazyLoad?: boolean
    image?: ImageSource
    imageWidths: ResponsivePictureSizes
    fixedRatio: FixedRatio | BreakpointRatios
    /**
     * Allows the selection of the original crop if it matches the
     * requested fixed ratio
     **/
    allowOriginalCrop?: boolean
    renderPicture?: (pic: React.ReactElement) => React.ReactElement
    imageDimensions?: ImageDimensions
    CSSCrop?: BreakpointState<ThemeConfig, ImageRatio>
    displayNoScriptImage?: boolean
}

export const WebPicture: React.FC<ResponsivePictureProps> = ({
    className,
    fixedRatio,
    image,
    imageWidths,
    renderPicture = (pic) => pic,
    layout,
    disableLazyLoad,
    imageFetchPriority,
    allowOriginalCrop = false,
    imageDimensions,
    CSSCrop,
    displayNoScriptImage,
}) => {
    const theme = useTheme()
    const useDoubleSizedImages = theme.kind === 'thenightly'
    const useNativeLazyLoading = useFeature('native-lazy-loading', true)

    const scaledImage: ImageSrcInfo = {
        srcCallback: scaleImage,
        initialWidth: imageWidths.fallbackWidth,
    }

    const breakpointRatios = fixedRatioToBreakpointRatios(fixedRatio)
    const breakpointCrops = getCrops(
        image?.crops,
        breakpointRatios,
        allowOriginalCrop,
    )
    const { renderTarget } = useContext(RenderTargetContext)

    if (!breakpointCrops) {
        return null
    }

    const layoutPictureStyles = pictureLayouts[layout].pictureStyles()

    return renderPicture(
        <ClassNames>
            {({ css, cx }) => (
                <LazyLoadingPicture
                    pictureClassName={cx(css(layoutPictureStyles), className)}
                    fixedRatio={fixedRatio}
                    image={image}
                    imageSizes={imageWidths}
                    srcInfo={scaledImage}
                    useNativeLazyLoading={useNativeLazyLoading}
                    disableLazyLoad={
                        disableLazyLoad || renderTarget === 'preview'
                    }
                    imageFetchPriority={imageFetchPriority}
                    breakpointCrops={breakpointCrops}
                    imageDimensions={imageDimensions}
                    CSSCrop={CSSCrop}
                    doubleSizedImages={useDoubleSizedImages}
                    displayNoScriptImage={displayNoScriptImage}
                />
            )}
        </ClassNames>,
    )
}

WebPicture.displayName = 'WebPicture'
