import styled from '@emotion/styled'
import { calcRem, zIndex } from '../../../__styling'
import { colorsPN } from '@news-mono/design-tokens'
import { PNCardOverlayButtonScale } from '../cards/PNArticleCard'
import { PNPlayButtonStatus } from './PNVideoPlayButton'

interface StyledPNVideoPlayButtonProps {
    overlayButtonScale: PNCardOverlayButtonScale
    isCentred?: boolean
    visible?: boolean
    status?: PNPlayButtonStatus
}

export const StyledPNVideoPlayButton =
    styled.button<StyledPNVideoPlayButtonProps>(
        ({
            overlayButtonScale = 'M',
            isCentred,
            visible,
            status = 'static',
        }) => {
            const iconStyle = getPlayIconStyle(overlayButtonScale)

            const positionStyle = isCentred
                ? {
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                  }
                : {
                      left: calcRem(iconStyle.marginLeft),
                      bottom: calcRem(iconStyle.marginBottom),
                  }
            return {
                visibility: visible ? 'visible' : 'hidden',
                opacity: visible ? 1 : 0,
                background:
                    visible && status !== 'static'
                        ? colorsPN.background.brand.strong
                        : colorsPN.fill.weak,
                transition: visible ? 'none' : 'all .5s ease-out',
                border: 'none',

                position: 'absolute',
                height: calcRem(
                    visible ? iconStyle.size : iconStyle.fadeOutSize,
                ),
                width: calcRem(
                    visible ? iconStyle.size : iconStyle.fadeOutSize,
                ),
                borderRadius: '50%',
                zIndex: zIndex.common.videoPlayButton,
                ...positionStyle,

                '&:hover': {
                    display: 'block',
                },

                svg: {
                    transform: `translateY(${calcRem(iconStyle.svgOffset)})`,
                    width: calcRem(iconStyle.svgWidth),
                },
            }
        },
    )

// To decide the button size defined here: https://www.figma.com/design/3veq2RFYCAfwUqTSUuHln8/PerthNow-Design-System?node-id=436-74788&node-type=frame&m=dev
function getPlayIconStyle(overlayButtonScale: PNCardOverlayButtonScale) {
    switch (overlayButtonScale) {
        case 'L':
            return {
                size: 64,
                fadeOutSize: 100,
                marginLeft: 21,
                marginBottom: 17,
                svgWidth: 32,
                svgOffset: 2,
            }
        case 'S':
            return {
                size: 30,
                fadeOutSize: 100,
                marginLeft: 8,
                marginBottom: 5,
                svgWidth: 16,
                svgOffset: -2,
            }
        default:
            return {
                size: 48,
                fadeOutSize: 100,
                marginLeft: 16,
                marginBottom: 13,
                svgWidth: 22,
                svgOffset: 2,
            }
    }
}
