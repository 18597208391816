import React from 'react'
import { SponsoredTopicProps } from '../../../advertising'
import { SectionHeaderNavLink } from '../../../collections'
import {
    SectionHeaderContainer,
    HeadingLink,
    HeadingWrapper,
    Heading,
    NavLinkWrapper,
    WebNavLink,
    LocalNavLink,
    NavLinkList,
    StyledNav,
} from './PNSectionHeader.styled'
import { IconNow } from '../../../icons/IconNow/IconNow'
import { metricsPN } from '@news-mono/design-tokens'
import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import { PNTopicAccordion } from '../navigation/PNTopicAccordion/PNTopicAccordion'
import { PNTopicAccordionProps } from '../navigation'

export type TopicPageType = 'primary' | 'secondary' | 'tertiary'

export interface PNSectionHeaderProps {
    // Functionality
    heading: string
    headingUrl?: string
    idTagName?: string
    onEvent?: (event: AllEvents) => void
    navLinks?: SectionHeaderNavLink[]
    // Determine h2 vs h3
    mainSection?: boolean
    // the 'Now' Logo
    hasHeaderLogo?: boolean
    // Show See All link
    showSeeAll?: boolean
    topicHeaderType?: TopicPageType
    accordionProps?: PNTopicAccordionProps
}

export const PNSectionHeader: React.FC<
    PNSectionHeaderProps & SponsoredTopicProps
> = ({
    heading,
    headingUrl,
    showSeeAll,
    idTagName,
    onEvent,
    navLinks,
    className,
    mainSection = false,
    hasHeaderLogo = false,
    topicHeaderType,
    accordionProps,
}) => {
    /**
     * Page header: h1
     * Section header: h2
     * Others: h3
     */
    const headingTag = topicHeaderType ? 'h1' : !mainSection ? 'h3' : undefined
    const showNavLinks =
        navLinks &&
        (typeof topicHeaderType === 'undefined' || // non-topic page
            topicHeaderType === 'primary') // primary topic page
    const showAccordion =
        topicHeaderType && accordionProps && topicHeaderType !== 'primary'

    return (
        <SectionHeaderContainer
            id={idTagName}
            className={className}
            marginBottom={
                mainSection ? metricsPN.spacing['L'] : metricsPN.spacing['S']
            }
            topicHeaderType={topicHeaderType}
        >
            {showAccordion && (
                <PNTopicAccordion
                    topics={accordionProps.topics}
                    perthNowSectionNavItems={
                        accordionProps.perthNowSectionNavItems
                    }
                    subTopicNavItems={accordionProps.subTopicNavItems}
                    topicHeaderType={topicHeaderType}
                    parentTopicId={accordionProps.parentTopicId}
                />
            )}
            {headingUrl ? (
                <HeadingWrapper>
                    <HeadingLink
                        to={headingUrl ?? ''}
                        openExternalInNewWindow={true}
                        onClick={() => {
                            onEvent &&
                                onEvent({
                                    type: DataLayerEventName.navClicked,
                                    originator: `${heading}.section.navLinks`,
                                    payload: {
                                        navName: `Default.PerthNow.Homepage.Section.Header`,
                                        navLocation: `Homepage.Section.Header`,
                                        navText: heading,
                                        navLink: headingUrl,
                                    },
                                })
                        }}
                        hideOnMobile={false}
                    >
                        <Heading
                            as={headingTag}
                            mainSection={mainSection}
                            isTopicPageHeader={!!topicHeaderType}
                        >
                            {heading}
                        </Heading>
                    </HeadingLink>
                    {showSeeAll && (
                        <HeadingLink
                            to={headingUrl ?? ''}
                            openExternalInNewWindow={true}
                            onClick={() => {
                                onEvent &&
                                    onEvent({
                                        type: DataLayerEventName.navClicked,
                                        originator: `${heading}.section.navLinks`,
                                        payload: {
                                            navName: `Default.PerthNow.Homepage.Section.SeeAll`,
                                            navLocation: `Homepage.Section.Header`,
                                            navText: 'See all',
                                            navLink: headingUrl,
                                        },
                                    })
                            }}
                            hideOnMobile={true}
                        >
                            See all
                        </HeadingLink>
                    )}
                </HeadingWrapper>
            ) : (
                <Heading
                    as={headingTag}
                    mainSection={mainSection}
                    hasLogo={hasHeaderLogo}
                    isTopicPageHeader={!!topicHeaderType}
                >
                    {heading}
                    {hasHeaderLogo && <IconNow />}
                </Heading>
            )}
            {showNavLinks && (
                <StyledNav>
                    <NavLinkList>
                        {navLinks.map((item, index) => {
                            const NavLink = item.isExternalLink
                                ? WebNavLink
                                : LocalNavLink

                            return (
                                <NavLinkWrapper key={index}>
                                    <NavLink
                                        to={item.link}
                                        onClick={() => {
                                            onEvent &&
                                                onEvent({
                                                    type: DataLayerEventName.navClicked,
                                                    originator: `${heading}.section.navLinks`,
                                                    payload: {
                                                        navName: `Default.PerthNow.Homepage.Section.SectionNav`,
                                                        navLocation: `Homepage.Section.Header`,
                                                        navText: item.text,
                                                        navLink: item.link,
                                                    },
                                                })
                                        }}
                                        forceInternalSSR={item.forceInternalSSR}
                                    >
                                        {item.text}
                                    </NavLink>
                                </NavLinkWrapper>
                            )
                        })}
                    </NavLinkList>
                </StyledNav>
            )}
        </SectionHeaderContainer>
    )
}

PNSectionHeader.displayName = 'PNSectionHeader'
