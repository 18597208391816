import { tokens } from '@news-mono/design-tokens'
import { TheNightlySections } from '@west-australian-newspapers/publication-types'
import { BaseSectionValues } from '.'

// If you ever need to add one-off 'section' types, add to this type.
export type TheNightlySection = keyof typeof TheNightlySections

export interface TheNightlySectionValues extends BaseSectionValues {
    backgroundColor: string
    numberColor: string
}

export type TheNightlySectionProps = {
    [section in TheNightlySection]: TheNightlySectionValues
}

const { section, uplate } = tokens.thenightly.colors.palette

export const theNightlySectionDefinitions: TheNightlySectionProps = {
    default: {
        primaryColor: uplate.default,
        numberColor: uplate.accent,
        backgroundColor: uplate.light,
    },
    australia: {
        primaryColor: section.australia.default,
        numberColor: section.australia[50],
        backgroundColor: section.australia[97],
    },
    opinion: {
        primaryColor: section.opinion.default,
        numberColor: section.opinion[50],
        backgroundColor: section.opinion[97],
    },
    world: {
        primaryColor: section.world.default,
        numberColor: section.world[50],
        backgroundColor: section.world[97],
    },
    politics: {
        primaryColor: section.politics.default,
        numberColor: section.politics[50],
        backgroundColor: section.politics[97],
    },
    business: {
        primaryColor: section.business.default,
        numberColor: section.business[50],
        backgroundColor: section.business[97],
    },
    sport: {
        primaryColor: section.sport.default,
        numberColor: section.sport[50],
        backgroundColor: section.sport[97],
    },
    society: {
        primaryColor: section.society.default,
        numberColor: section.society[50],
        backgroundColor: section.society[97],
    },
    culture: {
        primaryColor: section.culture.default,
        numberColor: section.culture[50],
        backgroundColor: section.culture[97],
    },
    travel: {
        primaryColor: section.lifestyle[30],
        numberColor: section.lifestyle[50],
        backgroundColor: section.lifestyle[97],
    },
    lifestyle: {
        primaryColor: section.lifestyle[30],
        numberColor: section.lifestyle[50],
        backgroundColor: section.lifestyle[97],
    },
    from: {
        primaryColor: section.from.default,
        numberColor: section.from[50],
        backgroundColor: section.from[97],
    },
    podcasts: {
        primaryColor: uplate.default,
        numberColor: uplate.accent,
        backgroundColor: uplate.light,
    },
    video: {
        primaryColor: uplate.default,
        numberColor: uplate.accent,
        backgroundColor: uplate.light,
    },
    get 'best-australian-yarn'() {
        return this.culture
    },
    get 'us-politics'() {
        return this.politics
    },
    get on() {
        return this.default
    },
}
