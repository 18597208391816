import React, { ReactNode } from 'react'
import {
    SponsoredTopic,
    SponsoredTopicProps,
} from '../../advertising/SponsoredTopic/SponsoredTopic'
import { SectionHeaderNavLink } from '../../collections'
import { IconArrowTopRight } from '../../icons/IconArrowTopRight/IconArrowTopRight'
import {
    Divider,
    HeadingWrapper,
    StyledChevronRight,
    Heading,
    HeadingLink,
    SponsorWrapper,
    LocalNavLink,
    NavLinkList,
    NavLinkWrapper,
    SectionHeaderContainer,
    WebNavLink,
    NavLinkDivider,
    ReadMoreWrapper,
    ReadMoreText,
} from '../../section-header/SectionHeader/SectionHeader.styled'
import { ConditionalWrapper } from '../../__helpers/ConditionalWrapper'
import { MarginProps, useComponentTheme } from '../../__styling'

export interface SectionHeaderProps {
    // Functionality
    heading: string
    headingUrl?: string
    navLinks?: SectionHeaderNavLink[]
    idTagName?: string
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    sectionText?: string

    // Margin
    hasBottomMargin?: boolean
    hasTopMargin?: boolean
    margin?: MarginProps

    // Styling
    colorOverride?: string

    // Controls
    showReadMore?: boolean
    showChevron?: boolean
    chevron?: ReactNode
    hideSponsorOnMobile?: boolean
    invisibleHeaderBreakpoint?: 'sm' | 'md' // Hides the section header visually but retains the space
    isStandalonePage?: boolean
}

export const SectionHeader: React.FC<SectionHeaderProps & SponsoredTopicProps> =
    ({
        heading,
        headingUrl,
        navLinks,
        idTagName,
        onClick,
        sectionText,

        hasBottomMargin,
        hasTopMargin,
        margin,

        colorOverride,

        showChevron,
        chevron = <StyledChevronRight />,
        showReadMore = true,
        hideSponsorOnMobile,
        invisibleHeaderBreakpoint,
        isStandalonePage,

        className,
        sponsor,
    }) => {
        const sectionHeaderTheme = useComponentTheme('sectionHeader')

        return (
            <SectionHeaderContainer
                id={idTagName}
                className={className}
                invisibleHeaderBreakpoint={invisibleHeaderBreakpoint}
                hasBottomMargin={hasBottomMargin}
                hasTopMargin={hasTopMargin}
                margin={margin}
            >
                <ConditionalWrapper
                    // Only wrap in a link if a URL is provided.
                    condition={headingUrl !== undefined}
                    Wrapper={({ children }) => (
                        <HeadingLink
                            to={headingUrl ?? ''}
                            openExternalInNewWindow={true}
                            onClick={onClick}
                        >
                            {children}
                        </HeadingLink>
                    )}
                >
                    <HeadingWrapper>
                        <Heading
                            as={isStandalonePage ? 'h1' : undefined}
                            colorOverride={colorOverride}
                        >
                            {sectionText && <p>{sectionText}</p>}
                            {heading}
                            {showChevron && chevron}
                        </Heading>
                        {sectionHeaderTheme.readMore && showReadMore && (
                            <ReadMoreWrapper>
                                <ReadMoreText>View all</ReadMoreText>
                                <IconArrowTopRight width={18} height={18} />
                            </ReadMoreWrapper>
                        )}
                        {sectionHeaderTheme.divider && <Divider />}
                    </HeadingWrapper>
                </ConditionalWrapper>
                {navLinks && (
                    <NavLinkList>
                        {navLinks.map((item, index) => {
                            const NavLink = item.isExternalLink
                                ? WebNavLink
                                : LocalNavLink

                            return (
                                <>
                                    <NavLinkWrapper key={index}>
                                        <NavLink
                                            to={item.link}
                                            onClick={onClick}
                                            colorOverride={colorOverride}
                                            forceInternalSSR={
                                                item.forceInternalSSR
                                            }
                                        >
                                            {item.text}
                                        </NavLink>
                                    </NavLinkWrapper>
                                    {index !== navLinks?.length - 1 && (
                                        // Place dividers between.
                                        <NavLinkDivider />
                                    )}
                                </>
                            )
                        })}
                    </NavLinkList>
                )}
                {sponsor && (
                    <SponsorWrapper hideOnMobile={hideSponsorOnMobile}>
                        <SponsoredTopic sponsor={sponsor} />
                    </SponsorWrapper>
                )}
            </SectionHeaderContainer>
        )
    }

SectionHeader.displayName = 'SectionHeader'
