import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling/Toggle/Toggle'
import { createRenderTarget } from '../../render-target'

export const LazyTaboola = loadable(() => import('./Taboola.web'))

const LazyAmpTaboola = loadable(() => import('./Taboola.amp'))

const TaboolaRender = createRenderTarget('Taboola', {
    web: LazyTaboola,
    amp: LazyAmpTaboola,
    rss: null,
    preview: null,
    app: LazyTaboola,
})

export interface TaboolaProps {
    taboolaContainerId: string
    locationType: TaboolaLocation
    ampPosition?: number
    isFourColumns?: boolean
    disableBreachAds?: boolean
}

export const SponsoredTaboola = (props: TaboolaProps) => (
    <FeatureToggle
        feature={'taboola'}
        on={() => <TaboolaRender {...props} />}
    />
)
